import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Map } from 'immutable';
import { updateContours, updateContourChoice, updateContoursGrid } from '../../actions/uitools';
import { setFetchingText, showLoading, hideLoading } from '../../actions'
import InterpolationWorker from './interpolation.worker.js';
// You only need the next two files if the one above is not working for you (if InterpolationWorker is not working)
//import worker from './webWorkerInterpolate.js';
//import WebWorker from '../../setupWebWorker';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/statBar.css';

class ContourToolComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      breaksValid: true,
      numberValid: true,
      breaksValue: props.contourBreaks.toString(),
      numberValue: props.nContours,
      type: 'number',
      showInfo: false
    }
  }

  componentDidMount = () => {
    this.interpolationWorker = new InterpolationWorker();
  }

  handleInfo(e) {
    this.setState({showInfo: !this.state.showInfo});
  }

  interpolationWebWorker(cellValue, weightValue, type) {
    this.interpolationWorker.postMessage({ event: 'interpolateSurface', gridType: 'points', cellSize: cellValue,  weight: weightValue, mapStat: this.props.radius, data: this.props.filteredLocations.toJS() });
    this.interpolationWorker.addEventListener("message", event => {
      var values;
      var breaks = [];
      if(type === 'number') {
        this.props.dispatch(updateContoursGrid(this.state.numberValue, [], event.data));
      } else {
        values = this.state.breaksValue.split(",");
        values.forEach(function(val){
          breaks.push(parseFloat(val, 10));
        })
        this.props.dispatch(updateContoursGrid(this.state.numberValue, breaks, event.data));
      }
      this.props.dispatch(hideLoading());
    });
  }

  handleUpdate(type) {
    // See if an interpolation grid exists first.
    // If it does not, create it before dispatching the contour changes.
    if(this.props.contourInterpolationGrid.size < 1) {
      this.props.dispatch(setFetchingText("Contouring..."));
      this.props.dispatch(showLoading());
      this.interpolationWebWorker(this.props.gridCellSize, this.props.distanceWeightingFactor, type);
    } else {
      var values;
      var breaks = [];
      if(type === 'number') {
        this.props.dispatch(updateContours(this.state.numberValue, []));
      } else {
        values = this.state.breaksValue.split(",");
        values.forEach(function(val){
          breaks.push(parseFloat(val, 10));
        })
        this.props.dispatch(updateContours(this.state.numberValue, breaks));
      }
    }
  }

  handleRadioChange(e) {
    this.setState({type: e.target.value})
    this.props.dispatch(updateContourChoice(e.target.value));
  }

  handleContourNumberChange(e) {
    var value = e.target.value;
    if(parseInt(+value, 10) > 2 && parseInt(+value, 10) < 21 && !isNaN(parseInt(+value, 10))) {
      this.setState({numberValid: true, numberValue: parseInt(+value, 10)})
    } else {
      this.setState({numberValid: false, numberValue: value})
    }
  }

  handleBreaksChange(e) {
    var value = e.target.value;
    var values = value.split(",");
    if(values.length < 21 && values.length > 2 && !values.some(isNaN)) {
      this.setState({breaksValid: true, breaksValue: value})
    } else {
      this.setState({breaksValid: false, breaksValue: value})
    }
  }

  render() {

    var breaks = this.state.breaksValue;
    var number = this.state.numberValue;

    return (
      <div style={{paddingTop: '5px', marginTop: '7px', borderTop: '1px solid'}}>
        <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='question-circle' size='lg' /></span>
        <h5>IDW Contour Layer</h5>
        {this.state.showInfo ? (
        <div className='toolInfo'>
          <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='times' size='lg' /></span>
          <p>This tool creates an interpolated grid in the background and creates contours based on the grid surface that was created. This grid and the resulting contours are calculated based on the currently chosen analyte and data from currently shown locations. The interpolation uses the user-chosen statistic (max, median, mean, etc.)
          at each shown location as the value inputs.</p>
          <p>The grid interpolation is done by inverse distance weighting (IDW). You can read more about that in the info section of the 'IDW Interpolation Grid' tool. The grid is used to perform a <a href="https://en.wikipedia.org/wiki/Marching_squares">marching squares
          algorithm</a> to determine if and where any of the contour levels occur. Essentially, for each contour level, each cell's interpolated value is compared to the value of the cell next to it. If the value of the contour level is crossed going between the two cells, the cell with the higher value is considered 'true' and the cell with the value below the contour level is considered 'false'.
          Cells are then assessed in groups of four - basically, a 2x2 grid. A part of a contour line is drawn in the 2x2 grid if it is composed of a mix of true and false cells. The position of that piece of the contour line is
          determined by the composition of the true and false values in the 2x2 grid. All of these line pieces are then merged into one contour line if possible and then a linear interpolation is applied to the vertices of the contour line using the original interpolated value of the cell the vertex is in. </p>
          <p>This tool allows you to either choose your own contour levels or to pick the number of contours you want to see. If you set the number of contours instead of the contour values themselves,
          a clustering algorithm is applied to group the data into n number of like clusters (as judged by the within-group sum-of-squared-deviations), where n is the number of contours you have chosen. The maximum value found in in each cluster is used as a contour level.</p>
          This method of contouring is quick, and is a good way to get an idea of what a surface might look like,
          but it is not a substitute for a targeted contouring effort.
        </div>) : (null) }
        <fieldset style={{paddingLeft: '8px'}} className="form-group" onChange={this.handleRadioChange.bind(this)}>
          <div className="form-check">
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="number" defaultChecked/>
              Change the number of contours created using the default contouring function.
            </label>
          </div>
          <div className="form-check">
          <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios2" value="breaks"/>
              Set contour levels manually.
            </label>
          </div>
        </fieldset>

        { this.state.type === 'number' ? (<div className="form-group has-danger">
          <label className="form-control-label">Set the Number of Contours</label>
          <input type="text" value={number} className={this.state.numberValid ? "form-control" : "form-control is-invalid"} onChange={this.handleContourNumberChange.bind(this)}/>
          { !this.state.numberValid ? (<div className="invalid-feedback">Please use an integer from 3 - 20.</div>) : null }
        </div>) : null }
        { this.state.type === 'breaks' ? (<div className="form-group has-danger">
          <label className="form-control-label">Set Contour Breaks</label>
          <input type="text" value={breaks} className={this.state.breaksValid ? "form-control" : "form-control is-invalid"} onChange={this.handleBreaksChange.bind(this)}/>
          { !this.state.breaksValid ? (<div className="invalid-feedback">Please use valid numbers in a comma-separated list of length at least 3 and no more than 20.</div>) : null }
        </div>) : null }

        { this.state.type === 'number' ? (<button disabled={!this.state.numberValid} type="submit" className={this.state.numberValid ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.handleUpdate.bind(this, 'number')}>Do Contours</button>) : null }
        { this.state.type === 'breaks' ? (<button disabled={!this.state.breaksValid} type="submit" className={this.state.breaksValid ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.handleUpdate.bind(this, 'breaks')}>Do Contours</button>) : null }

      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    contourInterpolationGrid: state.MapApp.get('contourInterpolationGrid'),
    gridCellSize: state.MapApp.get('gridCellSize'),
    distanceWeightingFactor: state.MapApp.get('distanceWeightingFactor'),
    filteredLocations: state.MapApp.get('filteredLocations'),
    radius: state.MapApp.get('radius'),
    nContours: state.MapApp.get('nContours'),
    contourBreaks: state.MapApp.get('contourBreaks'),
    useSetContourBreaks: state.MapApp.get('useSetContourBreaks')
  }
}

const ContourTool = connect(mapStateToProps)(ContourToolComp);

export default ContourTool;
