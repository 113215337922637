
const initialState = {
  'loggedIn':false,
  'account':{},
  'token':''
}
const LoginApp = (state = initialState, action) => {
  switch(action.type){
    case "LOGIN":

      var newState = Object.assign({}, state);
      newState.loggedIn = true;
      newState.account = action.account;
      newState.token = action.token;
      return newState;

    case "LOGOUT":
      console.log('LOGOUT')
      localStorage.clear()
      sessionStorage.removeItem('jwt')
      sessionStorage.clear()
      return initialState;

    default:
    return state;
  }
}

export default LoginApp;
