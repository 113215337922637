import {fromJS, Map, List} from 'immutable';
import moment from 'moment';
import numeral from 'numeral';

const isNumber = (str) => {
  return str !== null && str.length !== 0 && !isNaN(+str)
}

const isDate = (str) => {
  return str !== null && str.length !== 0
}

var interval;

const filterData = (state, action) => {
  switch(state.get('filterField')){
    case 'location':
      return state.get('locationFilter').length ? state.get('data').filter((row) => {
        return row.get('location').toLowerCase().includes(state.get('locationFilter').toLowerCase())
      }) : state.get('data')

    case 'result':
      interval = state.get('resultFilter')
      return state.get('data').filter((row) =>{
        return (!isNumber(interval[0]) || +row.get('result') >= +interval[0]) && (!isNumber(interval[1]) || +row.get('result') <= +interval[1])
      })

    case 'sample_date':
      interval = state.get('dateFilter')
      return state.get('data').filter((row) =>{
        return (!isDate(interval[0]) || moment(row.get('sample_date'), 'll').format() >= moment(interval[0], ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM/DD/YY', 'MM-DD-YY', 'YYYY-MM-DD', 'YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'MMM DD YYYY', 'MMMM DD YYYY', 'll', 'MMM YYYY', 'MMMM YYYY'], true).format() ) && (!isDate(interval[1]) || moment(row.get('sample_date'), 'll').format() <= moment(interval[1], ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM/DD/YY', 'MM-DD-YY', 'YYYY-MM-DD', 'YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'MMM DD YYYY', 'MMMM DD YYYY', 'll', 'MMM YYYY', 'MMMM YYYY'], true).format() )
      })
      //return state.get('dateFilter').length ? state.get('data').filter((row) => {
      //  return row.get('sample_date').toLowerCase().includes(state.get('dateFilter').toLowerCase())
      //}) : state.get('data')

    default:
      return state;
  }
}

const initialState = Map({
  'data': List(),
  'filteredData':List(),
  'locationFilter': '',
  'resultFilter': [null,null],
  'dateFilter': [null,null],
  //'dateFilter': '',
  'filter':'',
  'filterField':'location',
  "summaryTableSize":[1400,500],
  'showSummaryTable': false,
  'errorMessage': null
});

const DataView = (state = initialState, action) => {
  var newState, newRow, filtered;
  switch(action.type){

    case "API_ERROR":
      return state.set('errorMessage', action.message);

    case "DATA_ERROR":
      return state.set('errorMessage', action.message);

    case "CLEAR_ERROR_MESSAGE":
      return state.set('errorMessage', null);


    case "RECEIVE_DATA":

      var t1 = performance.now();
      var data = [];
      //var filteredData

      action.data.data.forEach(function(row) {
        row.lat = numeral(row.lat).format('0.000');
        row.long = numeral(row.long).format('0.000');
        row.sample_date = moment(row.sample_date).format('ll');
        data.push(row);
      })

      let dataList = fromJS(data)
      newState = state.set('data',dataList)
      let filteredData = filterData(newState,action)

      var t2 = performance.now();
      console.log('time to receive data in reducers/dataView.js: ' + (t2 - t1));
      //let data = fromJS(action.data.data);
      return state.set('data', dataList).set('filteredData', filteredData.map(row => {
        filtered = (row.get('filtered') === 'F' || row.get('filtered') === 'Y' || row.get('filtered') === 'D' || row.get('filtered') === 'DISSOLVED') ? 'Dissolved' : 'Total';
        newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water') ?
          row.get('matrix') + ' (' + filtered + ') ' + row.get('unit') :
          row.get('matrix') + ' ' + row.get('unit')
        );
        return newRow;
      })).set('errorMessage', null);


    case "FILTER_DATA_TABLE":
      console.log('FILTERING DATA TABLE')
      filteredData = state.get('data').filter((row) => {
        return row.get(state.get('filterField')).toLowerCase().includes(action.filter.toLowerCase())
      })

      return state.set('filteredData', filteredData.map(row => {
        filtered = (row.get('filtered') === 'F' || row.get('filtered') === 'Y' || row.get('filtered') === 'D' || row.get('filtered') === 'DISSOLVED') ? 'Dissolved' : 'Total';
        newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water') ?
          row.get('matrix') + ' (' + filtered + ') ' + row.get('unit') :
          row.get('matrix') + ' ' + row.get('unit')
        );
        return newRow;
      })).set('filter',action.filter).set('errorMessage', null);

    case "SELECT_DATA_FILTER":
      console.log('SELECTING DATA FILTER')
      newState = state.set('filterField', action.field);
      filteredData = filterData(newState,action)
      return newState.set('filteredData', filteredData.map(row => {
        filtered = (row.get('filtered') === 'F' || row.get('filtered') === 'Y' || row.get('filtered') === 'D' || row.get('filtered') === 'DISSOLVED') ? 'Dissolved' : 'Total';
        newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water') ?
          row.get('matrix') + ' (' + filtered + ') ' + row.get('unit') :
          row.get('matrix') + ' ' + row.get('unit')
        );
        return newRow;
      })).set('errorMessage', null);

    case "LOCATION_FILTER_DATA_TABLE":
      console.log('LOCATION FILTERING DATA TABLE')
      newState = state.set('locationFilter', action.filter)
      filteredData = filterData(newState, action)
      return newState.set('filteredData', filteredData.map(row => {
        filtered = (row.get('filtered') === 'F' || row.get('filtered') === 'Y' || row.get('filtered') === 'D' || row.get('filtered') === 'DISSOLVED') ? 'Dissolved' : 'Total';
        newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water') ?
          row.get('matrix') + ' (' + filtered + ') ' + row.get('unit') :
          row.get('matrix') + ' ' + row.get('unit')
        );
        return newRow;
      })).set('errorMessage', null);

    case "RESULT_FILTER_DATA_TABLE":
      console.log("RESULT_FILTER_DATA_TABLE")
      newState = state.set('resultFilter',action.filter)
      filteredData = filterData(newState,action)
      return newState.set('filteredData',filteredData.map(row => {
        filtered = (row.get('filtered') === 'F' || row.get('filtered') === 'Y' || row.get('filtered') === 'D' || row.get('filtered') === 'DISSOLVED') ? 'Dissolved' : 'Total';
        newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water') ?
          row.get('matrix') + ' (' + filtered + ') ' + row.get('unit') :
          row.get('matrix') + ' ' + row.get('unit')
        );
        return newRow;
      })).set('errorMessage', null);

    case "SET_DATE_FILTER":
      console.log('SETTING DATE FILTER')
      return state.set('dateFilter',action.filter);

    case "DATE_FILTER_DATA_TABLE":
      console.log("DATE_FILTER_DATA_TABLE")
      newState = state.set('dateFilter',action.filter)
      filteredData = filterData(newState,action)
      return newState.set('filteredData', filteredData.map(row => {
        filtered = (row.get('filtered') === 'F' || row.get('filtered') === 'Y' || row.get('filtered') === 'D' || row.get('filtered') === 'DISSOLVED') ? 'Dissolved' : 'Total'; 
        newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water') ?
          row.get('matrix') + ' (' + filtered + ') ' + row.get('unit') :
          row.get('matrix') + ' ' + row.get('unit')
        );
        return newRow;
      })).set('errorMessage', null);

    /* * * * * * * * * SUMMARY TABLE MODAL STUFF * * * * * * * * */
    /*case "TOGGLE_TABLE_MODAL":
      switch(action.table) {
        case 'summary':
          return state.set('showSummaryTable', !state.get('showSummaryTable'));
        default:
          return state;
      }*/

    case "RESIZE_TABLE_MODAL":
      switch(action.table) {
        case 'summary':
          return state.set('summaryTableSize', [action.width, action.height]);
        default:
          return state;
      }

    default:
      return state;
  }
}

export default DataView;
