import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClusterTool from './ClusterTool';
import GridTool from './GridTool';
import ContourTool from './ContourTool';
import HotSpotTool from './HotSpotTool';

class uiToolComp extends Component {
  render() {
    return(
      <div>
        {this.props.showHotSpot ? (<HotSpotTool/>) : null }
        {this.props.showClusters ? (<ClusterTool/>) : null }
        {this.props.showGrid ? (<GridTool/>) : null }
        {this.props.showContours ? (<ContourTool/>) : null }
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    showGrid: state.MapApp.get('showGrid'),
    showContours: state.MapApp.get('showContours'),
    showTin: state.MapApp.get('showTin'),
    showClusters: state.MapApp.get('showClusters'), 
    showHotSpot: state.MapApp.get('showHotSpot') 
  }
}

const UITool = connect(mapStateToProps)(uiToolComp);

export default UITool;
