import ReactGA from 'react-ga';
import Query from '../Query';
import fileDownload from 'js-file-download';
import json2csv from 'json2csv';
import {showLoading, hideLoading} from './index'

export const changeDataset = (value) => ({
  type: "CHANGE_QUERY_TYPE",
  value
})

export const toggleQueryFilter = (id) => ({
  type: "TOGGLE_QUERY_FILTER",
  id
})

export const toggleQueryField = (id) => ({
  type: "TOGGLE_QUERY_FIELD",
  id
})

export const dragQueryField = (id) => ({
  type: "DRAG_QUERY_FIELD",
  id
})

export const filterQueryData = () => ({
  type: "FILTER_QUERY_DATA"
})

export const getQueryFilters = () => ({
  type: "GET_QUERY_FILTERS"
})

export const clearQueryFilters = () => ({
  type: "CLEAR_QUERY_FILTERS"
})

export const selectQuery = (id) => ({
  type: "SELECT_QUERY",
  id
})

export const clearQuery = () => ({
  type: "CLEAR_QUERY"
})

export const searchFilters = (text) => ({
  type: "SEARCH_FILTERS",
  text
})

export const receiveDataNotified = () => ({
  type: "RECEIVE_DATA_NOTIFIED"
})

export const savedQueryNotified = () => ({
  type: "SAVED_QUERY_NOTIFIED"
})

export const downloadCSV = (query_id) => dispatch => {
  console.log("DOWNLOADING CSV");
  dispatch(showLoading())
  var t1 = performance.now();
  return Query.search('/api/get_csv/' + query_id).then( (results) => {
    if(results instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'There was an error downloading the CSV.'})
    } else {
      var timestamp = new Date().toDateString().replace(/ /g,"_");
      var queryname = results.name;
      if(results.data.length){
        var fields = Object.keys(results.data[0])
        var data = json2csv.parse(results.data, {fields: fields});
        queryname = results.name;
        fileDownload(data, queryname + '_' + timestamp + '.csv')
        dispatch(hideLoading())
      }
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Download CSV',
          variable: 'get',
          value: (performance.now() - t1), // in milliseconds
          label: 'Download CSV'
        });
      }
    }
  }).catch(error => {
    console.log(error);
    dispatch({ type: 'DATA_ERROR', message: 'There was an error processing the CSV data.'})
  });
}

export const createCSV = (query_id, query_type, query_name) => dispatch => {
  console.log("CREATING CSV");
  dispatch(showLoading())
  var t1 = performance.now();
  return Query.search('/api/get_csv/' + query_id + '/' + query_type + '/' + query_name).then( (result) => {
    if(result instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'There was an error downloading the CSV.'})
    } else {
      //var queryname = results.name;
      if(result.success){
        //var fields = Object.keys(results.data[0])
        //var data = json2csv({ data: results.data, fields: fields});
        //var queryname = results.name;
        //fileDownload(data,queryname + '.csv')
        dispatch(hideLoading())
      }
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Create CSV',
          variable: 'get',
          value: (performance.now() - t1), // in milliseconds
          label: 'Create CSV'
        });
      }
    }
  }).catch(error => {
    dispatch({ type: 'DATA_ERROR', message: 'There was an error processing the CSV data.'})
  });
}
