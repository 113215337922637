import { hideLoading } from './index'

/*export const updateInterpolationGrid = (cell, weight) => ({
  type:'CHANGE_INTERPOLATION_GRID',
  gridCellSize: cell,
  distanceWeightingFactor: weight
})*/

export const updateInterpolationGrid = (cell, weight) => dispatch => {
  // async/await
  /*dispatch(setFetchingText("Interpolating..."));
  dispatch(showLoading());
  //return async dispatch => {
  async dispatch => {
    try {
      await new Promise((resolve, reject) => setTimeout(() => resolve(), 2500));
      dispatch({type:'CHANGE_INTERPOLATION_GRID', gridCellSize: cell, distanceWeightingFactor: weight});
      dispatch(hideLoading());
      // return Promise.resolve(true)
    }
    catch(error) {
      console.log(error);
      dispatch(hideLoading());
      dispatch({ type: 'DATA_ERROR', message: "Interpolation Error: " + error})
      // return Promise.reject();
    }
  }*/

  // straight up promise
  var promise = new Promise((resolv, rejec) => setTimeout(() => resolv(true), 2500));
  promise.then(doit => {
    dispatch({type:'CHANGE_INTERPOLATION_GRID', gridCellSize: cell, distanceWeightingFactor: weight});
    dispatch(hideLoading());
  }).catch(error => {
    // TBD: Handle errors for Redux
    console.log(error);
  })
}

// redux-logic example...
/*export const myLogic = createLogic({
  process({action, getState}, dispatch, done) {
    myAsyncThunk('foo')(dispatch, getState)
    .then(result => dispatch(result))
    .then(() => dispatch(anyThingElseAsync('bar')))
    .then(() => done())
  }
};*/

/*export function updateInterpolationGrid(cell, weight, text) {
  return (dispatch, getState) => {
    Msg(text).then(interpolate(cell, weight).then(dispatch({ type: "HIDE_LOADING" })))
  }
}

async function Msg(text) {
  console.log('msg being called')
  return (dispatch, getState) => {
    dispatch({ type: "SET_FETCHING_TEXT", text: text });
    dispatch({ type: "SHOW_LOADING" });
  }
}

async function interpolate(cell, weight) {
  console.log('interpolate being called')
  return (dispatch, getState) => {
    dispatch({ type: "CHANGE_INTERPOLATION_GRID", gridCellSize: cell, distanceWeightingFactor: weight });
  }
}*/

export const updateInterpolationGridFromWorker = (cell, weight, grid) => ({
  type: 'CHANGE_INTERPOLATION_GRID_FROM_WORKER',
  gridCellSize: cell,
  distanceWeightingFactor: weight,
  interpolationGrid: grid
})

export const changeClusterNumber = (value) => ({
  type: 'CHANGE_NUMBER_OF_CLUSTERS',
  value
})

export const updateContours = (numbers, breaks) => ({
  type: 'CHANGE_CONTOURS',
  nContours: numbers,
  contourBreaks: breaks
})

export const updateContoursGrid = (numbers, breaks, grid) => ({
  type: 'CHANGE_CONTOURS_GRID',
  nContours: numbers,
  contourBreaks: breaks,
  contourInterpolationGrid: grid
})

export const updateContourChoice = (choice) => ({
  type:'CHANGE_CONTOUR_CHOICE',
  choice: choice
})

export const changeHotSpotUnit = (choice) => ({
  type:'CHANGE_HOT_SPOT_UNIT',
  unit: choice
})

export const updateHotSpotRadius = (value) => ({
  type:'UPDATE_HOT_SPOT_RADIUS',
  radius: value
})
