import { combineReducers } from 'redux';
//import filters from './filters'
import data from './data';
import map_data from './map_data';
import MapApp from './map';
import LoginApp from './login';
import MapStats from './mapStats';
import DataView from './dataView';
import QueryApp from './query';
import CasIds from './casids';
import StandardLocations from './standardLocations';
import PiperApp from './piperPlot';

import app_styles from '../css/index.css';

import { GET_DATA, GET_QUERIES, GET_QUERY_FILTERS, GET_RECORDS, SAVING_QUERY, RECEIVE_DATA, RECEIVE_QUERIES, RECEIVE_RECORDS, RECEIVE_QUERY, SAVED_QUERY, RECEIVE_QUERY_FILTERS, SET_FETCHING_TEXT, SHOW_LOADING, HIDE_LOADING } from '../actions';

const appEnvState = {
  'site': process.env.REACT_APP_SITE || 'bmi',
  'mode' : process.env.REACT_APP_ENV || 'staging'
}

function appEnv(state=appEnvState, action) {
  return state;
}

function isFetching(state = false, action) {
  switch (action.type) {
    case GET_DATA:
      return true;
    case GET_QUERY_FILTERS:
      return true;
    case GET_QUERIES:
      return true;
    case SAVING_QUERY:
      return true;
    case RECEIVE_DATA:
      return true;
    case SAVED_QUERY:
      return false;
    case RECEIVE_QUERIES:
      return false;
    case RECEIVE_QUERY:
      return false;
    case RECEIVE_QUERY_FILTERS:
      return false;
    case 'CALCULATE':
      return true;
    case 'CALCULATE_DONE':
      return false;
    /*case GET_RECORDS:
      return true;
    case RECEIVE_RECORDS:
      return false;*/
    case SHOW_LOADING:
      return true;
    case HIDE_LOADING:
    case 'API_ERROR':
    case 'DATA_ERROR':
      return false;
    default:
      return state;
  }
}

function fetchingText(state="Loading...", action){
  switch(action.type){
    case SET_FETCHING_TEXT:
      return action.text
    default:
      return state;
  }
}

function isFetchingQueryFilters(state = false, action) {
  switch (action.type) {
    case GET_QUERY_FILTERS:
      return true;
    case RECEIVE_QUERY_FILTERS:
    case 'API_ERROR':
    case 'DATA_ERROR':
      return false;
    default:
      return state;
  }
}

function isFetchingQueries(state = false, action) {
  switch (action.type) {
    case GET_QUERIES:
      return true;
    case 'API_ERROR':
    case 'DATA_ERROR':
    case RECEIVE_QUERIES:
      return false;
    default:
      return state;
  }
}

function isFetchingRecords(state = false, action) {
  switch (action.type) {
    case GET_RECORDS:
      return true;
    case RECEIVE_RECORDS:
    case 'API_ERROR':
    case 'DATA_ERROR':
      return false;
    default:
      return state;
  }
}

const templateInitialState = {
  'height': window.innerHeight,
  'width' : window.innerWidth,
  'navHeight': app_styles.navHeight,
  'compHeight': window.innerHeight - app_styles.navHeight
}

function Template(state=templateInitialState,action){
  switch (action.type) {
    case "SCREEN_RESIZE":
      var newState = Object.assign({},state)
      newState.height = window.innerHeight
      newState.width = window.innerWidth
      newState.compHeight = window.innerHeight - state.navHeight
      return newState

    default:
      return state
  }
}

//let initialOverviewState = {news:'', perchlorate: null}
function Overview(state={news:'', perchlorate: [null]},action){
  var newState;
  switch (action.type) {
    case "RECEIVE_NEWS":
      newState = Object.assign({},state)
      newState.news = action.data
      console.log(action)
      return newState

    case "RECEIVE_HISTORY":
      newState = Object.assign({},state)
      newState.perchlorate = action.data
      //console.log(action.data)
      return newState

    default:
      return state
  }
}

const dataApp = combineReducers({
  appEnv, data, map_data, isFetching, fetchingText, isFetchingQueries, isFetchingQueryFilters, isFetchingRecords, MapApp, MapStats, LoginApp, DataView, QueryApp, CasIds, StandardLocations, PiperApp, Template, Overview
});

const rootReducer = (state, action) => {

  if (action.type === 'LOGOUT') {
    console.log('RESETTING ALL REDUCERS')
    state = undefined
  }
  return dataApp(state, action)
}

export default rootReducer;
