//      
import React, { Component,                            } from 'react';

import { className } from '../utils';
import { Div } from '../primitives';
import { spacing } from '../theme';
import {                                                 } from '../types';

// ==============================
// Root Container
// ==============================

                                                              
                                       
                    
                   
                                                    
    
export const containerCSS = ({ isDisabled, isRtl }                ) => ({
  direction: isRtl ? 'rtl' : null,
  pointerEvents: isDisabled ? 'none' : null, // cancel mouse events when disabled
  position: 'relative',
});
export const SelectContainer = (props                ) => {
  const { children, getStyles, innerProps, isDisabled, isRtl } = props;
  return (
    <Div
      css={getStyles('container', props)}
      className={className('container', { isDisabled, isRtl })}
      {...innerProps}
    >
      {children}
    </Div>
  );
};

// ==============================
// Value Container
// ==============================

                                              
                   
                    
                    
                 
  
export const valueContainerCSS = ({ maxHeight }                     ) => ({
  alignItems: 'center',
  display: 'flex ',
  flex: 1,
  flexWrap: 'wrap',
  maxHeight: maxHeight, // max-height allows scroll when multi
  overflowY: 'auto',
  padding: `${spacing.baseUnit / 2}px ${spacing.baseUnit * 2}px`,
  position: 'relative',
});
export class ValueContainer extends Component                      {
  shouldScrollBottom          = false;
                    
  componentWillUpdate() {
    if (!this.props.isMulti) return;

    // scroll only if the user was already at the bottom
    const total = this.node.scrollTop + this.node.offsetHeight;
    this.shouldScrollBottom = total === this.node.scrollHeight;
  }
  componentDidUpdate() {
    if (!this.props.isMulti) return;

    // ensure we're showing items being added by forcing scroll to the bottom
    if (this.shouldScrollBottom && this.node) {
      this.node.scrollTop = this.node.scrollHeight;
    }
  }
  getScrollContainer = (ref               ) => {
    this.node = ref;
  };
  render() {
    const { children, isMulti, getStyles, hasValue } = this.props;

    return (
      <Div
        innerRef={isMulti ? this.getScrollContainer : undefined}
        className={className('value-container', { isMulti, hasValue })}
        css={getStyles('valueContainer', this.props)}
      >
        {children}
      </Div>
    );
  }
}

// ==============================
// Indicator Container
// ==============================

                                          
                                                                              

export const indicatorsContainerCSS = () => ({
  alignItems: 'center',
  alignSelf: 'stretch',
  display: 'flex ',
  flexShrink: 0,
});
export const IndicatorsContainer = (props                 ) => {
  const { children, getStyles } = props;

  return (
    <Div
      className={className('indicators')}
      css={getStyles('indicatorsContainer', props)}
    >
      {children}
    </Div>
  );
};
