import React, { Component } from 'react';
import * as d3 from 'd3';
import styles from '../../css/statBar.css';
import {chooseHistogram} from '../../actions/map';
import * as _ from 'lodash';

class ResultHistogram extends Component {

  constructor(props){
    super(props);
    this.svgHeight = 225//this.props.template.height;
    this.svgMargin = 15;
    this.svgWidth = this.props.template.width;
    this.minBarHeight = 10;
    this.scaleHeight = 30;
    this.barColor = styles.barColor;
    this.activeColor = styles.barActiveColor;
  }

  componentDidMount() {
    this.renderHistogram();
  }

  componentDidUpdate() {
    this.renderHistogram();
  }

  clickBar(selection, rect){
    var selectionStarts = selection.map(x => x[0])
    var selectionEnds = selection.map(x => x[1])
    /*if(selection[0] === rect.x0){
      this.props.dispatch(chooseHistogram(null, null));
    } else {
      if(this.props.log) {
        this.props.dispatch(chooseHistogram(d3.format(".2")(Math.exp(rect.x0)), d3.format(".2")(Math.exp(rect.x1))));
      } else {
        this.props.dispatch(chooseHistogram(rect.x0, rect.x1));
      }
    }*/
    if (selectionStarts.indexOf(rect.x0) >= 0) {
      this.props.dispatch(chooseHistogram(selectionStarts.indexOf(rect.x0), null, null))
    } else if (selectionEnds.indexOf(rect.x1) >= 0) {
      this.props.dispatch(chooseHistogram(selectionEnds.indexOf(rect.x1), null, null))
    } else {
      if (this.props.log) {
        this.props.dispatch(chooseHistogram(null, +d3.format('.2')(Math.exp(rect.x0)), +d3.format('.2')(Math.exp(rect.x1))))
      } else {
        this.props.dispatch(chooseHistogram(null, rect.x0, rect.x1))
      }
    }
  }

  renderHistogram() {

    d3.selectAll('.histBar').remove();
    d3.selectAll('.histAxis').remove();
    d3.selectAll('.resultHistLabel').remove();

    if(!this.props.data.length) return;
    var min = this.props.min,
        max = this.props.max,
        data = this.props.data,
        plotHeight = this.svgHeight - 2*this.svgMargin - this.scaleHeight,
        marginTop = this.svgMargin,
        plotWidth = this.svgWidth - 2*this.svgMargin,
        minBarHeight = this.minBarHeight,
        selection;

    if(this.props.log) {
      // selection = [+d3.format(".2")(Math.log(this.props.selection[0])), +d3.format(".2")(Math.log(this.props.selection[1]))];
      selection = this.props.selection.map(h => [+d3.format('.2')(Math.log(h[0])), +d3.format('.2')(Math.log(h[1]))]);
    } else {
      selection = this.props.selection;
    }

    var x = d3.scaleLinear()
      .range([0, plotWidth])
      .domain([min, (max + 1)])
      .nice();
    var y = d3.scaleLinear()
      .range([0, plotHeight])

    var xAxis = d3.axisBottom(x)
    //.tickFormat(d3.format(".2s"))
    .tickFormat((d) => this.props.log ? d3.format(".2s")(Math.exp(d)) : d3.format(".2s")(d));

    var svg = d3.select(this.refs.resultHistogram);
    var g = svg.select('.histogram');
    var histogram = d3.histogram()
      .domain(x.domain())
      .thresholds(x.ticks(10))

    var bins = histogram(data);

    //y.domain([0, this.props.maxRange]); // old
    y.domain([0, _.chain(bins).maxBy(_.size).size().value()]);

    var bar = g.selectAll(".histBar")
      .data(bins)
      .enter().append("g")
      .attr("class", "histBar")
      .attr("transform", function(d) {
        return "translate(" + x(d.x0) + "," + (plotHeight - d3.max([y(d.length), minBarHeight]) + marginTop) + ")"; });

    bar.append("rect")
      .attr("x", 1)
      // .attr("fill", function(d){ return d.x0 === selection[0] ? this.activeColor : this.barColor }.bind(this))
      .attr('fill', function (d) { return selection.map(h => h[0]).indexOf(d.x0) >= 0 || selection.map(h => h[1]).indexOf(d.x1) >= 0 ? this.activeColor : this.barColor }.bind(this))
      .attr("width", x(bins[0].x1) - x(bins[0].x0) - 1)
      .attr("height", function(d) { return d.length ?  d3.max([y(d.length), minBarHeight]) : 0 })
      .style('cursor','pointer')
      .on("click", this.clickBar.bind(this,selection));

    bar.append("text")
      .attr("dy", ".75em")
      .attr("y", -12)
      .attr("x", (x(bins[0].x1) - x(bins[0].x0)) / 2)
      .attr("text-anchor", "middle")
      .text(function(d) { return d.length ? d.length : ""; });

    var gAxis = svg.select('.axisContainer');
    gAxis.append('g')
      .attr('class', 'histAxis')
      .call(xAxis);

    svg.append("text")
          .attr("x", this.svgWidth / 2)
          .attr("y", this.svgHeight - 10)
          .attr("class","resultHistLabel")
          .style('text-anchor','middle')
      .text(this.props.label);
  }

  render(){
    return (
      <svg
        className="resultHistogram"
        ref='resultHistogram'
        style={{ width:this.svgWidth, height:this.svgHeight }}
       >
        <g
          className="histogram"
          ref='histogram'
          transform="translate(15, 0)"
        ></g>
        <g
          className="axisContainer"
          ref='axis'
          transform={"translate(15, " + (this.svgHeight - (this.scaleHeight + this.svgMargin)) +")"} style={{background:"#ddd"}}
        ></g>
      </svg>
    )
  }
}

export default ResultHistogram;
