import Query from '../Query';

export const receiveStandardLocations = (data) => ({
  type: "RECEIVE_STANDARD_LOCATIONS",
  data
})

export const searchStandardLocations = (value) => ({
  type: "SEARCH_STANDARD_LOCATIONS",
  value
})

export const updateStandardLocationsSearch = (value) => ({
  type: "UPDATE_STANDARD_LOCATIONS_SEARCH",
  value
})

export const fetchStandardLocations = (text) => dispatch => {
  console.log("FETCHING STANDARD LOCATIONS")
  return Query.search('/api/standard_locations').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve the standard locations from the database.'})
    } else {
      dispatch(receiveStandardLocations(data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing standard locations.'})
  })
}
