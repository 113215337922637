/*
 * Register.js
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/login.css';
import { Redirect, Link } from 'react-router-dom'
import Query from '../../Query';
import { doLogin } from '../../actions/login'

class Register extends Component {

  componentWillMount(){
    this.setState({'showLogin': false}); //don't show login form until we check for a jwt
    this.setState({'error':'.'})
    if(process.env.NODE_ENV === "development"){
      this.setState({email:'test',password:'test',repeat_password:'test'})
    }
  }


  fetch(path,body = "",callback){

    //const query_base_url = process.env.NODE_ENV === "development" ? "" : "";
    var jwt = sessionStorage.getItem('jwt');
    fetch('/api' + path, {
      accept:'application/json',
      method: 'post',
      headers: {
        "Authorization": "Bearer " + jwt,
        "Content-Type": "application/json"
      },
      body: body
    }).then(this.checkStatus)
      .then(this.parseJSON)
      .then(callback)
      .catch(callback)}

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    console.log("LOGIN COMP")
    throw error;
  }

  onFormChange(e){
    var state = {};
    state[e.target.id] = e.target.value;
    this.setState(state);
  }

  handleRegister(e){
    e.preventDefault();
    let self = this

    let email = this.state.email
    let pw = this.state.password
    let rpw = this.state.repeat_password
    let fn = this.state.firstname
    let ln = this.state.lastname

    if(!email || !pw || !rpw || !fn || !ln){
      this.setState({'error':'All fields are required.'})
    } else if( pw !== rpw) {
      this.setState({'error':'Passwords do not match.'})
    } else {
      console.log("ALL GOOD")
      var account = {
        'email':encodeURIComponent(email),
        'firstname':encodeURIComponent(fn),
        'lastname':encodeURIComponent(ln),
        'password':encodeURIComponent(pw)
      }

      Query.post('/api/register',account).then( (data) => {
        console.log(data)
        if(data.status === 200){
            var text = JSON.parse(data.text);
            var account = text.account;
            var token = text.token;
            sessionStorage.setItem('jwt',token)
            sessionStorage.setItem('username',account.username)
            sessionStorage.setItem('email',account.email)
            sessionStorage.setItem('firstname',account.firstname)
            sessionStorage.setItem('lastname',account.lastname)
            sessionStorage.setItem('query_id',null)
            sessionStorage.setItem('analyte_id',null)
            sessionStorage.setItem('user_id',account.user_id)

            self.props.dispatch(doLogin(account, token));

        } else {
          this.setState({'error':data.text})
        }

      })
    }
  }

  render(){

    return this.props.loggedIn ? (
      <Redirect to='/' />
    ) : (
      <div className='loginContainer'>
        <div className='loginBox registerBox' style={{marginTop:(window.innerHeight - 400)/2}}>
          <form onSubmit={this.handleRegister.bind(this)} className='form-horizontal'>
          <h3>Register</h3>
          <div className='error alert alert-warning' style={{'visibility': this.state.error.length > 1? "visible" : "hidden"}}>{this.state.error}</div>
          <div className="form-group row">
            <label htmlFor='email' className='col-sm-3 control-label'>Email:</label>
            <div className='col-sm-9'>
              <input autoFocus ref='email' onChange={this.onFormChange.bind(this)} id='email' type="text" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor='firstname' className='col-sm-3 control-label'>First Name:</label>
            <div className='col-sm-9'>
              <input autoFocus ref='firstname' onChange={this.onFormChange.bind(this)} id='firstname' type="text" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor='lastname' className='col-sm-3 control-label'>Last Name:</label>
            <div className='col-sm-9'>
              <input autoFocus ref='lastname' onChange={this.onFormChange.bind(this)} id='lastname' type="text" className="form-control" />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor='password' className='col-sm-3 control-label'>Password:</label>
            <div className='col-sm-9'>
              <input ref='password' onChange={this.onFormChange.bind(this)} id='password' type="password" className="form-control"/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor='repeat_password' className='col-sm-3 control-label'>Repeat Password:</label>
            <div className='col-sm-9'>
              <input ref='repeat_password' onChange={this.onFormChange.bind(this)} id='repeat_password' type="password" className="form-control"/>
            </div>
          </div>
          <div className="form-group row">
            <div className='col-sm-3' style={{float:'right'}}>
              <button onClick={this.handleRegister.bind(this)} className='form-control btn btn-primary'>Register</button>
            </div>
          </div>
          <div className="form-group row">
            <div className='col-sm-12' style={{float:'right'}}>
              I have an account<Link to='/login' >login</Link>
            </div>
            <div className='col-sm-12' style={{float:'right'}}>
              I forget my password,<Link to="/new_password">click to recover.</Link>
            </div>
          </div>
        </form>
       </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
 return {'loggedIn':state.LoginApp.loggedIn};
}

export default connect(mapStateToProps)(Register);
