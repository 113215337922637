import React, { Component } from 'react';
import * as d3 from 'd3';
//import styles from '../../css/map.css';
//import * as _ from 'lodash';
// used https://bl.ocks.org/john-guerra/17fe498351a3e70929e2e36d081e1067

class LegendScale extends Component {

  componentDidMount() {
    this.renderLegendScale();
  }

  componentDidUpdate() {
    this.renderLegendScale();
  }

  renderLegendScale() {

    //Continuous color scale
    let z = this.props.fill;

    let min = this.props.min;
    let max = this.props.max;

    let margin = {
      top: 10,
      right: 50,
      bottom: 10,
      left: 10
    };

    let width = this.props.width;
    let height = this.props.height;

    d3.selectAll('.legendColors').remove();
    d3.selectAll('.legendAxis').remove();

    //Translucent svg on top to show the axis
    var svg = d3.select(this.refs.legendScale);

    var y = d3.scaleLinear().domain([min, max]).range([(height - margin.bottom), margin.top]);

    // Let's add the colors
    var bar = svg.append("g")
      .attr("class", "legendColors")
      .attr("transform", "translate(0," + (margin.top / 2) + ")");
    
    // Let's add an axis
    svg.append("g")
      .attr("class", "axis legendAxis")
      .attr("width", 90)
      .attr("height", 230)
      .attr("transform", "translate(" + (((width - margin.right) / 2) + 5) + "," + (margin.top / 2) + ")")
      .call(d3.axisRight(y));
    
    var scaleHeight = (height - margin.bottom) - margin.top;
    var increment = (max - min) / scaleHeight
    // now fill the legend with the scale
    d3.range(min, max, increment)
      .forEach(function (d) {

        bar.append('line')
          .attr('x1', margin.left)
          .attr('x2', 50)
          .attr('y1', y(d))
          .attr('y2', y(d))
          .attr('stroke-width', 1)
          .attr('stroke', 'rgba(54,69,79,' + z(d) + ')');
      });
  }

  render(){
    return (
      <div style={{ position: 'relative', height: this.props.height }}>      
        <svg
          className="legendScale"
          ref='legendScale'
          style={{ width: this.props.width, height: this.props.height, position: 'absolute', left: 0, top: 0 }}
         >
        </svg>
      </div>
    )
  }
}

export default LegendScale;
