import { fromJS, List, Map } from 'immutable';
import numeral from 'numeral';

const turnOnField = (state, action_id) => {
  var newState = Map();
  var newerState = Map();
  var id = state.get('datasetFilterFields').findIndex(function(field) {
    return field.get("id") === action_id
  });
  var column = state.get('datasetFilterFields').get(id).get('column');
  //console.log(column)
  newState = state.update( // turn all fields off
    'datasetFilterFields',
    filterFields => filterFields.map(field => field.update(
      'active',
      active => false
    ))
  ).update(  // turn all filters off
    'datasetFilters',
    filters => filters.map(filter => filter.update(
      'visible',
      visible => false
    ))
  ).update(  // turn associated filters on, or else turn them off
    'datasetFilters',
    filters => filters.map(filter =>
      {
        if(filter.get('field_id') === action_id) {
          return filter.update(
            'visible',
            visible => true
          )
        } else {
          return filter.update(
            'visible',
            visible => false
          )
        }
      }
    )
  ).setIn(['datasetFilterFields', id, 'active'], true); // set active field on
  // Now check if the toggled field's column is 'analyte' or 'analyte_subtype' and if so, filter to only ones that fit the selected analyte_subtypes and/or analyte_types
  if(column === 'analyte_subtype' && newState.get('selectedAnalyteTypes').size > 0) {
    // turn off subtypes that aren't associated with the selected types
    // & because not all subtypes are associated with a type, need to include NULL if none found.
    var filters_visible = []
    newerState = newState.update('datasetFilters',
      filters => filters.map(filter => {
        //console.log(filter.get('field_id') === action.id)
          if(filter.get('field_id') === action_id) {
            if(!newState.get('selectedAnalyteTypes').includes(filter.get('analyte_type'))) {
              return filter.update('visible', visible => false)//.update('active', active => false)
            } else {
              filters_visible.push(1);
              return filter;
            }
          } else {
            return filter;
          }
        }
      )
    )
    //console.log(filters_visible)
    if(filters_visible.length < 1) {
      return newerState.update('datasetFilters',
        filters => filters.map(filter => {
          //console.log(filter.get('field_id') === action.id)
            if(filter.get('field_id') === action_id && filter.get('filter') === 'Null') {
              return filter.update('visible', visible => true)//.update('active', active => false)
            } else {
              return filter;
            }
          }
        )
      )
    } else {
      return newerState;
    }
  } else if(column === 'analyte_name' && (newState.get('selectedAnalyteTypes').size > 0 || newState.get('selectedAnalyteSubTypes').size > 0)) {
    if(newState.get('selectedAnalyteTypes').size > 0 && newState.get('selectedAnalyteSubTypes').size < 1) {
      return newState.update('datasetFilters',
        filters => filters.map(filter => {
            if(filter.get('field_id') === action_id && !newState.get('selectedAnalyteTypes').includes(filter.get('analyte_type'))) {
              return filter.update('visible', visible => false )
            } else {
              return filter//.update('active', active => false)
            }
          }
        )
      )
    } else if(newState.get('selectedAnalyteTypes').size < 1 && newState.get('selectedAnalyteSubTypes').size > 0) {
      return newState.update('datasetFilters',
        filters => filters.map(filter => {
            if(filter.get('field_id') === action_id && !newState.get('selectedAnalyteSubTypes').includes(filter.get('analyte_subtype'))) {
              return filter.update('visible', visible => false )
            } else {
              return filter;//.update('active', active => false)
            }
          }
        )
      )
    } else {
      return newState.update('datasetFilters',
        filters => filters.map(filter => {
            if(filter.get('field_id') === action_id && newState.get('selectedAnalyteTypes').includes(filter.get('analyte_type')) && newState.get('selectedAnalyteSubTypes').includes(filter.get('analyte_subtype'))) {
              return filter;
            } else {
              return filter.update('visible', visible => false)//.update('active', active => false)
            }
          }
        )
      )
    }
  } else {
    return newState;
  }
}

/* * * * * * * * * QueryApp Reducer * * * * * * * * */
let initialState = Map({
  "queryTypes": List(),
  "activeQueryType": 'BMI',
  "filterFields": Map(),
  "datasetFilterFields": List(),
  "activeFilterFields": List(),
  "filters": Map(),
  "datasetFilters": List(),
  "activeFilters": Map(),
  "queries": List(),
  "selectedQuery": Map(), //the query a user has selected
  "activeQuery":Map(),    //filled when data are returned from the server
  "records": 0,
  "includedFilters": List(),  // TODO - check that this works with datasets
  "receivedData": false,
  "savedQuery": false,
  "errorMessage": null,
  "selectedAnalyteTypes": List(),
  "selectedAnalyteSubTypes": List()
});

const QueryApp = (state = initialState, action) => {

  var newState = List();
  var newerState = List();
  var nextState = List();
  var id, currentVal;

  switch(action.type) {

/* * * * * * * * * ACTION RECEIVE QUERY FILTERS * * * * * * * * */
    case "API_ERROR":
      return state.set('errorMessage', action.message);

    case "DATA_ERROR":
      return state.set('errorMessage', action.message);

    case "CLEAR_ERROR_MESSAGE":
      return state.set('errorMessage',null);

    case "RECEIVE_DATA":
      var activeQuery = state.get('queries').filter((row) => row.get('id') === +action.text ).get(0)
      if(typeof(activeQuery) === 'undefined'){
        activeQuery = Map()
      }
      return state.set('receivedData', true).set('activeQuery',activeQuery).set('errorMessage', null);

    case "RECEIVE_DATA_NOTIFIED":
      return state.set('receivedData', false);

    case "CHANGE_QUERY_TYPE":
    console.log(action.value)
      return state.set('activeQueryType', action.value)
                  .set('datasetFilterFields', state.get('filterFields').get(action.value))
                  .set('activeFilterFields', Map())
                  .set('datasetFilters', state.get('filters').get(action.value))
                  .set('activeFilters', List())
                  .set('selectedAnalytetypes', List())
                  .set('selectedAnalyteSubTypes', List())

    case "RECEIVE_QUERY_TYPES":
      return state.set('queryTypes', fromJS(action.data).sortBy(type => type.get('id')));

    case "SAVED_QUERY":
      return state.set('savedQuery', true);

    case "DELETE_QUERY":
      if( state.getIn(['activeQuery','id']) === action.id){
        console.log("Deleted query is active... unsetting.")
        return state.set('activeQuery',Map()).set('receivedData',false)
      } else {
        return state
      }

    case "SAVED_QUERY_NOTIFIED":
      return state.set('savedQuery', false);

    case "RECEIVE_QUERY_FILTERS":
      let newData = fromJS({
        "filterFields": {'BMI': action.data.query_fields, 'SNWA': action.data.query_fields_snwa, 'WL': action.data.query_fields_wl },
        "datasetFilterFields": action.data.query_fields,
        "activeFilterFields": {},
        "filters": {'BMI': action.data.query_filters, 'SNWA': action.data.query_filters_snwa, 'WL': action.data.query_filters_wl },
        "datasetFilters": action.data.query_filters,
        "activeFilters": []
      });
      return state.merge(newData).set('errorMessage', null);

    case "RECEIVE_QUERIES":
      var queries = [];
      action.data.data.forEach(function(value) {
        value.visible = false;
        queries.push(value);
      })
      queries.sort(function compare(a, b){
        var dateA = new Date(a.pull_date);
        var dateB = new Date(b.pull_date);
        return dateA - dateB;
      })
      let newDataset = fromJS({
        "queries": queries
      });
      return state.merge(newDataset).set('errorMessage', null);

    case "RECEIVE_RECORDS":
      let newRecordset = fromJS({
        "records": numeral(action.data.records).format('0,0'),
        "includedFilters": action.data.includedFilters
      });
      newState = state.merge(newRecordset);
      newerState = newState.update(
          'datasetFilters',
          filters => filters.map(filter =>
            {
              id = newState.get('includedFilters').findIndex(function(incFilter) {
                return incFilter.get("field") === filter.get('field') && incFilter.get("filter") === filter.get("filter")
              });
              if(id > -1) {
                return filter.update(
                  'included',
                  included => true
                )
              } else {
                return filter.update(
                  'included',
                  included => false
                )
              }
            }
          )
        )
      return newerState.set('activeFilters', newerState.get('datasetFilters').filter(filter => filter.get('active') === true)
             .groupBy(filter => filter.get('title')) ).set('errorMessage', null);


  /* * * * * * * * * FILTER ACTIONS * * * * * * * * */
    case "TOGGLE_QUERY_FIELD":
      id = state.get('datasetFilterFields').findIndex(function(field) {
        return field.get("id") === action.id
      });
      //var column = state.get('filterFields').get(id).get('column');
      currentVal = state.get('datasetFilterFields').get(id).get('active');
      if(!currentVal) {  // if this toggle is turning it 'on', turn all others off and then turn it on
        return turnOnField(state, action.id)
      } else { // just turn it off (and the associated filters)
        return state.update(  // turn all filters off
          'datasetFilters',
          filters => filters.map(filter => filter.update(
            'visible',
            visible => false
          ))
        ).setIn(['datasetFilterFields', id, 'active'], !state.get('datasetFilterFields').get(id).get('active'));
      }

    case "DRAG_QUERY_FIELD":
      id = state.get('datasetFilterFields').findIndex(function(field) {
        return field.get("id") === parseInt(action.id.replace(/selected/g,''), 10)
      });
      if(action.id.includes('selected') && state.get('datasetFilterFields').get(id).get('active')) {
        newState = state.setIn(['datasetFilterFields', id, 'visible'], !state.get('datasetFilterFields').get(id).get('visible'))
          .setIn(['datasetFilterFields', id, 'active'], !state.get('datasetFilterFields').get(id).get('active'))
          .update('datasetFilters',
            filters => filters.map(filter => filter.update('visible', visible => false))
          ).update('datasetFilters', filters => filters.map(filter => {
            if(filter.get('field_id') === parseInt(action.id.replace(/selected/g,''), 10)) {
              return filter.update('active', active => false)
            } else {
              return filter.set('active', filter.get('active'))
            }
          })
        );
        return newState.set('activeFilters', newState.get('datasetFilters').filter(filter => filter.get('active') === true).groupBy(filter => filter.get('title')) );
      } else {
        return state.setIn(['datasetFilterFields', id, 'visible'], !state.get('datasetFilterFields').get(id).get('visible'));
      }


    /* OVERVIEW */
    /* 1. Check if toggled filter is being turned on or off
         2. If it is being turned on, check and see if the filter belongs to either:
            a) the analyte_type field, or
            b) the analyte_substype field
           3. If so, add the filter to the relevant selected filter List() in the state.
           4. If not, simply set the filter to active and included === true.

         3. If it is being turned off, check and see if the filter belongs to either:
            a) the analyte_type field, or
            b) the analyte_substype field
           4. If so, run trhough and updated the relevant selected filter List() (remove the filter from it),
              and then if either analyte_name or analyte_subtype fields are toggled on in the selected fields,
              run a function to turn them on again and effectively refilter the filters shown as visible.
           5. If not, simply set the filter to not active (i.e. active === false)
       6. Then set the activeFilters to the updated list.
    */
    case "TOGGLE_QUERY_FILTER":
      /* ids don't always match indexes  */
      id = state.get('datasetFilters').findIndex(filter => filter.get("id") === action.id );
      /*   */
      var selected_id;
      /* We added some steps to filter analyte subtypes and analyte names based on the choice(s) */
      /* made in the selection of analyte type and/or subtype */
      /* So in order to check if any of these need to be filtered we need to be able to check what is active. */
      /* ... find indexes of the analyte_name FIELD and the analyte_subtype FIELD */
      const indexOfAnalyteNameField = state.get('datasetFilterFields').findIndex(fields => fields.get('column') === 'analyte_name');
      const indexOfAnalyteSubTypeField = state.get('datasetFilterFields').findIndex(fields => fields.get('column') === 'analyte_subtype');
      /* use the indexes found above to get the 'id' value */
      var analyte_name_field_id = state.getIn(['datasetFilterFields', indexOfAnalyteNameField, 'id'])
      var analyte_subtype_field_id = state.getIn(['datasetFilterFields', indexOfAnalyteSubTypeField, 'id'])
      /* Check if filter is being turned on or off */
      if(state.get('datasetFilters').get(id).get('active')) {  // filter is active, need to turn it off
        if(state.get('datasetFilters').get(id).get('field') === 'analyte_type') {
          /* Get the index of the filter in the selectedAnalyteTypes List() so we can remove it from the list since the filter is being turned off */
          selected_id = state.get('selectedAnalyteTypes').indexOf(state.get('datasetFilters').get(id).get('filter'))
          /* Now check and see if the analyte_name or analyte_subtype fields are actively showing their filters so we can update them as if they were being toggled on (i.e. we want to refilter the filters that are visible) */
          if(state.getIn(['datasetFilterFields', indexOfAnalyteNameField, 'active'])) {
            nextState = state.setIn(['datasetFilters', id, 'active'], false).deleteIn(['selectedAnalyteTypes', selected_id])
            newState = turnOnField(nextState, analyte_name_field_id)
          } else if(state.getIn(['datasetFilterFields', indexOfAnalyteSubTypeField, 'active'])) {
            nextState = state.setIn(['datasetFilters', id, 'active'], false).deleteIn(['selectedAnalyteTypes', selected_id])
            newState = turnOnField(nextState, analyte_subtype_field_id)
          } else {
            newState = state.setIn(['datasetFilters', id, 'active'], false).deleteIn(['selectedAnalyteTypes', selected_id]);
          }
        } else if(state.get('datasetFilters').get(id).get('field') === 'analyte_subtype') {
          selected_id = state.get('selectedAnalyteSubTypes').indexOf(state.get('datasetFilters').get(id).get('filter'))
          /* Now check and see if the analyte_name field is actively showing its filters so we can update them as if they were being toggled on (i.e. we want to refilter the filters that are visible) */
          if(state.getIn(['datasetFilterFields', indexOfAnalyteNameField, 'active'])) {
            nextState = state.setIn(['datasetFilters', id, 'active'], false).deleteIn(['selectedAnalyteSubTypes', selected_id])
            newState = turnOnField(nextState, analyte_name_field_id)
          } else {
            newState = state.setIn(['datasetFilters', id, 'active'], false).deleteIn(['selectedAnalyteSubTypes', selected_id]);
          }
        } else {
          newState = state.setIn(['datasetFilters', id, 'active'], false);  // TODO - do we also need to set included to false?? Guess not.
        }
      } else { // filter is not active, need to turn it on
        if(state.get('datasetFilters').get(id).get('field') === 'analyte_type') {
          newState = state.setIn(['datasetFilters', id, 'active'], true).setIn(['datasetFilters', id, 'included'], true).update('selectedAnalyteTypes', selectedAnalyteTypes => selectedAnalyteTypes.push(state.get('datasetFilters').get(id).get('datasetFilter')));
        } else if(state.get('datasetFilters').get(id).get('field') === 'analyte_subtype') {
          newState = state.setIn(['datasetFilters', id, 'active'], true).setIn(['datasetFilters', id, 'included'], true).update('selectedAnalyteSubTypes', selectedAnalyteSubTypes => selectedAnalyteSubTypes.push(state.get('datasetFilters').get(id).get('datasetFilter')));
        } else {
          newState = state.setIn(['datasetFilters', id, 'active'], true).setIn(['datasetFilters', id, 'included'], true);
        }
      }
      return newState.set('activeFilters', newState.get('datasetFilters').filter(filter => filter.get('active') === true)
        .groupBy(filter => filter.get('title')) );

    case "CLEAR_QUERY":
      return state.set('selectedQuery',Map())

    case "SELECT_QUERY":
      let selectedQuery = state.get('queries').filter((row) => row.get('id') === action.id).get(0)
      return state.update('queries', queries => queries.map(query => {
        if(query.get('id') === action.id) {
          return query.update(
            'visible',
            visible => true
          )
        } else {
          return query.update(
            'visible',
            visible => false
          )
        }
      })
      ).set('selectedQuery',selectedQuery);

    case "SEARCH_FILTERS":
      //If you do a search when the column is empty, it will try to error on you, Jimbo.
      if(!state.get('datasetFilterFields').findEntry(x => x.get('active'))){return state}

      id = state.get('datasetFilterFields').findEntry(x => x.get('active'))[1].get('id');
      if(action.text === '') {
        return state.update(
          'datasetFilters',
          filters => filters.map(filter =>
            {
              if(filter.get('field_id') === id) {
                return filter.update(
                  'visible',
                  visible => true
                )
              } else {
                return filter.update(
                  'visible',
                  visible => false
                )
              }
            }
          )
        );
      } else {

        return state.update(
          'datasetFilters',
          filters => filters.map(filter =>
            {
              if(filter.get('field_id') === id && filter.get('filter').toUpperCase().includes(action.text.toUpperCase())) {
                return filter.update(
                  'visible',
                  visible => true
                )
              } else {
                return filter.update(
                  'visible',
                  visible => false
                )
              }
            }
          )
        );
      }

    case "CLEAR_QUERY_FILTERS":
      return state.update( // turn all fields off
        'datasetFilterFields',
        filterFields => filterFields.map(field => field.update(
          'active',
          active => false
        ).update(
          'visible',
          visible => true
        ))).update(  // turn all filters off
        'datasetFilters',
        filters => filters.map(filter => filter.update(
          'active',
          active => false
        ).update(
          'visible',
          visible => false
        ).update(
          'included',
          included => true
        ))
      ).set('activeFilters', Map()); // clear out active filters

    case "TOGGLE_QUERY":
      return state;

    default:
      return state;
  }
}

export default QueryApp;
