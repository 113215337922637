import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Table, Column, Cell} from 'fixed-data-table-2';
import {fetchCasIds, searchCasIds, updateCasIdSearch } from '../actions/casids';

const TextCell = ({data, col, rowIndex, ...props}) => (
  <Cell {...props}>
    {data.getIn([rowIndex,col])}
  </Cell>
)

class ExpandableCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, collapsedRows, callback, col, ...props} = this.props;
    let analyte_name = data.getIn([rowIndex,col]);
    let aliases = data.getIn([rowIndex,'raw_analyte_names']).filter(raw => raw.toUpperCase() !== analyte_name.toUpperCase());

    return (
      <Cell {...props}>
        {data.getIn([rowIndex,col])}
        { aliases.count() > 0 ?
          (<button onClick={() => callback(rowIndex)} className='seeAliases button-link'>
            {collapsedRows.has(rowIndex) ? 'Hide Aliases' : 'See Aliases'}
          </button>) : (<div></div>)
        }
      </Cell>
    );
  }
};

class Analytes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsedRows: new Set(),
    }

    this._handleCollapseClick = this._handleCollapseClick.bind(this);
    this._subRowHeightGetter = this._subRowHeightGetter.bind(this);
    this._rowExpandedGetter = this._rowExpandedGetter.bind(this);
  }

  componentWillMount(){
    if(!this.props.casids.count()){
      this.props.dispatch(fetchCasIds());
    }
  }

  onInputChange(e){
    this.props.dispatch(updateCasIdSearch(e.target.value));
    this.props.dispatch(searchCasIds(e.target.value));

    this.setState({
      collapsedRows: new Set([])
    });
  }

  _handleCollapseClick(rowIndex) {
    const {collapsedRows} = this.state;
    const shallowCopyOfCollapsedRows = new Set([...collapsedRows]);
    if (shallowCopyOfCollapsedRows.has(rowIndex)) {
      shallowCopyOfCollapsedRows.delete(rowIndex);
    } else {
      shallowCopyOfCollapsedRows.add(rowIndex);
    }

    this.setState({
      collapsedRows: shallowCopyOfCollapsedRows
    });
  }

  _subRowHeightGetter(rowIndex) {
    let analyte_name = this.props.casids.getIn([rowIndex,'analyte_name'])
    let aliases = this.props.casids.getIn([rowIndex,'raw_analyte_names']).filter(raw => raw.toUpperCase() !== analyte_name.toUpperCase());
    if(aliases.count() === 0){
      return 0
    }

    return this.state.collapsedRows.has(rowIndex) ? 40 * aliases.count() : 0;
  }

  _rowExpandedGetter({rowIndex, width, height}) {
    if (!this.state.collapsedRows.has(rowIndex)) {
      return null;
    }

    let analyte_name = this.props.casids.getIn([rowIndex,'analyte_name']);
    let aliases = this.props.casids.getIn([rowIndex,'raw_analyte_names']).filter(raw => raw.toUpperCase() !== analyte_name.toUpperCase()).map((row) =>(
      <li key={row}>
        {row}
      </li>
    ));

    const style = {
      height: height,
      width: width - 2,
    };
    return (
      <div style={style}>
        <ul className='expandedContent'>
          {aliases}
        </ul>
      </div>
    );
  }

  render() {

    let { collapsedRows }  = this.state;
    let width = Math.min( this.props.template.width - 40, 900)
    let height = this.props.template.height - 245;
    var data = this.props.casids;

    return (
      <div id='analyte_table'>
        <h3>Parameter ID Lookup Table</h3>
        This is a list of parameter names and parameter ids provided in the EDDs that make up the BMI Database. Standardized parameter names are shown.
        To see aliases for a parameter, click "Show Aliases".
        <div id='cas_id_search' className=''>
          <input value={this.props.searchTerm} id='cas' className='form-control' placeholder='Search Parameter Name, Parameter Alias, or Parameter ID..' onChange={this.onInputChange.bind(this)} style={{width:width}}/>
        </div>
        <Table
          rowHeight={40}
          headerHeight={40}
          rowsCount={data.count()}
          width={width}
          maxHeight={height}
          subRowHeightGetter={this._subRowHeightGetter}
          rowExpanded={this._rowExpandedGetter}
          style={{padding:'0 10px'}}
          {...this.props}
        >
          <Column
            header={<Cell>Parameter Name (standardized)</Cell>}
            cell={<ExpandableCell data={data} col='analyte_name' collapsedRows={collapsedRows} callback={this._handleCollapseClick} />}
            flexGrow={3}
            width={100}
          />
          <Column
            header={<Cell>Parameter ID</Cell>}
            cell={<TextCell data={data} col='cas_id'/>}
            flexGrow={1}
            width={100}
          />
        </Table>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    casids: state.CasIds.filteredCasids,
    searchTerm: state.CasIds.searchTerm,
    template: state.Template
  }
}

export default connect(
  mapStateToProps
)(Analytes)
