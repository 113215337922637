/*
 *
 * Should be no longer used... moved these to the MapApp reducer
 * -Nick
 */

import { Map } from 'immutable';

const initialState = Map({
  'showLocs':true,
  'radius':'location',
  'histogram':null,
  'location':null,
  'showQuantile':true,
  'quantileSize':[500,350]
})

const MapStats = (state = initialState, action) => {
  switch(action.type){

    case "RECEIVE_DATA":
      return state.set('location', null);

    case "CHOOSE_RADIUS":
     return  state.set('radius', action.value);

    case "TOGGLE_SHOW_LOCATIONS":
      return state.set('showLocs', !state.get('showLocs'))

    case "CHOOSE_HISTOGRAM":
      return state.set('histogram', action.x0 === state.get('histogram') ? null : action.x0);

    case "SELECT_ANALYTE":
      return state.set('histogram', null);

    case "CHOOSE_LOCATION":
      if(state.get('location') === action.location) {
        return state.set('location', null)
      } else {
        return state.set('location', action.location);
      }


    case "TOGGLE_QUANTILE":
      return state.set('showQuantile',!state.get('showQuantile'));

    case "RESIZE_QUANTILE":
      return state.set('quantileSize',[action.width, action.height])

    default:
      return state;
  }
}

export default MapStats;
