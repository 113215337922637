import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { setPiperLocation } from '../../actions/map';
import '../../css/piperPlot.css';

d3.selection.prototype.moveToFront = function() {
  return this.each(function(){
    this.parentNode.appendChild(this);
  });
};

class ModifiedPiperPlotComp extends Component {

  constructor(props){
    super(props)
    this.state = {
      filteredData : this.props.data.filter(item => item.get('visible') && item.get('ionCheck')).toJS()
    }
  }

  componentDidMount(){
    this.renderPlot();
  }
  componentDidUpdate(){
    this.renderPlot();
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      filteredData : nextProps.data.filter(item => item.get('visible') && item.get('ionCheck')).toJS()
    });
  }

  renderPlot(){

    d3.selectAll('.modifiedPiperPlotContainer').remove();

    var margin = {top: 20, right: 20, bottom: 50, left: 50};
    this.plotHeight = this.props.size[1] - (margin.top + margin.bottom);
    this.plotWidth = this.props.size[0]  - (margin.left + margin.right);

    var self = this;

    var upper_left_quadrant = "(Na - HCO\u2083)",
        upper_right_quadrant = "(Ca - Mg - HCO\u2083)",
        lower_left_quadrant = "(Na - Cl)",
        lower_right_quadrant = "(Ca - Mg - Cl)",
        y_axis_title = "(CO\u2083 + HCO\u2083) - (Cl + SO\u2084) [in % meq/L]",  // Millequivalent Percentage
        x_axis_title = "(Ca + Mg) - (Na + K) [in % meq/L]";

    var noZero = function(d) {
      return d === 0 || Math.abs(d) === 100 ? '' : d;
    };

    var x = d3.scaleLinear()
        .domain([-100, 100])
        .range([0, this.plotWidth]);

    var y = d3.scaleLinear()
        .domain([-100, 100])
        .range([this.plotHeight, 0]);

    var svg = d3.select(this.refs.modifiedPiperPlot);

    var plotContainer = svg.append('g')
      .attr('class', 'modifiedPiperPlotContainer')
      .attr('width', this.plotWidth)
      .attr('height', this.plotHeight)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    plotContainer.append("rect")
      .attr("width", this.plotWidth)
      .attr("height", this.plotHeight)
      .attr("fill", "#F6F6F6")
      .attr("fill-opacity", "0")
      .attr("stroke", "black")
      .attr("stroke-width", "1px");

    plotContainer.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + (this.plotHeight / 2) + ")")
      .call(d3.axisBottom(x).ticks(10).tickFormat(noZero));

    plotContainer.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + (this.plotWidth / 2) + ", 0)")
      .call(d3.axisLeft(y).ticks(10).tickFormat(noZero));


    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr('transform',"translate(-30," + ((this.plotHeight / 2) + margin.top) + ") rotate(-90)")
      .text('Millequivalent Percentage');

    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr('transform',"translate(-15," + ((this.plotHeight / 2) + margin.top) + ") rotate(-90)")
      .text(y_axis_title);

    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr('transform',"translate(" + (this.plotWidth / 2) + "," + (this.plotHeight + margin.top) + ")")
      .text('Millequivalent Percentage');

    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr('transform',"translate(" + (this.plotWidth / 2) + "," + (this.plotHeight + margin.top + 15) + ")")
      .text(x_axis_title);

    plotContainer.append("text") // Top Left
      .style('text-anchor','middle')
      .style('opacity', 0.6)
      .style('text-size', '0.9rem')
      .attr('transform',"translate(" + x(-60) + "," + y(60) + ")")
      .text(upper_left_quadrant);

    plotContainer.append("text")  // Top Right
      .style('text-anchor','middle')
      .style('opacity', 0.6)
      .style('text-size', '0.9rem')
      .attr('transform',"translate(" + x(60) + "," + y(60) + ")")
      .text(upper_right_quadrant);

    plotContainer.append("text") // Bottom Left
      .style('text-anchor','middle')
      .style('opacity', 0.6)
      .style('text-size', '0.9rem')
      .attr('transform',"translate(" + x(-60) + "," + y(-60) + ")")
      .text(lower_left_quadrant);

    plotContainer.append("text")  // Bottom Right
      .style('text-anchor','middle')
      .style('opacity', 0.6)
      .style('text-size', '0.9rem')
      .attr('transform',"translate(" + x(60) + "," + y(-60) + ")")
      .text(lower_right_quadrant);

    let me = this;
    var circles = plotContainer.append("g").selectAll("circle")
      .data(this.state.filteredData.map( function(d) {
          d.x = ((d.ca + d.mg) - (d.nak));
          d.y = ((d.carbs) - (d.cl + d.so4));
          return d;
      }));

    circles.enter().append("circle")
      //.attr("class", "modifiedPiper")
      // eslint-disable-next-line
      .attr('class', function(d) { return 'modifiedPiper ' + d.location.replace(/[\. ,:-]+/g, "-"); })
      .attr("r", 5)
      .attr("cx", function(d) { return x(d.x); })
      .attr("cy", function(d) { return y(d.y); })
      .style("stroke", function(d) {
          if (d.x >= 0 && d.y <= 0) {return "#60B19C"} // Top Left
          else if (d.x >= 0 && d.y >= 0) {return "#8EC9DC"} // Top Right
          else if (d.x <= 3 && d.y >= 0) {return "#D06B47"} // Bottom Left
          else { return "#b8c918" } //Bottom Right   // #A72D73    
      })
      .style("stroke-width", "1px")
      .style("fill", function(d) {
          if (d.x >= 0 && d.y <= 0) {return "#60B19C"} // Top Left
          else if (d.x >= 0 && d.y >= 0) {return "#8EC9DC"} // Top Right
          else if (d.x <= 3 && d.y >= 0) {return "#D06B47"} // Bottom Left
          else { return "#b8c918" } //Bottom Right   // #A72D73
      })
      .style("opacity", 0.6)
      .style("fill-opacity", 0.25)
      .on('mouseover', function(d) {
        d3.select(this).moveToFront();
        self.props.dispatch(setPiperLocation(d.location));
        // eslint-disable-next-line
        plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
          .attr('transform', 'translate(' + ((me.plotWidth/2) - (margin.left)) + ',' + (-5) + ')')
          .append("text")
            .attr('text-anchor','start')
            .text( "Location: " + d.location)
        // eslint-disable-next-line
        d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).classed('modified-hovered', true)
      })
      .on('mouseout', function(d) {
        // eslint-disable-next-line
        d3.selectAll(".location-" + d.location.replace(/[\. ,:-]+/g, "-")).remove();
        // eslint-disable-next-line
        d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).classed('modified-hovered', false)
        self.props.dispatch(setPiperLocation(null));
      });
  }

  render(){
    return (
      <svg ref='modifiedPiperPlot' width={this.props.size[0]} height={this.props.size[1]} ></svg>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.PiperApp.get('data'),
    size: state.PiperApp.get('modPiperSize')
  }
}

const ModifiedPiperPlot = connect(mapStateToProps)(ModifiedPiperPlotComp)

export default ModifiedPiperPlot;
