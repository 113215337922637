import React from 'react';
import { Route, NavLink} from 'react-router-dom';
import OverviewMain from './Overview';
import History from './History';
import WhatsNew from './WhatsNew';
import Howto from './Howto';
import Datasets from './Datasets';
import BugReport from './BugReport';
import '../../css/overview.css';

// let announcement = 'Please be aware that due to database cleanup of some fields, not all queries may return the same data as before. You can remove these queries and/or create new ones by going to the Query Data page.';

let announcement = '';

const ImportantAnnouncement = () => {
  return announcement ? (
    <div id='overview_announcement' className="alert alert-warning">
      <h3 style={{textAlign: 'center'}}> Attention! </h3>
      {announcement}
    </div>
  ) : null
}

const Sidebar = () => {
  if(process.env.REACT_APP_SITE === 'bmi') {
    return (
      <div id='overview_sidebar'>
        <ul>
          <li><NavLink exact={true} activeClassName='overview_active'to="/overview">Overview</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/history">History</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/howto">How To Use This Site</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/datasets">Downloadable Datasets</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/whatsnew">What's New</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/report_bug">Report a Bug / Feature</NavLink></li>
        </ul>
        { announcement.length > 0 ?
          (<ImportantAnnouncement />) : null
        }
      </div>
    )
  } else {
    return (
      <div id='overview_sidebar'>
        <ul>
          <li><NavLink exact={true} activeClassName='overview_active'to="/overview">Overview</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/howto">How To Use This Site</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/datasets">Downloadable Datasets</NavLink></li>
          <li><NavLink activeClassName='overview_active'to="/overview/report_bug">Report a Bug / Feature</NavLink></li>
        </ul>
        { announcement.length > 0 ?
          (<ImportantAnnouncement />) : null
        }
      </div>
    )
  }
}


export const Overview = (props) => {
  return (
    <div id='overview_container'>
      <Sidebar />
      <div id='overview_content'>
        <div id='overview_inner'>
          <Route exact path='/overview' component={OverviewMain}/>
          <Route exact path='/overview/history' component={History}/>
          <Route exact path='/overview/whatsnew' component={WhatsNew}/>
          <Route exact path='/overview/howto' component={Howto}/>
          <Route exact path='/overview/datasets' component={Datasets}/>
          <Route exact path='/overview/report_bug' component={BugReport}/>
        </div>
      </div>
    </div>
  )
}
