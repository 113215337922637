//      

                                                     

export const formatGroupLabel = (group           )       => group.label;

export const getOptionLabel = (option            )         => option.label;

export const getOptionValue = (option            )         => option.value;

export const isOptionDisabled = (option            )          =>
  !!option.isDisabled;
