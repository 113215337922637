/* * * * * * * * * * * * * * *
 *
 * This is main controller of the app.
 * We assign an id to each row of data, filters and filter fields,
 * then use that to set up our inital state.
 * We feed our inital state into our store, which takes a reducer (from reducers/map.js)
 * and an initial state object (in this case an ImmutableJS Map object).
 *
 * * * * * * * * * * * * * * */

import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import DataMap from './Map';
import FilterBar from './FilterBar';
import StatBar from './StatBar';
import '../../css/map.css';
import {clearErrorMessage} from '../../actions';

//FOR DEV, REMOVE
import { fetchData } from '../../actions'
//import { fetchQueries } from '../../actions'

const customStyles = {
  overlay: {
    zIndex                : '10000000'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

/* BUILDING THE STORE */
var v1 = performance.now();

function mapFilterStateToProps(state){
  return {
    filters : state.MapApp.get('filters'),
    filterFields : state.MapApp.get('filterFields'),
    analytes : state.MapApp.get('analytes'),
    analyte : state.MapApp.get('analyte'),
    selectedQuery : state.MapApp.get('selectedQuery'),
    possibleLeachate : state.MapApp.get('possibleLeachate'),
    isFetching: state.isFetching,
    errorMessage: state.MapApp.get('errorMessage'),
    showPiper: state.MapApp.get('showPiper'),
    showModifiedPiper: state.MapApp.get('showModifiedPiper'),
    showDurov: state.MapApp.get('showDurov'),
    template: state.Template
  }
}
function mapStatsStateToProps(state){
  return {
    data: state.MapApp.get('filteredData'),
    //sortedResults: state.MapApp.get('allResults'),
    locations: state.MapApp.get('filteredLocations'),
    max: state.MapApp.get('analyteMax'),
    min: state.MapApp.get('analyteMin'),
    maxRange: state.MapApp.get('filteredLocations').size,
    selectedLoc: state.MapApp.get('selectedLocation'),
    locResults: state.MapApp.get('locResults'),
    locStaticResults: state.MapApp.get('locStaticResults'),
    showQuantile: state.MapApp.get('showQuantile'),
    quantileSize: state.MapApp.get('quantileSize'),
    showHotSpot: state.MapApp.get('showHotSpot'),
    showSamplingFreq: state.MapApp.get('showSamplingFreq'),
    showGrid: state.MapApp.get('showGrid'),
    showBands: state.MapApp.get('showBands'),
    showContours: state.MapApp.get('showContours'),
    showTin: state.MapApp.get('showTin'),
    showClusters: state.MapApp.get('showClusters'),
    showPiper: state.MapApp.get('showPiper'),
    showModifiedPiper: state.MapApp.get('showModifiedPiper'),
    showDurov: state.MapApp.get('showDurov'),
    showBoxPlot: state.MapApp.get('showBoxPlot'),
    showQQPlot: state.MapApp.get('showQQPlot'),
    showInfoLegend: state.MapApp.get('showInfoLegend'),
    qqSize: state.MapApp.get('qqSize'),
    boxPlotSize: state.MapApp.get('boxPlotSize'),
    uiSize: state.MapApp.get('uiSize'),
    piperSize: state.PiperApp.get('piperSize'),
    modPiperSize: state.PiperApp.get('modPiperSize'),
    durovSize: state.PiperApp.get('durovSize'),
    piperLoaded: state.PiperApp.get('piperLoaded'),
    histogram: state.MapApp.get('histogramSelection'),
    boxData: state.MapApp.get('boxData'),
    errorMessage: state.MapApp.get('errorMessage'),
    radius: state.MapApp.get('radius'),
    hasNegatives: state.MapApp.get('hasNegatives'),
    showLocs: state.MapStats.get('showLocs'),
    showRadius: state.MapApp.get('showRadius'),
    showIntensity: state.MapApp.get('showIntensity'),
    showUITools: state.MapApp.get('showUITools'),
    unit: state.MapApp.get('filters').filter(x => x.get('active') && x.get('field') === 'unit'),
    frequencyData: state.MapApp.get('frequencyData'),
    selectedQuery: state.QueryApp.get('selectedQuery'),
    template: state.Template
  }
}
function mapMapStateToProps(state){
  return {
    data: state.MapApp.get('filteredLocations'),
    //sortedResults: state.MapApp.get('allResults'),
    max: state.MapApp.get('analyteMax'),
    min: state.MapApp.get('analyteMin'),
    mean: state.MapApp.get('analyteMean'),
    median: state.MapApp.get('analyteMedian'),
    sampleSize: state.MapApp.get('analyteSampleSize'),
    selectedLoc: state.MapStats.get('location'),
    foundLocation: state.MapApp.get('foundLocation'),
    showInfoLegend: state.MapApp.get('showInfoLegend'),
    radius: state.MapApp.get('radius'),
    showLocs: state.MapStats.get('showLocs'),
    showRadius: state.MapApp.get('showRadius'),
    showIntensity: state.MapApp.get('showIntensity'),
    showHotSpot: state.MapApp.get('showHotSpot'),
    hotSpotRadius: state.MapApp.get('hotSpotRadius'),
    hotSpotUnit: state.MapApp.get('hotSpotUnit'),
    showSamplingFreq: state.MapApp.get('showSamplingFreq'),
    showGrid: state.MapApp.get('showGrid'),
    doGrid: state.MapApp.get('doGrid'),
    interpolationGrid: state.MapApp.get('interpolationGrid'),
    contourInterpolationGrid: state.MapApp.get('contourInterpolationGrid'),
    showBands: state.MapApp.get('showBands'),
    showContours: state.MapApp.get('showContours'),
    doContours: state.MapApp.get('doContours'),
    showTin: state.MapApp.get('showTin'),
    showClusters: state.MapApp.get('showClusters'),
    nClusters: state.MapApp.get('nClusters'),
    useSetContourBreaks: state.MapApp.get('useSetContourBreaks'),
    overlays: state.MapApp.get('overlays'),
    isFetching: state.isFetching,
    errorMessage: state.MapApp.get('errorMessage'),
    mapCenter: state.MapApp.get('mapCenter'),
    mapZoom: state.MapApp.get('mapZoom'),
    mapBounds: state.MapApp.get('mapBounds'),
    draggable: state.MapApp.get('draggable'),
    gridCellSize: state.MapApp.get('gridCellSize'),
    distanceWeightingFactor: state.MapApp.get('distanceWeightingFactor'),
    nContours: state.MapApp.get('nContours'),
    contourBreaks: state.MapApp.get('contourBreaks'),
    mapBreaks: state.MapApp.get('mapBreaks'),
    filters: state.MapApp.get('activeFilters'),
    drawnPolygons: state.MapApp.get('drawnPolygons'),
    editKey: state.MapApp.get('editKey'),
    frequency: state.MapApp.get('frequencySelection'),
    quantileLocation: state.MapApp.get('quantileLocation'),
    piperLocation: state.MapApp.get('piperLocation'),
    showGauges: state.MapApp.get('showGauges'),
    gaugeData: state.MapApp.get('gaugeData'),
    gaugeSize: state.MapApp.get('gaugeSize'),
    template: state.Template
  }
}

const ConnectedFilters = connect(mapFilterStateToProps)(FilterBar);
const ConnectedStats = connect(mapStatsStateToProps)(StatBar);
const ConnectedMap = connect(mapMapStateToProps)(DataMap);

ReactModal.setAppElement('#root');

class MapViewComp extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalMessage: ''
    }

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  componentWillMount(){
    if(process.env.NODE_ENV === "development" && process.env.REACT_APP_SITE === 'bmi') {
      if(sessionStorage.email === 'aspringsteen@neptuneinc.org') {
        if(sessionStorage.query_id !== "null") {
          this.props.dispatch(fetchData(sessionStorage.query_id, sessionStorage.analyte_id))
        } else  {
          //this.props.dispatch(fetchData(5993, 0))  //Downgradient Metals Query, analyte Aluminum
          this.props.dispatch(fetchData(5997, 0))  //Arsenic 2007 - 2017, analyte Arsenic - water and soil
        }
      } 
    }

    this.setState({"navBarHeight":62})
    this.setState({"height":window.innerHeight - 62})
    this.setState({"width":window.innerWidth})
  }

  updateDimensions(){
    //this.setState({'width':window.innerWidth, 'height':window.innerHeight - this.state.navBarHeight});
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.errorMessage) {
      this.setState({modalMessage: nextProps.errorMessage });
      this.setState({modalIsOpen: true});
    }
  }

  componentDidMount(){
    //window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#222';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.props.dispatch(clearErrorMessage())
  }

  render(){
    return (
      <div className='mapApp'>
        <ReactModal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles} contentLabel="Query Name Modal">
          <h4 ref={subtitle => this.subtitle = subtitle}>{this.state.modalMessage}</h4>
          <button className="btn btn-info center-block" onClick={this.closeModal}>Okay</button>
        </ReactModal>
        <ConnectedFilters height={this.props.template.compHeight}/>
        <ConnectedStats height={this.props.template.compHeight}/>
        <ConnectedMap
          height={this.props.template.compHeight}
          width={this.props.template.width}
        />
    </div>
  )}
}

  function mapCompStateToProps(state){
    return {
      errorMessage: state.MapApp.get('errorMessage'),
      template:state.Template
    }
  }

  var v2 = performance.now();
  console.log('INITIAL RENDER: ' + (v2 - v1));

  const MapView = connect(mapCompStateToProps)(MapViewComp);


export default MapView;
