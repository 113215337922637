/*
 *
 * Login.js
 * Author: Nick Woodward
 * Email: nwoodward@neptuneinc.org
 * Version: v0.1
 *
 * This component works works with a REST api to authenticate a user using Json Web Tokens (JWTs).
 * When the component mounts it checks for a JWT. If it makes a call to '/auth'.
 *
 *
 *
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/login.css';
import { doLogin } from '../../actions/login'
import { Redirect, Link } from 'react-router-dom'


class Login extends Component {

  componentWillMount(){
    this.setState({'showLogin': false}); //don't show login form until we check for a jwt
    this.setState({'error':'.'})

  }

  componentDidMount(){
    var jwt = sessionStorage.getItem('jwt'),
        account = JSON.parse(sessionStorage.getItem('account')),
      //account = null,
        self = this;

    if(jwt){
      //console.log("AUTHING with JWT")
      this.fetch('/auth',"",function(res){
        if(res.status === 200){
          self.props.dispatch(doLogin(account));
        } else {
          self.setState({'showLogin': true});
        }
      })
    } else {
      this.setState({'showLogin': true});
    }

    if(this.state.showLogin && this.refs.email){
      this.refs.email.focus();
    }
  }

  fetch(path,body = "",callback){

    //const query_base_url = process.env.NODE_ENV === "development" ? "" : "";
    var jwt = sessionStorage.getItem('jwt');
    fetch('/api' + path, {
      accept:'application/json',
      method: 'post',
      headers: {
        "Authorization": "Bearer " + jwt,
        "Content-Type": "application/json"
      },
      body: body
    }).then(this.checkStatus)
      .then(this.parseJSON)
      .then(callback)
      .catch(callback)}

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    console.log("LOGIN COMP")
    throw error;
  }

  parseJSON(response) {
    return response.json();
  }

  handleLogin(e){
    e.preventDefault();
    var  self = this;

    if(!this.state.email || !this.state.password){
      this.setState({'error':"invalid email or password."})
    } else {
      this.setState({'error':"."})

      var data = {
        'email':encodeURIComponent(this.state.email),
        'password':encodeURIComponent(this.state.password)
      }

      this.fetch('/login',JSON.stringify(data), function(res){
            console.log('AUTHED!');
          if(res.status === 200){
            var text = JSON.parse(res.text);
            var account = text.account;
            var token = text.token;
            sessionStorage.setItem('jwt',token)
            sessionStorage.setItem('username',account.username)
            sessionStorage.setItem('email',account.email)
            sessionStorage.setItem('firstname',account.firstname)
            sessionStorage.setItem('lastname',account.lastname)
            sessionStorage.setItem('query_id',null)
            sessionStorage.setItem('analyte_id',null)
            sessionStorage.setItem('user_id',account.user_id)
            sessionStorage.setItem('account',JSON.stringify(account))

            self.props.dispatch(doLogin(account, token));

          } else {
            console.log('denied!');
            self.setState({'error':"invalid email or password."})
            if(self.refs.password){
              //self.refs.password.focus().select();
            }
          }
      })
    }
  }

  onFormChange(e){
    var state = {};
    state[e.target.id] = e.target.value;
    this.setState(state);
  }

  render(){

    const  {from}  = this.props.location.state || { from: { pathname: '/' } }

    if(this.props.loggedIn){
      return (<Redirect to={from}/>)
      //return null;
    } else {
      return (
        <div className='loginContainer'>
          <div className='loginBox ' style={{marginTop:(window.innerHeight - 400)/2, display: this.state.showLogin ? "block" : "none"}}>
            <form onSubmit={this.handleLogin.bind(this)} className='form-horizontal'>
            <h3>Login</h3>
            <div className='error alert alert-warning' style={{'visibility': this.state.error.length > 1? "visible" : "hidden"}}>{this.state.error}</div>
            <div className="form-group row">
              <label htmlFor='email' className='col-sm-2 control-label'>Email:</label>
              <div className='col-sm-10'>
                <input ref='email' onChange={this.onFormChange.bind(this)} id='email' type="text" className="form-control" />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor='password' className='col-sm-2 control-label'>Password:</label>
              <div className='col-sm-10'>
                <input ref='password' onChange={this.onFormChange.bind(this)} id='password' type="password" className="form-control"/>
              </div>
            </div>
            <div className="form-group row">
              <div className='col-sm-3' style={{float:'right'}}>
                <button onClick={this.handleLogin.bind(this)} className='form-control btn btn-primary'>Login</button>
              </div>
            </div>
            <div className="form-group row">
              {/*
              <div className='col-sm-12' style={{float:'right'}}>
                I don't have an account,<Link to="/register">click to register.</Link>
              </div>
              */}
              <div className='col-sm-12' style={{float:'right'}}>
                I forget my password,<Link to="/new_password">click to reset.</Link>
              </div>
            </div>
          </form>
         </div>
      </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
 return {'loggedIn':state.LoginApp.loggedIn};
}

export default connect(mapStateToProps)(Login);
