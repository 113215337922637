//      
import {
  IndicatorsContainer,
  SelectContainer,
  ValueContainer,
} from './containers';
import {
  ClearIndicator,
  DropdownIndicator,
  LoadingIndicator,
  IndicatorSeparator,
} from './indicators';

import Control from './Control';
import Group, { GroupHeading } from './Group';
import Input from './Input';
import Menu, { MenuList, NoOptionsMessage, LoadingMessage } from './Menu';
import MultiValue, {
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
} from './MultiValue';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';

                                
                                        
                          
                                              
                      
                                    
                                                  
                                                
                      
                                            
                    
                            
                                        
                                            
                                
                                                  
                                          
                                            
                        
                                  
                                          
                                  
                                        
  

                                                              

export const components                   = {
  ClearIndicator: ClearIndicator,
  Control: Control,
  DropdownIndicator: DropdownIndicator,
  Group: Group,
  GroupHeading: GroupHeading,
  IndicatorsContainer: IndicatorsContainer,
  IndicatorSeparator: IndicatorSeparator,
  Input: Input,
  LoadingIndicator: LoadingIndicator,
  Menu: Menu,
  MenuList: MenuList,
  LoadingMessage: LoadingMessage,
  NoOptionsMessage: NoOptionsMessage,
  MultiValue: MultiValue,
  MultiValueContainer: MultiValueContainer,
  MultiValueLabel: MultiValueLabel,
  MultiValueRemove: MultiValueRemove,
  Option: Option,
  Placeholder: Placeholder,
  SelectContainer: SelectContainer,
  SingleValue: SingleValue,
  ValueContainer: ValueContainer,
};

              
                                     
  

export const defaultComponents = (props       ) => ({
  ...components,
  ...props.components,
});
