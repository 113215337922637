export const setDateFilter = (filter) => ({
  type:'SET_DATE_FILTER',
  filter
})

export const dateFilterDataTable = (filter) => ({
  type:'DATE_FILTER_DATA_TABLE',
  filter
})

export const locationFilterDataTable = (filter) => ({
  type:'LOCATION_FILTER_DATA_TABLE',
  filter
})

export const resultFilterDataTable = (filter) => ({
  type:'RESULT_FILTER_DATA_TABLE',
  filter
})

export const  selectDataTableFilter = (field) => ({
  type:'SELECT_DATA_FILTER',
  field
})

export const toggleTableModal = (table) => ({
  type: "TOGGLE_TABLE_MODAL", table
});

export const resizeTableModal = (table,width,height) => ({
  type: "RESIZE_TABLE_MODAL",
  width,
  height,
  table
})

export const doneCalculating = () => ({
  type: 'CALCULATE_DONE'
})
