import {store} from './index';
import {doLogout} from './actions/login';

/* eslint-disable no-undef */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    console.log(response)
    if(response.status === 401){
      store.dispatch(doLogout())
    }
    throw error;
  }
}

/* eslint-disable no-undef */
function search(query) {
  var jwt = sessionStorage.getItem('jwt');
  return fetch(query, {
    accept: 'application/json',
    headers: {
      "Authorization": "Bearer " + jwt,
      "Content-Type": "application/json"
    }
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error);
}

function post(query, json_body) {
  var jwt = sessionStorage.getItem('jwt');
  return fetch(query, {
    method: 'POST',
    accept: 'application/json',
    headers: {
      "Authorization": "Bearer " + jwt,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(json_body)
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error);
}

function update(query, json_body) {
  var jwt = sessionStorage.getItem('jwt');
  return fetch(query, {
    method: 'PUT',
    accept: 'application/json',
    headers: {
      "Authorization": "Bearer " + jwt,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(json_body)
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error);
}

function usgs(query) {
  return fetch(query, {
    accept: 'application/json',
    headers: {
      "Content-Type": "application/json"
    }
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error);
}

const Query = { search, post, update, usgs };
export default Query;
