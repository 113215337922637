import React, { Component } from 'react';
import {toggleField, selectAnalyte } from '../../actions/map.js';  //filterData --- react compiler says this was never used
import {fetchData, setFetchingText} from '../../actions';
//import Select from 'react-select';
//import 'react-select/dist/react-select.css';
//import '../../css/react-select.css';
import styles from '../../css/filterBar.css';
import BrushSlider from './BrushSlider';
import DiscreteFilterList from './DiscreteFilterList';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import Select from '../../react-select-2/src_no_flow';


/* * * * * * * * * * * * FilterBar * * * * * * * * * * * * * */

class FilterBar extends Component {

  constructor(props) {
    super(props);

    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false,
      analyte: props.analyte
    }
  }

  componentWillMount(){
    this.setState({"timeout":null})
  }

  componentDidMount(){
    this.props.dispatch(selectAnalyte(this.props.analyte));
  }

  fieldClicked(id, e){
    this.props.dispatch(toggleField(id));
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  selectAnalyte(val){
    let value = val ? val.value : "";

    if(value !== this.props.analyte && value !== "") {
      this.setState({'analyte': value});
      var analyte_index = 0;
      this.props.analytes.forEach((a, a_index) => {
        if(a.get('value') === val.value) {
          analyte_index = a_index;
          return;
        }
      });
      console.log(this.props.selectedQuery)
      this.props.dispatch(setFetchingText("Loading Analyte: " + value))
      this.props.dispatch(fetchData(this.props.selectedQuery, analyte_index))
    }
  }

  render(){
    var filterBar = [];
    var filters;
    var analyteColor = styles.resultColor;
    var possibleLeachate = this.props.possibleLeachate;

    var groupedOptions = [];

    // for analyte select
    var analyteGroups = this.props.analytes.groupBy(x => x.get('subtype')).keySeq().toArray();
    var analytes = this.props.analytes;
    analyteGroups.forEach(function(group) {
      groupedOptions.push({ label: group, options: analytes.filter(row => row.get('subtype') === group).toJS() })
    })

    if(this.props.filterFields.size){
      this.props.filterFields.forEach( (field,field_index) => {
        var fieldComponent = null;

        filters = this.props.filters.filter( (filter) => {
          return filter.get('field') === field.get('column') && filter.get('filter');
        });

        switch(field.get('type')){
            case 'discrete':
              if(filters.size > 1 && field.get('visible')){
                fieldComponent = (
                  <DiscreteFilterList
                    showPiper={this.props.showPiper}
                    showModifiedPiper={this.props.showModifiedPiper}
                    showDurov={this.props.showDurov}
                    filters={filters}
                    field={field}
                    dispatch={this.props.dispatch}
                />)
              } else if(filters.size > 1 && !field.get('visible')) {
                fieldComponent = (<div></div>);
              } else {
                fieldComponent = null;
              }
            break

            case 'interval':
              let includeInterval = filters.size > 0 && (filters.get(0).get('filter').get(0) !== filters.get(0).get('filter').get(1)) && !isNaN(filters.get(0).get('filter').get(0)) && !isNaN(filters.get(0).get('filter').get(1))
              if(includeInterval && field.get('visible')){
                fieldComponent = (
                  <BrushSlider
                    showPiper={this.props.showPiper}
                    showModifiedPiper={this.props.showModifiedPiper}
                    showDurov={this.props.showDurov}
                    filters={filters}
                    field={field}
                    dispatch={this.props.dispatch}
                  />)
              } else if(includeInterval && !field.get('visible')) {
                fieldComponent = (<div></div>);
              } else {
                fieldComponent = null;
              }
            break

            default:
        }

        if(fieldComponent){
          filterBar.push(
          <div className="filterContainer" key={field.get('id')}>
            <h4 onClick={this.fieldClicked.bind(this, field.get('id'))}>
              {field.get('activeFilters') && <span><FontAwesome name={'check'} className='icon-left' /></span>}
              {field.get('title')}
              {field.get('title') === 'Matrix' && possibleLeachate ? (<span style={{marginLeft: '7px'}} onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover}><FontAwesome name={'exclamation-triangle'} className='icon-right' /></span>) : null }
              <span style={{marginLeft: '10px'}}>
                <FontAwesome name={field.get('visible') ? 'caret-down' : 'caret-right'} className='icon-right'/>
              </span>
            </h4>
            {
              //this.state.isHovering && (field.get('title') === 'Units' || field.get('title') === 'Matrix') && possibleLeachate &&
              this.state.isHovering && field.get('title') === 'Matrix' && possibleLeachate &&
              <div >
                <font color="red">Soil results in ug/L may be Leachate.</font>
              </div>
            }

              {fieldComponent}
          </div>
          )
       }
      })
    }

    return (
      <div className="filterBar" style={{height:this.props.template.compHeight}}>
        <h3 id='filterTitle'>Filters</h3>
        <div className='filterContainer'>
          <h4 style={{'color': analyteColor}}>
            Parameter
          </h4>
          <div  style={{padding:'5px', zIndex: 10000}}>
            <Select
              value={{value: this.props.analyte, label: (this.props.isFetching ? "Loading..." : this.props.analyte )}}
              options={groupedOptions}
              onChange={this.selectAnalyte.bind(this)}
              clearable={false}
              style={{'textAlign':'left'}}
            />
          </div>
        </div>
        {filterBar}
      </div>
    )
  }
}

export default FilterBar;
