import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeHotSpotUnit, updateHotSpotRadius } from '../../actions/uitools';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/statBar.css';

class HotSpotToolComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      choice: props.hotSpotUnit,
      value: props.hotSpotRadius,
      showInfo: false
    }
  }

  handleInfo(e) {
    this.setState({showInfo: !this.state.showInfo});
  }

  handleChange(e) {
    //console.log(e.target.value);
  }

  handleRadioChange(e){
    var value = e.target.value;
    this.setState({choice: value})
    this.props.dispatch(changeHotSpotUnit(value));
  }

  handleRadiusUpdate(e) {
    /* var value = e.target.value;
    this.setState({value: value})
    this.props.dispatch(updateHotSpotRadius(value)); */
    var value = e.target.value
    if (!isNaN(parseInt(+value, 10))) {
      this.setState({ isValid: true, value: parseInt(+value, 10) })
    } else {
      this.setState({ isValid: false, value: value })
    }
  }

  handleSubmit(e) {
    this.props.dispatch(updateHotSpotRadius(this.state.value))
    // this.props.dispatch(changeHotSpotUnit(this.state.choice))
  }

  render() {

    var unit = this.state.choice;
    var radius = this.state.value;
    var valid = this.state.isValid;

    return (
      <div style={{paddingTop: '5px', marginTop: '7px', borderTop: '1px solid'}}>
        <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='question-circle' size='lg' /></span>
        <h5>Hot Spot Layer</h5>
        {this.state.showInfo ? (
        <div className='toolInfo'>
          <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='times' size='lg' /></span>
          <p>This tool uses the summary statistic (mean, median, max, etc.) of all the shown locations to color the map in a radius
          around the sample location. </p>
          Filtering out extreme values (or locations with extreme values) from the map will improve the meaningfulness of the resulting clusters most of the time. Finding spatial patterns in the
          clusters is one way this tool may be useful.
        </div>) : (null) }
        <div className="form-group has-danger">
          {/*<label className="form-control-label">Hot Spot Unit</label>
          <div style={{paddingLeft: '2px'}} onChange={this.handleRadioChange.bind(this)}>
            <input type="radio" checked={unit === 'pixel'} value="pixel" name="gender" onChange={this.handleChange.bind(this)}/> Pixels
            <input className="radioHotSpot" type="radio" checked={unit === 'kilometer'} value="kilometer" name="gender" onChange={this.handleChange.bind(this)}/> Kilometers
          </div>
          <br></br>*/}
          <label className="form-control-label">Hot Spot Radius ({unit}s)</label>
          <input type="text" value={radius} className={valid ? "form-control" : "form-control is-invalid"} onChange={this.handleRadiusUpdate.bind(this)}/>

        </div>
        <button style={{ float: 'left' }} disabled={!this.state.isValid} type="submit" className={this.state.isValid ? 'btn btn-primary' : 'btn btn-primary disabled'} onClick={this.handleSubmit.bind(this)}>Update</button>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    hotSpotUnit: state.MapApp.get('hotSpotUnit'),
    hotSpotRadius: state.MapApp.get('hotSpotRadius')
  }
}

const HotSpotTool = connect(mapStateToProps)(HotSpotToolComp);

export default HotSpotTool;
