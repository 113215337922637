import ReactGA from 'react-ga';
import Query from '../Query';
import fileDownload from 'js-file-download';
//import XLSX from 'xlsx';
import {showLoading, hideLoading} from './index'

export const downloadXLSX = (filename) => dispatch => {
  console.log("DOWNLOADING FILE");
  dispatch(showLoading())
  var t1 = performance.now();
  return Query.search('/api/awdb/' + filename).then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'There was an error downloading the All Wells DB.'})
    } else {
      if(data.data.length){
        const blob = new Blob([new Uint8Array(data.data)], {type: data.type})
        fileDownload(blob, filename)
        dispatch(hideLoading())
      }
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Download AWDB',
          variable: 'get',
          value: (performance.now() - t1), // in milliseconds
          label: 'Download AWDB'
        });
      }
    }
  }).catch(error => {
    dispatch({ type: 'DATA_ERROR', message: 'There was an error processing the All Wells DB.'})
  });
}