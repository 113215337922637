import { fromJS, List, Map } from 'immutable';
import { createPiperData } from './piperHelper'
import * as d3 from 'd3';

let initialState = Map({
  "radius":"location",
  "scalePoints": true,
  "domain": [0,0],
  "piperSize":[750,600],
  "modPiperSize":[600,600],
  "durovSize":[600,600],
  "data": List(),
  "loaded": false,
  "errorMessage": null
});

const PiperApp = (state = initialState, action) => {

  var newState = Map();
  var newerState = Map();

  switch(action.type) {

/* * * * * * * * * ACTION RECEIVE DATA * * * * * * * * */
    case "API_ERROR":
      return state.set('errorMessage', action.message);

    case "DATA_ERROR":
      return state.set('errorMessage', action.message);

    case "RECEIVE_PIPER_DATA":
      return state.set('data', fromJS(createPiperData(action.data.data))).set('loaded', true)

    case "CLEAR_ERROR_MESSAGE":
      return state.set('errorMessage', null);

    case "CHOOSE_RADIUS":
      return state.set('radius', action.value)

    case "MAP_DATA_FILTERED":  
      var names = [];  
      var stats = [];
      var latlong = List();      
      if(action.matrix.size > 0) {
        if(action.matrix.get(0).get('filter') === 'Ground Water') {      
          action.data.forEach(function(loc) {   
            names.push(loc.get("location"));  
            stats.push(loc.get(state.get('radius')));
          });
          newState = state.update( 
            'data',
            data => data.map(item =>
              {
                if(names.indexOf(item.get('location')) >= 0) {
                  return item.update(
                    'visible',
                    visible => true
                  ).update('stat', stat => stats[names.indexOf(item.get('location'))])
                } else {
                  return item.update(
                    'visible',
                    visible => false
                  ).update('stat', stat => 0)
                }
              }
            ))
          return newState.set('domain', d3.extent(stats));
        } else {  
          // set all to false first
          newState = state.update('data', data => data.map(item => item.update('visible', visible => false).update('stat', stat => 0)));

          // now turn on any records with a lat/long combo within 10 meters (~0.0001 decimal degrees) of a site currently visible on the map
          newerState = newState.update( 
            'data',
            data => data.map(item =>
              {
                latlong = action.data.filter(loc => (loc.get("lat") >= (item.get("lat") - 0.0001)) && (loc.get("lat") <= (item.get("lat") + 0.0001)) && (loc.get("long") >= (item.get("long") - 0.0001)) && (loc.get("long") <= (item.get("long") + 0.0001)));
                latlong.forEach(function(loc) {   
                  stats.push(loc.get(state.get('radius')));
                });
                if(latlong.size > 0) {
                  return item.update(
                    'visible',
                    visible => true
                  ).update('stat', stat => latlong.get(0).get(state.get('radius')))
                } else {
                  return item
                }
              }
            ))
          return newerState.set('domain', d3.extent(stats));        
        }  
      } else {
        return state;
      }            
                     
    case "RESIZE_PLOT_MODAL":
      switch(action.plot) {
        case 'piper':
          return state.set('piperSize',[action.width, action.height]);
        case 'modpiper':
          return state.set('modPiperSize',[action.width, action.height]);
        case 'durov':
          return state.set('durovSize',[action.width, action.height]);
        default:
          return state;
      }

    default:
      return state;
  }
}

export default PiperApp;
