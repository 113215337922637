//      
/* eslint-disable */
import React, { Component,                            } from 'react';
import ScrollCaptor from 'react-scroll-captor';

import { createFilter } from './filters';
import { DummyInput } from './internal';
import { cleanValue, noop, scrollIntoView } from './utils';
import {
  formatGroupLabel,
  getOptionLabel,
  getOptionValue,
  isOptionDisabled,
} from './builtins';

import {
  defaultComponents,
                        
                              
} from './components/index';
import { A11yText } from './primitives';
import { defaultStyles,                   } from './styles';

             
             
              
                 
                    
            
                       
                
              
             
            
                 

                                                 
                              
                                    
                     
                         
  

              
                                                                                                
                              
                                       
                        
                                                                                   
                             
                                            
                      
                                                                           
                                 
                                                                                              
                             
                                                             
                             
                                
                                     
                                                                             
                     
                                                                            
                             
                                                                                 
                                                     
                                                            
                                            
                                                                         
                                                                  
                                                                                   
                                        
                                                                                        
                                        
                                              
                               
                                     
                     
                                                                          
                               
                                     
                        
                              
                      
                                                   
                     
                                                                           
                                                    
                                                                           
                                                          
                                         
                   
                                             
                 
                                              
                        
                                                   
                                                     
                                                   
                        
                                                              
                         
                                
                      
                                                                
                               
                                                                               
                          
                                                                                   
                
                                                 
                                                       
                                         
                             
                                          
                                            
                                          
                              
                                         
                                
                                            
                                   
                               
                         
                               
                          
                                                      
                       
                                                                          
                   
                                             
                      
                                         
                                                    
                              
                       
                                                                     
                           
                                                                 
                   
  

const defaultProps = {
  backspaceRemovesValue: true,
  captureMenuScroll: true,
  closeMenuOnSelect: true,
  components: {},
  escapeClearsValue: false,
  filterOption: createFilter(),
  formatGroupLabel: formatGroupLabel,
  getOptionLabel: getOptionLabel,
  getOptionValue: getOptionValue,
  hideSelectedOptions: true,
  isDisabled: false,
  isLoading: false,
  isMulti: false,
  isRtl: false,
  isSearchable: true,
  isOptionDisabled: isOptionDisabled,
  loadingMessage: () => 'Loading...',
  maxMenuHeight: 300,
  maxValueHeight: 100,
  menuIsOpen: false,
  menuPlacement: 'bottom',
  menuShouldFlip: true,
  noOptionsMessage: () => 'No options',
  options: [],
  pageSize: 5,
  placeholder: 'Select...',
  screenReaderStatus: ({ count }                   ) =>
    `${count} result${count !== 1 ? 's' : ''} available.`,
  styles: {},
  tabSelectsValue: true,
};

                    
                            
                               
  

              
                         
                     
                                   
                           
                           
  

                           

let instanceId = 1;

export default class Select extends Component               {
  static defaultProps = defaultProps;
  blockOptionHover          = false;
                               
                    // TODO
                    
                                 
  hasGroups          = false;
                
  inputHeight          = 20;
                                     
  instancePrefix         = '';
                        
  openAfterFocus          = false;
  scrollToFocusedOptionOnUpdate          = false;
  state = {
    focusedOption: null,
    inputIsHidden: false,
    isFocused: false,
    menuOptions: { render: [], focusable: [] },
    selectValue: [],
  };
  constructor(props       ) {
    super(props);
    const { options, value } = props;
    this.components = defaultComponents(props);
    this.instancePrefix =
      'react-select-' + (this.props.instanceId || ++instanceId) + '-';

    const selectValue = cleanValue(value);
    const menuOptions = this.buildMenuOptions(options, selectValue);

    this.state.menuOptions = menuOptions;
    this.state.selectValue = selectValue;
  }
  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }
  componentWillReceiveProps(nextProps       ) {
    const { components, options, value, inputValue } = this.props;
    // re-cache custom components
    if (nextProps.components !== components) {
      this.components = defaultComponents(nextProps);
    }
    // rebuild the menu options
    if (
      nextProps.value !== value ||
      nextProps.options !== options ||
      nextProps.inputValue !== inputValue
    ) {
      const selectValue = cleanValue(nextProps.value);
      const menuOptions = this.buildMenuOptions(
        nextProps.options,
        selectValue,
        nextProps.inputValue
      );
      const focusedOption = this.getNextFocusedOption(menuOptions.focusable);
      this.setState({ menuOptions, selectValue, focusedOption });
    }
    // some updates should toggle the state of the input visibility
    if (this.inputIsHiddenAfterUpdate != null) {
      this.setState({
        inputIsHidden: this.inputIsHiddenAfterUpdate,
      });
      delete this.inputIsHiddenAfterUpdate;
    }
  }

  componentDidUpdate() {
    if (
      this.menuRef &&
      this.focusedOptionRef &&
      this.scrollToFocusedOptionOnUpdate
    ) {
      scrollIntoView(this.menuRef, this.focusedOptionRef);
    }
    this.scrollToFocusedOptionOnUpdate = false;
  }
  getNextFocusedOption(options             ) {
    const { focusedOption: lastFocusedOption } = this.state;
    return lastFocusedOption && options.indexOf(lastFocusedOption) > -1
      ? lastFocusedOption
      : options[0];
  }
  buildMenuOptions(
    options             ,
    selectValue             ,
    inputValue         = ''
  )              {
    const { hideSelectedOptions, isMulti } = this.props;

    const toOption = (option, id) => {
      const isDisabled = this.isOptionDisabled(option);
      const isSelected = this.isOptionSelected(option, selectValue);
      const label = this.getOptionLabel(option);
      const value = this.getOptionValue(option);

      if (
        (isMulti && hideSelectedOptions && isSelected) ||
        !this.filterOption({ label, value, data: option }, inputValue)
      ) {
        return;
      }

      const onHover = isDisabled ? undefined : () => this.onOptionHover(option);
      const onSelect = isDisabled ? undefined : () => this.selectOption(option);
      const optionId = `${this.getElementId('option')}-${id}`;

      return {
        innerProps: {
          'aria-selected': isSelected,
          id: optionId,
          onClick: onSelect,
          onMouseMove: onHover,
          onMouseOver: onHover,
          role: 'option',
          tabIndex: -1,
        },
        data: option,
        isDisabled,
        isSelected,
        key: optionId,
        label,
        type: 'option',
        value,
      };
    };

    return options.reduce(
      (acc, item, itemIndex) => {
        if (item.options) {
          // TODO needs a tidier implementation
          if (!this.hasGroups) this.hasGroups = true;

          const { options: items } = item;
          const children = items
            .map((child, i) => {
              const option = toOption(child, `${itemIndex}-${i}`);
              if (option && !option.isDisabled) acc.focusable.push(child);
              return option;
            })
            .filter(Boolean);
          if (children.length) {
            const groupId = `${this.getElementId('group')}-${itemIndex}`;
            acc.render.push({
              type: 'group',
              key: groupId,
              data: item,
              options: children,
            });
          }
        } else {
          const option = toOption(item, itemIndex);
          if (option) {
            acc.render.push(option);
            if (!option.isDisabled) acc.focusable.push(item);
          }
        }
        return acc;
      },
      { render: [], focusable: [] }
    );
  }
  filterOption(option    , inputValue        ) {
    return this.props.filterOption
      ? this.props.filterOption(option, inputValue)
      : true;
  }
  formatOptionLabel(data            , context                          )       {
    if (typeof this.props.formatOptionLabel === 'function') {
      const { inputValue } = this.props;
      const { selectValue } = this.state;
      return this.props.formatOptionLabel(data, {
        context,
        inputValue,
        selectValue,
      });
    } else {
      return this.getOptionLabel(data);
    }
  }
  formatGroupLabel(data           ) {
    return this.props.formatGroupLabel(data);
  }
  getOptionLabel(data            )         {
    return this.props.getOptionLabel(data);
  }
  getOptionValue(data            )         {
    return this.props.getOptionValue(data);
  }
  getStyles = (key        , props    )     => {
    const base = defaultStyles[key](props);
    base.boxSizing = 'border-box';
    const custom = this.props.styles[key];
    return custom ? custom(base, props) : base;
  };
  hasValue() {
    const { selectValue } = this.state;
    return selectValue.length > 0;
  }
  hasOptions() {
    return !!this.state.menuOptions.render.length;
  }
  countOptions() {
    return this.state.menuOptions.focusable.length;
  }
  isClearable()          {
    const { isClearable, isMulti } = this.props;

    // single select, by default, IS NOT clearable
    // multi select, by default, IS clearable
    if (isClearable === undefined) return isMulti;

    return isClearable;
  }
  isOptionDisabled(option            )          {
    return typeof this.props.isOptionDisabled === 'function'
      ? this.props.isOptionDisabled(option)
      : false;
  }
  isOptionSelected(option            , selectValue             )          {
    if (selectValue.indexOf(option) > -1) return true;
    if (typeof this.props.isOptionSelected === 'function') {
      return this.props.isOptionSelected(option, selectValue);
    }
    const candidate = this.getOptionValue(option);
    return selectValue.some(i => this.getOptionValue(i) === candidate);
  }
  focus() {
    if (!this.input) return;
    this.input.focus();
  }
  blurInput() {
    if (!this.input) return;
    this.input.blur();
  }
  onMenuOpen() {
    this.props.onMenuOpen();
  }
  onMenuClose() {
    this.props.onMenuClose();
  }
  onInputChange(newValue        ) {
    this.props.onInputChange(newValue);
  }
  openMenu(focusOption                   = 'first') {
    const { menuOptions, selectValue } = this.state;
    const { isMulti } = this.props;

    let openAtIndex =
      focusOption === 'first' ? 0 : menuOptions.focusable.length - 1;

    if (!isMulti) {
      const selectedIndex = menuOptions.focusable.indexOf(selectValue[0]);
      if (selectedIndex > -1) {
        openAtIndex = selectedIndex;
      }
    }

    this.scrollToFocusedOptionOnUpdate = true;
    this.inputIsHiddenAfterUpdate = false;
    this.onMenuOpen();
    this.setState({
      focusedOption: menuOptions.focusable[openAtIndex],
    });
  }
  focusOption(direction                 = 'first') {
    const { pageSize } = this.props;
    const { focusedOption, menuOptions } = this.state;
    const options = menuOptions.focusable;
    if (!options.length) return;
    let nextFocus = 0; // handles 'first'
    const focusedIndex = focusedOption ? options.indexOf(focusedOption) : -1;
    if (direction === 'up') {
      nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1;
    } else if (direction === 'down') {
      nextFocus = (focusedIndex + 1) % options.length;
    } else if (direction === 'pageup') {
      nextFocus = focusedIndex - pageSize;
      if (nextFocus < 0) nextFocus = 0;
    } else if (direction === 'pagedown') {
      nextFocus = focusedIndex + pageSize;
      if (nextFocus > options.length - 1) nextFocus = options.length - 1;
    } else if (direction === 'last') {
      nextFocus = options.length - 1;
    }
    this.scrollToFocusedOptionOnUpdate = true;
    this.setState({
      focusedOption: options[nextFocus],
    });
  }
  setValue = (newValue           , action              = 'set-value') => {
    const { closeMenuOnSelect, isMulti, onChange } = this.props;
    // We update the state first because we should clear inputValue when an
    // option is selected; the onChange event fires when that's reconciled
    // otherwise the new menu items will be filtered with the old inputValue
    this.onInputChange('');
    if (closeMenuOnSelect) {
      this.inputIsHiddenAfterUpdate = !isMulti;
      this.onMenuClose();
    }
    onChange(newValue, { action });
  };
  selectOption = (newValue            ) => {
    const { isMulti } = this.props;
    if (isMulti) {
      const { selectValue } = this.state;
      if (this.isOptionSelected(newValue, selectValue)) {
        this.setValue(
          selectValue.filter(i => i !== newValue),
          'deselect-option'
        );
      } else {
        this.setValue([...selectValue, newValue], 'select-option');
      }
    } else {
      this.setValue(newValue, 'select-option');
    }
  };
  removeValue = (removedValue            ) => {
    const { onChange } = this.props;
    const { selectValue } = this.state;
    onChange(selectValue.filter(i => i !== removedValue), {
      action: 'remove-value',
    });
    this.focus();
  };
  clearValue = () => {
    const { isMulti, onChange } = this.props;
    onChange(isMulti ? [] : null, { action: 'clear' });
  };
  popValue = () => {
    const { onChange } = this.props;
    const { selectValue } = this.state;
    onChange(selectValue.slice(0, selectValue.length - 1), {
      action: 'pop-value',
    });
  };
  onControlRef = (ref               ) => {
    this.controlRef = ref;
  };
  onControlMouseDown = (event                                  ) => {
    if (!this.state.isFocused) {
      this.openAfterFocus = true;
      this.focus();
    } else if (!this.state.menuIsOpen) {
      this.openMenu('first');
    } else {
      this.onMenuClose();
    }
    if (event.target.tagName !== 'INPUT') {
      event.preventDefault();
    }
  };
  onKeyDown = (event                                     ) => {
    const {
      backspaceRemovesValue,
      escapeClearsValue,
      inputValue,
      isClearable,
      isDisabled,
      menuIsOpen,
      onKeyDown,
      tabSelectsValue,
    } = this.props;
    const { focusedOption } = this.state;

    if (isDisabled) return;

    if (typeof onKeyDown === 'function') {
      onKeyDown(event);
      if (event.defaultPrevented) {
        return;
      }
    }

    // Block option hover events when the user has just pressed a key
    this.blockOptionHover = true;

    switch (event.keyCode) {
      case 8: // backspace
        if (inputValue || !backspaceRemovesValue) return;
        this.popValue();
        break;
      case 9: // tab
        if (
          event.shiftKey ||
          !menuIsOpen ||
          !tabSelectsValue ||
          !focusedOption
        ) {
          return;
        }
        this.selectOption(focusedOption);
        return;
      case 13: // enter
        if (menuIsOpen) {
          if (!focusedOption) return;
          this.selectOption(focusedOption);
        } else {
          this.focusOption('first');
        }
        break;
      case 27: // escape
        if (menuIsOpen) {
          this.inputIsHiddenAfterUpdate = false;
          this.onInputChange('');
          this.onMenuClose();
        } else if (isClearable && escapeClearsValue) {
          this.clearValue();
        }
        break;
      case 32: // space
        if (inputValue) {
          return;
        }
        if (!menuIsOpen) {
          this.openMenu();
          break;
        }
        if (!focusedOption) return;
        this.selectOption(focusedOption);
        break;
      case 38: // up
        if (menuIsOpen) {
          this.focusOption('up');
        } else {
          this.openMenu('last');
        }
        break;
      case 40: // down
        if (menuIsOpen) {
          this.focusOption('down');
        } else {
          this.openMenu('first');
        }
        break;
      case 33: // page up
        if (!menuIsOpen) return;
        this.focusOption('pageup');
        break;
      case 34: // page down
        if (!menuIsOpen) return;
        this.focusOption('pagedown');
        break;
      case 36: // home key
        if (!menuIsOpen) return;
        this.focusOption('first');
        break;
      case 35: // end key
        if (!menuIsOpen) return;
        this.focusOption('last');
        break;
      default:
        return;
    }
    event.preventDefault();
  };
  // TODO: Review whether this is necessary
  onInputRef = (input       ) => {
    this.input = input;

    // cache the input height to use when the select is disabled
    if (input) {
      this.inputHeight = input.clientHeight;
    }
  };
  handleInputChange = (event                                          ) => {
    const inputValue = event.currentTarget.value;
    this.inputIsHiddenAfterUpdate = false;
    this.onInputChange(inputValue);
    this.onMenuOpen();
  };
  onInputFocus = (event                                       ) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
    this.inputIsHiddenAfterUpdate = false;
    this.setState({
      isFocused: true,
    });
    if (this.openAfterFocus) {
      this.openMenu('first');
    }
    this.openAfterFocus = false;
  };
  onInputBlur = (event                                       ) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    this.onInputChange('');
    this.onMenuClose();
    this.setState({
      isFocused: false,
    });
  };
  onMenuRef = (ref               ) => {
    this.menuRef = ref;
  };
  onMenuMouseDown = (event                                  ) => {
    if (event.button !== 0) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.focus();
  };
  onMenuMouseMove = (event                                  ) => {
    this.blockOptionHover = false;
  };
  onFocusedOptionRef = (ref               ) => {
    this.focusedOptionRef = ref;
  };
  onOptionHover = (focusedOption            ) => {
    if (this.blockOptionHover || this.state.focusedOption === focusedOption) {
      return;
    }
    this.setState({ focusedOption });
  };
  onDropdownIndicatorMouseDown = (event                                  ) => {
    // ignore mouse events that weren't triggered by the primary button
    if (event && event.type === 'mousedown' && event.button !== 0) {
      return;
    }
    if (this.props.isDisabled) return;
    const { isMulti, menuIsOpen } = this.props;
    if (!this.focused) {
      this.focus();
    }
    if (menuIsOpen) {
      this.inputIsHiddenAfterUpdate = !isMulti;
      this.onMenuClose();
    } else {
      this.openMenu();
    }
    event.preventDefault();
    event.stopPropagation();
  };
  onClearIndicatorMouseDown = (event                                  ) => {
    // ignore mouse events that weren't triggered by the primary button
    if (event && event.type === 'mousedown' && event.button !== 0) {
      return;
    }
    this.clearValue();
    event.stopPropagation();
    this.openAfterFocus = false;
    setTimeout(() => this.focus());
  };
  getElementId = (element                                          ) => {
    return `${this.instancePrefix}-${element}`;
  };
  getActiveDescendentId = () => {
    const { menuIsOpen } = this.props;
    const { focusedOption } = this.state;
    return focusedOption && menuIsOpen ? focusedOption.key : undefined;
  };
  renderScreenReaderStatus() {
    const { screenReaderStatus } = this.props;
    return (
      <A11yText aria-atomic="true" aria-live="polite" role="status">
        {screenReaderStatus({ count: this.countOptions() })}
      </A11yText>
    );
  }
  renderInput(id        ) {
    const {
      isDisabled,
      isLoading,
      isSearchable,
      inputValue,
      menuIsOpen,
    } = this.props;
    const { Input } = this.components;
    const { inputIsHidden } = this.state;

    if (!isSearchable) {
      // use a dummy input to maintain focus/blur functionality
      return (
        <DummyInput
          onBlur={this.onInputBlur}
          onChange={noop}
          onFocus={this.onInputFocus}
          innerRef={this.onInputRef}
          value=""
        />
      );
    } else if (isDisabled) {
      // maintain baseline alignment when the input is removed for disabled state
      return <div style={{ height: this.inputHeight }} />;
    }

    // aria attributes makes the JSX "noisy", separated for clarity
    const ariaAttributes = {
      'aria-activedescendant': this.getActiveDescendentId(),
      'aria-autocomplete': 'list',
      'aria-busy': isLoading,
      'aria-describedby': this.props['aria-describedby'],
      'aria-expanded': menuIsOpen,
      'aria-haspopup': menuIsOpen,
      'aria-label': this.props['aria-label'],
      'aria-labelledby': this.props['aria-labelledby'],
      'aria-owns': menuIsOpen ? this.getElementId('listbox') : undefined,
      role: 'combobox',
    };

    return (
      <Input
        autoCapitalize="none"
        autoComplete="off"
        autoCorrect="off"
        getStyles={this.getStyles}
        id={id}
        innerRef={this.onInputRef}
        isHidden={inputIsHidden}
        onBlur={this.onInputBlur}
        onChange={this.handleInputChange}
        onFocus={this.onInputFocus}
        spellCheck="false"
        tabIndex="0"
        type="text"
        value={inputValue}
        {...ariaAttributes}
      />
    );
  }
  renderPlaceholderOrValue() {
    const {
      MultiValue,
      MultiValueContainer,
      MultiValueLabel,
      MultiValueRemove,
      SingleValue,
      Placeholder,
    } = this.components;
    const { commonProps } = this;
    const { isDisabled, isMulti, inputValue, placeholder } = this.props;
    const { selectValue } = this.state;

    if (!this.hasValue()) {
      return inputValue ? null : (
        <Placeholder {...commonProps} key="placeholder" isDisabled={isDisabled}>
          {placeholder}
        </Placeholder>
      );
    }
    if (isMulti) {
      return selectValue.map(opt => (
        <MultiValue
          {...commonProps}
          components={{
            Container: MultiValueContainer,
            Label: MultiValueLabel,
            Remove: MultiValueRemove,
          }}
          isDisabled={isDisabled}
          key={this.getOptionValue(opt)}
          removeProps={{
            onClick: () => this.removeValue(opt),
            onMouseDown: e => {
              e.preventDefault();
              e.stopPropagation();
            },
          }}
          data={opt}
        >
          {this.formatOptionLabel(opt, 'value')}
        </MultiValue>
      ));
    }
    if (inputValue) return null;
    const singleValue = selectValue[0];
    return (
      <SingleValue {...commonProps} data={singleValue} isDisabled={isDisabled}>
        {this.formatOptionLabel(singleValue, 'value')}
      </SingleValue>
    );
  }
  renderClearIndicator() {
    const { ClearIndicator } = this.components;
    const { commonProps } = this;
    const { isDisabled, isLoading } = this.props;
    const { isFocused } = this.state;

    if (
      !this.isClearable() ||
      !ClearIndicator ||
      isDisabled ||
      !this.hasValue() ||
      isLoading
    ) {
      return null;
    }

    const innerProps = {
      onMouseDown: this.onClearIndicatorMouseDown,
      role: 'button',
    };

    return (
      <ClearIndicator
        {...commonProps}
        innerProps={innerProps}
        isFocused={isFocused}
      />
    );
  }
  renderLoadingIndicator() {
    const { LoadingIndicator } = this.components;
    const { commonProps } = this;
    const { isDisabled, isLoading } = this.props;
    const { isFocused } = this.state;

    if (!LoadingIndicator || !isLoading) return null;

    const innerProps = {
      role: 'presentation',
    };

    return (
      <LoadingIndicator
        {...commonProps}
        innerProps={innerProps}
        isDisabled={isDisabled}
        isFocused={isFocused}
      />
    );
  }
  renderIndicatorSeparator() {
    const { IndicatorSeparator } = this.components;
    if (!IndicatorSeparator) return null;
    const { commonProps } = this;
    const { isDisabled } = this.props;
    const { isFocused } = this.state;

    const innerProps = { role: 'presentation' };

    return (
      <IndicatorSeparator
        {...commonProps}
        innerProps={innerProps}
        isDisabled={isDisabled}
        isFocused={isFocused}
      />
    );
  }
  renderDropdownIndicator() {
    const { DropdownIndicator } = this.components;
    if (!DropdownIndicator) return null;
    const { commonProps } = this;
    const { isDisabled } = this.props;
    const { isFocused } = this.state;

    const innerProps = {
      onMouseDown: this.onDropdownIndicatorMouseDown,
      role: 'button',
    };

    return (
      <DropdownIndicator
        {...commonProps}
        innerProps={innerProps}
        isDisabled={isDisabled}
        isFocused={isFocused}
      />
    );
  }
  renderMenu() {
    const {
      Group,
      GroupHeading,
      Menu,
      MenuList,
      LoadingMessage,
      NoOptionsMessage,
      Option,
    } = this.components;
    const { commonProps } = this;
    const { focusedOption, menuOptions } = this.state;
    const {
      captureMenuScroll,
      inputValue,
      isLoading,
      isMulti,
      loadingMessage,
      maxMenuHeight,
      menuIsOpen,
      menuPlacement,
      menuShouldFlip,
      noOptionsMessage,
    } = this.props;

    if (!menuIsOpen) return null;

    // TODO: Internal Option Type here
    const render = (props            ) => {
      // for performance, the menu options in state aren't changed when the
      // focused option changes so we calculate additional props based on that
      const isFocused = focusedOption === props.data;
      props.innerProps.innerRef = isFocused
        ? this.onFocusedOptionRef
        : undefined;

      return (
        <Option {...commonProps} {...props} isFocused={isFocused}>
          {this.formatOptionLabel(props.data, 'menu')}
        </Option>
      );
    };

    let menuUI;

    if (this.hasOptions()) {
      menuUI = menuOptions.render.map(item => {
        if (item.type === 'group') {
          const { type, ...group } = item;
          const headingId = `${item.key}-heading`;

          return (
            <Group
              {...commonProps}
              {...group}
              Heading={GroupHeading}
              innerProps={{
                'aria-expanded': true,
                'aria-labelledby': headingId,
                role: 'group',
              }}
              headingProps={{
                id: headingId,
              }}
              label={this.formatGroupLabel(item.data)}
            >
              {item.options.map(option => render(option))}
            </Group>
          );
        } else if (item.type === 'option') {
          return render(item);
        }
      });
    } else if (isLoading) {
      menuUI = (
        <LoadingMessage {...commonProps}>
          {loadingMessage({ inputValue })}
        </LoadingMessage>
      );
    } else {
      menuUI = (
        <NoOptionsMessage {...commonProps}>
          {noOptionsMessage({ inputValue })}
        </NoOptionsMessage>
      );
    }

    return (
      <Menu
        {...commonProps}
        innerProps={{
          onMouseDown: this.onMenuMouseDown,
          onMouseMove: this.onMenuMouseMove,
        }}
        isLoading={isLoading}
        menuPlacement={menuPlacement}
        menuShouldFlip={menuShouldFlip}
      >
        <ScrollCaptor isEnabled={captureMenuScroll}>
          <MenuList
            {...commonProps}
            innerProps={{
              'aria-multiselectable': isMulti,
              id: this.getElementId('listbox'),
              innerRef: this.onMenuRef,
              role: 'listbox',
            }}
            isLoading={isLoading}
            maxHeight={maxMenuHeight}
          >
            {menuUI}
          </MenuList>
        </ScrollCaptor>
      </Menu>
    );
  }
  renderFormField() {
    const { delimiter, isDisabled, isMulti, name } = this.props;
    const { selectValue } = this.state;

    if (!name || isDisabled) return;

    if (isMulti) {
      if (delimiter) {
        const value = selectValue
          .map(opt => this.getOptionValue(opt))
          .join(delimiter);
        return <input name={name} type="hidden" value={value} />;
      } else {
        return (
          <div>
            {selectValue.map((opt, i) => (
              <input
                key={`i-${i}`}
                name={name}
                type="hidden"
                value={this.getOptionValue(opt)}
              />
            ))}
          </div>
        );
      }
    } else {
      const value = selectValue[0] ? this.getOptionValue(selectValue[0]) : '';
      return <input name={name} type="hidden" value={value} />;
    }
  }
  getCommonProps() {
    const { clearValue, getStyles, setValue, selectOption, props } = this;
    const { isMulti, isRtl, options } = props;
    const { selectValue } = this.state;
    const hasValue = this.hasValue();
    const getValue = () => selectValue;
    return {
      clearValue,
      getStyles,
      getValue,
      hasValue,
      isMulti,
      isRtl,
      options,
      selectOption,
      setValue,
      selectProps: props,
    };
  }
  render() {
    const {
      Control,
      IndicatorsContainer,
      SelectContainer,
      ValueContainer,
    } = this.components;

    const { isDisabled, maxValueHeight } = this.props;
    const { isFocused } = this.state;
    const inputId = this.getElementId('input');

    const commonProps = (this.commonProps = this.getCommonProps());

    return (
      <SelectContainer
        {...commonProps}
        innerProps={{
          onKeyDown: this.onKeyDown,
        }}
        isDisabled={isDisabled}
        isFocused={isFocused}
      >
        {this.renderScreenReaderStatus()}
        <Control
          {...commonProps}
          innerProps={{
            onMouseDown: this.onControlMouseDown,
            innerRef: this.onControlRef,
          }}
          isDisabled={isDisabled}
          isFocused={isFocused}
        >
          <ValueContainer
            {...commonProps}
            isDisabled={isDisabled}
            maxHeight={maxValueHeight}
          >
            {this.renderPlaceholderOrValue()}
            {this.renderInput(inputId)}
          </ValueContainer>
          <IndicatorsContainer {...commonProps} isDisabled={isDisabled}>
            {this.renderClearIndicator()}
            {this.renderLoadingIndicator()}
            {this.renderIndicatorSeparator()}
            {this.renderDropdownIndicator()}
          </IndicatorsContainer>
        </Control>
        {this.renderMenu()}
        {this.renderFormField()}
      </SelectContainer>
    );
  }
}
