import React, { Component } from 'react';
import { List } from 'immutable';
import * as d3 from 'd3';
import '../../css/statBar.css';
import {chooseFrequency} from '../../actions/map';

class SamplingOverTime extends Component {

  constructor(props){
    super(props);
    this.svgHeight = this.props.template.height;
    this.svgMargin = 15;
    this.svgWidth = this.props.template.width;
    this.scaleHeight = 30; //xAxisHeight
    this.yAxisWidth = 30;
    this.plotHeight = this.svgHeight - ((2*this.svgMargin) + this.scaleHeight);
    this.plotWidth = this.svgWidth - ((2*this.svgMargin) + this.yAxisWidth);
    this.topBubble = 10;

    this.state = {
      quarter: '',
      samples: 0,
      date: new Date(),
      xPos: 0,
      yPos: 0,
      p: 0
    }
  }

  componentDidMount() {
    this.renderPlot();
  }

  componentDidUpdate() {
    this.renderPlot();
  }

  updateGuide(p, x, y, q, s, d) {
    this.setState({
      quarter: q,
      samples: s,
      date: d,
      xPos: x,
      yPos: y,
      p: p
    })
  }

  renderPlot(){

    d3.selectAll('.freqPlotContainer').remove();

    const { p, date, samples } = this.state;

    const parseDate = d3.timeParse("%Y-%m-%d");

    if(!this.props.data.size) return;

    const getDate = (q) => {
      var year = q.substring(0,4);
      var day = '15';
      var month;
      if(q.slice(-2) === 'Q1') {
        month = '02';
      } else if(q.slice(-2) === 'Q2') {
        month = '05';
      } else if(q.slice(-2) === 'Q3') {
        month = '08';
      } else {
        month = '11';
      }
      return parseDate(year + '-' + month + '-' + day);
    };

    var monthFormat = d3.timeFormat("%m");
    const getQuarter = (d) => {
      var month = monthFormat(d);
      if(month === '02') {
        return 'Q1';
      } else if(month === '05') {
        return 'Q2';
      } else if(month === '08') {
        return 'Q3';
      } else {
        return 'Q4';
      }
    };

    const sortByQuarter = (a, b) => {
      if(a === null || b === null) return 0;
      if ( parseInt(a.replace(' - Q', ''), 10) > parseInt(b.replace(' - Q', ''), 10) ) return 1;
      if ( parseInt(a.replace(' - Q', ''), 10) < parseInt(b.replace(' - Q', ''), 10) ) return -1;
      return 0;
    };

    var data = [];
    var quarters = this.props.data.keySeq();
    var orderedQuarters = quarters.sort(sortByQuarter);

    orderedQuarters.forEach((q,i,a) => {
      if(q !== null) {
        data.push({ quarter: q, samples: this.props.data.get(q).size, date: getDate(q) });
      }
    });

    var dateDomain = d3.extent(data, (d) => d.date);

    const getClosest = (val, arr, obj = 'date') => {
      var curr = arr[0];
      var diff = Math.abs(val - curr[obj]);
      for (var idx = 0; idx < arr.length; idx++) {
          var newdiff = Math.abs(val - arr[idx][obj]);
          if (newdiff < diff) {
              diff = newdiff;
              curr = arr[idx];
          }
      }
      return curr;
    }

    /*var xRanges =[];
    var xIncrement = this.plotWidth / data.length;

    orderedQuarters.forEach((q,i,a) => {
      xRanges.push(0 + (i * xIncrement));
    });*/

    var svg = d3.select(this.refs.freqPlot);

    var plotContainer = svg.append('g')
      .attr('class','freqPlotContainer')
      .attr('transform', "translate(0," + this.topBubble + ")")
      .attr('style',"width:" + (this.plotWidth + this.yAxisWidth ) + "px")

    var x = d3.scaleTime()
      .domain(dateDomain)
      .range([0, this.plotWidth])
      //.nice();

    var xAxisQuarter = d3.axisBottom(x)
      //.tickFormat(d3.timeFormat('%b'))
      .tickFormat(d => getQuarter(d))
      .ticks(6);

    var xAxisYear = d3.axisBottom(x)
      .tickFormat(d3.timeFormat("%Y"))
      .ticks(6);

    /*var x = d3.scaleOrdinal()
      .domain(orderedQuarters.toJS())
      .range(xRanges);

    var xAxisQuarter = d3.axisBottom(x)
      .tickFormat(d => d.slice(-2))
      .ticks(5);

    var xAxisYear = d3.axisBottom(x)
      .tickFormat(d => d.substring(0,4))
      .ticks(5);*/

    //The lowest result (can be negative!?) or 0
    //let min = data.length > 0 ? d3.min(data.map(x => x.samples)) : 0;
    let max = data.length > 0 ? d3.max(data.map(x => x.samples)) : 0;

    var y = d3.scaleLinear()
      .domain([max, 0])
      .range([0, this.plotHeight])
      //.nice();

    var yAxis = d3.axisLeft(y).tickFormat(d3.format(".2s"));

    var guide_line = d3.line()
      .x(d => { return x(d.x) }) 
      .y(d => { return y(d.y) });

    var guide = d3.select(".tooltip"); 

    var valuearea = d3.area()
      .x(d => x(d.date))
      .y0(this.plotHeight)
      .y1(d => y(d.samples))
      .curve(d3.curveCatmullRom);

    var valueline = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.samples))
      .curve(d3.curveCatmullRom);

    var plot = plotContainer.append('g')
      .attr('class','freqPlotG')
      .attr('transform', "translate(" + this.yAxisWidth + "," + this.topBubble + ")")
      .attr('style', "width:" + this.plotWidth + "px")

    plot.append('g')
      .append('path')
        .attr('class','freqArea')
        .attr('d', valuearea(data))

    plot.append('g')
      .append('path')
        .attr('class','freqLine')
        .attr('d', valueline(data))

    var xAxisCon = plot.append("g")
      .attr('class',"plotXAxis")
      .attr('transform', "translate(0," + this.plotHeight + ")")

     xAxisCon.append("g")
      .call(xAxisQuarter)

     xAxisCon.append("g")
      .attr('transform', "translate(0,12)")
      .attr('class','xAxisYear')
      .call(xAxisYear)

    plot.append("g")
      .attr('class',"plotYAxis")
      //.attr('transform', "translate(" + 0 + "," + this.topBubble + ")")
      .call(yAxis)

    plotContainer.append("text")
      .attr('transform',"translate(0," + (this.plotHeight/2 + this.svgMargin) + ") rotate(-90)")
      .attr('text-anchor', 'middle')
      .text("# Locations Sampled");

    var guideLine = plot.selectAll('.guideLine')
      .data([[{x: date, y: 0},{x: date, y: samples}]])
      .enter().append('g')
        .attr('class', 'guideLine')
        .style("display", "none");

    guideLine.append("path")
      .attr('class', 'axis')
      .attr('d', d => guide_line(d))
      .style('stroke', '#3e3f3a')
      .style('stroke-width', '0.8')
      .style('stroke-dasharray', ("4, 2"))

    var guideLine2 = plot.selectAll('.guideLine2')
      .data([[{x: date, y: samples},{x: date, y: max}]])
      .enter().append('g')
        .attr('class', 'guideLine')
        .style("display", "none");

    guideLine2.append("path")
      .attr('class', 'axis')
      .attr('d', d => guide_line(d))
      .style('stroke', '#3e3f3a')
      .style('stroke-width', '0.8')

    var dot = plot.selectAll('.plotDot')
      .data([{x: date, y: samples}])
      .enter().append('circle')
      .attr('class', 'plotDot')
      .attr('r', 3)
      .attr("cx", (d) => x(d.x))
      .attr("cy", (d) => y(d.y))
      .style("display", "none");

    var me = this;
    plotContainer.append("rect")
      .attr("class", "overlay")
      .attr('transform', "translate(0," + (0 - this.topBubble) + ")")
      .attr("width", this.svgWidth - this.svgMargin) // rect. extends beyond plot -- covers the yAxisWidth and the right svgMargin
      .attr("height", this.svgHeight)
      .on("mouseover", function() { 
        guide.style("display", null); 
        guideLine.style("display", null); 
        guideLine2.style("display", null); 
        dot.style("display", null); 
      })
      .on("mousemove", mousemove)
      .on("mouseout", function() { 
        guide.style("display", "none");
        guideLine.style("display", "none"); 
        guideLine2.style("display", "none"); 
        dot.style("display", "none"); 
        me.props.dispatch(chooseFrequency(List()));
      });
      

    function mousemove() {
      var x0 = x.invert((d3.mouse(this)[0] - me.yAxisWidth));
      //get closest element in data set
      var nearestQuarter = getClosest(x0, data);
      var p0 = nearestQuarter.quarter;

      if(d3.mouse(this)[0] < me.yAxisWidth || d3.mouse(this)[0] > (me.plotWidth + me.yAxisWidth)) {
        guide.style("display", "none");
        guideLine.style("display", "none"); 
        guideLine2.style("display", "none"); 
        dot.style("display", "none");
        me.props.dispatch(chooseFrequency(List()));
      } else if(p0 !== p && (x0 >= x.domain()[0] || x0 <= x.domain()[1])) { 
        guide.style("opacity", 0.9);
        me.updateGuide(p0, d3.event.pageX, d3.event.pageY, nearestQuarter.quarter, nearestQuarter.samples, nearestQuarter.date);
        me.props.dispatch(chooseFrequency(me.props.data.get(p0)));
      } 

      guide.style('left', (x(nearestQuarter.date) + (me.yAxisWidth / 2)) + 'px').style('top', (y.domain()[0] - 70) + "px").style('opacity', 0.9);//(y(max) + 20)
    }   
  }

  render(){
    const { yPos, quarter, samples } = this.state;

    var guideDiv = yPos > 0 ? (
      <div className='tooltip' style={{height: '30px', width: '60px'}}>
        {quarter}
        <br></br>
        {samples}
      </div>
    ) : null;

    return (
      <div>
        <div style={{height: '30px', position: 'relative', top: '42px'}}>
          {guideDiv}
        </div>
        <svg
          className="freqPlot"
          ref="freqPlot"
          style={{width:this.svgWidth, height:this.svgHeight, padding:"0 0 0 " + this.svgMargin}}
        >
        </svg>
      </div>
    )
  }
}

export default SamplingOverTime;
