import React, { Component } from 'react'
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import { Link } from 'react-router-dom'
//import * as validate from "validate.js";
import '../../css/query.css'
import { fetchQueryTypes, fetchQueryFilters, saveQuery, fetchRecords, fetchQueries, clearErrorMessage } from '../../actions'
import { clearQueryFilters, changeDataset } from '../../actions/query.js'
import { toggleQueryField, toggleQueryFilter, dragQueryField, searchFilters, savedQueryNotified, receiveDataNotified } from '../../actions/query.js'
import query_styles from '../../css/query.css'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

ReactModal.setAppElement('#root');

class NewQueryViewComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalMessage: '',
      dataset: 'BMI',
      name: null
    }

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.errorMessage) {
      this.setState({modalMessage: nextProps.errorMessage });
      this.setState({modalIsOpen: true});
    }

    if(nextProps.savedQuery) {
      this.setState({modalMessage: "The query has been saved to the database."});
      this.setState({modalIsOpen: true});
    }
  }
  onNameChange(e) {
    this.setState({name: e.target.value});
  }
  clearQueryBuilder() {
    this.props.dispatch(clearQueryFilters());
  }

  componentWillMount(){
    this.setState({"navBarHeight":62})
    this.setState({"queryTypeSelectHeight":66})
    this.setState({"height":window.innerHeight - 62 - 70 - 76})
    this.setState({"width":(window.innerWidth - 40)})
    this.setState({"sideMargins":40})
    this.setState({"bottomMargin":90})
    this.setState({"name":null})
    this.setState({"search":''})
    this.setState({"timeout":null})

    if(this.props.queryTypes.size === 0) {
      this.props.dispatch(fetchQueryTypes('api/query_types'));
    }
    if(this.props.filterFields.size === 0) {
      this.props.dispatch(fetchQueryFilters('api/new_query_filters'));
    }
  }

  handleDatasetChange(e) {
    this.setState({"dataset": e.target.value});
    this.props.dispatch(changeDataset(e.target.value));
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#222';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.props.dispatch(receiveDataNotified());
    this.props.dispatch(savedQueryNotified());
    this.props.dispatch(clearErrorMessage());
  }

  onSaveNewQueryButtonClick() {
    /*var constraints = {
      queryname: {
        length: {
          minimum: 3
        },
        presence: {
          allowEmpty: false
        },
        format: {
          pattern: "[a-z0-9]+",
          flags: "i",
          message: "can only contain a-z and 0-9"
        }
      }
    };
    var validationResults = validate({queryname: this.state.name}, constraints);
    console.log(validationResults);
    return;*/
    if(this.state.name === null || this.state.name === '') {  // or in existing query names!!  //TODO
      //alert('please create a new name for this query!');
      this.setState({modalMessage: "Please give your query a unique name."});
      this.setState({modalIsOpen: true});
    } else {
      this.setState({"timeout": clearTimeout(this.state.timeout)});
      this.props.dispatch(saveQuery({data: this.props.activeFilters, name: encodeURIComponent(this.state.name), records: this.props.records, query_type: this.props.queryTypes.filter(x => x.get('class') === this.state.dataset).get(0).get('id')}))

      this.setState({"timeout": setTimeout(() => {  // should make this fire on a promise instead (aka use thunk dispatch().then() functionality)
        this.props.dispatch(fetchQueries());
      }, 1000)}); // TODO - may need to adjust this
      if(this.props.env.mode === 'production') {
        ReactGA.event({
          category: 'Query',
          action: 'Saved a New Query',
          label: 'Query View',
          nonInteraction: false
        });
      }
    }
  }

  onChangeSearch(e) {
    this.setState({"search": e.target.value})
  }

  onKeyUpSearch(e) {
    this.setState({"timeout": clearTimeout(this.state.timeout)});
    this.setState({"timeout": setTimeout(() => {
      this.props.dispatch(searchFilters(this.state.search));
    }, 500)});
  }

  dragEnter(e) {
    if(e.dataTransfer.types.includes('selected')){
      e.preventDefault();
      if ( e.target.className.includes("droptarget") ) {
        e.target.style.borderWidth = "3px";
      }
    }
  }

  dragEnterSelected(e) {
    if(!e.dataTransfer.types.includes('selected')){
      e.preventDefault();
      if ( e.target.className.includes("droptarget") ) {  // need to work on this!
        e.target.style.borderWidth = "3px";
      }
    }
  }

  dragLeave(e, divId) {
    e.preventDefault();
    if ( e.target.className.includes("droptarget") ) {
      e.target.style.borderWidth = "2px";
    }
  }

  drop(e) {
    e.preventDefault();
    this.props.dispatch(dragQueryField(e.dataTransfer.getData("text")));
    e.target.style.borderWidth = "2px";
  }

  dragEnd(e) {
    e.preventDefault();
    e.target.style.opacity = "1";
  }

  drag(e) {
    e.preventDefault();
  }

  dragOver(e) {
    if(e.dataTransfer.types.includes('selected')){
      e.preventDefault();
    }
  }

  dragOverSelected(e) {
    if(!e.dataTransfer.types.includes('selected')){
      e.preventDefault();
    }
  }

  fieldDragged(id, e){
    e.target.style.opacity = "0.4";
    e.dataTransfer.setData("text", id);
  }

  fieldDraggedSelected(id, e){
    e.target.style.opacity = "0.4";
    e.dataTransfer.setData("text", 'selected' + id);
    e.dataTransfer.setData('selected', '')
  }

  fieldClicked(id, e){
    this.setState({"search":''});
    this.props.dispatch(toggleQueryField(id));
  }

  filterClicked(id, e){
    this.setState({"timeout": clearTimeout(this.state.timeout)});
    this.props.dispatch(toggleQueryFilter(id));

    this.setState({"timeout": setTimeout(() => {
      this.props.dispatch(fetchRecords(this.props.activeFilters, this.props.queryTypes.filter(x => x.get('class') === this.state.dataset).get(0).get('id')));
    }, 2000)}); // TODO - may need to adjust this
  }

  render() {

    const isFetchingQueryFilters = this.props.isFetchingQueryFilters;
    const isFetchingRecords = this.props.isFetchingRecords;

    var fields = [],
        selectedFields = [],
        filters = [],
        selectedFilters = [],
        queryTypes = [];

    queryTypes.push(
      <div key='00' className="form-check-inline">
        <b>Select Dataset to Query:</b>
      </div>
    )
    this.props.queryTypes.toJS().forEach( (type, id) => {
      if(type.class === this.state.dataset) {
        queryTypes.push(
          <div key={id} className="form-check-inline">
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsDataset" id={"optionsRadios_" + id}  value={type.class} defaultChecked onChange={this.handleDatasetChange.bind(this)} />
              {type.name}
            </label>
          </div>
        )
      } else {
        queryTypes.push(
          <div key={id} className="form-check-inline">
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="optionsDataset" id={"optionsRadios_" + id} value={type.class} onChange={this.handleDatasetChange.bind(this)} />
              {type.name}
            </label>
          </div>
        )
      }
    })

    this.props.filterFields.forEach( (field,field_index) => {
      if(field.get('visible')) {
        fields.push(
          <div className="filterQueryContainer" key={field.get('id')} draggable="true" onDragStart={this.fieldDragged.bind(this, field.get('id'))} onDrag={this.drag.bind(this)} onDragEnd={this.dragEnd.bind(this)}>
            <div className="filterQueryContainerInner">
              <h4> {field.get('title')} </h4>
            </div>
          </div>
        )
      }
      if(!field.get('visible')) {
        selectedFields.push(
          <div className="filterQueryContainer" onClick={this.fieldClicked.bind(this, field.get('id'))} key={field.get('id')} draggable="true" onDragStart={this.fieldDraggedSelected.bind(this, field.get('id'))} onDrag={this.drag.bind(this)} onDragEnd={this.dragEnd.bind(this)}>
            <div className="filterQueryContainerInner">
              <h4 className={field.get('active') ? 'blue-text' : ''}>{field.get('title')}
              </h4>
            </div>
              <span style={{paddingLeft: '5px' }}>
                <FontAwesome className='blue-text' name={field.get('active') ? 'caret-right' : ''} aria-hidden="true" />
              </span>
          </div>
        )
      }
    });

    this.props.filters.forEach((filter, filter_index) => {   //(.sortBy(filter => filter.get('filter'))
      if(filter.get('visible')) {
        filters.push(
          <li key={filter.get('id')} id={filter.get('id')} className={(filter.get('active') ? "bold-text" : "") + (filter.get('filter') === 'Null' ? " italic-text" : "")} onClick={this.filterClicked.bind(this, filter.get('id'))}>
            <span style={{paddingRight: '5px' }}>
              <FontAwesome name={filter.get('active') ? 'check' : ''} aria-hidden="true" />
            </span>
            {filter.get('filter')}
          </li>
        );
      }
    });

    this.props.activeFilters.forEach(filterKey => {
      selectedFilters.push(
        <h5 className="dashed-underline" key={filterKey.first().get('title')}><strong>{filterKey.first().get('title')}</strong></h5>
      );
      filterKey.forEach(filter => {
        selectedFilters.push(
          <li key={filter.get('id')} id={filter.get('id')} className={(filter.get('included') ? '' : 'gray-out') + (filter.get('filter') === 'Null' ? " italic-text" : "")}>
            <span style={{paddingLeft: '5px' }}>
              <FontAwesome name="times" onClick={this.filterClicked.bind(this, filter.get('id'))} />
            </span>
            {filter.get('filter')}
          </li>
        );
      });
    });

    let compHeight = this.props.template.height - this.props.template.navHeight - this.state.queryTypeSelectHeight - this.state.bottomMargin
    //var dataset = this.state.dataset;

    //CREATE QUERY
    return(
      <div id="newquery">

        <div className='row'>
          <div className='col-md-4 queryBtnContainer'>
            <Link to='/query'>
              <button className="btn btn-info queryBtn" type="button">
                <span style={{paddingRight: '5px' }}>
                  <FontAwesome name='plus' />
                </span>
                View All Queries
              </button>
            </Link>
          </div>
        </div>

        <div className="row" style={{paddingTop:20}}>
          <div className='col-md-12'>
            <fieldset className="form-group">
              {queryTypes}
            </fieldset>
          </div>
        </div>

        <div className="row">
          <div className='col-md-5'>
            <input type="text" maxLength="40" disabled={isFetchingQueryFilters} className="form-control" id="queryName1" placeholder="Name of new query..." onChange={this.onNameChange.bind(this)}/>
          </div>
          <div className='col-md-3' style={{paddingTop: 7,fontSize:"120%", textAlign:"center"}}>No. of Records: {this.props.records}</div>
          <div className="col-sm-2">
            <button disabled={this.props.activeFilters.size < 1 || isFetchingRecords} style={{width:"100%"}} className="btn btn-info" onClick={this.onSaveNewQueryButtonClick.bind(this)} type="button">
              Save New Query
            </button>
          </div>
          <div className='col-md-2'>
            <button className="btn btn-warning" style={{width:"100%"}} onClick={() => this.clearQueryBuilder()} type="button">
              Clear Options
            </button>
          </div>
        </div>

        <div>
          <ReactModal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles} contentLabel="Query Name Modal">
            <h4 ref={subtitle => this.subtitle = subtitle}>{this.state.modalMessage}</h4>
            <button className="btn btn-info center-block" onClick={this.closeModal}>Okay</button>
          </ReactModal>
        </div>
        <div className="row" style={{marginBottom:'10px',marginTop:'10px'}}>
          <div className="form-group has-danger">
            {/*<label className="form-control-label">Data Set</label>*/}
          </div>
          <div id="fields" className='col-sm-3 query-border-left fieldContainer droptarget' style={{height:compHeight, textAlign:'center'}} onDrop={this.drop.bind(this)} onDragOver={this.dragOver.bind(this)} onDragEnter={this.dragEnter.bind(this)} onDragLeave={this.dragLeave.bind(this)}>
            <h3>Fields</h3>
            {/*<div style={{paddingLeft: '2px'}} onChange={this.handleDatasetChange.bind(this)}>
              <input type="radio" checked={dataset === 'db'} value="db" name="db" onChange={this.dummyHandleChange.bind(this)}/> BMI
              <input className="radioHotSpot" type="radio" checked={dataset === 'snwa'} value="snwa" name="snwa" onChange={this.dummyHandleChange.bind(this)}/> SNWA
              <input className="radioHotSpot" type="radio" checked={dataset === 'level'} value="level" name="level" onChange={this.dummyHandleChange.bind(this)}/> GW Level
            </div>*/}
            {isFetchingQueryFilters ? (
              <ReactLoading type='bars' color={query_styles.siteColor} height={50} width={200} className='center-loader' /> ) : (
              <div className='newQueryFilters' style={{ height: (compHeight - 40) }}> {fields} </div>
            )}
          </div>
          <div id="selectedFields" className='col-sm-3 query-border fieldContainer droptarget' style={{height:compHeight, textAlign:'center'}} onDrop={this.drop.bind(this)} onDragOver={this.dragOverSelected.bind(this)} onDragEnter={this.dragEnterSelected.bind(this)} onDragLeave={this.dragLeave.bind(this)}>
            <h3>Selected Fields</h3>
            <div className="newQueryFilters" style={{ height: (compHeight - 40) }}> {selectedFields.length ? selectedFields : "(Drag fields here)"} </div>
          </div>
          <div className='col-sm-3 query-border fieldContainer' style={{height:compHeight, textAlign:'center'}}>
            <h3>Filters</h3>
            <input type="text" className="form-control" id="filterSearch1" placeholder="search..." value={this.state.search} onKeyUp={this.onKeyUpSearch.bind(this)} onChange={this.onChangeSearch.bind(this)}></input>
            <div className="newQueryFilters" id="avilableFilters" style={{ height: (compHeight - 80) }}>
              <ul className='filterList'>
                {filters}
              </ul>
            </div>
          </div>
          <div className='col-sm-3 query-border-right fieldContainer' style={{height:compHeight, textAlign:'center'}}>
            <h3>Selected Filters</h3>
            <div className="newQueryFilters" style={{ height: (compHeight - 40) }}>
              <ul className='filterList'>
                {selectedFilters}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    env: state.appEnv,
    queryTypes: state.QueryApp.get('queryTypes'),
    filters : state.QueryApp.get('datasetFilters'),
    activeFilters: state.QueryApp.get('activeFilters'),
    filterFields : state.QueryApp.get('datasetFilterFields'),
    records: state.QueryApp.get('records'),
    includedFilters: state.QueryApp.get('includedFilters'),
    data: state.data,
    isFetching: state.isFetching,
    isFetchingQueryFilters: state.isFetchingQueryFilters,
    isFetchingRecords: state.isFetchingRecords,
    receivedData: state.QueryApp.get('receivedData'),
    savedQuery: state.QueryApp.get('savedQuery'),
    selectedAnalyteTypes: state.QueryApp.get('selectedAnalyteTypes'),
    selectedAnalyteSubTypes: state.QueryApp.get('selectedAnalyteSubTypes'),
    errorMessage: state.QueryApp.get('errorMessage'),
    template: state.Template
  }
}

const NewQueryView = connect(mapStateToProps)(NewQueryViewComp)

export default NewQueryView;
