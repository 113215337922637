import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { setPiperLocation } from '../../actions/map';
import '../../css/piperPlot.css';

class DurovPlotComp extends Component {

  constructor(props){
    super(props)
    this.state = {
      filteredData : this.props.data.filter(item => item.get('visible') && item.get('ionCheck')).toJS()
    }
  }

  componentDidMount(){
    this.renderPlot();
  }
  componentDidUpdate(){
    this.renderPlot();
  }
  
  componentWillReceiveProps(nextProps){
    this.setState({
      filteredData : nextProps.data.filter(item => item.get('visible') && item.get('ionCheck')).toJS()
    });
  }
  
  renderPlot(){

    var scale = this.props.scale;
    var radiusStat = this.props.radius;
    var getRadius = d3.scaleLinear().domain(this.props.domain).range([2,9]).clamp(true); 

    d3.selectAll('.durovPlotContainer').remove();

    var dim = Math.min(this.props.size[0], this.props.size[1]);

    var svgMargin = 20;
    this.plotHeight = dim -  2*svgMargin;
    this.plotWidth = dim  - 2*svgMargin;

    var self = this;

    var cation_axes_labels = ['Mg', 'Ca', 'Na + K'],
        anion_axes_labels = ['CO\u2083 + HCO\u2083', 'Cl', 'SO\u2084'],
        axis_ticks = [0,20,40,60,80,100],
        axis_tick_labels = [20,40,60,80],
        tds_ticks = d3.range(0, 20001, 5000),
        ph_ticks = d3.range(4, 12.5, 2),
        tickLabelMargin = 5,
        axisLabelMargin = 10,
        minor_axis_ticks = d3.range(0, 101, 5),
        minor_tds_ticks = d3.range(0, 20001, 2500),
        minor_ph_ticks = d3.range(4, 12.5, 1),
        w_excess = this.props.size[0] - dim - (2*svgMargin),
        h_excess = this.props.size[1] - dim - (2*svgMargin),
        font_size;

    if(dim >= 500) {
      font_size = "0.8rem"
    } else if(dim < 500 && dim >= 30){
      font_size = "0.6rem"
    } else {
      font_size = "0.4rem"
    }

    if (w_excess < 0) {
      w_excess = 0;
    }
    if (h_excess < 0) {
      h_excess = 0;
    }

    var svg = d3.select(this.refs.durovPlot);

    var plotContainer = svg.append('g')
      .attr('class', 'durovPlotContainer')
      .attr('width', dim)
      .attr('height', dim)

    var ternaryCation = plotContainer.append('g')
      .attr('class', 'ternary cation')
      .attr('width', (this.plotWidth/3))
      .attr('height', (this.plotHeight/3))
      .attr("transform", "translate(" + (svgMargin + (w_excess/2)) + "," + ((this.plotHeight/3)+svgMargin+(h_excess/2)) + ")"); 

    var ternaryAnion = plotContainer.append('g')
      .attr('class', 'ternary anion')
      .attr('width', (this.plotWidth/3))
      .attr('height', (this.plotHeight/3))
      .attr("transform", "translate(" + ((svgMargin + (w_excess/2) + (this.plotWidth/3))) + "," + (svgMargin+(h_excess/2)) + ")");

    var centerSquare = plotContainer.append('g')
      .attr('class', 'diamond')
      .attr('width', (this.plotWidth/3))
      .attr('height', (this.plotHeight/3))
      .attr("transform", "translate(" + ((svgMargin + (w_excess/2) + (this.plotWidth/3) )) + "," + ((this.plotHeight/3)+svgMargin+(h_excess/2)) + ")");

    var phSquare = plotContainer.append('g')
      .attr('class', 'diamond')
      .attr('width', (this.plotWidth/3))
      .attr('height', (this.plotHeight/3))
      .attr("transform", "translate(" + ((svgMargin + (w_excess/2) + (this.plotWidth/3) )) + "," + (((this.plotHeight/3)*2)+svgMargin+(h_excess/2)) + ")");

    var tdsSquare = plotContainer.append('g')
      .attr('class', 'diamond')
      .attr('width', (this.plotWidth/3))
      .attr('height', (this.plotHeight/3))
      .attr("transform", "translate(" + ((svgMargin + (w_excess/2) + ((this.plotWidth/3)*2) )) + "," + ((this.plotHeight/3)+svgMargin+(h_excess/2)) + ")");

    var axesCation = ternaryCation.append('g').attr('class','axes');

    var axesAnion = ternaryAnion.append('g').attr('class','axes');

    var axesSquare = centerSquare.append('g').attr('class','axes');

    var axesTds = tdsSquare.append('g').attr('class','axes');

    var axesPh = phSquare.append('g').attr('class','axes');

    var w = (this.plotHeight/3) ; // length of one side of the triangle
    var h = Math.sqrt( w * w - (w/2)*(w/2));

    // ANION TRIANGLE
    var corners = [
      [(w/2), 0 + (w - h)], // top 
      [0, h + (w - h)], // ll
      [w, h + (w - h)] // lr
    ] 

    // CATION TRIANGLE
    var corners2 = [
      [h + (w - h), 0], // top (ll) top
      [0 + (w - h), (h/2)], // side (lr) side
      [h + (w - h), w] // bottom (top)
    ]

    // CENTER SQUARE & OTHER SQUARES 
    var x = d3.scaleLinear()
        .domain([0, 100])
        .range([0, (this.plotWidth/3)]);

    var y = d3.scaleLinear()
        .domain([0, 100])
        .range([(this.plotHeight/3), 0]);

    var pHy = d3.scaleLinear()
        .domain([12, 4])
        .range([(this.plotHeight/3), 0]);

    var TDSx = d3.scaleLinear()
        .domain([0, 20000])
        .range([0, (this.plotWidth/3)]);

    //cation axis names
    axesCation.selectAll('.axis-title')
      .data(cation_axes_labels)
      .enter()
        .append('g')
          .attr('class', 'axis-title')
          .attr('transform',function(d,i){
            var coord_label,
                v = 50;
            if(i===0) coord_label = coord0( [0, 100, 0] ); // Mg
            if(i===1) coord_label = coord0( [v, 100-v, 0] ); // Ca
            if(i===2) coord_label = coord0( [0, 100-v, v] ); // Na + K
            return 'translate(' + coord_label[0] + ',' + coord_label[1] + ')'
          })
        .append('text')
        .style('font-size', font_size)
        .text(function(d){ return d; })
        .attr('text-anchor', function(d,i){
          if(i===0) return 'middle';//'end';
          if(i===1) return 'middle';
          return 'middle';//'start';  
        })
        .attr('x', function(d,i) {
          var x;
          if(i===0) x = 0;
          if(i===1) x = 0;
          if(i===2) x = 0;
          return x;
        })
        .attr('y', function(d,i) {
          var y;
          if(i===0) y = -axisLabelMargin; //0;
          if(i===1) y = -axisLabelMargin * 3;
          if(i===2) y = axisLabelMargin * 4; //0;
          return y;
        })
        .attr('transform', function(d,i) {
          var r;
          if(i===0) r = 'rotate(-90)';
          if(i===1) r = 'rotate(-30)';
          if(i===2) r = 'rotate(35)';
          return r;
        });

    //anion axis names
    axesAnion.selectAll('.axis-title')
      .data(anion_axes_labels)
      .enter()
        .append('g')
          .attr('class', 'axis-title')
          .attr('transform', function(d,i){
            var coord_label,
                v = 50;
            if(i===0) coord_label = coord( [v, 0, 100-v] );
            if(i===1) coord_label = coord( [v, 100-v, 0] );
            if(i===2) coord_label = coord( [100, 0, 0] );  // SO4
            //if(i===2) coord_label = [((dim/6)), (svgMargin + (h_excess/2))];  // SO4
            return 'translate(' + coord_label[0] + ',' + coord_label[1] + ')'
          })
        .append('text')
        .style('font-size', font_size)
          .text(function(d){ return d; })
          .attr('text-anchor', function(d,i){
            if(i===0) return 'middle';//'end';
            if(i===1) return 'middle';
            return 'middle';//'start';  
          })
          .attr('x', function(d,i) {
            var x;
            if(i===0) x = -axisLabelMargin * 0;
            if(i===1) x = 0;
            if(i===2) x = axisLabelMargin * 0;
            return x;
          })
          .attr('y', function(d,i) {
            var y;
            if(i===0) y = -axisLabelMargin * 3; //0;
            if(i===1) y = -axisLabelMargin * 3;
            if(i===2) y = -axisLabelMargin; //0;
            return y;
          })
          .attr('transform', function(d,i) {
            var r;
            if(i===0) r = 'rotate(60)';
            if(i===1) r = 'rotate(-60)';
            if(i===2) r = 'rotate(0)';
            return r;
          });

    //minor ticks
    //ANION TRIANGLE
    if(minor_axis_ticks){
      minor_axis_ticks.forEach(function(v) {  
        var coord1 = coord( [v, 0, 100-v] );
        var coord2 = coord( [v, 100-v, 0] );
        var coord3 = coord( [0, 100-v, v] );
        var coord4 = coord( [100-v, 0, v] ); 

      axesAnion.append("line")
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis minor-tick', true);  

      axesAnion.append("line")
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis minor-tick', true);  

      axesAnion.append("line")
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis minor-tick', true);      
      });
    }

    //CATION TRIANGLE
    if(minor_axis_ticks){
      minor_axis_ticks.forEach(function(v) {  
        var coord1 = coord0( [v, 0, 100-v] );
        var coord2 = coord0( [v, 100-v, 0] );
        var coord3 = coord0( [0, 100-v, v] );
        var coord4 = coord0( [100-v, 0, v] );

      axesCation.append("line")
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis minor-tick', true);  

      axesCation.append("line")
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis minor-tick', true);  

      axesCation.append("line")
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis minor-tick', true);  
      });
    }

    //CENTER SQUARE
    if(minor_axis_ticks){
      minor_axis_ticks.forEach(function(v) {  
        axesSquare.append("line")
          .attr( "x1", x(v) )
          .attr( "x2", x(v) )
          .attr( "y1", y(0) )
          .attr( "y2", y(100) )
          .classed('a-axis minor-tick', true);  

        axesSquare.append("line")
          .attr( "x1", x(0) )
          .attr( "x2", x(100) )
          .attr( "y1", y(v) )
          .attr( "y2", y(v) )
          .classed('b-axis minor-tick', true);   
      });
    }

    //TDS SQUARE
    if(minor_tds_ticks){
      minor_tds_ticks.forEach(function(v) {  
        axesTds.append("line")
          .attr( "x1", TDSx(v) )
          .attr( "x2", TDSx(v) )
          .attr( "y1", y(0) )
          .attr( "y2", y(100) )
          .classed('a-axis minor-tick', true);  
      });
    }

    //PH SQUARE
    if(minor_ph_ticks){
      minor_ph_ticks.forEach(function(v) {  
        axesPh.append("line")
          .attr( "x1", x(0) )
          .attr( "x2", x(100) )
          .attr( "y1", pHy(v) )
          .attr( "y2", pHy(v) )
          .classed('b-axis minor-tick', true);   
      });
    }

    // major ticks
    //ANION TRIANGLE
    axis_ticks.forEach(function(v) {  
      var coord1 = coord( [v, 0, 100-v] );
      var coord2 = coord( [v, 100-v, 0] );
      var coord3 = coord( [0, 100-v, v] );
      var coord4 = coord( [100-v, 0, v] );

      axesAnion.append("line")
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis tick', true);  

      axesAnion.append("line")
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis tick', true);  

      axesAnion.append("line")
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis tick', true); 
    });

    //CATION TRIANGLE
    axis_ticks.forEach(function(v) {  
      var coord1 = coord0( [v, 0, 100-v] );
      var coord2 = coord0( [v, 100-v, 0] );
      var coord3 = coord0( [0, 100-v, v] );
      var coord4 = coord0( [100-v, 0, v] );

      axesCation.append("line")
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis tick', true);  

      axesCation.append("line")
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis tick', true);  

      axesCation.append("line") //nak
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis tick', true); 
    }); 

    //CENTER SQUARE
    axis_ticks.forEach(function(v) {  
      axesSquare.append("line")
        .attr( "x1", x(v) )
        .attr( "x2", x(v) )
        .attr( "y1", y(0) )
        .attr( "y2", y(100) )
        .classed('a-axis tick', true);  

      axesSquare.append("line")
        .attr( "x1", x(0) )
        .attr( "x2", x(100) )
        .attr( "y1", y(v) )
        .attr( "y2", y(v) )
        .classed('b-axis tick', true);   
    });

    //TDS SQUARE
    tds_ticks.forEach(function(v) {  
      axesTds.append("line")
        .attr( "x1", TDSx(v) )
        .attr( "x2", TDSx(v) )
        .attr( "y1", y(0) )
        .attr( "y2", y(100) )
        .classed('a-axis tick', true);  
    });

    axesTds.append("line")
      .attr( "x1", TDSx(0) )
      .attr( "x2", TDSx(20000) )
      .attr( "y1", y(0) )
      .attr( "y2", y(0) )
      .classed('a-axis tick', true);

    axesTds.append("line")
      .attr( "x1", TDSx(0) )
      .attr( "x2", TDSx(20000) )
      .attr( "y1", y(100) )
      .attr( "y2", y(100) )
      .classed('a-axis tick', true);  

    //PH SQUARE
    ph_ticks.forEach(function(v) { 
      axesPh.append("line")
        .attr( "x1", x(0) )
        .attr( "x2", x(100) )
        .attr( "y1", pHy(v) )
        .attr( "y2", pHy(v) )
        .classed('b-axis tick', true);   
    });

    axesPh.append("line")
      .attr( "x1", x(0) )
      .attr( "x2", x(0) )
      .attr( "y1", pHy(12) )
      .attr( "y2", pHy(0) )
      .classed('b-axis tick', true); 

    axesPh.append("line")
      .attr( "x1", x(100) )
      .attr( "x2", x(100) )
      .attr( "y1", pHy(12) )
      .attr( "y2", pHy(0) )
      .classed('b-axis tick', true); 

    // major tick labels
    //ANION TRIANGLE
    axis_tick_labels.forEach(function(v) {  
      var coord1 = coord( [v, 0, 100-v] );
      var coord2 = coord( [v, 100-v, 0] );
      //var coord3 = coord( [0, 100-v, v] );
      //var coord4 = coord( [100-v, 0, v] );

      axesAnion.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord1[0] + ',' + coord1[1] + ')'
        })
        .append("text")
          .attr('text-anchor','start')
          .attr('x', tickLabelMargin)
          .text( function (d) { return 100 - v; } )
          .classed('a-axis tick-text', true );

      axesAnion.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord2[0] + ',' + coord2[1] + ')'
        })
        .append("text")
          .attr('text-anchor','end')
          .attr('x', -tickLabelMargin)
          .text( function (d) { return 100 - v; } )
          .classed('b-axis tick-text', true);

      /*axesAnion.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord3[0] + ',' + coord3[1] + ')'
        })
        .append("text")
          .text( function (d) { return v; } )
          .attr('x',tickLabelMargin)
          .classed('c-axis tick-text', true);*/
    }); 

    //CATION TRIANGLE
    axis_tick_labels.forEach(function(v) {  
      //var coord1 = coord0( [v, 0, 100-v] );
      var coord2 = coord0( [v, 100-v, 0] );
      var coord3 = coord0( [0, 100-v, v] );
      //var coord4 = coord0( [100-v, 0, v] );

      /*axesCation.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord1[0] + ',' + coord1[1] + ')'
        })
        .append("text")
          .attr('text-anchor','end')
          .attr('x',-tickLabelMargin)
          .text( function (d) { return (100 - v); } )
          .classed('a-axis tick-text', true ); */
      
      axesCation.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord2[0] + ',' + coord2[1] + ')'
        })
        .append("text")
          .attr('text-anchor','end')
          .attr('y', -tickLabelMargin)
          .text( function (d) { return v; } )
          .classed('b-axis tick-text', true); 
      
      axesCation.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord3[0] + ',' + coord3[1] + ')'
        })
        .append("text")
          .text( function (d) { return v; } )
          .attr('text-anchor','end')
          .attr('y', tickLabelMargin * 3)
          .classed('c-axis tick-text', true);
    });

    //PH SQUARE
    axesPh.append('g')
      .style('font-size', font_size)
      .attr("class", "axis-title")
      .attr('transform', 'translate(' + -10 + ',' + this.plotHeight / 6 + ')')
      .append("text")
        .attr('text-anchor','middle')
        .attr('x', -tickLabelMargin * 3)
        .text( 'pH' );

    ph_ticks.forEach(function(v) {  
      if(v !== 0 && v !== 4) {
        axesPh.append('g')
          .attr('transform', 'translate(' + x(100) + ',' + pHy(v) + ')')
          .append("text")
            .attr('text-anchor','start')
            .attr('x', tickLabelMargin)
            .attr('y', 5)
            .text( v )
            .classed('c-axis tick-text', true);
      }
    });

    //TDS SQUARE
    var formatComma = d3.format(",")
    axesTds.append('g')
      .style('font-size', font_size)
      .attr("class", "axis-title")
      .attr('transform', 'translate(' + this.plotWidth/6 + ',' + this.plotHeight/3 + ')')
      .append("text")
        .attr('text-anchor','middle')
        .attr('y', tickLabelMargin * 3)
        .text( 'TDS (mg/L)' );

    tds_ticks.forEach(function(v) {  
      if(v !== 0) {
        axesTds.append('g')
          .attr('transform', 'translate(' + TDSx(v) + ',' + y(100) + ') rotate(' + -90 + ')')
          .append("text")
            .attr('text-anchor','start')
            .attr('x', 5)
            .attr('y', 5)
            .text( formatComma(v) )
            .classed('c-axis tick-text', true);
      }
    });
          

    function lineAttributes(p1, p2){
      return { x1:p1[0], y1:p1[1],
           x2:p2[0], y2:p2[1] };
    }

    function coord(arr){
      var a = arr[0], b=arr[1], c=arr[2]; 
      var sum, pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
          a /= sum;
          b /= sum;
          c /= sum;
        pos[0] =  corners[0][0]  * a + corners[1][0]  * b + corners[2][0]  * c;
        pos[1] =  corners[0][1]  * a + corners[1][1]  * b + corners[2][1]  * c;
      }
        return pos;
    }

    function coord0(arr){
      var a = arr[0], b=arr[1], c=arr[2]; 
      var sum, pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
          a /= sum;
          b /= sum;
          c /= sum;
        pos[0] =  corners2[0][0]  * a + corners2[1][0]  * b + corners2[2][0]  * c;
        pos[1] =  corners2[0][1]  * a + corners2[1][1]  * b + corners2[2][1]  * c;
      }
        return pos;
    }

    var coords;
    var me = this;

    function circles(arr){
      var a = arr[0], b=arr[1], c=arr[2]; 
      var sum, pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
          a /= sum;
          b /= sum;
          c /= sum;
        pos[0] = (c/2) + ((1-c)*(a/(b+a))); 
        //pos[0] =  corners2[0][0]  * a + corners2[1][0]  * b + corners2[2][0]  * c;
                        //top          // ll                   //lr
        //pos[1] =  corners[0][1]  * a + corners[1][1]  * b + corners[2][1]  * c;
      }
        return pos;
    }

    var anionCircles = ternaryAnion.append("g").selectAll("circle")
      .data( this.state.filteredData.map( function(d) { 
          coords = circles([d.carbs, d.cl, d.so4])
          d.x = x(coords[0]*100)
          d.y = y((d.so4 * 0.8660254))
          return d; 
      }))

    anionCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'anion piper ' + d.location.replace(/[\. ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) {
          self.props.dispatch(setPiperLocation(d.location));
          // eslint-disable-next-line
          plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .style('font', font_size + ' sans-serif')
            .attr('transform', 'translate(' + (((me.plotWidth/3) * 2) + TDSx(5000) + (w_excess/2)) + ',' + ((me.plotHeight/6) + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location )
              //.text( "so4: " + d.so4 + "cl: " + d.cl + " alk: " + d.carbs)
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".ph").classed('ph', false).classed('piper-highlighted-ph', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".tds").classed('tds', false).classed('piper-highlighted-tds', true).raise();
          // eslint-disable-next-line 
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
        })
        .on('mouseout', function(d) { 
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\. ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-ph').classed('piper-highlighted-ph', false).classed('ph', true);
          d3.selectAll('.piper-highlighted-tds').classed('piper-highlighted-tds', false).classed('tds', true);
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', anionTip.show)
        //.on('mouseout', anionTip.hide);

    function circles0(arr){
      var a = arr[0], b=arr[1], c=arr[2]; 
      var sum, pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
          a /= sum;
          b /= sum;
          c /= sum;
        //pos[0] =  corners2[0][0]  * a + corners2[1][0]  * b + corners2[2][0]  * c;
        pos[0] =  (corners2[0][0] * (1 - b));
        //pos[1] =  corners2[0][1]  * a + corners2[1][1]  * b + corners2[2][1]  * c;
        pos[1] =  (b/2) + ((1-b)*(a/(c+a))); 
      }
        return pos;
    }

    var cationCircles = ternaryCation.append("g").selectAll("circle")
      .data( this.state.filteredData.map( function(d) { 
          coords = circles0([ d.ca, d.mg, d.nak ])  //a (top), b (side), c (bottom)
          //d.x = coords[0]
          //d.y = coords[1]
          d.x = x(100 - (d.mg * 0.8660254))  //(sqrt 0.75)  - some sort of equilateral triangle height or top point measure compared to base?
          d.y = y((coords[1]*100))
          return d; 
      }))

    cationCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'cation piper ' + d.location.replace(/[\. ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) { 
          self.props.dispatch(setPiperLocation(d.location));
          // eslint-disable-next-line
          plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .style('font', font_size + ' sans-serif')
            .attr('transform', 'translate(' + (((me.plotWidth/3) * 2) + TDSx(5000) + (w_excess/2)) + ',' + ((me.plotHeight/6) + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location)
              //.text( "mg: " + d.mg + "ca: " + d.ca + " nak: " + d.nak)

          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".ph").classed('ph', false).classed('piper-highlighted-ph', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".tds").classed('tds', false).classed('piper-highlighted-tds', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
        })
        .on('mouseout', function(d) { 
          //locTip.hide(d);
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\. ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-ph').classed('piper-highlighted-ph', false).classed('ph', true);
          d3.selectAll('.piper-highlighted-tds').classed('piper-highlighted-tds', false).classed('tds', true);
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', cationTip.show)
        //.on('mouseout', cationTip.hide);       
        
    var centerCircles = centerSquare.append("g").selectAll("circle")
      .data( this.state.filteredData.map( function(d) { 
          d.x = x(circles0([ d.carbs, d.so4, d.cl ])[1]*100)
          //d.y = y(d.mg)
          d.y = y(circles0([ d.ca, d.mg, d.nak ])[1]*100)
          return d; 
      }))

    centerCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'diamond piper ' + d.location.replace(/[\. ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) { 
          self.props.dispatch(setPiperLocation(d.location));
          // eslint-disable-next-line
          plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .style('font', font_size + ' sans-serif')
            .attr('transform', 'translate(' + (((me.plotWidth/3) * 2) + TDSx(5000) + (w_excess/2)) + ',' + ((me.plotHeight/6) + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location )
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".ph").classed('ph', false).classed('piper-highlighted-ph', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".tds").classed('tds', false).classed('piper-highlighted-tds', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
        })
        .on('mouseout', function(d) { 
          //locTip.hide(d);
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\. ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-ph').classed('piper-highlighted-ph', false).classed('ph', true);
          d3.selectAll('.piper-highlighted-tds').classed('piper-highlighted-tds', false).classed('tds', true);
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', cationTip.show)
        //.on('mouseout', cationTip.hide);      
    
    var tdsCircles = tdsSquare.append("g").selectAll("circle")
      .data( this.state.filteredData.filter(x => x.tds > 0 && x.tds <= 20000).map( function(d) { 
          d.x = TDSx(d.tds)
          //d.y = y(d.mg)
          d.y = y(circles0([ d.ca, d.mg, d.nak ])[1]*100)
          return d; 
      }))

    tdsCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'tds piper ' + d.location.replace(/[\. ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) { 
          self.props.dispatch(setPiperLocation(d.location));
          // eslint-disable-next-line
          plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .style('font', font_size + ' sans-serif')
            .attr('transform', 'translate(' + (((me.plotWidth/3) * 2) + TDSx(5000) + (w_excess/2)) + ',' + ((me.plotHeight/6) + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location )
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".ph").classed('ph', false).classed('piper-highlighted-ph', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".tds").classed('tds', false).classed('piper-highlighted-tds', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
        })
        .on('mouseout', function(d) { 
          //locTip.hide(d);
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\. ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-ph').classed('piper-highlighted-ph', false).classed('ph', true);
          d3.selectAll('.piper-highlighted-tds').classed('piper-highlighted-tds', false).classed('tds', true);
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', cationTip.show)
        //.on('mouseout', cationTip.hide);     
        
    var phCircles = phSquare.append("g").selectAll("circle")
      .data( this.state.filteredData.filter(x => x.ph > 0).map( function(d) { 
        //d.x = x(100 - d.so4)
        d.x = x(circles0([ d.carbs, d.so4, d.cl ])[1]*100)
        d.y = pHy(d.ph)
        return d; 
      }))

    phCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'ph piper ' + d.location.replace(/[\. ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) { 
          self.props.dispatch(setPiperLocation(d.location));
          // eslint-disable-next-line
          plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .style('font', font_size + ' sans-serif')
            .attr('transform', 'translate(' + (((me.plotWidth/3) * 2) + TDSx(5000) + (w_excess/2)) + ',' + ((me.plotHeight/6) + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location )
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".ph").classed('ph', false).classed('piper-highlighted-ph', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".tds").classed('tds', false).classed('piper-highlighted-tds', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\. ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
        })
        .on('mouseout', function(d) { 
          //locTip.hide(d);
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\. ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-ph').classed('piper-highlighted-ph', false).classed('ph', true);
          d3.selectAll('.piper-highlighted-tds').classed('piper-highlighted-tds', false).classed('tds', true);
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', cationTip.show)
        //.on('mouseout', cationTip.hide); 
        
    plotContainer.append("g")
            .attr('transform', 'translate(' + svgMargin + ',' + ((this.plotHeight + (svgMargin * 2)) - 5) + ')')
            .append("text")
              .style('font', '8px sans-serif')
              .attr('text-anchor','start')
              .text( "* TDS values greater than 20,000 mg/L are not shown" )    
             
  }
  
  render(){
    return (
      <svg ref='durovPlot' width={this.props.size[0]} height={this.props.size[1]} ></svg>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.PiperApp.get('data'),
    size: state.PiperApp.get('durovSize'),
    scale: state.PiperApp.get('scalePoints'),
    domain: state.PiperApp.get('domain'),
    radius: state.PiperApp.get('radius')
  }
}

const DurovPlot = connect(mapStateToProps)(DurovPlotComp)

export default DurovPlot;