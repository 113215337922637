import * as d3 from 'd3';

/* * * * * * * * * FUNCTIONS USED IN REDUCER(S) * * * * * * * * */

// Convert measurements to meq/L from mg/L or ug/L
function meq(value, unit, conversion) {
  if((unit === 'ug/L' || unit === 'mg/L') && value) {
    switch (unit) {
      case 'ug/L':
        return Math.round((value/(conversion * 1000))*1000000)/1000000;
      case 'mg/L':
        return Math.round((value/conversion)*10000)/10000;
      default:
        return null;
    }
  } else {
    return null;
  }
}

function mg(value, unit) {
  if((unit === 'ug/L' || unit === 'mg/L') && value) {
    switch (unit) {
      case 'ug/L':
        return value/1000;
      default:
        return value;
    }
  } else {
    return null;
  }
}

function checkTDS(record) {
  var sumIons = mg(record.ca, record.ca_units) + 
    mg(record.mg, record.mg_units) + 
    mg(record.na, record.na_units) + 
    mg(record.k, record.k_units) + 
    mg(record.hco3, record.hco3_units) + 
    mg(record.co3, record.co3_units) + 
    mg(record.cl, record.cl_units) + 
    mg(record.so4, record.so4_units) //+ 
    //mg(record.f, record.f_unit) + 
    //mg(record.no3, record.no3_unit) + 
    //mg(record.cio4, record.cio4_unit);  

  if(record.tds > 0) {
    var tds = mg(record.tds, record.tds_unit);
    return (tds/sumIons > 1.2 ? false : true)
  } else {
    return true
  }
}

function toMeq(record) {
  var cameq, mgmeq, nameq, kmeq, hco3meq, co3meq, clmeq, so4meq;//, fmeq, no3meq, cio4meq;
  
  // calculate millequivalents 
  cameq = meq(record.ca, record.ca_units, 20.04)  // Calcium has a molecular weight of 40.08 and a valence of 2.  40.08/2 = 20.04 ... this is the Eq per g ... and the mEq per mg!
  mgmeq = meq(record.mg, record.mg_units, 12.1525)  // Magnesium has a molecular weight of 24.305 and a valence of 2.  24.305/2 = 12.1525 ...
  nameq = meq(record.na, record.na_units, 22.99)  // Sodium has a molecular weight of 22.99 and a valence of 1.  22.99/1 = 22.99 ... 
  kmeq = meq(record.k, record.k_units, 39.098)  // Potassium has a molecular weight of 39.098 and a valence of 1.  39.098/1 = 39.098 ...
  hco3meq = meq(record.hco3, record.hco3_units, 61.008)  // Bicarbonate has a molecular weight of 61.008 and a valence of 1.  61.008/1 = 61.008 ...
  co3meq = meq(record.co3, record.co3_units, 30.004)  // Carbonate has a molecular weight of 60.008 and a valence of 2.  60.008/2 = 30.004 ...
  clmeq = meq(record.cl, record.cl_units, 35.453)  // Chloride has a molecular weight of 35.453 and a valence of 1.  35.453/1 = 35.453 ...
  so4meq = meq(record.so4, record.so4_units, 48.03) // Sulfate has a molecular weight of 96.06 and a valence of 2.  96.06/2 = 48.03 ...
  //fmeq = meq(record.f, record.f_unit, 19)  // Fluoride has a molecular weight of 19 and a valence of 1.  19/1 = 19 ...
  //no3meq = meq(record.no3, record.no3_unit, 62.004)  // Nitrate has a molecular weight of 62.004 and a valence of 1.  62.004/1 = 62.004 ...
  //cio4meq = meq(record.cio4, record.cio4_unit, 99.449)  // Perchlorate has a molecular weight of 99.449 and a valence of 1.  99.449/1 = 99.449 ...

  return {ca: cameq, mg: mgmeq, na: nameq, k: kmeq, cl: clmeq, so4: so4meq, hco3: hco3meq, co3: co3meq}//, f: fmeq, no3: no3meq, cio4: cio4meq}
}

function checkIonBalance(record) {
  var anionSum, cationSum, ionBalance;

  anionSum = record.hco3 + record.co3 + record.cl + record.so4;// + record.f + record.no3 + record.cio4;
  cationSum = record.ca + record.mg + record.na + record.k;
  ionBalance = Math.round(((cationSum - anionSum)/(cationSum + anionSum) * 100) * 10)/10

  if(anionSum > 800) {  //if anion sum is greater than 800, no plotting, no ion balance test
    return false;
  } else if(anionSum > 0 && anionSum <= 3 && ionBalance >= -0.2 && ionBalance <= 0.2) {  // if anion sum is greater than 0, but less than or equal to 3, and the balance is between (and including) -0.2% & 0.2%, it's correct
    return true;
  } else if(anionSum > 3 && anionSum <= 10 && ionBalance >= -2 && ionBalance <= 2) {  // if anion sum is greater than 3, but less than or equal to 10, and the balance is between (and including) -2% & 2%, it's correct
    return true;
  } else if(anionSum > 10 && anionSum <= 800 && ionBalance >= -5 && ionBalance <= 5) {  // if anion sum is greater than 10, but less than or equal to 800, and the balance is between (and including) -5% & 5%, it's correct
    return true;
  } else {
    return false;
  }
}

function percentMeq(record) {
  var sumCations, sumAnions;
  var ca, mg, nak, cl, so4, carbs;
  
  // calculate percent millequivalents 
  sumCations = record.ca + record.mg + record.na + record.k;
  sumAnions = record.cl + record.so4 + record.hco3 + record.co3;

  ca = Math.round(( (record.ca/sumCations) * 100) * 100)/100
  mg = Math.round(( (record.mg/sumCations) * 100) * 100)/100
  nak = Math.round(( ((record.na + record.k)/sumCations) * 100) * 100)/100
  cl = Math.round(( (record.cl/sumAnions) * 100) * 100)/100
  so4 = Math.round(( (record.so4/sumAnions) * 100) * 100)/100
  carbs = Math.round(( ((record.hco3 + record.co3)/sumAnions) * 100) * 100)/100

  return { ca: ca, mg: mg, nak: nak, cl: cl, so4: so4, carbs: carbs }
}


export const createPiperData = (data, ionCheck = true, tdsCheck = false) => {
  var piper = [], i; 

  for (i = 0; i < data.length; i++) { 
    var record, meq, percent;

    record = data[i];

    piper[i] = {
      ca_text: d3.format(',')(record.ca) + ' ' + record.ca_units, 
      mg_text: d3.format(',')(record.mg) + ' ' + record.mg_units, 
      nak_text: d3.format(',')(record.na) + ' ' + record.mg_units + ' + ' + record.k + ' ' + record.k_units,
      cl_text: d3.format(',')(record.cl) + ' ' + record.cl_units, 
      so4_text: d3.format(',')(record.so4) + ' ' + record.so4_units, 
      carbs_text: d3.format(',')(record.hco3) + ' ' + record.hco3_units + ' + ' + record.co3 + ' ' + record.co3_units,
      sample_date: record.sample_date,
      year: record.year,
      quarter: record.quarter,
      location: record.location,
      lat: record.lat,
      long: record.long,
      stat: 0,
      tds: mg(record.tds, record.tds_units),
      ph: record.ph
    };
    
    if(tdsCheck) {
      piper[i].tdsCheck = checkTDS(record);
    } else {
      piper[i].tdsCheck = true;
    }

    meq = toMeq(record);
    
    if(ionCheck) {
      piper[i].ionCheck = checkIonBalance(meq);
    } else {
      piper[i].ionCheck = true;
    }

    percent = percentMeq(meq);

    piper[i].ca = percent.ca;
    piper[i].mg = percent.mg;
    piper[i].nak = percent.nak;
    piper[i].cl = percent.cl;
    piper[i].so4 = percent.so4;
    piper[i].carbs = percent.carbs;
    piper[i].visible = true;
    piper[i].active = false;
 }

 return piper;
}