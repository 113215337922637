import React, { Component } from 'react'
import { List } from 'immutable';
import * as d3 from 'd3';
import { d3box } from './d3.box.js';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/boxPlot.css';

class BoxPlot extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSize: props.data.size,
      log: false
    }
  }

  componentDidMount(){
    if(this.props.data.size > 0) {
      this.renderPlot();
    }
  }

  componentDidUpdate(){
    if(this.props.data.size > 0) {
      this.renderPlot();
    } else {
      d3.selectAll('.boxPlotContainer').remove();
    }
  }

  toggleLogData(){
    this.setState({ 'log': !this.state.log })
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){ return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  }

  renderPlot(){

    d3.selectAll('.boxPlotContainer').remove();
    //d3.selectAll('.logPlotContainer').remove();

    var labels = true; // show the text labels beside individual boxplots?

    var margin = {top: 30, right: 50, bottom: 70, left: 50},
        width = this.props.width,
        height = this.props.height - 50;

    /*var min = Infinity,
        max = -Infinity;*/  // these should get set by the data... maybe in a reducers.

    var mins = List();
    var maxes = List();

    var chart;
        //.whiskers(iqr(1.5))
        //.height(height - (margin.top + margin.bottom))
        //.domain([mins.min(), maxes.max()])
        //.showLabels(labels);

    var svg = d3.select(this.refs.boxPlot);

    /*var logContainer = svg.append('g')
      .attr('class', 'logPlotContainer')
      .attr('width', width - (margin.right + margin.left))
      .attr('height', 40)

    var logContainerText = logContainer.append("text")
          .attr("x", (width - (margin.right + margin.left)) / 2)
          .attr("y", 20)
          .attr("text-anchor", "middle")
          .style("font-size", "12px")
          .text('Log Data');*/

    var plotContainer = svg.append('g')
      .attr("class", "box")
      .attr('class', 'boxPlotContainer')
      .attr('width', width - (margin.right + margin.left))
      //.attr('height', height - (margin.top + margin.bottom))
      .attr('height', (height * this.props.data.size))
      .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // the x-axis
    /*var x = d3.scaleOrdinal()
      .domain( this.props.data.get('filtered').map(function(d) { console.log(d); return d[0] } ) )
      .rangeRoundBands([0 , width], 0.7, 0.3);*/
    // loop through all box plot types
    var data;
    var me = this;
    var idx = 1;
    var dat;

    this.props.data.keySeq().forEach(function(k) {
      if(me.state.log) {
        data = me.props.data.get(k).toJS().map(l => [l[0], l[1].map(r => Math.log(r))]);
      } else {
        data = me.props.data.get(k).toJS();
      }
      me.props.data.get(k).valueSeq().forEach(v => {
        dat = v.toJS()
        if(me.state.log) {
          mins = mins.push(Math.log(List(dat[1]).min()));
          maxes = maxes.push(Math.log(List(dat[1]).max()));
        } else {
          mins = mins.push(List(dat[1]).min());
          maxes = maxes.push(List(dat[1]).max());
        }
      });

      chart = d3box(1.5, height - (margin.top + margin.bottom), [mins.min(), maxes.max()], labels, idx);

      var x = d3.scaleBand()
        .domain( data.map(function(d) {
          return d[0] } )
        )
        .rangeRound([0 , width - (margin.right + margin.left)])
        .paddingInner(0.7)
        .paddingOuter(0.3);

      /*var xAxis = d3.svg.axis()
        .scale(x)
        .orient("bottom");*/
      var xAxis = d3.axisBottom(x);

      // the y-axis
      var y = d3.scaleLinear()
        .domain([mins.min(), maxes.max()])
        .range([height - margin.bottom, 0 + margin.top]);

      /*var yAxis = d3.svg.axis()
        .scale(y)
        .orient("left");*/
      var yAxis = d3.axisLeft(y).tickFormat( (d) => me.state.log ? d3.format(".2s")(Math.exp(d)) : d3.format(".2s")(d) );

      // draw the boxplots
      plotContainer.selectAll(".box-" + idx)
          .data(data)
        .enter().append("g")
        .attr("transform", function(d) { return "translate(" +  x(d[0])  + "," + ((height * (idx - 1)) + margin.top) + ")"; } )
          .call(chart.width(x.bandwidth()));

      // add a title
      plotContainer.append("text")
            .attr("x", (width - (margin.right + margin.left)) / 2)
            .attr("y", 0 + (margin.top / 2))
            .attr("transform", "translate(0," + (height * (idx - 1)) + ")")
            .attr("text-anchor", "middle")
            .style("font-size", "18px")
            .text(me.toTitleCase(k));

       // draw y axis
      plotContainer.append("g")
            .attr("transform", "translate(0," + ((height * (idx - 1))) + ")")
            .attr("class", "y axis")
            .call(yAxis)

      plotContainer.append("text") // and text1
          //.attr("transform", "rotate(-90)")
          .attr("transform", "translate(-40,"+ (height/2 + (height * (idx - 1))) +") rotate(-90)")
          //.attr("y", 6)
          //.attr("dy", ".71em")
          //.style("text-anchor", "end")
          //.style("font-size", "16px")
          //.text(me.state.log ? me.props.unit.get(0).get('filter') + ' (logged)' : me.props.unit.get(0).get('filter'));
          .text(me.props.unit.get(0).get('filter'));

      // draw x axis
      plotContainer.append("g")
          .attr("class", "x axis")
          //.attr("transform", "translate(0," + (height  + margin.top + 10) + ")")
          .attr("transform", "translate(0," + ((height * idx)  - (margin.bottom )) + ")")
          .call(xAxis)
          .selectAll("text")
            .attr("y", 0)
            .attr("x", 9)
            .attr("dy", ".35em")
            .attr("transform", "rotate(45)")
            .style("text-anchor", "start");
        /*
        .append("text")             // text label for the x axis
            .attr("x", ( (width - (margin.right + margin.left)) / 2) )
            .attr("y",  10 )
        .attr("dy", ".71em")
            .style("text-anchor", "middle")
        .style("font-size", "16px")
            .text("");
            */

      idx = ++idx;
    })
  }

  render(){
    return (
      <div>
        <div id='logData' width={this.props.width} className={this.props.canLog ? 'logCheckbox' : 'logCheckbox disabled' } onClick={this.toggleLogData.bind(this)} disabled={this.props.canLog}>
          <span><FontAwesome name={this.state.log ? 'check-square' : 'square'} className="fa icon-left far"/></span>
          Log Data
        </div>
        <svg ref='boxPlot' width={this.props.width} height={(this.props.height - 50) * this.state.dataSize} ></svg>
      </div>
    )
  }
}

export default BoxPlot;
