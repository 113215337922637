import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Map } from 'immutable';
import { changeClusterNumber } from '../../actions/uitools';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/statBar.css';

class ClusterToolComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      value: props.nClusters,
      showInfo: false
    }
  }

  handleInfo(e) {
    this.setState({showInfo: !this.state.showInfo});
  }

  handleUpdate() {
    this.props.dispatch(changeClusterNumber(this.state.value));
  }

  handleChange(e){
    var value = e.target.value;
    if(parseInt(+value, 10) < 12 && parseInt(+value, 10) > 2 && !isNaN(parseInt(+value, 10))) {
      this.setState({isValid: true, value: parseInt(+value, 10)})
    } else {
      this.setState({isValid: false, value: value})
    }
  }

  render() {

    var clusters = this.state.value;
    var valid = this.state.isValid;
    
    return (
      <div style={{paddingTop: '5px', marginTop: '7px', borderTop: '1px solid'}}>
        <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='question-circle' size='lg' /></span>
        <h5>Cluster Locations</h5>
        {this.state.showInfo ? (
        <div className='toolInfo'>
          <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='times' size='lg' /></span>
          <p>This tool uses the summary statistic (mean, median, max, etc.) of all the shown locations to cluster them into like groups. The locations are then colored to show which locations 
          belong in the same group, or cluster, as other locations. You can use this tool to pick the number of clusters. The algorithm used to do the clustering is called <a href=" https://simplestatistics.org/docs/#ckmeans">'ckmeans'</a> and is an improvement 
          over traditional k-means clustering. It does not have a feature to determine the 
          optimal number of clusters, however, you can quickly recluster using a new number of clusters and view the output. </p> 
          Filtering out extreme values (or locations with extreme values) from the map will improve the meaningfulness of the resulting clusters most of the time. Finding spatial patterns in the 
          clusters is one way this tool may be useful.
        </div>) : (null) }
        <div className="form-group has-danger">
          <label className="form-control-label">Number of Clusters</label>
          <input type="text" value={clusters} className={valid ? "form-control" : "form-control is-invalid"} onChange={this.handleChange.bind(this)}/>
          { !valid ? (<div className="invalid-feedback">Please use an integer from 3 - 11.</div>) : null }
        </div>
        <button disabled={!this.state.isValid} type="submit" className={this.state.isValid ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.handleUpdate.bind(this)}>Update</button>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    nClusters: state.MapApp.get('nClusters')
  }
}

const ClusterTool = connect(mapStateToProps)(ClusterToolComp);

export default ClusterTool;