export const toggleFilter = (id) => ({
  type: "TOGGLE_FILTER",
  id
})

export const toggleField = (id) => ({
  type: "TOGGLE_FIELD",
  id
})

export const recenterMap = () => ({
  type: "RECENTER_MAP"
})

export const setMapBounds = (minLat, minLong, maxLat, maxLong) => ({
  type: "SET_MAP_BOUNDS",
  bounds: [[minLat, minLong], [maxLat, maxLong]]

})

export const updateSlider = (value, field, field_id, filter_id, brush,active) => ({
  type: "UPDATE_SLIDER",
  value,
  field,
  field_id,
  filter_id,
  brush,
  active
})

export const filterData = (id) => ({
  type: "FILTER_DATA",
  id
})

export function dataFiltered() {
  console.log('data filtered being called')
  return (dispatch, getState) => {
    const state = getState();
    dispatch({type: "MAP_DATA_FILTERED", matrix: state.MapApp.get('filters').filter(x => x.get('active') && x.get('field') === 'matrix'), data: state.MapApp.get('filteredLocations').filter(x => x.get('visible')) }); //.map(x => x.get('location')
  }
}

export function locSearch(text) {
  return (dispatch, getState) => {
    const state = getState();
    var loc = state.MapApp.get('filteredLocations').filter(x => x.get('location') === text);
    if(loc.size > 0) {
      dispatch({type: "FIND_LOCATION", location: text});
    } else {
      dispatch({type: "LOSE_LOCATION"});
      dispatch({type: "DATA_ERROR", message: 'Location not shown or not found.'})
    }
  }
}

export const loseLocation = () => ({
  type: "LOSE_LOCATION"
})

export const getFilters = () => ({
  type: "GET_FILTERS"
})

export const selectAnalyte = (value) => ({
  type: "SELECT_ANALYTE",
  value
})

export const chooseRadius = (value) => ({
  type: "CHOOSE_RADIUS",
  value
})

export const chooseColor = (value) => ({
  type: "CHOOSE_COLOR",
  value
})

export const toggleShowLocs = () => ({
  type: "TOGGLE_SHOW_LOCATIONS"
})

export const toggleShowRadius = () => ({
  type: "TOGGLE_SHOW_RADIUS"
})

export const toggleShowIntensity = () => ({
  type: "TOGGLE_SHOW_INTENSITY"
})

export const chooseHistogram = (idx, x0, x1, radius) => ({
  type: "CHOOSE_HISTOGRAM",
  idx,
  x0,
  x1,
  radius
})

export const chooseFrequency = (locations) => ({
  type: "CHOOSE_FREQUENCY",
  locations
})

export const chooseLocation = (location) => ({
  type: "CHOOSE_LOCATION",
  location
})

export const setQuantileLocation = (location) => ({
  type: "QUANTILE_LOCATION",
  location
})

export const setPiperLocation = (location) => ({
  type: "PIPER_LOCATION",
  location
})

export const togglePlotModal = (plot) => ({
  type: "TOGGLE_PLOT_MODAL",
  plot
})

export const resizePlotModal = (plot,width,height) => ({
  type: "RESIZE_PLOT_MODAL",
  width,
  height,
  plot
})

export const createdPolygon = (id, polygon, layer) => ({
  type: "POLYGON_CREATED",
  id,
  polygon,
  layer
})

export const deletedPolygon = (ids) => ({
  type: "POLYGON_DELETED",
  ids
})

export const resetPolygons = (data) => ({
  type: "POLYGON_RESET",
  data
})

export const viewpointChange = (viewpoint) => ({
  type: "VIEWPOINT_CHANGE",
  viewpoint
})

export const showGauge = (id) => ({
  type: "SHOW_GAUGE",
  id
})

export const hideGauge = (id) => ({
  type: "HIDE_GAUGE",
  id
})

export const addGauge = (id) => dispatch => {
  return fetch("https://waterservices.usgs.gov/nwis/stat/?format=rdb,1.0&sites=0" + id + "&statReportType=monthly&statTypeCd=mean&missingData=on&parameterCd=00060")
    .then(res => {
      return res.text()
    })
    .then(
      (tabFile) => {
        var n = tabFile.lastIndexOf("#");
        var Obj = tabFile.slice((n + 1));
        var lines = Obj.split("\n");
        var headers=lines[1].split("\t");
        var json = [{"date":[],"flow":[]}];
        for (var i = 3; i < (lines.length - 1); i++) {
            var line = lines[i].split("\t");
            var time;
            json[0].flow.push(parseFloat(line[(line.length - 1)]));
            if(headers.indexOf("day_nu") > -1) {
                var month = line[6],
                    day = line[7];
                if(line[6].length === 1) month = '0' + line[6];
                if(line[7].length === 1) day = '0' + line[7];
                time = line[5] + '-' + month + '-' + day;
                json[0].date.push(new Date(time));
            } else if(headers.indexOf("month_nu") > -1) {
                if(line[6].length === 1) {
                    time = line[5] + '-0' + line[6];
                } else {
                    time = line[5] + '-' + line[6];
                }
                json[0].date.push(new Date(time));
            } else {
                time = line[5];
                json[0].date.push(new Date(time));
            }
        }
        dispatch({ type: 'ADD_GAUGE', id: id, data: json})
      },
      (error) => {
        console.log(error)
        dispatch({ type: 'DATA_ERROR', message: 'Error getting USGS flow data.'})
      }
    )
}

export const removeGauge = (id) => ({
  type: "REMOVE_GAUGE",
  id
})
