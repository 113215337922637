import React, { Component } from 'react';
import { List } from 'immutable';
import L from 'leaflet';
import shortid from 'shortid';
//import * as leafletPrint from 'leaflet.browser.print/dist/leaflet.browser.print.js';
//import keyIndex from 'react-key-index';
import * as ss from 'simple-statistics';
import hexGrid from '@turf/hex-grid';
import turf_smooth from '@turf/polygon-smooth'; //only for polygons and multipolygons
import turf_bezierSpline from '@turf/bezier-spline'; //only for linestrings
import { lineString as turf_lineString } from '@turf/helpers';
//import smooth from 'chaikin-smooth'; //only for polylines
//import paper from 'paper'; // another potential line-smoother (http://paperjs.org/tutorials/paths/smoothing-simplifying-flattening/)
//import turf_interpolate from '@turf/interpolate';
import turf_isolines from '@turf/isolines';
import turf_isobands from '@turf/isobands';
import turf_tin from '@turf/tin';
import * as d3 from 'd3';
import * as d3chrome from 'd3-scale-chromatic';
import chroma from 'chroma-js';
import { fromJS } from 'immutable';
import turf_count from 'turf-count';
import pointsWithinPolygon from '@turf/points-within-polygon';
import FontAwesome from 'react-fontawesome';
import { point as turf_point, featureCollection as turf_featureCollection } from '@turf/helpers';
import { Map, Pane, TileLayer, LayersControl, CircleMarker, FeatureGroup, ScaleControl, GeoJSON, Tooltip, Popup } from 'react-leaflet'; //, Popup
import { EditControl } from "react-leaflet-draw";
import Control from 'react-leaflet-control';
//import ReactLoading from 'react-loading';
import HeatmapLayer from './HeatmapLayer';
import Modal from '../extras/Modal';
import GaugeTool from './GaugeTool';
import { fetchOverlays } from '../../actions' //setFetchingText, showLoading, hideLoading
import { chooseLocation, locSearch, createdPolygon, deletedPolygon, resetPolygons, filterData, dataFiltered, viewpointChange, recenterMap, loseLocation, resizePlotModal, togglePlotModal, addGauge, showGauge } from '../../actions/map';
import { format } from '../../reducers/mapHelper.js';
import * as topojson from './topojson.js';
import { style_subareas, style_lith, style_soil, style_paleo, style_weirs, style_seeps, style_gauges, style_flowlines, style_wash, style_allwells, style_bkg_soils, style_first_contact } from './overlay_styles.js';
import { onEveryFeatureLith, onEveryFeatureSeep, onEveryFeatureSoil, onEveryFeatureSubarea, onEveryFeatureAllWells, onEveryFeatureBkgSoils, onEveryFeatureFirstContact } from './overlay_styles.js';
import LegendScale from './LegendScale';
import 'font-awesome/css/font-awesome.css';
import 'leaflet/dist/leaflet.css';
import '../../css/leaflet.draw.css';
import map_styles from '../../css/map.css';

const { BaseLayer, Overlay } = LayersControl

function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

const LegendContinuous = (props) => {
  let title = props.scalar;
  let min = props.min;
  let max = props.max;
  let fill = props.colorScale;

  return (
    <div style={{margin:'10px', padding:'10px', background:'rgba(255,255,255,.9)', borderRadius:"5px", 'boxShadow': '0px 1px 5px rgba(0,0,0,0.35)'}}>
      <h4>{title}</h4>
      <LegendScale height={250} width={150} fill={fill} min={min} max={max} />
    </div>
  );

};

const ToolLegendRow = (props) => {
  var text = props.category;
  let color = props.color;

  if(!text) {
    text = 'Null';
  }

  return (
    <div className='legendRow' >
      <div style={{minWidth:50 + 'px', float:'left'}}>
        <div className='legendCircle' style={{
          "background": color,
          "borderRadius":'5px',
          "width": 50 + 'px',
          "height": 25 + 'px',
          "border":"1px solid " + chroma(color).darken(),
          "marginTop": "5px",
          "marginBottom": "5px",
          "marginRight": "5px",
          "marginLeft": 5 + 'px'
        }}>
        </div>
      </div>
      <div style={{paddingTop: 7.5 + 'px', minWidth: 50 + 'px', float:'left' }}>
        {'\u2264 ' + text}
      </div>
      <div style={{clear:"both"}}></div>
    </div>
  )
}

const ToolLegend = (props) => {
  let values = props.categories;
  let colors = props.colors;
  let title = props.scalar;

  var legendRows = [];

  for(let i = 0; i < values.length; i++) {
    legendRows.push((
      <ToolLegendRow key={i} i={i} color={colors[i]} category={Math.max(...values[i])} />
    ));
  }

  return (
    <div style={{margin:'10px', padding:'10px', background:'rgba(255,255,255,.9)', borderRadius:"5px", 'boxShadow': '0px 1px 5px rgba(0,0,0,0.35)'}}>
      <h4>{title}</h4>
      {legendRows}
    </div>
  );
};

const LegendRow = (props) => {
  //var i = props.i;
  let radius = props.radius;
  let color = props.color;
  let max = props.max;
  let minWidth = 50;

  return (
    <div className='legendRow' >
      <div style={{width:((max * 2) + 10) + 'px', float:'left'}}>
        <div className='legendCircle' style={{
          "background": color,
          "borderRadius":80,
          "width": (radius * 2) + 'px',
          "height": (radius * 2) + 'px',
          "border":"3px solid " + color,
          "marginTop": "5px",
          "marginBottom": "5px",
          "marginRight": "5px",
          "marginLeft": 5 + (max - radius) + 'px'
        }}>
        </div>
      </div>
      <div style={{paddingTop: (radius/2) + 'px', minWidth: minWidth + 'px', float:'left' }}>
        {props.text}
      </div>
      <div style={{clear:"both"}}></div>
    </div>
  )
}

const Legend = (props) => {
  let numIntervals = props.numIntervals;
  let interval = props.interval;
  let intervals = props.intervals;
  let intensity = props.intensity;
  let radius = props.radius;
  let radiusMin = props.radiusMin;
  let radiusMax = props.radiusMax;
  let type = props.scaleType;
  let stat = props.stat;
  let units = props.filters.get('unit') ? props.filters.get('unit').get('filters').toJS()[0] : '';

  //let unit = props.unit;
  var legendRows = [];

  /*
  //TODO Right now there's no null values for locations because they are calculated
  //based on the "filteredResults", but should we include all locations?
  legendRows.push(
      <LegendRow  key={0} i={1} text="Null" numIntervals={numIntervals} color="#555" />
  );
  */

  /* trying to avoid whites in the middle of a RdBu scale AND oranges at the lower end of a red scale */
  var bluesScale = d3.scaleLinear().domain([0, 0.5]).range([1, 0.55]);
  var redsScale = d3.scaleLinear().domain([0.5, 1]).range([0.55, 1]);

  var radiusScale, colorScale, radiusValue, colorValue,
  radii = [],
  percents = [];
  //for use on the radius and the color scale.  -- continuous
  if(type === 'linear') {
    radiusScale = d3.scaleLinear().domain(intervals).range([radiusMin, radiusMax]);
  } else {
    //for use on the radius and the color scale.  -- using ckmeans or equal interval breaks
    for (var p = 0; p < (numIntervals + 2); p++) {
      percents.push(p * interval)
      if(p === 0) {
        radii.push(0)
      } else {
        radii.push(radiusMin + ((p - 1) * ((radiusMax - radiusMin)/(numIntervals - 1))))
      }
    }
    radiusScale = d3.scaleThreshold().domain(intervals).range(radii);
    colorScale = d3.scaleThreshold().domain(intervals).range(percents);
  }

//console.log({interval: interval, numIntervals: numIntervals, intervals: intervals, percents: percents})

  for(let i = 1; i <= intervals.length - 1; i++) {

    //console.log([intervals[i], colorScale(intervals[i - 1] ), (radiusScale( intervals[i - 1]))])

    if(radius) {
      radiusValue = radiusScale( intervals[i - 1] )
    } else {
      radiusValue = 5;
    }

    if(intensity) {
      if(colorScale(intervals[i - 1]) <= 0.5) {
        colorValue = d3chrome.interpolateBlues( bluesScale( colorScale(intervals[i - 1]  ) )).replace(')', ', 0.5)').replace('rgb', 'rgba')
      } else {
        colorValue = d3chrome.interpolateReds( redsScale( colorScale(intervals[i - 1]  ) )).replace(')', ', 0.5)').replace('rgb', 'rgba')
      }
    } else {
      colorValue = map_styles.resultColorAlpha;
    }

    var text;
    if(stat === 'sampleSize') {
      if(i === (intervals.length - 1) && type !== 'linear') {
        text = String.fromCharCode(8804) + " " + parseInt(intervals[i] - 1, 10);
      } else if(i === (intervals.length - 1) && type === 'linear') {
        text = String.fromCharCode(8804) + " " + parseInt(intervals[i], 10);
      } else {
        text = String.fromCharCode(60) + " " + parseInt(intervals[i], 10);
      }
    } else {
      if(i === (intervals.length - 1) && type !== 'linear') {
        text = String.fromCharCode(8804) + " " + format(intervals[i] - 1) + " " + units;
      } else if(i === (intervals.length - 1) && type === 'linear') {
        text = String.fromCharCode(8804) + " " + format(intervals[i]) + " " + units;
      } else {
        text = String.fromCharCode(60) + " " + format(intervals[i]) + " " + units;
      }
    }
    var textValue = intervals[i]
    legendRows.push((
        <LegendRow key={i} i={i} color={colorValue} textValue={textValue} text={text} radius={radiusValue} max={radiusMax}/>
    ));
  }
  return (
    <div style={{margin:'10px', padding:'10px', background:'rgba(255,255,255,.9)', borderRadius:"5px", 'boxShadow': '0px 1px 5px rgba(0,0,0,0.35)'}}>
      {legendRows}
    </div>
  );

};

const InfoLegend = (props) => {
  var row = props.row;

  return (
    <div style={{margin:'10px',padding:'10px', background:'rgba(255,255,255,.9)', borderRadius:"5px", textAlign:'left', 'boxShadow': '0px 1px 5px rgba(0,0,0,0.35)'}}>
        <div><strong>{row.get('location')}</strong></div>
        <div>Samples: {row.get('sampleSize')}</div>
        <div>Max: {format(row.get('max'))}</div>
        <div>Min: {format(row.get('min'))}</div>
        <div>Mean: {format(row.get('mean'))}</div>
        <div>Median: {format(row.get('median'))}</div>
    </div>
  )
}

class DataMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltips: true,
      draggable:true
    }
  }

  componentWillMount(){
    if(this.props.overlays.size === 0) {
      this.props.dispatch(fetchOverlays());
    }
  }

  componentDidMount(){
    const { popupLocation } = this.refs;
    //const map = this.refs.map.leafletElement;

    //L.control.browserPrint().addTo(map);
    //map.on("browser-print-start", function(e){
			/*on print start we already have a print map and we can create new control and add it to the print map to be able to print custom information */
			//L.legendControl({position: 'bottomright'}).addTo(e.printMap);
		//});

    /*map.dragging._enabled = false
    map.dragging._draggable._enabled = false
    console.log(map.dragging)*/
    //if(this.props.mapBounds)  map.leafletElement.setBounds(this.props.mapBounds)
    //map.leafletElement.getPane('overlayPane').style.pointerEvents = 'paintedVisisble';
    if(this.props.foundLocation) {
      popupLocation.leafletElement.openPopup()
      setTimeout(function(){
        this.props.dispatch(loseLocation());
      }.bind(this), 3000);  // wait 3 seconds, then clear out the chosen location.
    }
  }

  componentDidUpdate(){
    const { popupLocation } = this.refs //, edit
    //this._onDrawMounted(edit.leafletElement);
    /*if(this.props.mapBounds) {
      console.log(map)
      map.leafletElement.setBounds(this.props.mapBounds)
    }*/
    //map.leafletElement.getPane('overlayPane').style.pointerEvents = 'paintedVisisble';
    if(this.props.foundLocation) {
      popupLocation.leafletElement.openPopup()
      setTimeout(function(){
        this.props.dispatch(loseLocation());
      }.bind(this), 3000);  // wait 3 seconds, then clear out the chosen location.
    }
  }

  handleMarkerClick(location){
    this.props.dispatch(chooseLocation(location));
  }

  handleMarkerHover(e){
    e.target.bringToFront();
  }

  performLocSearch(e){
    if(e.keyCode === 13) {
      this.props.dispatch(locSearch(e.target.value));
    }
  }

  clearLocSearchProps() {
    this.props.dispatch(loseLocation());
  }

  recenterMap() {  //minLat, minLong, maxLat, maxLong
    //if(minLat !== 0 && minLong !== 0 && maxLat !== 0 && maxLong !== 0) {
    //  this.props.dispatch(setMapBounds(minLat, minLong, maxLat, maxLong));
    //} else {
      this.props.dispatch(recenterMap());
    //}
  }

  toggleTooltips() {
    this.setState({ tooltips: !this.state.tooltips })
  }

  /*turnOffLoading() {
    this.props.dispatch(hideLoading());
  }*/

  /*interpolate(data, cellSize, type, weighting) {
    this.props.dispatch(setFetchingText("Interpolating..."));
    this.props.dispatch(showLoading());
    var grid = turf_interpolate(data, cellSize, {gridType: type, property: 'zValue', units: 'kilometers', weight: weighting});
    this.props.dispatch(hideLoading());
    return grid;
  }/*/

  style_hexes(feature){
    return {
      fillColor: "#36454f",
      fillOpacity: feature.properties.opacity || 0.9,
      color: 'white',
      weight: 0.8
    };
  }

  viewpointChanged(viewpoint){
    console.log("VIEWPOINT CHANGED")
    this.props.dispatch(viewpointChange(viewpoint));
  }

  /*
   * POLYGON STUFF
   */
  addPreviouslyDrawnItems(layerContainer) {
    var layers = this.props.drawnPolygons.toJS();

    layers.forEach(layer => {
      new L.GeoJSON(layer.layer, {
        pointToLayer: function(feature, latlng) {
          return L.polygon(latlng);
        },
        onEachFeature: function(feature, layer) {
          layer.addTo(layerContainer);
        },
        style: { color: '#27ae60' }
      })
    })
  }

  removePreviouslyDrawnItems(layerContainer) {
    var layers = layerContainer._layers;
    var layer_ids = Object.keys(layers);
    var layer;

    layer_ids.forEach(id => {
      layer = layers[id]
      layerContainer.removeLayer(layer);
    })
  }

  resetStoredPolygons(layers) {
    var redrawnPolygons = [],
        layer, j, feature,
        layer_ids = Object.keys(layers);

    layer_ids.forEach(id => {
      layer = layers[id]

      j = layer.toGeoJSON();
      j.properties = layer.options;
      feature = "";
      feature += JSON.stringify(j);

      redrawnPolygons.push({ id: layer._leaflet_id, coords: layer._latlngs, layer: JSON.parse(feature) })
    })

    this.props.dispatch(resetPolygons(redrawnPolygons));
  }

  _onDrawMounted(e) {
    //console.log('Draw has mounted')
    var layerContainer = e.options.edit.featureGroup;
    if(isEmpty(layerContainer._layers) && this.props.drawnPolygons.size > 0) {
      //console.log('there are saved layers that are not shown')
      this.addPreviouslyDrawnItems(layerContainer);
      this.resetStoredPolygons(layerContainer._layers)
    }
    if(!isEmpty(layerContainer._layers) && this.props.drawnPolygons.size < 1) {
      this.removePreviouslyDrawnItems(layerContainer);
    }
  }

  _onDrawStart(e){
    //console.log("onDrawStart",e)
    //Hacky, disable map dragging while drawing polygon or else a re-render clears our polygon
    e.target.dragging._draggable._enabled = false;
  }

  _onDrawStop(e){
    //console.log("onDrawStop",e)
    e.target.dragging._draggable._enabled = true;
  }

  onPolygonCreated(e) {
    var layer = e.layer;

    var j = layer.toGeoJSON();
    j.properties = layer.options;
    var feature = "";
    feature += JSON.stringify(j);

    this.props.dispatch(createdPolygon(e.layer._leaflet_id, e.layer._latlngs, JSON.parse(feature)));
    this.props.dispatch(filterData(-1));
    this.props.dispatch(dataFiltered());
  }

  onPolygonDeleted(e){
    var layer_ids = Object.keys(e.layers._layers);

    this.props.dispatch(deletedPolygon(layer_ids));
    this.props.dispatch(filterData(-1));
    this.props.dispatch(dataFiltered());
  }

  togglePlotModal(plot){
    this.props.dispatch(togglePlotModal(plot));
  }

  resizePlotModal(plot,width,height){
    this.props.dispatch(resizePlotModal(plot,width,height))
  }

  onEveryFeatureGauge(feature, layer){
    var self = this;
    if (feature.properties) {

      layer.bindPopup('<b>Gauge:</b> ' + feature.properties.Gauage_nm + ' (0' + feature.properties.ID + ')' +
          '<br />Datum:</b> ' + feature.properties.Gage_dtm_f + ' (ft)'
      );

      layer.on('mouseover', function(e) {
          this.openPopup();
      });

      layer.on('mouseout', function(e) {
        this.closePopup();
      })

      layer.on('click', function(e) {
        var gaugeDat = self.props.gaugeData.filter(gauge => gauge.get('id') === e.sourceTarget.feature.properties.ID.toString()).get(0)
        if(!gaugeDat.get('show')) {
          if(gaugeDat.get('data') !== null) {
            self.props.dispatch(showGauge(e.sourceTarget.feature.properties.ID.toString()));
          } else {
            self.props.dispatch(addGauge(e.sourceTarget.feature.properties.ID.toString()));
          }
        }

      })
    }
  }

  render() {
    //var isFetching = this.props.isFetching;

    var locSearch =(<input className="form-control" type="search" placeholder="search for a location" onKeyDown={this.performLocSearch.bind(this)} tabIndex="0" style={{'width':'200px', 'height':'45px', 'marginRight':'350px', 'padding':'10px', 'background':'rgba(255,255,255,.9)', 'borderRadius':"5px", 'boxShadow': '0px 1px 5px rgba(0,0,0,0.35)'}}/>)
    var fitBounds =(
      <button className="recenterButton" onClick={() => this.recenterMap()} type="button" style={{'cursor': 'pointer', 'width':'34px', 'height':'33px'}}>
        <span style={{ textAlign: 'center' }}><FontAwesome name='crosshairs' style={{textAlign: 'center', textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', 'fontSize': '20px', verticalAlign: 'middle', 'lineHeight': '20px' }} /></span>
      </button>
    )

    // format overlays ---- NOTE: order matters right now. Should key these out later.
    var overlay_data = this.props.overlays.toJS();
    var features = ['NDEP_Flowline', 'USGS_gauges', 'lithography', 'PaleoChannels', 'Seeps_LasVegasWash_2000', 'soil', 'subareas', 'LV_Wash', 'LVW_Weir', 'Wells', 'Background_Soils', null];

    var flowlines = undefined;
    var gauges = undefined;
    var lith = undefined;
    var paleo = undefined;
    var seeps = undefined;
    var soil = undefined;
    var subareas = undefined;
    var wash = undefined;
    var weirs = undefined;
    var allwells = undefined;
    var bkg_soils = undefined;
    var first_contact = undefined;

    //var north = undefined, south = undefined, warm = undefined; //close = undefined,

    if(this.props.overlays.size > 0) {
      flowlines = (<GeoJSON key={'0'} data={topojson.feature(overlay_data[0], overlay_data[0].objects[features[0]])} style={style_flowlines}></GeoJSON>);
      gauges = (<GeoJSON key={'1'} data={topojson.feature(overlay_data[1], overlay_data[1].objects[features[1]])} onEachFeature={this.onEveryFeatureGauge.bind(this)} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_gauges);}}></GeoJSON>);
      lith = (<GeoJSON key={'2'} data={topojson.feature(overlay_data[2], overlay_data[2].objects[features[2]])} style={style_lith} onEachFeature={onEveryFeatureLith}></GeoJSON>);
      // paleo = (<GeoJSON key={'3'} data={topojson.feature(overlay_data[3], overlay_data[3].objects[features[3]])} style={style_paleo}></GeoJSON>);
      paleo = (<GeoJSON key={'3'} data={overlay_data[3]} style={style_paleo}></GeoJSON>);
      seeps = (<GeoJSON key={'4'} data={topojson.feature(overlay_data[4], overlay_data[4].objects[features[4]])} onEachFeature={onEveryFeatureSeep} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_seeps(feature));}}></GeoJSON>);
      soil = (<GeoJSON key={'5'} data={topojson.feature(overlay_data[5], overlay_data[5].objects[features[5]])} onEachFeature={onEveryFeatureSoil} style={style_soil}></GeoJSON>);
      subareas = (<GeoJSON key={'6'} data={topojson.feature(overlay_data[6], overlay_data[6].objects[features[6]])} onEachFeature={onEveryFeatureSubarea} style={style_subareas}></GeoJSON>);
      wash = (<GeoJSON key={'7'} data={topojson.feature(overlay_data[7], overlay_data[7].objects[features[7]])} style={style_wash}></GeoJSON>);
      weirs = (<GeoJSON key={'8'} data={topojson.feature(overlay_data[8], overlay_data[8].objects[features[8]])} style={style_weirs}></GeoJSON>);
      allwells = (<GeoJSON key={'9'} data={overlay_data[9]} onEachFeature={onEveryFeatureAllWells} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_allwells);}}></GeoJSON>);
      bkg_soils = (<GeoJSON key={'10'} data={overlay_data[10]} onEachFeature={onEveryFeatureBkgSoils} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_bkg_soils(feature));}}></GeoJSON>);
      first_contact = (<GeoJSON key={'11'} data={overlay_data[11]} onEachFeature={onEveryFeatureFirstContact} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_first_contact(feature));}}></GeoJSON>);

      /*north = (<GeoJSON key={'10'} data={overlay_data[10]} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_allwells);}}></GeoJSON>);
      south = (<GeoJSON key={'11'} data={overlay_data[11]} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_allwells);}}></GeoJSON>);
      //close = (<GeoJSON key={'12'} data={overlay_data[12]} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_allwells);}}></GeoJSON>);
      warm = (<GeoJSON key={'13'} data={overlay_data[13]} pointToLayer={function(feature, latlng){return L.circleMarker(latlng, style_allwells);}}></GeoJSON>);*/
    }

    /* --- USGS GAUGE TOOL FOR OTHER BUTTONS  --- **/
    var gaugeTool = (<GaugeTool data={this.props.gaugeData} height={this.props.gaugeSize[1]} width={this.props.gaugeSize[0]} dispatch={this.props.dispatch}/>)
    var gaugeModal = this.props.showGauges ? (<Modal
      title='USGS Gauge Flow Plots'
      body={gaugeTool}
      width={this.props.gaugeSize[0]}
      height={this.props.gaugeSize[1]}
      close_button={true}
      collapsible={false}
      overflow="scroll"
      onResize={this.resizePlotModal.bind(this, 'gauge')}
      onClose={this.togglePlotModal.bind(this, 'gauge')}/>
    ) : null;
    /* --- END USGS GAUGE TOOL --- **/
    var data = this.props.data.filter(row => row.get('lat') != null && row.get('long') != null).sort((a,b) => b.get('visible') - a.get('visible')).reverse(),
        markers = null,
        position,
        color,
        infoLegend = null,
        resultColor = map_styles.resultColor,
        noResultColor = map_styles.noResultColor,
        highlightColor = map_styles.highlightColor,
        selectedColor = map_styles.selectedColor,
        plotColor = map_styles.plotColor,
        piperColor = '#CC3232',
        legend = null,
        scaleValue = 5,
        radiusStat = this.props.radius,
        radius = 5,
        numIntervals = 6,
        maxStat,
        minStat,
        interval,
        intervals = [],
        maxLat = 0,
        minLat = 0,
        minLong = 0,
        maxLong = 0,
        key,
        allData = [],
        turf_pts = [],
        radiusMax = 18,
        radiusMin = 4,
        //turf_tin_pts = [],
        //turf_tin_collection,
        turf_collection;

    if(data.size){
      //if(this.props.isFetching) this.turnOffLoading();

      maxLat = data.filter(x => x.get('visible') && x.get('lat') !== null).map((row) => parseFloat(row.get('lat'),10)).max();
      minLat = data.filter(x => x.get('visible') && x.get('lat') !== null).map((row) => parseFloat(row.get('lat'),10)).min();
      minLong = data.filter(x => x.get('visible') && x.get('lat') !== null).map((row) => parseFloat(row.get('long'),10)).min();
      maxLong = data.filter(x => x.get('visible') && x.get('lat') !== null).map((row) => parseFloat(row.get('long'),10)).max();

      if(radiusStat !== 'location'){
        maxStat = data.filter(x => x.get('visible')).map((row) => row.get(radiusStat) || 0).max();
        minStat = data.filter(x => x.get('visible')).map((row) => row.get(radiusStat) || 0).min();
        data.filter(x => x.get('visible')).map((row) => {
          allData.push(+row.get(radiusStat));
          return row;
        });

        var tmpints, c, p,
            ints = [];
        if(allData.length < numIntervals) {
          numIntervals = (allData.length - 1);
          intervals = [...new Set(allData)];  // or d3.set(allData).values(); for a unique set of interval breaks
          interval = (1 / numIntervals);
        } else {
          if(this.props.mapBreaks === 'ckmeans') {
            tmpints = ss.ckmeans(allData, numIntervals)
            ints[0] = tmpints[0][0];
            for (c = 0; c < tmpints.length; c++) {
              if(c === (tmpints.length - 1)) {
                ints.push(d3.max(tmpints[c]) + 1)
              } else {
                ints.push(d3.max(tmpints[c]))
              }
            }
            intervals = [...new Set(ints)];
            //interval = Math.ceil(radiusMax / numIntervals, 10);
            interval = (1 / numIntervals);
          } else if(this.props.mapBreaks === 'equal') {
            tmpints = ss.equalIntervalBreaks(allData, numIntervals)
            ints[0] = tmpints[0][0];
            for (c = 0; c < tmpints.length; c++) {
              if(c === (tmpints.length - 1)) {
                ints.push(d3.max(tmpints[c]) + 1)
              } else {
                ints.push(d3.max(tmpints[c]))
              }
            }
            intervals = [...new Set(ints)];
            //interval = Math.ceil(radiusMax / numIntervals, 10);
            interval = (1 / numIntervals);
          } else if(this.props.mapBreaks === 'linear') {
            //interval = Math.ceil(this.props[radiusStat] / numIntervals, 10);
            interval = Math.ceil(maxStat / numIntervals, 10);
            intervals = [];
            for (p = 0; p < (numIntervals - 1); p++) {
              intervals.push(interval + (p * interval))
            }
          }
        }

        legend = this.props.radius === 'location' ? null : (
          <Legend stat={radiusStat} filters={this.props.filters} scaleType={this.props.mapBreaks} intervals={intervals} interval={interval} numIntervals={numIntervals} intensity={this.props.showIntensity} radius={this.props.showRadius} radiusMin={radiusMin} radiusMax={radiusMax}/>
        )

      } else {
        maxStat = data.filter(x => x.get('visible')).map((row) => row.get('max') || 0).max();
        minStat = data.filter(x => x.get('visible')).map((row) => row.get('max') || 0).min();
        data.filter(x => x.get('visible')).map((row) => {
          allData.push(+row.get('max'));
          return row;
        });
        interval = Math.ceil(maxStat / numIntervals, 10);
      }
    }

    var mapStat = radiusStat;
    if(radiusStat === 'location') {
      mapStat = 'max';
    }

    // KMeans clustering
    /*var clusters = [maxStat];
    var clusterLegend = null;
    var clusterColors = function(idx) {
      return '#aaa';
    }
    if(allData.length > this.props.nClusters) {
      var numClusters = this.props.nClusters;
      clusters = chroma.limits(allData, 'k', numClusters);
      clusterColors = d3chrome.schemeBrBG[numClusters];
    }*/

    // CKMeans clustering
    var clusters = [maxStat];
    var clusterLegend = null;
    var clusterColors = function(idx) {
      return '#aaa';
    }
    if(allData.length > this.props.nClusters) {
      var numClusters = this.props.nClusters;
      //clusters = chroma.limits(allData, 'k', numClusters);
      clusters = ss.ckmeans(allData, numClusters);
      clusterColors = d3chrome.schemeBrBG[numClusters];
      clusterLegend = (
        <ToolLegend categories={clusters} scalar='Clusters' colors={clusterColors} />
      )
    }

    /* trying to avoid whites in the middle of a RdBu scale AND oranges at the lower end of a red scale */
    var bluesScale = d3.scaleLinear().domain([0, 0.5]).range([1, 0.55]);
    var redsScale = d3.scaleLinear().domain([0.5, 1]).range([0.55, 1]);

    var radiusScale, colorScale,
    radii = [],
    percents = [];
    //for use on the radius and the color scale.  -- continuous
    if(this.props.mapBreaks === 'linear') {
      radiusScale = d3.scaleLinear().domain([minStat, maxStat]).range([radiusMin, radiusMax]);
    } else {
      //for use on the radius and the color scale.  -- using ckmeans or equal interval breaks
      for (p = 0; p < (numIntervals + 2); p++) {
        percents.push(p * interval)
        if(p === 0) {
          radii.push(0)
        } else {
          radii.push(radiusMin + ((p - 1) * ((radiusMax - radiusMin)/(numIntervals - 1))))
        }
      }
      radiusScale = d3.scaleThreshold().domain(intervals).range(radii);
      colorScale = d3.scaleThreshold().domain(intervals).range(percents);
    }

    markers = data.filter((row) => row.get('lat') && row.get('long'))
      .map( (row, i) => {
        position = [parseFloat(row.get('lat'),10), parseFloat(row.get('long'), 10)];

        scaleValue = radiusScale( row.get(radiusStat) )

        //console.log([row.get(radiusStat), scaleValue, colorScale( row.get(radiusStat))])

        //scaleValue =  Math.round((row.get(radiusStat) / maxStat) * numIntervals) * 3 + 3
        //We can have situations where the scaleValue is really big... put a cap on it.
        if(scaleValue > radiusMax){
          scaleValue = radiusMax
        }
        if(scaleValue < radiusMin){
          scaleValue = radiusMin
        }

        radius = (radiusStat !== 'location' && this.props.showRadius) ? scaleValue : 5;

        //key = keyIndex([row.get('location')], i)[0].id  // really shouldn't use i  --- should just use 1, but apparently we have locations with the same name... hopefully this will get 'fixed' with the database cleanup
        key = i;

        if(this.props.showIntensity) {
          /* continuous */
          if(this.props.mapBreak === 'linear') {
            if(row.get(radiusStat)/maxStat <= 0.5) {
              color = !row.get('max') ?  noResultColor : (radiusStat !== 'location' ? d3chrome.interpolateBlues( bluesScale( row.get(radiusStat) / maxStat) ) : resultColor);
            } else {
              color = !row.get('max') ?  noResultColor : (radiusStat !== 'location' ? d3chrome.interpolateReds( redsScale( row.get(radiusStat) / maxStat) ) : resultColor);
            }
          } else {
            /* using ckmeans or equal interval breaks */
            if(colorScale( row.get(radiusStat) ) <= 0.5) {
              color = !row.get('max') ?  noResultColor : (radiusStat !== 'location' ? d3chrome.interpolateBlues( bluesScale( colorScale(row.get(radiusStat)) )) : resultColor);
            } else {
              color = !row.get('max') ?  noResultColor : (radiusStat !== 'location' ? d3chrome.interpolateReds( redsScale( colorScale(row.get(radiusStat)) )) : resultColor);
            }
          }

        } else {
          color = !row.get('max') ?  noResultColor : resultColor;
        }

        if(this.props.showClusters) {
          var k = 0;
          for (i = 0; i < (clusters.length - 1); i++) {
            //if(row.get(mapStat) > clusters[i] && row.get(mapStat) <= clusters[(i + 1)]) {
            if(row.get(mapStat) > Math.max(...clusters[i]) && row.get(mapStat) <= Math.max(...clusters[(i + 1)])) {
              k = i + 1;
            }
          }
          color = clusterColors[k];
        }
        color = row.get('highlight') ? highlightColor : color;
        color = row.get('location') === this.props.quantileLocation ? plotColor : color;
        color = row.get('location') === this.props.piperLocation ? piperColor : color;
        color = row.get('location') === this.props.selectedLoc ? selectedColor : color;
        if(this.props.frequency.includes(row.get('location'))) {
          color = map_styles.frequencyColor;
        }

        if(row.get('location') === this.props.selectedLoc){
          infoLegend = <InfoLegend row={row} />;
        }

        if(radius < 1 || !radius) radius = 1;
        if( row.get('visible') && parseFloat(row.get('lat'),10) > 0){
          turf_pts.push(turf_point([position[1], position[0]], {zValue: row.get(mapStat), samples: row.get('sampleSize')}));
          //turf_tin_pts.push(turf_point([position[1] * 1000000, position[0] * 1000000], {zValue: row.get(mapStat)}));
          if(this.props.foundLocation && row.get('location') === this.props.foundLocation) {
            return (
              <CircleMarker ref="popupLocation" center={position} zIndexOffset={100} radius={radius} key={key} color={color} onClick={this.handleMarkerClick.bind(this,row.get('location'))} onMouseover={this.handleMarkerHover.bind(this)}>
                <Popup>
                  <span>{row.get('location')}</span>
                </Popup>
              </CircleMarker>
            )
          } else {
            return (
              <CircleMarker center={position} zIndexOffset={100} radius={radius} key={key} color={color} onClick={this.handleMarkerClick.bind(this,row.get('location'))} onMouseover={this.handleMarkerHover.bind(this)}>
                {this.state.tooltips ? (
                  <Tooltip direction='top' offset={[0, (0 - (radius + 2))]} opacity={1}>
                    <span>{row.get('location')}</span>
                  </Tooltip>
                  ) : ( null )
                }
              </CircleMarker>
            )
          }
        } else {
          return (
            <CircleMarker center={position} zIndexOffset={10} radius={1} key={key} color={'#aaa'}></CircleMarker>
          );
        }
      }
    )

    turf_collection = turf_featureCollection(turf_pts);
    //turf_tin_collection = turf_featureCollection(turf_tin_pts);

    // Heat Map Stuff
    if(this.props.showHotSpot) {
      var heatMapData = data.filter(x => x.get('visible')).sortBy(x => x.get(mapStat)).map((row) => [row.get('long'), row.get('lat'), row.get(mapStat)]);
      var heatMapMax = data.filter(x => x.get('visible')).map((row) => row.get(mapStat)).max()
    }

    // Sampling Frequency Hex Grid stuff
    var updatedHexGrid = List();
    if(data.size > 0 && allData.length > 0) {
      var bbox = [(minLong - 0.0025), (minLat - 0.0025), (maxLong + 0.0025), (maxLat + 0.0025)];
    }

    function onEachHex(feature, layer) {
      layer.bindPopup("# of Samples: " + Math.round(feature.properties.pt_count));
    }

    var hexLegend = null;
    if(this.props.showSamplingFreq && data.size > 0 && allData.length > 0) {
      var hexOptions = {units: 'kilometers'};
      var grid = hexGrid(bbox, 0.15, hexOptions);
      var counted_hex_grid = turf_count(grid, turf_collection, 'pt_count');
      var counts = [], i;

      for (i = 0; i < grid.features.length; i++) {
        counted_hex_grid.features[i].properties.opacity = 0.9;
      }

      counted_hex_grid.features.forEach(function(feature, ix) {
        var samples = [];
        var pip = pointsWithinPolygon(turf_collection, turf_featureCollection([feature]))
        if(pip.features.length > 0) {
          pip.features.forEach(function(feature) {
            samples.push(feature.properties.samples)
          })
          counts.push(d3.sum(samples));
        } else {
          counts.push(pip.features.length);
        }
      })

      // get maximum pt_count &
      // apply d3 scale to get opacity value
      var hex_colors = d3.scaleLinear()
                         .domain([0, 1, d3.max(counts)])
                         .range([1, 0.55, 0]);

      updatedHexGrid = fromJS(counted_hex_grid).get('features').map((row, ix) => {
        return row.updateIn(['properties','pt_count'], val => counts[ix]).updateIn(['properties','opacity'], val => hex_colors(counts[ix]))
      });
      hexLegend = (
        <LegendContinuous scalar='Number of Samples' min={0} max={d3.max(counts)} colorScale={hex_colors} />
      )
    }

    // Interpolation Points and Grid for Isolines and Isobands
    // contour levels
    //var breaks = [];
    //var numBreaks = 8;
    /*var maxBreak = allData.sort((a,b) => a - b)[Math.ceil(allData.length / 1.5)];   // essentially setting the max contour value at the 67th percentile of the data
    for (i = 1; i < (numBreaks + 1); i++) {
      breaks.push(minStat + ((maxBreak / numBreaks) * i));
    }*/

    var setBreaks = this.props.contourBreaks;
    var numBreaks = this.props.nContours;
    var numGridBreaks = 11;
    var gridBreaks = [];
    //var breaks = this.props.contourBreaks;
    //var numBreaks = Math.min(this.props.nContours, allData.length);
    var maxBreak = 1;
    var maxGridBreak = 1;
    var bins = [1];
    var gridBins = [1];
    var breaks = [];

    /* if user has chosen a set of contour breaks */
    //if(!this.props.useSetContourBreaks) {
    //if(breaks.length < 1) {
      if(allData.length > 1 && allData.length > numBreaks) {
        bins = ss.ckmeans(allData, numBreaks);
        //bins = ss.equalIntervalBreaks(allData, numBreaks)
      }
      for (i = 0; i < (bins.length - 1); i++) {
        breaks.push(d3.max(bins[i]));
        //maxBreak = d3.max(bins[i]);
      }
      //} else {
      //maxBreak = breaks[(breaks.length - 1)]
    //}

    if(allData.length > 1 && allData.length > numGridBreaks) {
      gridBins = ss.ckmeans(allData, numGridBreaks);
    }

    for (i = 0; i < (gridBins.length - 1); i++) {
      gridBreaks.push(d3.max(gridBins[i]));
    }

    maxGridBreak = gridBreaks[(gridBreaks.length - 1)];

    if(!this.props.useSetContourBreaks) {
      maxBreak = breaks[(breaks.length - 1)];
    } else if(this.props.useSetContourBreaks && setBreaks.length < 1) {
      setBreaks = breaks;
      maxBreak = setBreaks[(setBreaks.length - 1)];
    } else {
      maxBreak = d3.max(setBreaks);
    }

    //breaks = bins;
    //maxBreak = bins[(bins.length - 2)]

    //var isoColors = chroma.scale('RdBu').mode('lab');
    var isoColors = chroma.scale('RdBu').domain([1,0]); // reversing the color order (i.e. to 'BuRd')

    function isoLinesStyle(feature) {
      return {
        color: isoColors(feature.properties.zValue/maxBreak),
        weight: 1.8,
        fillOpacity: 0.8
      };
    }

    function onEachContour(feature, layer) {
      layer.bindPopup("Contour: " + Math.round(feature.properties.zValue));
    }

    function isoBandsStyle(feature) {
      return {
        color: isoColors(feature.properties.zValue/maxBreak),
        fillColor: isoColors(feature.properties.zValue/maxBreak),
        weight: 1.8,
        fillOpacity: 0.8
      };
    }

    function isoGridStyle(feature) {
      return {
        color: isoColors(feature.properties.zValue/maxGridBreak),
        fillColor: isoColors(feature.properties.zValue/maxGridBreak),
        weight: 1.8,
        opacity: 0.0,
        fillOpacity: 0.5
      };
    }

    var isolines = [];
    var isobands = [],
    // eslint-disable-next-line no-unused-vars
        smooth_isobands = [];
    var interpolationGrid = [];
    var interpolation = [];
    var lline, new_lline;//, paper_path;

    if((this.props.showContours || this.props.showGrid || this.props.showBands) && turf_collection.features.length > 0 && allData.length > 1) { //&& (this.props.doGrid || this.props.doContours)) {

      // Interpolation Grid
      if(this.props.showGrid && this.props.doGrid) {
        interpolationGrid = this.props.interpolationGrid.toJS();
      }

      // Isolines
      if(this.props.showContours && this.props.doContours) {
        //interpolation = turf_interpolate(turf_collection, this.props.gridCellSize, {gridType: 'points', property: 'zValue', units: 'kilometers', weight: this.props.distanceWeightingFactor});
        interpolation = this.props.contourInterpolationGrid.toJS();
        if(!this.props.useSetContourBreaks) {
          isolines = turf_isolines(interpolation, breaks, {zProperty: 'zValue'});
        } else {
          isolines = turf_isolines(interpolation, setBreaks, {zProperty: 'zValue'});
        }
        for (let ffeature = 0; ffeature < isolines.features.length; ffeature++) {
          for (let line_coords = 0; line_coords < isolines.features[ffeature].geometry.coordinates.length; line_coords++) {
            lline = turf_lineString(isolines.features[ffeature].geometry.coordinates[line_coords]);
            new_lline = turf_bezierSpline(lline, {resolution: 20000, sharpness: 0.9});
            isolines.features[ffeature].geometry.coordinates[line_coords] = new_lline.geometry.coordinates;
            //isolines.features[ffeature].geometry.coordinates[line_coords] = smooth(lline.geometry.coordinates);
            //paper_path = new paper.Path(); // I think we might have to use canvas for this, and I don't want to
            //paper_path = new paper.Path({segments: lline.geometry.coordinates, strokeColor: 'black', closed: false});
            //console.log(paper_path.smooth())
          }
        }
      }

      // Isobands
      if(this.props.showBands) {
        interpolation = this.props.contourInterpolationGrid.toJS();
        if(!this.props.useSetContourBreaks) {
          isobands = turf_isobands(interpolation, breaks, {zProperty: 'zValue'});
        } else {
          isobands = turf_isobands(interpolation, setBreaks, {zProperty: 'zValue'});
        }
        // eslint-disable-next-line no-unused-vars
        smooth_isobands = turf_smooth(isobands, {iterations: 3});
      }
    }

    // Tin
    var colorRamp = chroma.scale(['white', 'red']).mode('lab');
    var maxAvg = 0;
    if(this.props.showTin) {
      var tin = turf_tin(turf_collection, 'zValue');

      for (var g = 0; g < tin.features.length; g++) {
        var properties  = tin.features[g].properties;
        properties.average = (properties.a+properties.b+properties.c)/3;
        if (properties.average > maxAvg) maxAvg = properties.average;

        /*var coordinates = tin.features[g].geometry.coordinates[0];
        coordinates[0][0] = coordinates[0][0]/1000000
        coordinates[0][1] = coordinates[0][1]/1000000
        coordinates[1][0] = coordinates[1][0]/1000000
        coordinates[1][1] = coordinates[1][1]/1000000
        coordinates[2][0] = coordinates[2][0]/1000000
        coordinates[2][1] = coordinates[2][1]/1000000*/
      }
    }

    function onEachTri(feature, layer) {
      layer.bindPopup("Average: " + Math.round(feature.properties.average));
    }

    function tinStyle(feature) {
      return {
        fillColor: colorRamp(feature.properties.average/maxAvg),
          weight: 0,
          fillOpacity: 0.7
      };
    }

    return (
      <div className="map_container" style={{height: this.props.height}}>
        {gaugeModal}
         <Map ref="map" center={this.props.mapCenter} zoom={this.props.mapZoom} onViewportChanged={this.viewpointChanged.bind(this)}>
           {this.props.showHotSpot ? (
          <HeatmapLayer max={heatMapMax} minOpacity={0.05} points={heatMapData.toJS()} longitudeExtractor={m => m[0]} latitudeExtractor={m => m[1]} intensityExtractor={m => parseFloat(m[2])} style={{ zIndex: 425 }} radius={this.props.hotSpotRadius}/>
          ) : null }
          <LayersControl position='topleft'>
            { /* <BaseLayer name='Open Street Map'>
              <TileLayer
                url='https://tile.openstreetmap.org/{z}/{x}/{y}.{ext}'
                attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                minZoom={0}
                maxZoom={18}
                ext='png'
              />
            </BaseLayer> */ }
            <BaseLayer name='Stamen - Terrain'>
              <TileLayer
                url='https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.{ext}'
                attribution='Map tiles by <a href="https://stamen.com">Stamen Design</a>, <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>'
                subdomains='abcd'
                minZoom={0}
                maxZoom={18}
                ext='png'
              />
            </BaseLayer>
            <BaseLayer name='ESRI - World Imagery'>
              <TileLayer
                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              />
            </BaseLayer>
            <BaseLayer checked name='CartoDB - Positron'>
              <TileLayer
                url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
                subdomains='abcd'
                maxZoom={19}
              />
            </BaseLayer>
            <BaseLayer name='CartoDB - Dark Matter'>
              <TileLayer
                url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://cartodb.com/attributions">CartoDB</a>'
                subdomains='abcd'
                maxZoom={19}
              />
            </BaseLayer>
            {allwells !== undefined ? (<Overlay name='All Wells Database'>
              {allwells}
            </Overlay>) : null }
            {first_contact !== undefined ? (<Overlay name='All Wells Database - Depth to Qal/UMCf Contact'>
              {first_contact}
            </Overlay>) : null }
            {bkg_soils !== undefined ? (<Overlay name='Background Soil Locations'>
              {bkg_soils}
            </Overlay>) : null }
            {subareas !== undefined ? (<Overlay name='Subareas'>
              {subareas}
            </Overlay>) : null }
            {seeps !== undefined ? (<Overlay name='Seeps (2000)'>
              {seeps}
            </Overlay>) : null }
            {flowlines !== undefined ? (<Overlay name='NDEP Flowlines'>
              {flowlines}
            </Overlay>) : null }
            {paleo !== undefined ? (<Overlay name='Paleo Channels'>
              {paleo}
            </Overlay>) : null }
            {weirs !== undefined ? (<Overlay name='Las Vegas Wash Weirs'>
              {weirs}
            </Overlay>) : null }
            {wash !== undefined ? (<Overlay name='Surface Water and Ponds'>
              {wash}
            </Overlay>) : null }
            {gauges !== undefined ? (<Overlay name='USGS Gauges'>
              {gauges}
            </Overlay>) : null }
            {soil !== undefined ? (<Overlay name='Soil'>
              {soil}
            </Overlay>) : null }
            {lith !== undefined ? (<Overlay name='Geology'>
              {lith}
            </Overlay>) : null }
          </LayersControl>
          <Control position="bottomleft">
            <div style={{zIndex: 100}}>
              { this.props.showInfoLegend ? infoLegend : null}
              { this.props.showLocs && (this.props.showRadius || this.props.showIntensity) ? legend : null}
            </div>
          </Control>
          <Control position="bottomright">
            <div style={{zIndex: 100}}>
              { this.props.showLocs && this.props.showClusters ? clusterLegend : null}
              { this.props.showSamplingFreq ? hexLegend : null}
            </div>
          </Control>
          <Control position="topright">
            <div>
              {locSearch}
            </div>
          </Control>
          <Control position="topright">
            <div className="recenter-div">
              {fitBounds}
            </div>
          </Control>
          { this.props.showTin ? (
            <GeoJSON key={shortid.generate()} data={tin} style={tinStyle} onEachFeature={onEachTri}></GeoJSON>
          ) : null }
          { this.props.showGrid && this.props.doGrid && interpolationGrid ? (
            <GeoJSON key={shortid.generate()} data={interpolationGrid} style={isoGridStyle} ></GeoJSON>
          ) : null }
          { this.props.showBands ? (
            <GeoJSON key={shortid.generate()} data={isobands} style={isoBandsStyle} ></GeoJSON>
          ) : null }
          { this.props.showContours && this.props.doContours ? (
            <GeoJSON key={shortid.generate()} data={isolines} style={isoLinesStyle} onEachFeature={onEachContour}></GeoJSON>
          ) : null }
          <Pane name="leaflet-locations-overlay-pane" style={{ zIndex: 450 }}>
            {this.props.showLocs && markers}
          </Pane>
          <Pane name="leaflet-sampling-frequency-overlay-pane" style={{ zIndex: 300 }}>
            { this.props.showSamplingFreq ? (
            <GeoJSON key={shortid.generate()} data={updatedHexGrid.toJS()} style={this.style_hexes} onEachFeature={onEachHex}></GeoJSON>
          ) : null }
          </Pane>
          <FeatureGroup>
            <EditControl
              key={this.props.editKey}
              ref="edit"
              position='topleft'
              onMounted={this._onDrawMounted.bind(this)}
              onCreated={this.onPolygonCreated.bind(this)}
              onDeleted={this.onPolygonDeleted.bind(this)}
              onDrawStart={this._onDrawStart.bind(this)}
              onDrawStop={this._onDrawStop.bind(this)}
              draw={{
                rectangle: false,
                circle: false,
                marker: false,
                polyline: false,
                circlemarker: false,
                polygon: {shapeOptions: { color: '#27ae60' }}
              }}
              edit={{
                edit: false
              }}
            />
          </FeatureGroup>
          <ScaleControl position='bottomright' />
        </Map>
      </div>
    );
  }
}

export default DataMap;
//url='https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
