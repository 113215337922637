import React, { Component } from 'react';
import Draggable from 'react-draggable';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/modal.css'
//REQUIRES sandstone.css

class Modal extends Component {
  constructor(props){
    super(props)

    this.modalId =  Math.random().toString(36).substring(7);

    /*CONFIGS... TODO should be configurable.*/
    this.headerHeight = 20;
    this.padding = 15;

    var bodyHeight = this.props.height ? this.props.height : 200;
    var bodyWidth = this.props.width ? this.props.width : 300;
    var height = bodyHeight + 2*this.padding + this.headerHeight;
    var width = bodyWidth + 2*this.padding;
    var overflow = this.props.overflow ? this.props.overflow : 'hidden';

    var visible = this.props.visible === undefined ? true : this.props.visible;
    var x = this.props.blocking ? 0 : (window.innerWidth - width)/2;
    var y = this.props.blocking ? 0 : 100;

    this.state = {
      'width':width,
      'height':height,
      'x':x,
      'y':y,
      //'clientX':0,
      //'clientY':0,
      'visible':visible,
      'bodyWidth':bodyWidth,
      'bodyHeight':bodyHeight,
      'overflow':overflow,
      'dragging': false,
      'new' : true,
      'active':true,
      'collapsed': false
    }

    this.overlay = this.props.blocking ? (<div className="modal" style={{display:"block",background:"rgba(0,0,0,.5)"}}></div>) : null;
    this.handleDomClick = this.handleDomClick.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleDomClick);
    //let updateActive = new Event('updateActive')
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDomClick);
  }

  setRef(node){
    this.containerRef = node;
  }

  handleDomClick(e){
    //decide if we've clicked a modal or not.
    let modals = document.getElementsByClassName("react-modal-container");
    //console.log(modals.length)
    for(let i = 0; i < modals.length; i++){
      let modal = modals[i];
      if(modal.contains(e.target)){
        this.setState({active:false})
      }
    }

    if(this.containerRef && this.containerRef.contains(e.target)){
      document.lastReactModalClicked = this.modalId
      this.setState({active:true})
    }
    this.setState({'new':false})

  }

  /* HANDLE DRAG */
  onStartResize(e){
      this.setState({'clientX': e.clientX, 'clientY':e.clientY,'dragging':true})
  }

  onStopResize(e){
      this.setState({'dragging':false})
  }

  isValidLocation(x,y,width,height){
    return x + width < window.innerWidth && y + height < window.innerHeight;
  }

  setResizeState(x,y,width,height){

    if(width > 300){
      this.setState({'width':width});
      this.setState({'bodyWidth':width - 2 * this.padding})

      if(x >= 0){
        this.setState({ 'x': x});
      }
    }

    if(height > 200){
      this.setState({ 'height': height});
      this.setState({'bodyHeight':height - 2 * this.padding - this.headerHeight})
      if(y >= 0){
        this.setState({ 'y': y});
      }
    }

    if(this.props.onResize){
      this.props.onResize(this.state.bodyWidth, this.state.bodyHeight);
    }
  }

  calcTopHeight(e){
    return this.state.height + this.state.y - Math.max(e.clientY,0);
  }
  calcBottomHeight(e){
    return Math.min(e.clientY - this.state.y + 10, window.innerHeight - this.state.y)
  }
  calcLeftWidth(e){
    return this.state.width + this.state.x - Math.max(e.clientX,0);
  }
  calcRightWidth(e){
    return Math.min(e.clientX - this.state.x + 10, window.innerWidth - this.state.x)
  }

  onResizeTopLeft(e){
    let newX = Math.max(e.clientX, 0)
    let newY = Math.max(e.clientY, 0)
    this.setResizeState(newX, newY, this.calcLeftWidth(e), this.calcTopHeight(e))
  }

  onResizeBottomLeft(e){
    let newX = Math.max(e.clientX, 0)
    this.setResizeState(newX, this.state.y, this.calcLeftWidth(e), this.calcBottomHeight(e))
  }

  onResizeTopRight(e){
    let newY = Math.max(e.clientY, 0)
    this.setResizeState(this.state.x, newY, this.calcRightWidth(e), this.calcTopHeight(e))
  }

  onResizeBottomRight(e){
    this.setResizeState(this.state.x,this.state.y,this.calcRightWidth(e), this.calcBottomHeight(e))
  }

  onResizeTop(e){
    let newY = Math.max(e.clientY, 0)
    this.setResizeState(this.state.x,newY,this.state.width, this.calcTopHeight(e))
  }
  onResizeBottom(e){
    this.setResizeState(this.state.x,this.state.y,this.state.width, this.calcBottomHeight(e))
  }
  onResizeLeft(e){
    let newX = Math.max(e.clientX, 0)
    this.setResizeState(newX,this.state.y,this.calcLeftWidth(e), this.state.height)
  }
  onResizeRight(e){
    this.setResizeState(this.state.x,this.state.y,this.calcRightWidth(e), this.state.height)
  }

  onStartDrag(e, position){
    this.setState({ 'dragging':true })
  }
  onStopDrag(e, position){
    this.setState({'x':position.x, 'y': position.y,'dragging':false});
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed});
  }

  closeModal(){
    if(this.props.onClose){
      this.props.onClose();
    } else {
      //maybe hide it here??
      this.setState({'visible':false})
    }

  }

  render(){

    var closeable = this.props.close_button === undefined ? true : this.props.close_button;
    var collapsible = this.props.collapsible === undefined ? false : this.props.collapsible;
    var close_button = closeable ? (
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closeModal.bind(this)}>Close</button>
    ) : null;

    close_button = null;

    let activeClass = this.state.active ? 'active-modal' : "";
    let newClass = this.state.new ? 'new-modal' : "";

    return (
      <div className={'react-modal-container ' + activeClass + " " + newClass} ref={this.setRef.bind(this)}>
        <Draggable
          handle='.drag-handle'
          onStop={this.onStopDrag.bind(this)}
          onStart={this.onStartDrag.bind(this)}
          position={{x:this.state.x, y:this.state.y}}
          bounds='body'
        >
          <div className="react-modal" style={{
            height:this.state.collapsed ? this.state.headerHeight : this.state.height,
            width:this.state.width,
            padding:this.padding,
            zIndex:10500,
            display: this.state.visible ? 'block' : 'none',
            paddingTop:0
          }}>
              <div className="react-modal-content" >
                <div className="react-modal-header cf drag-handle" style={{paddingTop:this.padding}}>
                  {closeable ? (<span><FontAwesome name="times" className="icon-right modal-close-x" onClick={this.closeModal.bind(this)} data-dismiss="modal" /></span> ) : null }
                  {collapsible ? (<span onClick={this.toggleCollapsed.bind(this)} style={{'cursor': 'pointer'}}><FontAwesome className="icon-right" name={this.state.collapsed ? 'chevron-right' : 'chevron-down'} /></span> ) : null }
                  <h4 className="react-modal-title" style={{height:this.headerHeight}}>{this.props.title}</h4>
                </div>
                <div className={ 'react-modal-body collapse ' +
                  (this.state.collapsed ? '' : ' show') +
                  (this.state.dragging ? ' dragging' : '')

                }
                  style={{width:this.state.bodyWidth,height:this.state.bodyHeight,overflow:this.state.overflow}}
                >
                  {/*this.state.dragging ? null : this.props.body*/}
                  {this.props.body}
                </div>
                <div className="react-modal-footer" style={{paddingTop:this.padding}}>
                  {close_button}
                </div>
              </div>
              {/* RESIZE HANDLES*/}

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeBottomRight.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalBottomRight resizeModalCorner' >
                  <FontAwesome className="fa-rotate-45" name="chevron-down" />
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeTopRight.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalTopRight resizeModalCorner' >
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeTopLeft.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalTopLeft resizeModalCorner' >
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeBottomLeft.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalBottomLeft resizeModalCorner' >
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeTop.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalTop resizeModalCorner' >
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeBottom.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalBottom resizeModalCorner' >
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeLeft.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalLeft resizeModalCorner' >
                </div>
              </Draggable>

              <Draggable
                onStart={this.onStartResize.bind(this)}
                onDrag={this.onResizeRight.bind(this)}
                onStop={this.onStopResize.bind(this)}
                position={{x:this.props.dragPos, y:this.props.dragPos}}
              >
                <div className='resizeModalRight resizeModalCorner' >
                </div>
              </Draggable>

            {/* DRAG HANDLES*/}
          </div>
        </Draggable>
      </div>
    )
  }
}

export default Modal;
