import React, { Component } from 'react';
import { setFetchingText, calculatingData, doneCalculatingData } from '../../actions';
import { toggleFilter, filterData, dataFiltered } from '../../actions/map.js';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

/* * * * * * * * * * * * DiscreteFilterList * * * * * * * * * * * * * */

class DiscreteFilterList extends Component{

  filterClicked(id, e){
    this.props.dispatch(setFetchingText('Filtering data...'))
    this.props.dispatch(calculatingData());
    this.props.dispatch(toggleFilter(id));
    this.props.dispatch(filterData(id));
    if(this.props.showPiper || this.props.showDurov || this.props.showModifiedPiper) {
      this.props.dispatch(dataFiltered());
    }
    this.props.dispatch(doneCalculatingData());
  }

  render() {
    var filterList = [];
      this.props.filters.forEach((filter,filter_index) => {
          filterList.push(
            <li key={filter.get('id')} id={filter.get('id')} className={(filter.get('active') ? "active " : "") + (filter.get('filter') === 'Null' ? "italic-text" : "")} onClick={this.filterClicked.bind(this, filter.get('id'))}>
              <FontAwesome name={filter.get('active') ? "check-square" : "square" } className={filter.get('active') ? 'filterCheck active' : 'filterCheck' } />
              {filter.get('filter')}
            </li>
          );
      })

    return (
      <ul className='filterList'>
        {filterList}
      </ul>
    );
  }
}

export default DiscreteFilterList;