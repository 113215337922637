import Query from '../Query';

export const receiveCasIds = (data) => ({
  type: "RECEIVE_CAS_IDS",
  data
})

export const searchCasIds = (value) => ({
  type: "SEARCH_CAS_IDS",
  value
})

export const updateCasIdSearch = (value) => ({
  type: "UPDATE_CAS_ID_SEARCH",
  value
})

export const fetchCasIds = (text) => dispatch => {
  console.log("FETCHING CAS IDS")
  return Query.search('/api/cas_ids').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve the CAS IDs from the database.'})
    } else {
      dispatch(receiveCasIds(data))
    }
  }).catch(error => {
    dispatch({ type: 'DATA_ERROR', message: 'Error processing CAS IDs.'})
  })
}
