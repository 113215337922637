/*
 * Register.js
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/login.css';
import Query from '../../Query';

class Register extends Component {
  constructor(props){
    super(props)
    this.state = {
      email : props.account.email,
      firstname : props.account.firstname,
      lastname : props.account.lastname,
      error : '',
      success: ''
    }

  }

  onFormChange(e){
    var state = {};
    state[e.target.id] = e.target.value;
    this.setState(state);
  }

  changePassword(e){
    e.preventDefault();

    let email = this.state.email
    let pw = this.state.password
    let npw = this.state.new_password
    let rpw = this.state.repeat_password

    if(!pw || !npw || !rpw || !email){
      this.setState({'error':'Email and all password fields are required.'})
    } else if( npw !== rpw) {
      this.setState({'error':'Passwords do not match.'})
    } else {

      var account = {
        'email':encodeURIComponent(email),
        'old_password':pw,
        'new_password':npw
      }

      Query.post('/api/change_pw',account).then( (data) => {
        if(data.success){
          this.setState({'success':"Password successfully updated.",
                         'error':""});
        } else {
          this.setState({'error':data.text})
        }
      })
    }
  }

  updateInfo(e){
    e.preventDefault();

    let email = this.state.email
    let fn = this.state.firstname
    let ln = this.state.lastname

    if(!email){
      this.setState({'error':'Email is required.'})
    } else {
      var account = {
        'email':encodeURIComponent(email),
        'firstname':encodeURIComponent(fn),
        'lastname':encodeURIComponent(ln)
      }

      Query.post('/api/update_account',account).then( (data) => {
        this.setState({'error':data.text})
        setTimeout( () => {
          this.setState({'error':""})
        },2000)

        var account = this.props.account;
        account.firstname = fn;
        account.lastname = ln;
        //this.props.dispatch(doLogin(account))
        sessionStorage.setItem('account',JSON.stringify(account))


      })
    }
  }

  render(){

    return (
      <div className='loginContainer'>
        <div className='loginBox accountBox' style={{marginTop:100}}>
          <form onSubmit={this.updateInfo.bind(this)} className='form-horizontal'>
            <h3>Account</h3>
            <div className='success alert alert-success' style={{'visibility': this.state.success.length > 1? "visible" : "hidden"}}>{this.state.success}</div>
            <div className='error alert alert-warning' style={{'visibility': this.state.error.length > 1? "visible" : "hidden"}}>{this.state.error}</div>
            <div className='accountInner'>
              <div className="form-group row">
                <label htmlFor='email' className='col-sm-3 control-label'>Email:</label>
                <div className='col-sm-9'>
                  {/*<input autoFocus ref='email' onChange={this.onFormChange.bind(this)} id='email' type="text" className="form-control" value={this.state.email} />*/}
                  {this.state.email}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor='firstname' className='col-sm-3 control-label'>First Name:</label>
                <div className='col-sm-9'>
                  <input autoFocus ref='firstname' onChange={this.onFormChange.bind(this)} id='firstname' type="text" className="form-control" value={this.state.firstname} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor='lastname' className='col-sm-3 control-label'>Last Name:</label>
                <div className='col-sm-9'>
                  <input autoFocus ref='lastname' onChange={this.onFormChange.bind(this)} id='lastname' type="text" className="form-control" value={this.state.lastname} />
                </div>
              </div>
              <div className="form-group row">
                <div className='col-sm-3' style={{float:'right'}}>
                  <button onClick={this.updateInfo.bind(this)} className='form-control btn btn-primary'>Update</button>
                </div>
              </div>
            </div>
          </form>
          <form onSubmit={this.changePassword.bind(this)} className='form-horizontal'>
            <div className='accountInner' style={{marginTop:20}}>
              <h4 style={{marginBottom:15}}>Change Password:</h4>
              <div className="form-group row">
                <label htmlFor='password' className='col-sm-3 control-label'>Current Password:</label>
                <div className='col-sm-9'>
                  <input ref='password' onChange={this.onFormChange.bind(this)} id='password' type="password" className="form-control"/>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor='new_password' className='col-sm-3 control-label'>New Password:</label>
                <div className='col-sm-9'>
                  <input ref='new_password' onChange={this.onFormChange.bind(this)} id='new_password' type="password" className="form-control"/>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor='repeat_password' className='col-sm-3 control-label'>Repeat New Password:</label>
                <div className='col-sm-9'>
                  <input ref='repeat_password' onChange={this.onFormChange.bind(this)} id='repeat_password' type="password" className="form-control"/>
                </div>
              </div>
              <div className="form-group row">
                <div className='col-sm-3' style={{float:'right'}}>
                  <button onClick={this.changePassword.bind(this)} className='form-control btn btn-primary'>Update</button>
                </div>
              </div>
            </div>
          </form>
       </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {'account':state.LoginApp.account};
}

export default connect(mapStateToProps)(Register);
