import {fromJS, List, Map} from 'immutable';

const initialState = {
  standardLocations: List(),
  filteredStandardLocations: List(),
  searchTerm:""
}

const filterStanLocs = (data, term) => {
  return data.filter((row) => row.get('standard_name') !== null).filter((row) => row.get('standard_name').toLowerCase().includes(term.toLowerCase()) || row.get('location_ids').join().toLowerCase().includes(term.toLowerCase()));
}

const StandardLocations = (state = initialState, action) => {
  let newState = Object.assign({}, state);

  switch(action.type){
    
    case "RECEIVE_STANDARD_LOCATIONS":
      if(action.data.success){
        let data = fromJS(action.data.data);
        let groupedData = data.groupBy((row) => row.get('standard_name'))
          .map((row) => {
            return Map({
              'standard_name': row.getIn([0,'standard_name']),
              'standard_easting': row.getIn([0,'standard_easting']),
              'standard_northing': row.getIn([0,'standard_northing']),
              'location_ids': row.map((item) => item.get('location_id')),
              'owners': row.map((item) => item.get('owner')),
              'eastings': row.map((item) => item.get('easting')),
              'northings': row.map((item) => item.get('northing'))
            })
          }).toList();

        newState.standardLocations = groupedData;
        newState.filteredStandardLocations = filterStanLocs(groupedData, state.searchTerm);
      }  
      return newState;
      

    case "SEARCH_STANDARD_LOCATIONS":
      newState.searchTerm = action.value;
      if(action.value === '' || !action.value) {
        newState.filteredStandardLocations = state.standardLocations
      } else {
        newState.filteredStandardLocations = filterStanLocs(state.standardLocations, action.value)
      }
      return newState;

    case "UPDATE_STANDARD_LOCATIONS_SEARCH":
      //newState.searchTerm = action.value;
      return newState;

    default:
      return state;
  }
}

export default StandardLocations;
