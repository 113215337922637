import React from 'react';
//import HistoryDeckGLComp from './DeckGL';

const History = (props) => {
  return (
    <div id='history'>
      <h3>History</h3>
      <p>
        In 1941, the U.S. Government deeded approximately 5,000 acres of vacant desert in the southeastern part of the Las Vegas Valley that would become the site of the world's largest magnesium plant - a plant that played a critical role in the World War II effort. Following the war, portions of the plant and adjacent land were leased to various industrial, government and business entities primarily involved in the production of chemicals and products containing chemicals.
      </p>
      <p>
        During the ensuing years, operations at these plants produced a variety of industrial and municipal effluents that were historically disposed of on-site in unlined evaporation ponds, transported off-site via ditches, or disposed of on the land surface. These disposal practices were industry-standard and legal at the time. Some of the wastes migrated into the Las Vegas Wash, a waterway that flows into Lake Mead, a primary source of drinking water for southern Nevada and millions of downstream users. Current waste disposal practices are consistent with all local, state and federal guidelines and regulations.
      </p>
      <p>
        Additional details regarding the Site history can be found at the NDEP’s dedicated website:<br/>
        <a target="_blank" rel="noopener noreferrer" href='http://ndep.nv.gov/environmental-cleanup/site-cleanup-program/active-cleanup-sites/bmi-complex'> http://ndep.nv.gov/environmental-cleanup/site-cleanup-program/active-cleanup-sites/bmi-complex</a>
      </p>
      {/*<HistoryDeckGLComp />*/}
    </div>
  )
}

export default History;
