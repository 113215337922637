/*
 *
 * PrivateRoute.js
 * Author: Nick Woodward
 * email: nwoodward@neptuneinc.org
 *
 * This component is used in a react-router, redux and Login.js it redirects any component
 *  <PrivateRoute /> to '/login'
 *
 * EXAMPLE USAGE:
 *    <Router>
 *      <Switch>
 *       <Route exact path="/" component={ Overview } /> //NON-PROTECTED ROUTE
 *       <PrivateRoute exact path="/map" component={ MapView } />
 *       <Route exact path="/login" component={ Login } />
 *      </Switch>
 *    </Router>
 *
 * See Login.js for usage. However this can be used with any authentication component
 * that toggles a redux authentication state such as state.LoginApp.loggedIn below.
 *
 * This component is based on the react-router authentication example:
 * https://reacttraining.com/react-router/web/example/auth-workflow
 *
 */

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';

//ReactGA.initialize('UA-101850507-5');

function logPageView() {
  if(process.env.REACT_APP_ENV === 'production') {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

const LoginRoute = (props, ...rest ) => {
  const Component = props.component;
  const loggedIn = props.loggedIn;
  return ( <Route {...rest} onEnter={logPageView()} render={props => (  
      loggedIn ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)
}

const mapStateToProps = (state) => {
  return {'loggedIn':state.LoginApp.loggedIn};
}

const PrivateRoute = connect(mapStateToProps)(LoginRoute);

export default PrivateRoute;
