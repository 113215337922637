import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Link, NavLink, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';
// It'd be nice to split the code and not load these all at once, but that's for later.
import {Overview} from './components/Overview'
import Analytes from './components/Analytes';
import Locations from './components/Locations';
import DataView from './components/DataView';
import QueryView from './components/QueryView';
import MapView from './components/MapView';
import Login from './components/Login/Login';
import Register from './components/Login/Register';
import Account from './components/Login/Account';
import NewPassword from './components/Login/NewPassword';
import { doLogout } from './actions/login'
import PrivateRoute from './components/PrivateRoute';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

function logPageView() {
  if(process.env.REACT_APP_ENV === 'production') {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

class Navigation extends Component {
  constructor(props){
    super(props)
    this.state = {showNav:false}
  }

  clickLogout(){
    this.props.dispatch(doLogout())
  }
  toggleNav(show){
    this.setState({showNav:show})
  }

  render(){
    let version = ' v.' + process.env.REACT_APP_VERSION;
    let navbarBrand = ((this.props.env.site === 'bmi') ? 'BMI Regional Database' : 'NERT Groundwater Database')
    let activeQuery = this.props.activeQuery && this.props.activeQuery.count()
      ? "Selected Query: " +  this.props.activeQuery.get('name') : null
    let navClass = this.state.showNav ? 'show' : '';

    return(
      <div>
        <Router>
          <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
              <button style={{pointerEvents: 'none', cursor: 'default'}} className="navbar-brand button-link">{navbarBrand}<small>{version}</small></button>
              <button className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarColor01"
                aria-controls="navbarColor01"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.toggleNav.bind(this,!this.state.showNav)}
              >
                  <span className="navbar-toggler-icon"></span>
                </button>
              <div className={"collapse navbar-collapse " + navClass} id="navbarColor01">
                <ul className="navbar-nav mr-auto" onClick={this.toggleNav.bind(this,false)}>
                  <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/overview">Home</NavLink></li>
                  <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/locations">Standardized GW Locations</NavLink></li>
                  <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/analytes">Parameter Table</NavLink></li>
                  <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/query">Query Data</NavLink></li>
                  <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/dataview">View Data</NavLink></li>
                  <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/map">Map</NavLink></li>
                  <span className="navbar-text float-left navSelectedQuery" style={{display: activeQuery ? "block":"none"}}>{activeQuery}</span>
                </ul>
                <ul className="navbar-nav" onClick={this.toggleNav.bind(this,false)}>
                  <li className="nav-item"><Link className='nav-link' to="/account">Account</Link></li>
                  <li className="nav-item"><Link className='nav-link' onClick={this.clickLogout.bind(this)} to="/overview">Logout</Link></li>
                </ul>
              </div>
            </nav>
            <div>
              <Switch>
                <PrivateRoute path="/account" component={ Account }/>
                <Route path="/register" component={ Register }/>
                <Route path="/new_password" component={ NewPassword }/>
                <PrivateRoute path="/overview" component={ Overview }/>
                <PrivateRoute exact path="/analytes" component={ Analytes }/>
                <PrivateRoute exact path="/locations" component={ Locations }/>
                <PrivateRoute path="/query" component={ QueryView }/>
                <PrivateRoute exact path="/dataview" component={ DataView }/>
                <PrivateRoute exact path="/map" component={ MapView }/>
                <Route exact path="/login" component={ Login } onEnter={logPageView}/>
                <Redirect from="/" to="/overview"/>
              </Switch>
            </div>
          </div>
        </Router>
        <div id='fetchingIndicator' className={this.props.isFetching ? 'fetchingActive' : 'fetchingInactive'} >
          {this.props.fetchingText}
        </div>
        <div className='fetchingSpinner' style={{ visibility: this.props.isFetching ? 'visible' : 'hidden' }}>
          <span style={{ textAlign: 'center'}}><FontAwesome name='spinner' style={{textAlign: 'center', textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', 'fontSize': '80px', verticalAlign: 'middle', 'lineHeight': '80px' }} /></span>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isFetching: state.isFetching,
    fetchingText: state.fetchingText,
    height: state.Template.height,
    width: state.Template.width,
    activeQuery: state.QueryApp.get('activeQuery'),
    data: state.DataView.get('data'),
    env: state.appEnv
  };
}

export default connect(mapStateToProps)(Navigation);
//export default Navigation;
