import React, { Component } from 'react';
import {connect} from 'react-redux';
import {downloadXLSX} from '../../actions/datasets';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

class DatasetsComp extends Component {

  onDownloadFileClick(filename){
    this.props.dispatch(downloadXLSX(filename))
  }

  render() {
    return (
      <div id='downloadable_datasets' style={{maxWidth:600}}>
        <h2>Downloadable Datasets</h2>
        <br/>
        <br/>
        <h3 style={{textDecoration: 'underline'}}>All Wells Database</h3>
        <button className="btn btn-outline-info" onClick={this.onDownloadFileClick.bind(this, 'All-Wells Database (July 2022).xlsx')} type="button">
          <span style={{marginRight: '5px'}}>
            <FontAwesome name="arrow-circle-down" />
          </span>
          All-Wells Database (July 2022).xlsx
        </button>
        <br/>
        <br/>
        <h3 style={{textDecoration: 'underline'}}>Background Soil Data</h3>
        <button className="btn btn-outline-info" onClick={this.onDownloadFileClick.bind(this, 'AttachmentA_BackgroundSoilData.xlsx')} type="button">
          <span style={{marginRight: '5px'}}>
            <FontAwesome name="arrow-circle-down" />
          </span>
          AttachmentA_BackgroundSoilData.xlsx
        </button>
        <br/>
        <br/>
        <h3 style={{textDecoration: 'underline'}}>EDD Guidance - Analyte CAS IDs</h3>
        <button className="btn btn-outline-info" onClick={this.onDownloadFileClick.bind(this, 'ndep_cas_ids.xlsx')} type="button">
          <span style={{marginRight: '5px'}}>
            <FontAwesome name="arrow-circle-down" />
          </span>
          ndep_cas_ids.xlsx
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    template: state.Template
  }
}

const Datasets = connect(
  mapStateToProps
)(DatasetsComp)

export default Datasets;
