import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/login.css';
import {Link} from 'react-router-dom';
import Query from '../../Query';

class Register extends Component {

  componentWillMount(){
    this.setState({'showLogin': false}); //don't show login form until we check for a jwt
    this.setState({'error':'.'})
  }


  fetch(path,body = "",callback){

    //const query_base_url = process.env.NODE_ENV === "development" ? "" : "";
    var jwt = sessionStorage.getItem('jwt');
    fetch('/api' + path, {
      accept:'application/json',
      method: 'post',
      headers: {
        "Authorization": "Bearer " + jwt,
        "Content-Type": "application/json"
      },
      body: body
    }).then(this.checkStatus)
      .then(this.parseJSON)
      .then(callback)
      .catch(callback)}

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    console.log("LOGIN COMP")
    throw error;
  }

  onFormChange(e){
    var state = {};
    state[e.target.id] = e.target.value;
    this.setState(state);
  }

  handleNewPw(e){
    e.preventDefault();

    let email = this.state.email

    if(!email){
      this.setState({'error':'Please enter an email.'})
    } else {
      var account = {
        'email':encodeURIComponent(email),
      }

      Query.post('/api/new_pw',account).then( (data) => {
        if(data.success){
          this.setState({'success':true})
          this.setState({'error':""})
        } else {
          console.log(data)
          this.setState({'error': data.text !== undefined ? data.text : 'Error!'})
        }
      })

    }
  }

  render(){

    return (
      <div className='loginContainer'>
        <div className='loginBox ' style={{marginTop:(window.innerHeight - 400)/2}}>
          <form onSubmit={this.handleNewPw.bind(this)} className='form-horizontal'>
          <h3>Get a New Password</h3>
          <div className='error alert alert-warning' style={{'visibility': this.state.error.length > 1? "visible" : "hidden"}}>{this.state.error}</div>
            {this.state.success ? (
              <div className="form-group row">
                <div className='col-sm-12'>
                  A new password has been emailed to you,<Link to='/login' >click here to log in.</Link>
                  <br />
                  You can change your password in the Account section of the site after login.
                </div>
              </div>
            ):(
              <div>
                <div className="form-group row">
                  <label htmlFor='email' className='col-sm-2 control-label'>Email:</label>
                  <div className='col-sm-10'>
                    <input ref='email' onChange={this.onFormChange.bind(this)} id='email' type="text" className="form-control" />
                  </div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-3' style={{float:'right'}}>
                    <button onClick={this.handleNewPw.bind(this)} className='form-control btn btn-primary'>Submit</button>
                  </div>
                </div>
              </div>
            )}
          <div className="form-group row">
            {!this.state.success ? (
              <div className='col-sm-12' style={{float:'right'}}>
                I remember my password,<Link to='/login' >login</Link>
              </div>
            ) : null }
            {/*
            <div className='col-sm-12' style={{float:'right'}}>
              I need an account,<Link to='/register'>register</Link>
            </div>
            */}
          </div>
        </form>
       </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
 return {'loggedIn':state.LoginApp.loggedIn};
}

export default connect(mapStateToProps)(Register);
