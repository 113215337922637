import React, { Component } from 'react';
import { Map } from 'immutable';
import '../../css/statBar.css';
import ResultHistogram from './ResultHistogram';
import SamplingOverTime from './SamplingOverTime';
import LocPlot from './LocPlot';
import DepthPlot from './DepthPlot';
import Modal from '../extras/Modal';
import { togglePlotModal, resizePlotModal, chooseRadius, toggleShowLocs, toggleShowRadius, toggleShowIntensity, dataFiltered, chooseHistogram } from '../../actions/map';
import { fetchPiperData } from '../../actions'
//import { fetchData } from '../../actions'
import UITool from './uiTool';
import QuantilePlot from './QuantilePlot';
import PiperPlot from './PiperPlot';
import ModifiedPiperPlot from './ModifiedPiperPlot';
import DurovPlot from './DurovPlot';
import BoxPlot from './BoxPlot';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
//import QQPlot from './QQPlot';

//A hard coded template, maybe this should be in the reducer?
const template = {
  buttons: {width:75, padding:20},
  histogram: {width:350, height:300},
  plot: {width:350, height:300},
  freq: {width:350, height:250}
}

class SelectRadius extends Component {
  handleChange(value){
    this.props.dispatch(chooseRadius(value));
    //this.props.dispatch(dataFiltered());
  }

  render(){
    var options = Map({ "max":"Max", "mean":"Mean", "median":"Median", "sampleSize":"Sample Size"});
    var radios = options.map( (stat,index) => {
      var active = index === this.props.radius;
      return (
        <div
          className={active ? "markerRadius active" : "markerRadius"}
          key={index}
          onClick={this.handleChange.bind(this, index)}
        >
          {stat}
        </div>
      )
    }).toList()

    return (
      <div className="selectRadius clearfix" style={{textAlign:'center'}}>
        {radios}
      </div>
    )
  }
}

class StatBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      histCollapsed: false,
      plotsCollapsed: false,
      freqCollapsed: true,
      logHist: false,
      logPlot: false
    }
  }

  componentWillMount(){
    if(!this.props.piperLoaded) {
      this.props.dispatch(fetchPiperData());
    }
  }

  resizePlotModal(plot,width,height){
    this.props.dispatch(resizePlotModal(plot,width,height))
  }
  togglePlotModal(plot){
    if(plot === 'piper' || plot === 'durov' || plot === 'modPiper') {
      this.props.dispatch(dataFiltered());
    }
    this.props.dispatch(togglePlotModal(plot));
  }
  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed});
  }
  toggleHistCollapsed() {
    this.setState({histCollapsed: !this.state.histCollapsed});
  }
  togglePlotsCollapsed() {
    this.setState({plotsCollapsed: !this.state.plotsCollapsed});
  }
  toggleFreqCollapsed() {
    this.setState({freqCollapsed: !this.state.freqCollapsed});
  }

  toggleShowLocs(){
    this.props.dispatch(toggleShowLocs())
  }

  toggleShowRadius(){
    this.props.dispatch(toggleShowRadius())
  }

  toggleShowIntensity(){
    this.props.dispatch(toggleShowIntensity())
  }

  toggleLogHist(){
    this.setState({ 'logHist': !this.state.logHist })
    this.props.dispatch(chooseHistogram('clear', null, null))
  }

  toggleLogPlot(){
    this.setState({ 'logPlot': !this.state.logPlot })
  }

  render() {

    var data = this.props.data;

    /* --- WHAT PROPORTION OF LOCATIONS IS SHOWN ON THE MAP?  --- **/
    var locsMsg = 'No Data';
    if(this.props.locations.size > 0) {
      var numLocs = this.props.locations.filter(x => x.get('visible')).size;
      var numLocsPlotted = this.props.locations.filter(x => x.get('visible') && x.get('lat') !== null).size;
      locsMsg = numLocs === numLocsPlotted ? 'All ' + numLocs + ' sampling locations shown' : '' + numLocsPlotted + ' of ' + numLocs + ' sampling locations shown';
    }

    /* --- UI TOOL FOR OTHER BUTTONS  --- **/
    var uiTool = (<UITool/>)

    var uiModal = this.props.showUITools ? (<Modal
      title='Spatial Tools Interface'
      body={uiTool}
      width={this.props.uiSize[0]}
      height={this.props.uiSize[1]}
      close_button={false}
      collapsible={true}
      overflow="scroll"
      onResize={this.resizePlotModal.bind(this, 'ui')}/>
    ) : null;
    /* --- END UI TOOL --- **/


    /* --- QUANTILE PLOT  --- **/
    //array of results, but unordered.
    //var quantileData = this.props.data.map((row) => +row.get('result')).toJS()
    //var quantileData = this.props.data;
    var quantilePlot = (
      <QuantilePlot data={data.toJS()} canLog={!this.props.hasNegatives} height={this.props.quantileSize[1]} width={this.props.quantileSize[0]} dispatch={this.props.dispatch}/>
    )

    var quantileModal =  this.props.showQuantile ? (<Modal
      title='Quantile Plot'
      body={quantilePlot}
      width={this.props.quantileSize[0]}
      height={this.props.quantileSize[1]}
      onClose={this.togglePlotModal.bind(this, 'quantile')}
      onResize={this.resizePlotModal.bind(this, 'quantile')}/>
    ) : null;
    /* --- END QUANTILE PLOT --- **/


     /* --- QQ PLOT  --- **/
    /*var qqPlot = (<QQPlot data={this.props.data.toJS()} height={this.props.qqSize[1]} width={this.props.qqSize[0]}/>)

    var qqModal = this.props.showQQPlot ? (<Modal
      title='QQ Plot'
      body={qqPlot}
      width={this.props.qqSize[0]}
      height={this.props.qqSize[1]}
      onClose={this.togglePlotModal.bind(this, 'qqplot')}
      onResize={this.resizePlotModal.bind(this, 'qqplot')}/>
    ) : null;*/
    /* --- END QQ PLOT --- **/

    /* --- PIPER DIAGRAM  --- **/
    var piperPlot = (<PiperPlot dispatch={this.props.dispatch}/>)

    var piperModal = this.props.showPiper ? (<Modal
      title='Piper Diagram'
      body={piperPlot}
      width={this.props.piperSize[0]}
      height={this.props.piperSize[1]}
      onClose={this.togglePlotModal.bind(this, 'piper')}
      onResize={this.resizePlotModal.bind(this, 'piper')}/>
    ) : null;
    /* --- END PIPER DIAGRAM --- **/


    /* --- MODIFIED PIPER DIAGRAM  --- **/
    var modPiperPlot = (<ModifiedPiperPlot dispatch={this.props.dispatch}/>)

    var modPiperModal = this.props.showModifiedPiper ? (<Modal
      title="Modified Piper (Chadha's) Diagram"
      body={modPiperPlot}
      dispatch={this.props.dispatch}
      width={this.props.modPiperSize[0]}
      height={this.props.modPiperSize[1]}
      onClose={this.togglePlotModal.bind(this, 'modpiper')}
      onResize={this.resizePlotModal.bind(this, 'modpiper')}/>
    ) : null;
    /* --- END MODIFIED PIPER DIAGRAM --- **/


    /* --- EXTENDED DUROV DIAGRAM  --- **/
    var durovPlot = (<DurovPlot dispatch={this.props.dispatch}/>)

    var durovModal = this.props.showDurov ? (<Modal
      title='Extended Durov Diagram'
      body={durovPlot}
      dispatch={this.props.dispatch}
      width={this.props.durovSize[0]}
      height={this.props.durovSize[1]}
      onClose={this.togglePlotModal.bind(this, 'durov')}
      onResize={this.resizePlotModal.bind(this, 'durov')}/>
    ) : null;
    /* --- END EXTENDED DUROV DIAGRAM --- **/


    /* --- BOX PLOTS  --- **/
    var boxPlot = (<BoxPlot data={this.props.boxData} unit={this.props.unit} canLog={!this.props.hasNegatives} height={this.props.boxPlotSize[1]} width={this.props.boxPlotSize[0]} />)

    var boxPlotModal = this.props.showBoxPlot ? (<Modal
      title='Box Plots'
      body={boxPlot}
      overflow='scroll'
      width={this.props.boxPlotSize[0]}
      height={this.props.boxPlotSize[1]}
      onClose={this.togglePlotModal.bind(this, 'boxplot')}
      onResize={this.resizePlotModal.bind(this, 'boxplot')}/>
    ) : null;
    /* --- END BOX PLOTS --- **/

    let radius = this.props.radius;

    let histData = this.props.locations.map((row) => {
      var value
      switch(radius){
        case 'location':
          value = 'max'
          break
        default:
          value = radius
      }
      return row.get(value)
    })

    //remove null and undefined values
    if(this.state.logHist) {
      histData = histData.filter((row) => row !== null && row !== undefined).map(val => Math.log(val))
    } else {
      histData = histData.filter((row) => row !== null && row !== undefined)
    }
    let histMax = histData.max()
    let histMin = histData.min()

    var unit = this.props.unit.getIn([0,'filter'])
    var typeLabel = unit === 'ft' ? 'Elevation' : 'Concentration'
    var histLabel = ""
    switch(radius){
        case 'location':
          histLabel = 'Max ' + typeLabel + ' (' + unit + ')'
        break
        case 'max':
          histLabel = 'Max ' + typeLabel + ' (' + unit + ')'
        break
        case 'mean':
          histLabel = 'Mean ' + typeLabel + ' (' + unit + ')'
        break
        case 'median':
          histLabel = 'Median ' + typeLabel + ' (' + unit + ')'
        break
        case 'sampleSize':
          histLabel = 'Sample Size'
        break
        default: histLabel = ''
    }

    var dataLength = this.props.data.filter(x => x.get('visible')).size
    var gridDisabled = dataLength > 19 ? true : false;
    var contoursDisabled = dataLength > 19 ? true : false;
    var piperDisabled = true;

    if(this.props.data.size) {
      piperDisabled = this.props.data.get(0).get('matrix') === 'Ground Water' ? false : true;
    }

    return (
      <div>
      {uiModal}
      {quantileModal}
      {piperModal}
      {modPiperModal}
      {durovModal}
      {boxPlotModal}
      <div className="statBar">
        <div className="statBarInner" style={{height:this.props.height}}>
          <div id='locationControl' className='cf'>
            <div className='plot-toggle-header'>
              <h5>Locations</h5>
              <h6>{locsMsg}</h6>
            </div>

            <div style={{textAlign:'center',paddingBottom:4}}>Location Statistic</div>
            <SelectRadius
              activeRadius="location"
              dispatch={this.props.dispatch}
              radius={radius}
              template={template.buttons}
            />
            { radius !== 'location' && (
            <div className='cf'>
              <div id='showRadius' className='locCheckbox' onClick={this.toggleShowRadius.bind(this)}>
                <span><FontAwesome name={this.props.showRadius ? 'check-square' : 'square'} className="icon-left far"/></span>
                Bubble Plot
              </div>

              <div id='showIntensity' className='locCheckbox' onClick={this.toggleShowIntensity.bind(this)}>
                <span><FontAwesome name={this.props.showIntensity ? 'check-square' : 'square'} className="icon-left far"/></span>
                Intensity Plot
              </div>
            </div>
            )}

            <div id='showLocations' className='locCheckbox' onClick={this.toggleShowLocs.bind(this)}>
              <span><FontAwesome name={this.props.showLocs ? 'check-square' : 'square'} className="icon-left far"/></span>
              Show Locations
            </div>
          </div>
          <div className="plot-toggle-header" onClick={this.toggleCollapsed.bind(this)} style={{'cursor': 'pointer'}}>
            <span><FontAwesome className="icon-right" name={this.state.collapsed ? 'chevron-right' : 'chevron-down'} /></span>
            <h5>Additional Plots & Layers</h5>
          </div>
          <div className={this.state.collapsed ? 'collapse' : 'collapse show'}>
            <button type="button" className={this.props.showHotSpot ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='hotspotButton' onClick={this.togglePlotModal.bind(this, 'hotspot')}>Hot Spot Layer</button>
            <button type="button" className={this.props.showSamplingFreq ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='samplingButton' onClick={this.togglePlotModal.bind(this, 'sampling')}>Sampling Frequency Layer</button>
            <button type="button" disabled={gridDisabled} className={this.props.showGrid ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='gridButton' onClick={this.togglePlotModal.bind(this, 'grid')}>IDW Interpolation Grid</button>
            <button type="button" style={{display: 'none'}} className={this.props.showBands ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='bandButton' onClick={this.togglePlotModal.bind(this, 'bands')}>IDW Filled Contour Layer</button>
            <button type="button" disabled={contoursDisabled} className={this.props.showContours ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='contourButton' onClick={this.togglePlotModal.bind(this, 'contour')}>IDW Contour Layer</button>
            <button type="button" style={{display: 'none'}} className={this.props.showTin ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='tinButton' onClick={this.togglePlotModal.bind(this, 'tin')}>TIN Layer</button>
            <button type="button" className={this.props.showClusters ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='clusterButton' onClick={this.togglePlotModal.bind(this, 'cluster')}>Cluster Locations</button>
            <button type="button" className={this.props.showQuantile ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='quantileButton' onClick={this.togglePlotModal.bind(this, 'quantile')}>Quantile Plot</button>
            <button type="button" className={this.props.showBoxPlot ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='boxPlotButton' onClick={this.togglePlotModal.bind(this, 'boxplot')}>Box Plots</button>
            <button type="button" disabled={piperDisabled} className={this.props.showPiper ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='piperButton' onClick={this.togglePlotModal.bind(this, 'piper')}>Piper Diagram</button>
            <button type="button" disabled={piperDisabled} className={this.props.showModifiedPiper ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='piperButton' onClick={this.togglePlotModal.bind(this, 'modpiper')}>Modified Piper Diagram</button>
            <button type="button" disabled={piperDisabled} className={this.props.showDurov ? 'btn btn-info plot-toggle toggled-on' : 'btn btn-info plot-toggle'} id='piperButton' onClick={this.togglePlotModal.bind(this, 'durov')}>Extended Durov Diagram</button>
          </div>

          <div className="plot-toggle-header" onClick={this.toggleHistCollapsed.bind(this)} style={{'cursor': 'pointer'}}>
            <span><FontAwesome className="icon-right" name={this.state.histCollapsed ? 'chevron-right' : 'chevron-down'} /></span>
            <h5>Sample Histogram</h5>
          </div>
          <div className={this.state.histCollapsed ? 'collapse' : 'collapse show'}>
            { histMax ?
              (<div style={{width: '100%'}} className={!this.props.hasNegatives ? 'logCheckbox' : 'logCheckbox disabled' } onClick={this.toggleLogHist.bind(this)} disabled={!this.props.hasNegatives}>
                <span><FontAwesome name={this.state.logHist ? 'check-square' : 'square'} className="fa icon-left far"/></span>
                Log Data
                </div>) : null
            }
            <ResultHistogram
              min={this.props.selectedQuery.get('query_type') === 2 ? histMin : 0}
              log={this.state.logHist}
              max={histMax}
              maxRange={this.props.maxRange}
              dispatch={this.props.dispatch}
              data={histData.toJS()}
              selection={this.props.histogram}
              template={template.histogram}
              label={histLabel}
            />
          </div>

          <div className="plot-toggle-header" onClick={this.toggleFreqCollapsed.bind(this)} style={{'cursor': 'pointer'}}>
            <span><FontAwesome className="icon-right" name={this.state.freqCollapsed ? 'chevron-right' : 'chevron-down'} /></span>
            <h5>Sampling Frequency Over Time</h5>
          </div>
          <div className={this.state.freqCollapsed ? 'collapse' : 'collapse show'}>
            <SamplingOverTime
              dispatch={this.props.dispatch}
              data={this.props.frequencyData}
              template={template.freq}
            />
          </div>

          <div className="plot-toggle-header" onClick={this.togglePlotsCollapsed.bind(this)} style={{'cursor': 'pointer'}} >
            <span><FontAwesome className="icon-right" name={this.state.plotsCollapsed ? 'chevron-right' : 'chevron-down'} /></span>
            <h5>Location Samples</h5>
          </div>
            <div className={this.state.plotsCollapsed ? 'collapse' : 'collapse show'}>
            { this.props.selectedLoc ?
              (<div style={{width: '100%'}} className={!this.props.hasNegatives ? 'logCheckbox' : 'logCheckbox disabled' } onClick={this.toggleLogPlot.bind(this)} disabled={!this.props.hasNegatives}>
                <span><FontAwesome name={this.state.logPlot ? 'check-square' : 'square'} className="fa icon-left far"/></span>
                Log Data
                </div>) : null
            }
            { this.props.selectedLoc ?
            (<div>
              <LocPlot
                location={this.props.selectedLoc}
                results={this.props.locResults}
                allResults={this.props.locStaticResults}
                unit={this.props.unit}
                log={this.state.logPlot}
                type={this.props.selectedQuery.get('query_type')}
                template={template.plot}
              />
              {this.props.selectedQuery.get('query_type') !== 2 ?
              (<DepthPlot
                location={this.props.selectedLoc}
                results={this.props.locResults}
                allResults={this.props.locStaticResults}
                unit={this.props.unit}
                log={this.state.logPlot}
                template={template.plot}
              />) : null }
            </div>) : <span style={{padding:'5px 30px'}}>Click on a location to display sample plot.</span> }
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default StatBar;
