import React, { Component } from 'react'
import { connect } from 'react-redux';
//import FontAwesome from 'react-fontawesome';
import { clearQueryFilters } from '../../actions/query.js'
import SavedQueryView from './SavedQueries';
import NewQueryView from './NewQuery';
import { Switch, Route } from 'react-router-dom';
import 'font-awesome/css/font-awesome.css';

import '../../css/query.css'

class QueryViewComp extends Component {
  constructor(props){
    super(props)
    this.state ={
      "navBarHeight":62,
      "queryTypeSelectHeight":66,
      "height":window.innerHeight - 62 - 70 - 76,
      "width":(window.innerWidth - 40),
      "sideMargins":40,
      "bottomMargin":70,
      "name":null,
      "search":'',
      "timeout":null
    }
  }

  onBlur(e) {
    this.setState({name: e.target.value});
  }
  clearQueryBuilder() {
    this.props.dispatch(clearQueryFilters());
  }

  render() {


    return(
      <div className="container queryPageContainer">
          <Switch>
            <Route exact path="/query" component={ SavedQueryView }/>
            <Route path="/query/new_query" component={ NewQueryView }/>
          </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingQueryFilters: state.isFetchingQueryFilters,
    records: state.QueryApp.get('records'),
    template: state.Template,
    env: state.appEnv
  }
}

const QueryView = connect(mapStateToProps)(QueryViewComp)

export default QueryView;
