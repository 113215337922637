import React, { Component } from 'react';
import * as d3  from 'd3';
import d3Tip from 'd3-tip';
import styles from '../../css/locPlot.css';

class LocPlot extends Component{

  constructor(props){
    super(props);
    this.svgHeight = this.props.template.height;
    this.svgMargin = 15;
    this.svgWidth = this.props.template.width;
    this.yAxisWidth = 50;
    this.xAxisHeight = 50;
    this.plotHeight = this.svgHeight - 2*this.svgMargin - this.xAxisHeight;
    this.plotWidth = this.svgWidth - 3*this.svgMargin - this.yAxisWidth;
  }

  componentDidMount() {
    this.renderPlot();
  }

  componentDidUpdate() {
    this.renderPlot();
  }

  isPointData(row){
    let threshold = 1;
    return typeof(row.sample_bottom_depth) === 'undefined' || typeof(row.sample_top_depth) === 'undefined' || Math.abs(row.sample_bottom_depth - row.sample_top_depth) < threshold;
  }
  getDepth(row){
      if(typeof(row.sample_bottom_depth) !== 'undefined'){
        return row.sample_bottom_depth
      } else {
        return row.sample_top_depth
      }
  }

  renderPlot(){
    var unit = this.props.unit.get(0);

    d3.selectAll('.depthPlotContainer').remove();

    let results = this.props.results.filter((row) => row.get('sample_bottom_depth') || row.get('sample_top_depth'))
    let allResults = this.props.allResults.filter((row) => row.get('sample_bottom_depth') || row.get('sample_top_depth'))

    var svg = d3.select(this.refs.depthPlot);
    var plotContainer = svg.append('g')
      .attr('class','depthPlotContainer')
      .attr('transform', "translate(0,20)")
      .attr('style',"width:"+this.svgWidth-2*this.svgMargin +"px")

    if(!this.props.location) return

    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr("x", this.svgWidth/2)
      .text("Location: " + this.props.location + " Sample Depths (ft)");

    if(!allResults.size){ //kill it if we don't have any results.

      plotContainer.append("text")
        .style('text-anchor','middle')
        .attr("x", this.svgWidth/2)
        .attr("y", 20)
        .text('No samples with reported depth.')
      return;
    }

    var depthDomain = [],
      data = results.map((row) => { return {'sample_bottom_depth': row.get('sample_bottom_depth'), 'sample_top_depth': row.get('sample_top_depth'), 'result':row.get('result'), 'detect':row.get('detect')}}),
      allData = allResults.map((row) => { return {'sample_bottom_depth': row.get('sample_bottom_depth'), 'sample_top_depth': row.get('sample_top_depth'), 'result':row.get('result'), 'detect':row.get('detect')}});


    let pointData = data.filter( row => this.isPointData(row) ).map( row => { row.depth = this.getDepth(row); return row }).toJS()
    let intervalData = data.filter( row => !this.isPointData(row) ).toJS()
    let pointAllData =  allData.filter( row => this.isPointData(row) ).map( row => { row.depth = this.getDepth(row); return row }).toJS()
    let intervalAllData = allData.filter( row => !this.isPointData(row) ).toJS()

    let nullDataSize = this.props.allResults.size - pointAllData.length - intervalAllData.length

    let depths = allData.map(row => d3.max([row.sample_bottom_depth, row.sample_top_depth])).flatten().toJS()
    let maxDepth = d3.max(depths)
      /*
    if(depths.size > 1){
      depthDomain = d3.extent(allData, (d) => d3.max([d.sample_bottom_depth, d.sample_top_depth]));
    } else {
      var depth = allResults.get(0).get('sample_bottom_depth')
      depthDomain = [depth - 1, depth + 1]
    }
    */

    depthDomain = [0, maxDepth]

    var x = d3.scaleLinear()
      .domain(depthDomain)
      .range([0, this.plotWidth])
      .nice();


    var xAxis = d3.axisBottom(x)
      .tickFormat(d3.format(".2s"))
    //.ticks(5);

    //The lowest result (can be negative!?) or 0
    let resultMin = this.props.log ? d3.min([0, d3.min(allData.toJS(), d => Math.log(d.result))]) : d3.min([0,d3.min(allData.toJS(), d => d.result)])
    let yDomain = this.props.log ? [d3.max(allData.toJS(), (d) => Math.log(d.result)) * 1.1, resultMin * 1.1] : [d3.max(allData.toJS(), (d) => d.result) * 1.1, resultMin * 1.1]
    var y = d3.scaleLinear()
      .domain(yDomain)
      .range([0, this.plotHeight])
      .nice();


    var yAxis = d3.axisLeft(y)
      .tickFormat((d) => this.props.log ? d3.format(".2s")(Math.exp(d)) : d3.format(".2s")(d));

      /*
    var valueline = d3.line()
      .x(function(d) { return x(d.sample_bottom_depth); })
      .y(function(d) { return y(d.result); });
      */

    var plot = plotContainer.append('g')
      .attr('class','depthPlotG')
      .attr('transform', "translate("+this.yAxisWidth+",10)")
      .attr('style',"width:"+this.plotWidth +"px")

      /*
    plot.append('g')
      .append('path')
        .attr('class','locPath')
        .attr('d', valueline(allData))
        */

      /*
    let tip = null;
    var tip = d3.tip()
    */
    var tip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-20, 0])
      .html(function(d) {
        return d.detect + ' ' + d3.format(',')(d.result) + " " + unit.get('filter');
      })

    svg.call(tip);

    plot.selectAll('.noDot')
      .data(pointAllData)
      .enter().append('circle')
      .attr('class','noDot')
      .attr('r', 2.6)
      .style('fill', '#ccc')
      .attr("cx", (d) =>  x(d.depth))
      .attr("cy", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))

    plot.selectAll('.plotDot')
      .data(pointData)
      .enter().append('circle')
      .attr('class','plotDot')
      .attr('r', (d) => d.detect === '<' ? 2.6 : 2.6) //TODO Make legend for detect/nondetect
      .style('fill', styles.selectedColor)
      .attr("cx", (d) => x(d.depth))
      .attr("cy", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))

    plot.selectAll('.plotDotBuffer')
      .data(pointData)
      .enter().append('circle')
      .attr('class','plotDotBuffer')
      .attr('r',8)
      .attr("cx", (d) => x(d.depth))
      .attr("cy", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide)

    plot.append("g")
      .attr('class',"plotXAxis")
      .attr('transform', "translate(0," + this.plotHeight + ")")
      .call(xAxis)

    plotContainer.append("g")
      .attr('class',"plotYAxis")
      .attr('transform', "translate("+this.yAxisWidth+",10)")
      .call(yAxis)

    plotContainer.append("text")
      .attr('transform',"translate(10,"+ (this.plotHeight/2 + this.svgMargin) +") rotate(-90)")
      .text(this.props.unit.get(0).get('filter'));

    plotContainer.append("text")
      .attr('transform',"translate(" + this.plotWidth/2 + ","+ ( this.plotHeight + this.xAxisHeight ) +")")
      .text("Depth (ft)")

    if(nullDataSize > 0){
      plotContainer.append("text")
        .style('text-anchor','middle')
        .attr("x", this.svgWidth/2)
        .attr("y", 20)
        .text(nullDataSize + " samples with no reported depth.")
    }

    plot.selectAll('.plotNoResultInterval')
      .data(intervalAllData)
      .enter().append('line')
      .attr('class','plotNoResultLine')
      .attr('stroke-width', 3)
      .attr('stroke-linecap', 'round')
      .style('fill', styles.selectedColor)
      .style('stroke', '#ccc')
      .attr("x1", (d) => x(d.sample_top_depth))
      .attr("x2", (d) => x(d.sample_bottom_depth))
      .attr("y1", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))
      .attr("y2", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))

    plot.selectAll('.plotInnerInterval')
      .data(intervalData)
      .enter().append('line')
      .attr('class','plotInnerLine')
      .attr('stroke-width', 4)
      .attr('stroke-linecap', 'round')
      .style('stroke', styles.selectedColor)
      .attr("x1", (d) => x(d.sample_top_depth))
      .attr("x2", (d) => x(d.sample_bottom_depth))
      .attr("y1", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))
      .attr("y2", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))

    plot.selectAll('.plotOuterInterval')
      .data(intervalData)
      .enter().append('line')
      .attr('class','plotOuterLine')
      .attr('stroke-width', 16)
      .attr('stroke-linecap', 'round')
      .style('fill', styles.selectedColor)
      .style('stroke', '#333')
      .style('opacity', .1)
      .attr("x1", (d) => x(d.sample_top_depth))
      .attr("x2", (d) => x(d.sample_bottom_depth))
      .attr("y1", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))
      .attr("y2", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide)
  }

  render(){
    return (
      <svg
        className="depthPlot"
        ref="depthPlot"
        style={{width:this.svgWidth, height:this.svgHeight, padding:"0 " + this.svgMargin}}
      >
      </svg>
    )
  }
}

export default LocPlot;
