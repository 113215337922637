import React, { Component } from 'react'
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga';
import FontAwesome from 'react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom'
import 'font-awesome/css/font-awesome.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import query_styles from '../../css/query.css';

import { fetchData, fetchQueryTypes, fetchQueries, setFetchingText, deleteQuery } from '../../actions';
import { selectQuery, receiveDataNotified, downloadCSV, createCSV, savedQueryNotified, clearQuery } from '../../actions/query.js';
import {clearErrorMessage} from '../../actions';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

ReactModal.setAppElement('#root');

class SavedQueryViewComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalMessage: ''
    }

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.errorMessage) {
      this.setState({modalMessage: nextProps.errorMessage });
      this.setState({modalIsOpen: true});
    }
  }

  componentWillMount(){
    this.setState({"navBarHeight":62})
    this.setState({"queryTypeSelectHeight":66})
    this.setState({"height":window.innerHeight - 62 - 70 - 76})
    this.setState({"width":(window.innerWidth - 40)})
    this.setState({"sideMargins":40})
    this.setState({"bottomMargin":70})
    this.setState({"name":null})
    this.setState({"search":''})
    this.setState({"timeout":null})
    this.setState({"selectedQuery":null})

    if(this.props.queryTypes.size === 0) {
      this.props.dispatch(fetchQueryTypes('api/query_types'));
    }
    if(this.props.queries.size === 0) {
      this.props.dispatch(fetchQueries('/api/queries'))
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#222';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.props.dispatch(receiveDataNotified());
    this.props.dispatch(savedQueryNotified());
    this.props.dispatch(clearErrorMessage());
  }

  onGetQueryDataButtonClick() {
    let selectedQuery = this.props.selectedQuery.get('id')
    if(selectedQuery && this.props.env.mode === 'production') {
      ReactGA.event({
        category: 'Query',
        action: 'Selected a Query',
        label: 'Query View',
        nonInteraction: false
      });
    }
    if(selectedQuery){
      let queryName = this.props.selectedQuery.get('name')
      console.log("Executing query: " + queryName)
      this.props.dispatch(setFetchingText("Loading query: " + queryName))
      this.props.dispatch(fetchData(selectedQuery, 0))
    } else {
      this.setState({modalMessage: "Please click on a query to select it."});
      this.setState({modalIsOpen: true});
    }
  }

  onDownloadCSVButtonClick() {
    let selectedQuery = this.props.selectedQuery
    if(selectedQuery.get('id')) {
      if(selectedQuery.get('records') < 10000){
        this.props.dispatch(setFetchingText("Downloading CSV..."))
        this.props.dispatch(downloadCSV(selectedQuery.get('id')))
      } else {
        this.setState({modalMessage: (<div><p text-align="center">There is a limit of 10,000 records for immediate CSV download. Larger exports are processed separately.</p> <p text-align="center"><strong>You will get an email with a link shortly.</strong> The link will be valid for 7 days.</p></div>)});
        this.setState({modalIsOpen: true});
        this.props.dispatch(createCSV(selectedQuery.get('id'), selectedQuery.get('name'), selectedQuery.get('query_type')))
      }
    }
  }

  queryClicked(id, e) {
    this.setState({"selectedQuery": id});
    this.props.dispatch(selectQuery(id));
  }

  confirmDeleteQuery = (id, e) => {
    confirmAlert({
      title: 'Confirm to Delete',                        // Title dialog
      message: 'Are you sure to delete this query?',               // Message dialog
      childrenElement: () => <div></div>,       // Custom UI or Component
      buttons: [
        {
          label: 'Confirm',
          onClick: () => this.deleteQuery(id)
        },
        {
          label: 'Cancel',
          onClick: () => null
        }
      ]//,
      //confirmLabel: 'Confirm',                           // Text button confirm
      //cancelLabel: 'Cancel',                             // Text button cancel
      //onConfirm: (id) => this.deleteQuery(id),    // Action after Confirm
      //onCancel: () => null      // Action after Cancel
    })
  };

  deleteQuery(id) {
    this.setState({"selectedQuery": null});
    this.props.dispatch(deleteQuery(id));
    this.props.dispatch(clearQuery());
  }

  render() {

    const isFetchingQueries = this.props.isFetchingQueries;
    const isFetchingRecords = this.props.isFetchingRecords;
    const isFetching = this.props.isFetching;

    var queries = [],
        selectedQuery = [];

    this.props.queries.reverse().forEach((query, query_index) => {
      queries.push(
        <div className="filterQueryContainer" onClick={this.queryClicked.bind(this, query.get('id'))} key={query.get('id') + query.get('name')}>
          <div className="filterQueryContainerInner">
            <h4 className={query.get('visible') ? 'blue-text' : ''}>{query.get('name')}</h4>
          </div>
          <FontAwesome name={query.get('visible') ? 'caret-right' : ''} className='blue-text' />
        </div>
      )
      if(query.get('visible')) {
        selectedQuery.push(
          <li key={query.get('id')} id={query.get('id')}>
            {query.get('criteria')}
          </li>
        )
      }
    })

    //let compHeight = this.props.template.height - this.props.template.navHeight - this.state.queryTypeSelectHeight - this.state.bottomMargin
    let compHeight = this.state.height;

    //SELECT SAVED QUERY
    return(
      <div>
        <div className='row'>
          <div className='col-md-4 queryBtnContainer'>
            <Link to='/query/new_query'>
              <button className="btn btn-info queryBtn" type="button">
                <span style={{paddingRight: '5px' }}>
                  <FontAwesome name='plus' />
                </span>
                Build New Query
              </button>
            </Link>
          </div>
        </div>
        <div>
          <ReactModal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles} contentLabel="Query Name Modal">
            <h4 ref={subtitle => this.subtitle = subtitle}>{this.state.modalMessage}</h4>
            <button className="btn btn-info center-block" onClick={this.closeModal}>Okay</button>
          </ReactModal>
        </div>
        <div className="row" style={{marginBottom:'10px',marginTop:'10px'}}>
          <div className='col-sm-4 fieldContainer' style={{height:compHeight}}>
            <h3>Queries</h3>
            { isFetchingQueries ? (
              <ReactLoading type='bars' color={query_styles.siteColor} height={50} width={200} className='center-loader' /> ) : (
              <div style={{overflow: 'scroll', height: compHeight - 40}} className='queryFilters'>{queries}</div>
            )}
          </div>
          <div className='col-sm-8 fieldContainer' >
            <h3>Selected Query</h3>
              { this.props.selectedQuery.count() ? (
                <div className='queryFilters'>
                  <div className='selectedQueryInfo' style={{ maxHeight: '600px', overflow: 'auto' }}>
                    <div className='form-group cf row'>
                      <div className='col-sm-2 queryLabel'>Name</div>
                      <div className='col-sm-10 queryInfo'>{this.props.selectedQuery.get('name')}</div>
                    </div>
                    <div className="form-group cf row">
                      <div className='col-sm-2 queryLabel'>Records</div>
                      <div className='col-sm-10 queryInfo'>{this.props.selectedQuery.get('records').toLocaleString()}</div>
                    </div>
                    <div className="form-group cf row">
                      <div className='col-sm-2 queryLabel'>Query</div>
                      <div className='col-sm-10 queryInfo'>{this.props.selectedQuery.get('criteria')}</div>
                    </div>
                    <div className="form-group cf row">
                      <div className='col-sm-2 queryLabel'>Dataset</div>
                      <div className='col-sm-10 queryInfo'>{this.props.queryTypes.filter(x => x.get('id') === this.props.selectedQuery.get('query_type')).get(0).get('name')}</div>
                    </div>
                    <div className="form-group cf row">
                      <div className='col-sm-2 queryLabel'>Status</div>
                      <div className='col-sm-10 queryInfo'>{this.props.selectedQuery.get('id') === this.props.activeQuery.get('id') ? "Data Loaded": "Data Not Loaded - Ready to Execute"}</div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <button disabled={isFetching || isFetchingQueries || isFetchingRecords} className="btn btn-danger ndep-danger executeQueryButton" onClick={this.confirmDeleteQuery.bind(this, this.props.selectedQuery.get('id'))} type="button">
                        Delete Query
                        <span style={{marginLeft: '5px'}}>
                          <FontAwesome name="times" />
                        </span>
                      </button>
                      <button disabled={isFetching || isFetchingQueries || isFetchingRecords} className="btn btn-info executeQueryButton" onClick={this.onDownloadCSVButtonClick.bind(this)} type="button">
                        Download Data (CSV)
                        <span style={{marginLeft: '5px'}}>
                          <FontAwesome name="chevron-down" />
                        </span>
                      </button>
                      <button disabled={isFetching || isFetchingQueries || isFetchingRecords} className="btn btn-info executeQueryButton" onClick={this.onGetQueryDataButtonClick.bind(this)} type="button">
                        Execute Query
                        <span style={{marginLeft: '5px'}}>
                          <FontAwesome name="chevron-right" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    env: state.appEnv,
    queries: state.QueryApp.get('queries'),
    queryTypes: state.QueryApp.get('queryTypes'),
    selectedQuery: state.QueryApp.get('selectedQuery'),
    activeQuery: state.QueryApp.get('activeQuery'),
    records: state.QueryApp.get('records'),
    data: state.data,
    isFetching: state.isFetching,
    isFetchingQueries: state.isFetchingQueries,
    isFetchingRecords: state.isFetchingRecords,
    receivedData: state.QueryApp.get('receivedData'),
    errorMessage: state.QueryApp.get('errorMessage'),
    template: state.Template
  }
}

const SavedQueryView = connect(mapStateToProps)(SavedQueryViewComp)

export default SavedQueryView;
