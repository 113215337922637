import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Map } from 'immutable';
import { updateInterpolationGridFromWorker } from '../../actions/uitools'; //updateInterpolationGrid,
import { setFetchingText, showLoading, hideLoading } from '../../actions'
import InterpolationWorker from './interpolation.worker.js';
// You only need the next two files if the one above is not working for you (if InterpolationWorker is not working)
//import worker from './webWorkerInterpolate.js';
//import WebWorker from '../../setupWebWorker';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/statBar.css';

class GridToolComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cellValid: true,
      weightValid: true,
      cellValue: props.gridCellSize,
      weightValue: props.distanceWeightingFactor,
      showInfo: false
    }
  }

  componentDidMount = () => {
    this.interpolationWorker = new InterpolationWorker();
  }

  handleInfo(e) {
    this.setState({showInfo: !this.state.showInfo});
  }

  interpolationWebWorker(cellValue, weightValue) {
    this.interpolationWorker.postMessage({ event: 'interpolateSurface', gridType: 'square', cellSize: cellValue,  weight: weightValue, mapStat: this.props.radius, data: this.props.filteredLocations.toJS() });
    this.interpolationWorker.addEventListener("message", event => {
      this.props.dispatch(updateInterpolationGridFromWorker(this.state.cellValue, this.state.weightValue, event.data));
      this.props.dispatch(hideLoading());
    });
  }

  handleUpdate() {
    this.props.dispatch(setFetchingText("Interpolating..."));
    this.props.dispatch(showLoading());
    this.interpolationWebWorker(this.state.cellValue, this.state.weightValue);
  }

  handleCellSizeChange(e) {
    var value = e.target.value;
    if(parseFloat(+value, 10) > 0.099999999999 && parseFloat(+value, 10) < 10.0000000000001 ) {
      this.setState({cellValid: true, cellValue: parseFloat(+value, 10)})
    } else {
      this.setState({cellValid: false, cellValue: value})
    }
  }

  handleWeightingFactorChange(e) {
    var value = e.target.value;
    if(parseInt(+value, 10) < 11 && parseInt(+value, 10) > 0 && !isNaN(parseInt(+value, 10))) {
      this.setState({weightValid: true, weightValue: parseInt(+value, 10)})
    } else {
      this.setState({weightValid: false, weightValue: value})
    }
  }

  render() {

    var cellSize = this.state.cellValue;
    var weight = this.state.weightValue;

    return (
      <div style={{paddingTop: '5px', marginTop: '7px', borderTop: '1px solid'}}>
        <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='question-circle' size='lg' /></span>
        <h5>IDW Interpolation Grid</h5>
        {this.state.showInfo ? (
        <div className='toolInfo'>
          <span onClick={this.handleInfo.bind(this)} style={{cursor: 'pointer'}}><FontAwesome className="icon-right" name='times' size='lg' /></span>
          <p>This tool creates an interpolated grid of values based on the currently chosen analyte and data from currently shown locations. The interpolation uses the user-chosen statistic (max, median, mean, etc.)
          at each shown location as the value inputs.</p>
          <p>The interpolation is done by <a href="http://planet.botany.uwc.ac.za/nisl/GIS/spatial/chap_1_15.htm">inverse distance weighting</a> (IDW). Essentially, the extent of the resulting interpolation grid is set to the rectangular extent of the shown locations.
          The grid is made up of square 'cells' of a certain size (which you can adjust using this tool). A smaller cell size means there will be more cells calculated. The more cells that are calculated, the longer interpolation will take,
          but the better the resolution of the interpolated grid will be. However, it is also good to keep in mind that while a better resolution looks better to the eye, it does not mean that it is a better interpolation. If you are interpolating a grid
          from only 5 shown locations spread out over the site, all that better resolution does is smooth out the interpolation.  </p>
          <p>This is because inverse distance weighting takes the distance between two locations, and for each cell between them for which a value is estimated (i.e. interpolated), this value is going to be some value between location 1 and location 2.
           The value estimated for each cell will be more similar to the locations it is closer to. Some IDW functions use a search radius to limit which locations have input into the interpolated value of a cell. The function used here does not. All shown locations are used. However, inverse distance weighting means that locations closer to an estimated value will have 'more effect' on the estimated value than locations farther away. How
           much they affect the estimated value can be adjusted by adjusting the distance weighting factor.
          </p>
          This method of interpolation is quick, and is a good way to get an idea of what might be going on inbetween sampling locations on-the-fly (i.e. switching quickly between filtered and unfiltered samples and seeing if things change),
          but it is not a substitute for a targeted interpolation effort that includes such effects as spatial correlation, which IDW does not.
        </div>) : (null) }
        <div className="form-group has-danger">
          <label className="form-control-label">Grid Cell Size (in kilometers)</label>
          <input type="text" value={cellSize} className={this.state.cellValid ? "form-control" : "form-control is-invalid"} onChange={this.handleCellSizeChange.bind(this)}/>
          { !this.state.cellValid ? (<div className="invalid-feedback">Please use a positive no more than 10 km and at least 0.1 km.</div>) : null }
        </div>
        <div className="form-group has-danger">
          <label className="form-control-label">Distance Weighting Factor</label>
          <input type="text" value={weight} className={this.state.weightValid ? "form-control" : "form-control is-invalid"} onChange={this.handleWeightingFactorChange.bind(this)}/>
          { !this.state.weightValid ? (<div className="invalid-feedback">Please use an integer from 1 - 10.</div>) : null }
        </div>
        <button disabled={!this.state.cellValid || !this.state.weightValid} type="submit" className={this.state.cellValid && this.state.weightValid ? "btn btn-primary" : "btn btn-primary disabled"} onClick={this.handleUpdate.bind(this)}>Do Grid</button>
      </div>
    )
  }
}

function mapStateToProps(state){
  return {
    gridCellSize: state.MapApp.get('gridCellSize'),
    distanceWeightingFactor: state.MapApp.get('distanceWeightingFactor'),
    filteredLocations: state.MapApp.get('filteredLocations'),
    radius: state.MapApp.get('radius')
  }
}

const GridTool = connect(mapStateToProps)(GridToolComp);

export default GridTool;
