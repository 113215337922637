import { updateSlider, filterData, dataFiltered } from '../../actions/map.js';
import { setFetchingText, calculatingData, doneCalculatingData } from '../../actions';
import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { List } from 'immutable';
import * as d3  from '../../../node_modules/d3';
import styles from '../../css/index.css'

/* * * * * * * * * * * * BrushSlider * * * * * * * * * * * * * */

class BrushSlider extends Component{
  constructor(props){
    super(props)
    this.brushClass = props.field.get('column') + '_brush';

    this.state = {
      extent: []
    }
  }
  sliderChanged(field, id, endpoints){
    this.props.dispatch(setFetchingText('Filtering data...'))
    this.props.dispatch(calculatingData());
    this.props.dispatch(updateSlider(endpoints,field,id));
    this.props.dispatch(filterData(id));
    if(this.props.showPiper || this.props.showDurov || this.props.showModifiedPiper) {
      this.props.dispatch(dataFiltered());
    }
    this.props.dispatch(doneCalculatingData());
  }

  clearBrush(){
    let value = this.props.field.get('extent').toJS();
    let active = false;
    let brush = [];

    this.setState({extent: []});

    this.props.dispatch(setFetchingText('Filtering data...'))
    this.props.dispatch(calculatingData());
    this.props.dispatch(updateSlider( List(value), this.props.field.get('column'), this.props.field.get('id'), this.props.filters.get(0).get('id'), brush, active));
    this.props.dispatch(filterData(this.props.filters.get(0).get('id')));
    this.props.dispatch(doneCalculatingData());
  }

  brushed() {
    if (!d3.event || !d3.event.sourceEvent) return;// Only transition after input.
    //if (!d3.event.selection) return;// Ignore empty selections.
    //return if the event is just the brush moving itself.
    if(!d3.event.sourceEvent || d3.event.sourceEvent.type !== "mouseup") return;

    var value= [],
        brush,
        active;

    //If we have an event, etc.. and our selection is > 10px then we set our brush, otherwise clear it
    let setBrush = d3.event && d3.event.selection && d3.event.selection[1] - d3.event.selection[0] > 10

    if(setBrush){
      var extent = this.props.field.get('extent').toJS();
      value[0] = (this.height - d3.event.selection[0]) / this.height * (extent[1] - extent[0]) + extent[0];
      value[1] = (this.height - d3.event.selection[1]) / this.height * (extent[1] - extent[0]) + extent[0];
      brush = d3.event.selection;
      active = true;

      this.setState({extent: value});

    } else {
      value = this.props.field.get('extent').toJS();
      active = false;
      brush = [];

      this.setState({extent: []});
    }
    this.props.dispatch(setFetchingText('Filtering data...'))
    this.props.dispatch(calculatingData());
    this.props.dispatch(updateSlider( List(value), this.props.field.get('column'), this.props.field.get('id'), this.props.filters.get(0).get('id'), brush, active));
    this.props.dispatch(filterData(this.props.filters.get(0).get('id')));
    this.props.dispatch(doneCalculatingData());
  }

  componentDidMount() {
    this.renderBrush();
  }

  componentDidUpdate() {
    this.renderBrush();
  }

  renderBrush() {
    var min = this.props.field.get('extent').get(0),
        max = this.props.field.get('extent').get(1),
        selection = this.props.field.get('brush').toJS(),
        numTicks = parseInt(Math.min(max - min, 10), 10);

    this.height = 300;
    this.width = 30;
    var formatType = this.props.field.get('column') === 'result' ? ".2s" : "d";

    var scale = d3.scaleLinear()
      .range([0, 300])
      .domain([max,min]);

    var  yAxis = d3.axisRight(scale)
      .ticks(numTicks)
      .tickFormat(d3.format(formatType));

    var svg  = d3.select(this.refs[this.brushClass]);
    svg.selectAll('.brush').remove()

    var d3brush = d3.brushY()
        .extent([[0,0], [this.width,this.height]])
        //.on('brush', this.brushing.bind(this))
        .on('end', this.brushed.bind(this));
    var g = svg.select('.brush_inner').append('g').attr("class",'brush')
    g.call(d3brush);
    this.g = g;
    this.d3brush = d3brush;

    if(selection){
      g.call(d3brush.move, selection) //else draw our selection
    }

    g.selectAll('.handle').attr("fill","#aaa");
    g.selectAll('.overlay').attr("fill","#eee");
    g.selectAll('.selection').attr("fill", styles.brushSelectionColor).attr('transform', 'translate(2,0)');
    g.selectAll('.domain').attr("display","none");

    var s = svg.select(".brush-scale");
    s.call(yAxis)
    s.selectAll("path").attr("stroke","#333");
    s.selectAll("text").attr("font-weight","100");
  }

  render(){ 

    const { extent } = this.state;
    var sliderProps = this.props.field.toJS();
    var text = '', years;

    if(extent.length > 0) {
      if(sliderProps.column === 'year') {
        years = [parseInt(+extent[0], 10), parseInt(+extent[1], 10)]
        text = moment(years[1] + '-01-01').add(moment.duration((+extent[1] - years[1]), 'years')).format('ll') + ' - ' + moment(years[0] + '-01-01').add(moment.duration((+extent[0] - years[0]), 'years')).format('ll');
      } else if(sliderProps.column === 'result') {
        //text = numeral(+extent[1]).format('0.[0]a') + ' - ' + numeral(+extent[0]).format('0,0.[00]');
        text = numeral(+extent[1]).format('0.[0]a') + ' - ' + numeral(+extent[0]).format('0.[0]a');
      } else {
        text = Math.round(+extent[1], 2) + 'ft. - ' + Math.round(+extent[0], 2) + 'ft.';
      }
    } else {
      text = '';
    }

    return (
      <div className='brushSlider'>
        <div>{text}</div>
        <svg className="brush-container" ref={this.brushClass} style={{height:"330px", width:"80px", background:"#fff"}}>
          <g className="brush_inner" ref="brush_inner" transform="translate(0,15)" height="300" width="30" fill="#eee" style={{background:"#eee"}}></g>
          <g className="brush-scale" ref="scale" stroke="#333" transform="translate(35,15)" ></g>
        </svg>
        <div
          className='clearBrush link'
          style={{cursor:'pointer',display: this.props.field.get('brush').count() ? 'block' : 'none'}}
          onClick={this.clearBrush.bind(this)}
        >
          clear
        </div>
      </div>
    );
  }
}

export default BrushSlider;
