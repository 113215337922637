import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Table, Column, Cell} from 'fixed-data-table-2';
import {fetchStandardLocations, searchStandardLocations, updateStandardLocationsSearch } from '../actions/standardLocations';

const TextCell = ({data, col, rowIndex, ...props}) => (
  <Cell {...props}>
    {data.getIn([rowIndex,col])}
  </Cell>
)

class ExpandableCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, collapsedRows, callback, col, ...props} = this.props;
    let standard_name = data.getIn([rowIndex,col]);
    let aliases = data.getIn([rowIndex,'location_ids']).filter(raw => raw.toUpperCase() !== standard_name.toUpperCase());

    return (
      <Cell {...props}>
        {data.getIn([rowIndex,col])}
        { aliases.count() > 0 ?
          (<button onClick={() => callback(rowIndex)} className='seeAliases button-link'>
            {collapsedRows.has(rowIndex) ? 'Hide Aliases' : 'See Aliases'}
          </button>) : (<div></div>)
        }
      </Cell>
    );
  }
};

class Locations extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsedRows: new Set(),
    }

    this._handleCollapseClick = this._handleCollapseClick.bind(this);
    this._subRowHeightGetter = this._subRowHeightGetter.bind(this);
    this._rowExpandedGetter = this._rowExpandedGetter.bind(this);
  }

  componentWillMount(){
    if(!this.props.standardLocations.count()){
      this.props.dispatch(fetchStandardLocations());
    }
  }

  onInputChange(e){
    this.props.dispatch(updateStandardLocationsSearch(e.target.value));
    this.props.dispatch(searchStandardLocations(e.target.value));

    this.setState({
      collapsedRows: new Set([])
    });
  }

  _handleCollapseClick(rowIndex) {
    const {collapsedRows} = this.state;
    const shallowCopyOfCollapsedRows = new Set([...collapsedRows]);
    if (shallowCopyOfCollapsedRows.has(rowIndex)) {
      shallowCopyOfCollapsedRows.delete(rowIndex);
    } else {
      shallowCopyOfCollapsedRows.add(rowIndex);
    }

    this.setState({
      collapsedRows: shallowCopyOfCollapsedRows
    });
  }

  _subRowHeightGetter(rowIndex) {
    let standard_name = this.props.standardLocations.getIn([rowIndex,'standard_name'])
    let aliases = this.props.standardLocations.getIn([rowIndex,'location_ids']).filter(raw => raw.toUpperCase() !== standard_name.toUpperCase());
    if(aliases.count() === 0){
      return 0
    }

    return this.state.collapsedRows.has(rowIndex) ? 40 * aliases.count() : 0;
  }

  _rowExpandedGetter({rowIndex, width, height}) {
    if (!this.state.collapsedRows.has(rowIndex)) {
      return null;
    }

    let standard_name = this.props.standardLocations.getIn([rowIndex,'standard_name']);
    let aliases = this.props.standardLocations.getIn([rowIndex,'location_ids']).filter(raw => raw.toUpperCase() !== standard_name.toUpperCase()).map((row) =>(
      <li key={row}>
        {row}
      </li>
    ));

    const style = {
      height: height,
      width: width - 2,
    };
    return (
      <div style={style}>
        <ul className='expandedContent'>
          {aliases}
        </ul>
      </div>
    );
  }

  render() {

    let { collapsedRows }  = this.state;
    let width = Math.min( this.props.template.width - 40, 900)
    let height = this.props.template.height - 245;
    var data = this.props.standardLocations;

    return (
      <div id='analyte_table'>
        <h3>Groundwater Location ID Lookup Table</h3>
        This is a list of standardized well names and the raw location IDs for groundwater sampling locations provided in the BMI Database. Standardized location IDs are shown.
        To see aliases for a location, click "Show Aliases". Location IDs were standardized using information from a 2018 NERT all wells KMZ file, the All Wells Database, and information submitted to the BMI Database in EDDs.
        <div id='cas_id_search' className=''>
          <input value={this.props.searchTerm} id='cas' className='form-control' placeholder='Search Location ID or Location ID Alias...' onChange={this.onInputChange.bind(this)} style={{width:width}}/>
        </div>
        <Table
          rowHeight={40}
          headerHeight={40}
          rowsCount={data.count()}
          width={width}
          maxHeight={height}
          subRowHeightGetter={this._subRowHeightGetter}
          rowExpanded={this._rowExpandedGetter}
          style={{padding:'0 10px'}}
          {...this.props}
        >
          <Column
            header={<Cell>Location ID (standardized)</Cell>}
            cell={<ExpandableCell data={data} col='standard_name' collapsedRows={collapsedRows} callback={this._handleCollapseClick} />}
            flexGrow={3}
            width={100}
          />
          <Column
            header={<Cell>Easting (standardized)</Cell>}
            cell={<TextCell data={data} col='standard_easting'/>}
            flexGrow={1}
            width={100}
          />
          <Column
            header={<Cell>Northing (standardized)</Cell>}
            cell={<TextCell data={data} col='standard_northing'/>}
            flexGrow={1}
            width={100}
          />
        </Table>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    standardLocations: state.StandardLocations.filteredStandardLocations,
    searchTerm: state.StandardLocations.searchTerm,
    template: state.Template
  }
}

export default connect(
  mapStateToProps
)(Locations)
