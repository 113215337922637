//      
import React, {           } from 'react';

import { className } from '../utils';
import { colors, spacing } from '../theme';
import { Div } from '../primitives';
import {                                     } from '../types';

              
                      
                     
                      
  
                   
                           
             
                     
              
                             
                                 
                 
                   
  
                              
           
                   
                           
                  
                   
    

export const css = ({ isDisabled, isFocused, isSelected }       ) => ({
  backgroundColor: isSelected
    ? colors.primary
    : isFocused ? colors.primaryLight : 'transparent',
  color: isDisabled
    ? colors.neutral20
    : isSelected ? colors.neutral0 : 'inherit',
  cursor: 'default',
  display: 'block',
  fontSize: 'inherit',
  padding: `${spacing.baseUnit * 2}px ${spacing.baseUnit * 3}px`,
  width: '100%',
});

const Option = (props       ) => {
  const { children, getStyles, isFocused, isSelected, innerProps } = props;

  return (
    <Div
      className={className('option', { isFocused, isSelected })}
      css={getStyles('option', props)}
      {...innerProps}
    >
      {children}
    </Div>
  );
};

export default Option;
