import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import FilterBar from '../MapView/FilterBar';
import DataTableView from './DataTableView';
import {clearErrorMessage} from '../../actions';

const customStyles = {
  overlay: {
    zIndex                : '10000000'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

function mapFilterStateToProps(state){
  return {
    filters : state.MapApp.get('filters'),
    filterFields : state.MapApp.get('filterFields'),
    analytes : state.MapApp.get('analytes'),
    analyte : state.MapApp.get('analyte'),
    selectedQuery : state.MapApp.get('selectedQuery'),
    possibleLeachate : state.MapApp.get('possibleLeachate'),
    isFetching: state.isFetching,
    errorMessage: state.MapApp.get('errorMessage'),
    showPiper: state.MapApp.get('showPiper'),
    template: state.Template
  }
}

const ConnectedFilters = connect(mapFilterStateToProps)(FilterBar);

class DataViewComp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalMessage: ''
    }

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  componentWillMount() {
    this.setState({"navBarHeight":62})
    this.setState({"height":window.innerHeight - 62})
    this.setState({"width":window.innerWidth})
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.errorMessage) {
      this.setState({modalMessage: nextProps.errorMessage });
      this.setState({modalIsOpen: true});
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#222';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.props.dispatch(clearErrorMessage())
  }

  render() {

    //let compHeight = this.props.template.height - this.props.template.navHeight;
    //let compWidth = this.props.template.width;

    return(
      <div>
        <ReactModal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal} style={customStyles} contentLabel="Query Name Modal">
          <h4 ref={subtitle => this.subtitle = subtitle}>{this.state.modalMessage}</h4>
          <button className="btn btn-info center-block" onClick={this.closeModal}>Okay</button>
        </ReactModal>
        <ConnectedFilters height={this.state.height}/>
        <DataTableView />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  //return state;
  return {
    errorMessage: state.MapApp.get('errorMessage'),
    template:state.Template
  }
}

const DataView = connect(mapStateToProps)(DataViewComp)

export default DataView;
