import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import 'react-table/react-table.css';
//import * as d3 from 'd3-array';
//import * as ss from 'simple-statistics';
import moment from 'moment';
import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
//import * as Immutable from 'immutable';

class SummaryTableViewComp extends Component {

   /*constructor(props) {
    super(props)
    console.log('contructor is running');
    this._data = this.makeData(props.data);

     this.state = {
      data: this._data
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!Immutable.is(this.state.data, nextProps.data)){
      this._data = this.makeData(nextProps.data);

      this.setState({
        data: this._data
      });
    }
  }*/

  render() {

    const data = this.props.data.toJS();
    const height = this.props.height
    const analyte_type = this.props.analyte_type

    var showCols = {
      'matrix_fraction': true,
      'location': true,
      'samples': true,
      'sum': false,
      'detects': true,
      'detect_freq': true,
      'min_sample_date': true,
      'max_sample_date': true,
      'min_detect': true,
      '25_detect': false,
      'median_detect': true,
      'mean_detect': true,
      '75_detect': false,
      'max_detect': true,
      'sd_detect': true,
      'nondetects': false,
      'min_nondetect': true,
      '25_nondetect' : false,
      'median_nondetect': false,
      'mean_nondetect': false,
      '75_nondetect' : false,
      'max_nondetect': true,
      'sd_nondetect': false
    }

    if(analyte_type === 'Rad') {
      showCols.min_nondetect = false;
      showCols.max_nondetect = false;
      showCols.detect_freq = false;
    } else if(analyte_type === 'Asbestos') {
      showCols.min_nondetect = false;
      showCols.max_nondetect = false;
      showCols.detect_freq = false;
      showCols.sd_detect = false;
      showCols.max_detect = false;
      showCols.min_detect = false;
      showCols.mean_detect = false;
      showCols.median_detect = false;
      showCols.detect_freq = false;

      showCols.sum = true
    }

    return (

      <div>
        <ReactTable
          data={data[0]}
          noDataText="No Data"
          columns={[
            {
              Header: this.props.analyte ? this.props.analyte : '',
              columns: [
                {
                  Header: "Data Type",
                  accessor: "matrix_fraction",
                  minWidth: 200
                }
              ]
            },
            {
              Header: "Summary Stats",
              columns: [
                {
                  Header: "Location",
                  id: "location",
                  accessor: d => d.location //+ ' (' + d.samples + ')'
                },
                {
                  Header: "N",
                  id: "samples",
                  accessor: d => numeral(d.samples).format('0,0.[0000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Num Detect",
                  id: "detects",
                  accessor: d => numeral(d.detects).format('0,0.[0000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Abestos Structures",
                  show: showCols.sum,
                  id: "sum",
                  accessor: d => numeral(d.sum).format('0,0'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Detect Freq.",
                  show: showCols.detect_freq,
                  id: "detect_freq",
                  accessor: d => numeral(d.detect_freq).format('0.0%'),
                  Cell: row => {
                    //console.log(row); 
                    return  (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                    )
                  }
                },
                {
                  Header: "Min. Detect",
                  show: showCols.min_detect,
                  id: "min_detect",
                  accessor: d => numeral(d.min_detect).format('0,0.[0000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Median Detect",
                  show: showCols.median_detect,
                  id: "median_detect",
                  accessor: d => numeral(d.median_detect).format('0,0.[000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Mean Detect",
                  show: showCols.mean_detect,
                  id: "mean_detect",
                  accessor: d => numeral(d.mean_detect).format('0,0.[000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Max. Detect",
                  show: showCols.max_detect,
                  id: "max_detect",
                  accessor: d => numeral(d.max_detect).format('0,0.[0000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "S.D. Detect",
                  show: showCols.sd_detect,
                  id: "sd_detect",
                  accessor: d => numeral(d.sd_detect).format('0,0.[000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                /*{
                  Header: "Non-Detect Range",
                  id: "nondetect_range",
                  accessor: d => d.min_nondetect + ' - ' + d.max_nondetect,
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },*/
                {
                  Header: "ND",
                  show: showCols.nondetects,
                  id: "nondetects",
                  accessor: d => numeral(d.nondetects).format('0,0'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Min. ND",
                  show: showCols.min_nondetect,
                  id: "min_nondetect",
                  accessor: d => numeral(d.min_nondetect).format('0,0.[0000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Max. ND",
                  show: showCols.max_detect,
                  id: "max_nondetect",
                  accessor: d => numeral(d.max_nondetect).format('0,0.[0000]'),
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
                {
                  Header: "Date Range",
                  id: "sample_date_range",
                  accessor: d => moment(d.min_sample_date).format('MMM, YYYY') + ' - ' + moment(d.max_sample_date).format('MMM, YYYY'),
                  minWidth: 120,
                  Cell: row => (
                    <div style={{ textAlign: "center" }}>
                      {row.value}
                    </div>
                  )
                },
              ]
            }
          ]}
          pivotBy={["matrix_fraction"]}
          style={{ height: height + 'px' }}
          showPagination={false}        
          ExpanderComponent={({ isExpanded, ...rest }) => isExpanded ? <span> <FontAwesome name="minus-square-o" /> </span> : <span> <FontAwesome name="plus-square-o" /> </span>}
          defaultPageSize={data[1] === 0 ? 4 : data[1]}
          className="-striped -highlight"
          AggregatedComponent={ ({ subRows, column }) => {
            
            var summaryValue = '';
            var m;

            switch(column.id) {
              case 'location':
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'samples':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'detects':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'sum':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'detect_freq':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0.0%')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'min_detect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[0000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'median_detect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'mean_detect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'max_detect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[0000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'sd_detect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'nondetects':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'min_nondetect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[0000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'max_nondetect':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0][column.id]).format('0,0.[0000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'nondetect_range':
                m = subRows[0].matrix_fraction;
                summaryValue = numeral(data[2].filter(x => x.matrix_fraction === m)[0]['min_nondetect']).format('0,0.[0000]') + ' - ' + numeral(data[2].filter(x => x.matrix_fraction === m)[0]['max_nondetect']).format('0,0.[0000]')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              case 'sample_date_range':
                m = subRows[0].matrix_fraction;
                summaryValue = moment(data[2].filter(x => x.matrix_fraction === m)[0]['min_sample_date']).format('MMM, YYYY') + ' - ' + moment(data[2].filter(x => x.matrix_fraction === m)[0]['max_sample_date']).format('MMM, YYYY')
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )

              default:
                return (
                  <div style={{ textAlign: "center" }}>
                    <strong>{summaryValue}</strong>
                  </div>
                )
            }
          }}
        />
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.MapApp.get('filteredSummaryTableData'),
    analyte : state.MapApp.get('analyte')
  }
}

const SummaryTableView = connect(
  mapStateToProps
)(SummaryTableViewComp)

export default SummaryTableView;