import {fromJS, List, Map} from 'immutable';

const initialState = {
  casids: List(),
  filteredCasids: List(),
  searchTerm:""
}


const filter = (data, term) => {
  return data.filter((row) => {
    var in_raw_names = false;
    row.get('raw_analyte_names').forEach((row) => {
      if(row.includes(term.toLowerCase())){
        in_raw_names = true;
      }
    })
    return row.get('analyte_name').toLowerCase().includes(term.toLowerCase())
      || row.get('cas_id').toLowerCase().includes(term.toLowerCase())
      || in_raw_names
  })

}

const CasIds = (state = initialState, action) => {
  let newState = Object.assign({},state);

  switch(action.type){
    
    case "RECEIVE_CAS_IDS":
      if(action.data.success){
        let data = fromJS(action.data.data);
        let groupedData = data.groupBy((row) => row.get('analyte_name'))
          .map((row) => {
            return Map({
              'analyte_name':row.getIn([0,'analyte_name']),
              'cas_id':row.getIn([0,'cas_id']),
              'raw_analyte_names': row.map((item) => item.get('analyte_name_raw').toLowerCase())
            })
          }).toList()
        newState.casids = groupedData;
        newState.filteredCasids = filter(groupedData,state.searchTerm)
      }
      return newState;

    case "SEARCH_CAS_IDS":
      newState.searchTerm = action.value;
      if(action.value === '' || !action.value) {
        newState.filteredCasids = state.casids
      } else {
        newState.filteredCasids = filter(state.casids,action.value)
      }
      return newState;

    case "UPDATE_CAS_ID_SEARCH":
      //newState.searchTerm = action.value;
      return newState;

    default:
      return state;
  }
}

export default CasIds;
