import React, { Component } from 'react';
import {connect} from 'react-redux';
import {fetchNews} from '../../actions';
//import OldNews from './oldNews'

class WhatsNew extends Component {
  componentWillMount(){
    this.props.dispatch(fetchNews())
  }
  render(){
  console.log(this.props.news)
  return (
    <div id='whatsnew'>
      <h3>What's New</h3>
      <div dangerouslySetInnerHTML={{__html:this.props.news}}></div>
    </div>

      )
  }
}
const mstp = (state) => {
  return {
  template: state.template,
  news: state.Overview.news
}}


export default connect(mstp)(WhatsNew);
