import React, { Component } from 'react';
import {connect} from 'react-redux';
import {submitBug} from '../../actions';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

class BugReportComp extends Component {
  constructor(props){
    super(props)
    this.state = {
      message : "",
      error : "",
      success : "",
      button : true,
      count : 1,
      initMessage: 'Thank you for helping keep this site up to date. Please describe your bug or feature below.'
    }
  }

  onInputChange(e){
    this.setState({'message':e.target.value})
  }
  onSubmitAnotherBug(){
    this.setState({
      message: "",
      success: "",
      error: "",
      initMessage: "Thank you for helping keep this site up to date. Please describe your bug or feature below."
    })
  }
  onSubmitBug(){

    if(this.state.message.length){
      if(this.state.count <= 5){
        this.setState({
          error: "",
          success: "Thank you, your report or request has been sent",
          count: this.state.count + 1,
          initMessage: ""
        })

        let post_data = {
          email : sessionStorage.getItem('email'),
          name : sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname'),
          message : this.state.message
        }

        this.props.dispatch(submitBug(post_data))
      } else {
        this.setState({
          "error":"You have submitted 5 bugs and/or features. If you have more please contact the database administrators.",
          "button":false,
          "success":"",
           "initMessage": ""
        })
      }
    } else {
      this.setState({
        "error":"Please provide a bug or feature description.",
        "success":"",
        "initMessage": "Thank you for helping keep this site up to date. Please describe your bug or feature below."
      })
    }
  }

  render() {
    // eslint-disable-next-line  
    let email = sessionStorage.getItem('email')
    // eslint-disable-next-line
    let firstname = sessionStorage.getItem('firstname')
    // eslint-disable-next-line
    let lastname = sessionStorage.getItem('lastname')
    // eslint-disable-next-line
    const { initMessage } = this.state;

    return (
      <div id='bug_report' style={{maxWidth:600}}>
        <h3>Report a Bug / Request a Feature</h3>
        {initMessage}
        <br/>
        <br/>
        {!this.state.success ? (
        <div className="form-group">
          <label htmlFor="message">Bug or Feature Description:</label>
          <textarea className="form-control" rows="5" id="message" style={{marginBottom:15}}onChange={this.onInputChange.bind(this)}></textarea>
          <div className='error' style={{color:'#b71616',textAlign:'center'}}>{this.state.error}</div>
          <button disabled={!this.state.button}style={{marginTop:"15px"}} className="btn btn-info" onClick={this.onSubmitBug.bind(this)} type="button">
            Submit
            <span style={{paddingLeft: '5px' }}>
              <FontAwesome name="chevron-right" />
            </span>
          </button>
        </div>
        ):
        <div className="form-group" style={{marginTop:20}}>
          <div className='success' style={{color:'#333'}}>{this.state.success}</div>
          <button style={{marginTop:"15px"}} className="btn btn-info" onClick={this.onSubmitAnotherBug.bind(this)} type="button">
            Report Another Bug / Request Another Feature
            <span style={{paddingLeft: '5px' }}>
              <FontAwesome name="chevron-right" />
            </span>
          </button>
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    template: state.Template
  }
}

const BugReport = connect(
  mapStateToProps
)(BugReportComp)

export default BugReport;
