import ReactGA from 'react-ga';
import Query from '../Query';
import { toggleQueryFilter } from './query'

export const GET_DATA = 'GET_DATA';
export const GET_QUERY_FILTERS = 'GET_QUERY_FILTERS';
export const GET_QUERIES = 'GET_QUERIES';
export const SAVING_QUERY = 'SAVING_QUERY';
export const GET_RECORDS = 'GET_RECORDS';

export const RECEIVE_DATA = 'RECEIVE_DATA';
export const RECEIVE_PIPER_DATA = 'RECEIVE_PIPER_DATA';
export const RECEIVE_QUERY_FILTERS = 'RECEIVE_QUERY_FILTERS';
export const RECEIVE_QUERIES = 'RECEIVE_QUERIES';
export const RECEIVE_QUERY = 'RECEIVE_QUERY';
export const SAVED_QUERY = 'SAVED_QUERY';
export const RECEIVE_RECORDS = 'RECEIVE_RECORDS';
export const RECEIVE_OVERLAYS = 'RECEIVE_OVERLAYS';
export const SET_FETCHING_TEXT = 'SET_FETCHING_TEXT';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SCREEN_RESIZE = 'SCREEN_RESIZE';

export const screenResize = () => ({
  type: 'SCREEN_RESIZE'
})

export const calculatingData = () => ({
  type: SHOW_LOADING
})

export const doneCalculatingData = () => ({
  type: 'CALCULATE_DONE'
})

export const getData = (text) => ({
  type: 'GET_DATA',
  text
})

export const getQueryFilters = (text) => ({
  type: 'GET_QUERY_FILTERS',
  text
})

export const getQueries = () => ({
  type: 'GET_QUERIES'
})

export const savingQuery = (text) => ({
  type: 'SAVING_QUERY',
  text
})

export const getRecords = (text) => ({
  type: 'GET_RECORDS',
  text
})

export const receiveData = (text, data, t1, t2) => ({
  type: RECEIVE_DATA,
  text,
  data: data,
  milliseconds: (t2-t1)
})

export const receivePiperData = (data) => ({
  type: RECEIVE_PIPER_DATA,
  data: data
})

export const receiveGaugeData = (data) => ({
  type: "RECEIVE_GAUGE_DATA",
  data: data
})

export const receiveQueryFilters = (text, data) => ({
  type: RECEIVE_QUERY_FILTERS,
  text,
  data: data
})

export const receiveQueries = (data) => ({
  type: RECEIVE_QUERIES,
  data: data
})

export const receiveQuery = (text, data) => ({
  type: RECEIVE_QUERY,
  text,
  data: data
})

export const savedQuery = (text, data) => ({
  type: SAVED_QUERY,
  text,
  data: data
})

export const receiveRecords = (text, data) => ({
  type: RECEIVE_RECORDS,
  text,
  data: data
})

export const receiveOverlays = (data) => ({
  type: RECEIVE_OVERLAYS,
  data: data
})

export const receiveQueryTypes = (data) => ({
  type: 'RECEIVE_QUERY_TYPES',
  data: data
})

export const setFetchingText = (text) => ({
  type: SET_FETCHING_TEXT,
  text
})

export const fetchData = (id, analyte_id) => dispatch => {
  console.log(id)
  console.log("FETCHING DATA");
  dispatch(setFetchingText("Loading data..."))
  dispatch(getData(id))
  dispatch({type: "LOSE_LOCATION"});
  var t1 = performance.now();
  return Query.search('/api/queries/' + id + '/' + analyte_id).then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve records from the database.'})
    } else {
      sessionStorage.setItem('query_id',id)
      sessionStorage.setItem('analyte_id',analyte_id)
      var t2 = performance.now();
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Query Data',
          variable: 'get',
          value: (performance.now() - t1), // in milliseconds
          label: 'Query View'
        });
      }
      if(data.success){
        dispatch(receiveData(id, data, t1, t2));
        dispatch({type: "HIDE_LOADING"});
      } else {
        console.log(data.error)
        dispatch({ type: 'DATA_ERROR', message: "Error: " + data.error})
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing data.'})
  })
}

export const fetchQueryTypes = (text) => dispatch => {
  dispatch(setFetchingText("Loading query types."))
  //dispatch(getQueryFilters(text))
  return Query.search('/api/query_types').then( data => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve query types from the database.'})
    } else {
      dispatch(receiveQueryTypes(data.query_types))
      dispatch({type: "HIDE_LOADING"});
    }
  }).catch(error => {
    console.log(error)
  });
}

//export const fetchQueryFilters = (text) => next => dispatch => {
export const fetchQueryFilters = (text) => dispatch => {
  console.log("FETCHING QUERY FILTERS");
  dispatch(setFetchingText("Loading query filters."))
  dispatch(getQueryFilters(text))
  var t1 = performance.now();
  return Query.search('/api/new_query_filters').then( data => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve query filters from the database.'})
    } else {
      var t2 = performance.now();
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Query Filters',
          variable: 'get',
          value: (t2 - t1), // in milliseconds
          label: 'Query View'
        });
      }
      dispatch(receiveQueryFilters(text, data))
    }
  }).catch(error => {
    console.log(error)
  });
  //next();
}

export const fetchQueries = () => dispatch => {
  console.log("FETCHING QUERIES");
  dispatch(setFetchingText("Loading queries."))
  dispatch(getQueries())
  var t1 = performance.now();
  return Query.search('/api/queries').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve saved queries from the database.'})
    } else {
      var t2 = performance.now();
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Queries',
          variable: 'get',
          value: (t2 - t1), // in milliseconds
          label: 'Query View'
        });
      }
      dispatch(receiveQueries(data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing data.'})
  });
  //next(dispatch);
}

export const fetchQuery = (id) => dispatch => {
  console.log("FETCHING QUERY");
  dispatch(getData(id))
  var t1 = performance.now();
  return Query.search('/api/queries/' + id).then( (data) => {
    if( data instanceof Error ) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve information about the query from the database.'})
    } else {
      var t2 = performance.now();
      console.log(t2 - t1);
      if(process.env.REACT_APP_ENV === 'production') {
        ReactGA.timing({
          category: 'Query Data',
          variable: 'get',
          value: (t2 - t1), // in milliseconds
          label: 'Query View'
        });
      }
      dispatch(receiveQuery(id, data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing data.'})
  })
}

export const saveQuery = (text) => dispatch => {
  console.log("SAVING QUERY");
  dispatch(savingQuery(text))
  //console.log(text)
  return Query.post('/api/queries', text).then( (data) => {
    if(data instanceof Error){
      dispatch({ type: 'API_ERROR', message: 'There was an error saving the query.'})
    } else {
      dispatch(savedQuery(text, data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing save request.'})
  });
}


export const deleteQuery = (id) => dispatch => {
  console.log("DELETING QUERY");
  //dispatch(savingQuery(text))
  return Query.update('/api/queries/' + id, {'deleted': 1}).then( (data) => {
    if(data instanceof Error){
      dispatch({ type: 'API_ERROR', message: 'There was an error deleting the query.'})
    } else {
      dispatch(fetchQueries())
      dispatch({ type: "DELETE_QUERY", id: id})
    }
  }).catch(error => {
    dispatch({ type: 'DATA_ERROR', message: 'Error processing delete request.'})
  });
}

export const fetchRecords = (text, type) => dispatch => {
  console.log("GETTING RECORDS");
  dispatch(setFetchingText("Calculating number of records."))
  //let query = JSON.stringify(text)
  let query = encodeURIComponent(JSON.stringify(text))
  dispatch(getRecords(text))
  return Query.search('/api/records/' + query + '/' + type).then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'There was an error fetching the records from the database.'})
    } else {
      dispatch(receiveRecords(query, data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing records.'})
  });
}

export const fetchOverlays = () => dispatch => {
  //console.log("GETTING OVERLAYS");
  return Query.search('/api/overlays').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'There was an error fetching the map overlays from the server.'})
    } else {
      var layers = [];
      data.layers.forEach(function(x) {
        layers.push(JSON.parse(x));
      })
      dispatch(receiveOverlays(layers))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing map overlays.'})
  });
}

export function toggleQueryFilterAndFetchRecords(id) {
  return function (dispatch, getState) {
    let timeout = null;
    dispatch(toggleQueryFilter(id))
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      const filtersActive = getState().QueryApp.get('activeFilters')
      dispatch(fetchRecords(filtersActive))
    }, 200)
  }
}

export const fetchPiperData = () => dispatch => {
  return Query.search('/api/piper').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve piper diagram data from the database.'})
    } else {
      dispatch(receivePiperData(data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error processing piper data.'})
  })
}

export const showLoading = () => ({
  type: SHOW_LOADING
})

export const hideLoading = () => ({
  type: HIDE_LOADING
})

export const submitBug = (post_data) => dispatch => {
  console.log('submit bug')
  dispatch(setFetchingText("Submitting bug report."))
  dispatch(showLoading())
  return Query.post('/api/report_bug', post_data).then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'There was an error submitting this bug report.'})
    } else {
      console.log(data)
      dispatch(hideLoading())
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'There was an error processing this bug report request.'})
  });
}

export const clearErrorMessage = () => ({
  type: "CLEAR_ERROR_MESSAGE"
})

export const fetchNews = () => dispatch => {
  return Query.search('/api/whats_new').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve news updates from the database.'})
    } else {
      console.log(data.data)
      dispatch(receiveNews(data.data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error recieving news.'})
  })
}

export const receiveNews = (data) => ({
  type: "RECEIVE_NEWS",
  data
})

export const fetchHistory = () => dispatch => {
  return Query.search('/api/perchlorate_history').then( (data) => {
    if(data instanceof Error) {
      dispatch({ type: 'API_ERROR', message: 'Could not retrieve data from the database.'})
    } else {
      dispatch(receiveHistory(data.data))
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'DATA_ERROR', message: 'Error recieving data.'})
  })
}

export const receiveHistory = (data) => ({
  type: "RECEIVE_HISTORY",
  data
})
