import React, { Component } from 'react';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { setQuantileLocation } from '../../actions/map';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import '../../css/quantilePlot.css';

class QuantilePlot extends Component {

  constructor(props) {
    super(props);

    this.state = {
      log: false
    }
  }

  componentDidMount(){
    this.renderPlot();
  }
  componentDidUpdate(){
    this.renderPlot();
  }

  toggleLogData(){
    this.setState({ 'log': !this.state.log })
  }

  renderPlot(){
    d3.selectAll('.quantilePlotContainer').remove();

    this.svgMargin = 20;
    this.yAxisWidth = 60;
    this.xAxisHeight = 20;
    this.plotHeight = this.props.height - ((2*this.svgMargin) + 50);
    this.plotWidth = this.props.width - (2*this.svgMargin);

    var self = this;

    var max = [],
        count = this.props.data.length,
        sortedData = this.props.data.sort((a,b) => +a.result > +b.result ? 1 : -1),
        data = sortedData.map((value,index) => {
          if(this.state.log) {
            value.value = Math.log(+value.result);
            max.push(Math.log(+value.result));
          } else {
            value.value = +value.result;
            max.push(+value.result);
          }
          value.detected = ['F','U','N'].indexOf(value.detect) < 0 ? '' : '< ';
          value.percentile = (index + 1) / count;
          return value;
        });

    var x = d3.scaleLinear()
      .domain([0,1])
      .range([0, (this.plotWidth - this.yAxisWidth)])
      .nice();

    var y = d3.scaleLinear()
      .domain([0,d3.max(max)])
      .range([(this.plotHeight),this.svgMargin])
      .nice();

    var xAxis = d3.axisBottom(x)
      .tickFormat(d3.format(""))
      .ticks(11);

    var yAxis = d3.axisLeft(y)
      .tickFormat((d) => this.state.log ? d3.format(".2s")(Math.exp(d)) : d3.format(".2s")(d));

    var valueline = d3.line()
      .x((d) => x(d.percentile))
      .y((d) => {return y(d.value)});

    var svg = d3.select(this.refs.quantilePlot);

    var plotContainer = svg.append('g')
      .attr('class','quantilePlotContainer')
      .attr('width',this.plotWidth)
      .attr('height', this.plotHeight)
      .attr('transform', "translate(0," + this.svgMargin + ")")

    var plot = plotContainer.append('g')
      .attr('class','quantilePlotG')
      .attr('width', this.plotWidth - this.yAxisWidth)
      .attr('height', this.plotHeight - this.xAxisHeight)
      .attr('transform', "translate("+this.yAxisWidth +"," + -this.xAxisHeight + ")")

    plot.append('g')
      .append('path')
        .attr('class','quantilePath')
        .attr('d', valueline(data))

    var tip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html((d) => d.detected + d3.format(',')(d.value) + ' ' + d.unit )

    svg.call(tip);

    plot.selectAll('.quanDot')
      .data(data)
      .enter().append('circle')
      .attr('class','quanDot')
      .attr('r',2.5)
      .attr("cx", (d) => x(d.percentile))
      .attr("cy", (d) => y(d.value))

    plotContainer.append("g")
      .attr('class',"quantileYAxis")
      .attr('transform', "translate(" + this.yAxisWidth + "," + -this.xAxisHeight + ")")
      .call(yAxis)

    plotContainer.append("g")
      .attr('class',"quantileXAxis")
      .attr('transform', "translate(" + this.yAxisWidth+ "," + (this.plotHeight-this.xAxisHeight) +")")
      .call(xAxis)

    plotContainer.append("text")
      .attr('transform',"translate(15," + (this.plotHeight/2) + ") rotate(-90)")
      .text('ug/L');

    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr('transform',"translate(" + (this.props.width/2) + "," + ((this.plotHeight + this.xAxisHeight)) + ")")
      .text("Percentile");

    plot.selectAll('.quanDotBuffer')
      .data(data)
      .enter().append('circle')
      .attr('class','quanDotBuffer')
      .attr('r',5)
      .attr("cx", (d) => x(d.percentile))
      .attr("cy", (d) => y(d.value))
      .on('mouseover', function (d) {
        self.props.dispatch(setQuantileLocation(d.location));
        //tip.show(d);
      })
      .on('mouseout', function(d) {
        //tip.hide(d);
        self.props.dispatch(setQuantileLocation(null));
      })
  }

  render(){
    return (
      <div>
        <div width={this.props.width} className={this.props.canLog ? 'logCheckbox' : 'logCheckbox disabled' } onClick={this.toggleLogData.bind(this)} disabled={this.props.canLog}>
          <span><FontAwesome name={this.state.log ? 'check-square' : 'square'} className="fa icon-left far"/></span>
          Log Data
        </div>
        <svg ref='quantilePlot' width={this.props.width} height={this.props.height - 50} ></svg>
      </div>
    )
  }
}

export default QuantilePlot;
