import * as d3 from 'd3';
//import L from 'leaflet';

export function style_subareas(feature){
    return {
        color: 'black',
        fillOpacity: 0,
        weight: 2,
        zIndex: 2
    };
}

export function style_lith(feature){
  return {
    interactive: true,
    fillColor: "rgba(" + feature.properties.lithrgb_r + "," + feature.properties.lithrgb_g + "," + feature.properties.lithrgb_b + ",0.4)",
    fillOpacity: 0.4,
    color: 'black',
    weight: 1,
    zIndex: 3
  };
}

export function style_soil(feature){
  return {
    fillColor: feature.properties.color,
    fillOpacity: 0.4,
    color: 'black',
    weight: 1,
    zIndex: 3
  };
}

export function style_paleo(feature){
  return {
    fillColor: '#FF7F50',
    color: '#FF7F50',
    zIndex: 3
  };
}

export function style_weirs(feature){
  return {
    fillColor: '#800080',
    color: '#800080',
    weight: 1,
    zIndex: 5
  };
}

var seepRadii = d3.scaleLinear().domain([0,50,200,500,3000,57000]).range([3,4,5,6,7,8])

export function style_seeps(feature) {
    return {
        radius: seepRadii(feature.properties.ClO4_ppb),
        fillColor: "#FFA500",
        color: "#A9A9A9",
        fillOpacity: 0.8,
        zIndex: 15
    }
};

/*export function style_allwells(feature) {
    return {
        radius: 4,
        fillColor: "black",
        color: "white",
        fillOpacity: 1,
        zIndex: 15
    }
};*/

export const style_allwells = {
    radius: 4,
    fillColor: "black",
    color: "white",
    fillOpacity: 0.8,
    weight: 0.8
};

export function soilDataset(dataset) {
  if(dataset === 'NERT') {
    return '#ee4035'
  } else if(dataset === 'BRC/Timet') {
    return '#f37736'
  } else if(dataset === '2008 Supplement') {
    return '#fdf498'
  } else if(dataset === '2008 Deep') {
    return '#7bc043'
  } else if(dataset === 'Environ') {
    return '#0392cf'
  } else {
    return '#283655'
  }
}

export function style_bkg_soils(feature) {
    return {
        radius: 4,
        fillColor: soilDataset(feature.properties.Dataset),
        color: "white",
        fillOpacity: 0.8,
        weight: 0.8
    }
};

export function style_first_contact(feature) {
  return {
      radius: 3,
      fillColor: feature.properties.color,
      color: "black",
      fillOpacity: 0.8,
      weight: 0.8
  }
};

export const style_gauges = {
    radius: 5,
    fillColor: "#FF7F50",
    color: "#A9A9A9",
    fillOpacity: 0.8,
    weight: 0.8,
    zIndex: 16
};

export const style_flowlines = {
    color: "#AF1616",
    weight: 2.5
};

export const style_wash = {
    color: "#270491",
    weight: 1.5
};

export function onEveryFeatureSeep(feature, layer){
  if (feature.properties) {
      layer.bindPopup('<b>ID:</b> ' + feature.properties.SampleId +
          '<br />ClO4:</b> ' + feature.properties.ClO4_ppb + ' (ppb)'
      );
  }
}

export function onEveryFeatureAllWells(feature, layer){
    if (feature.properties) {
      layer.bindPopup('<b>ID:</b> ' + feature.properties["Well ID"] + '' +
          '<br/><b>Owner:</b> ' + ((feature.properties.Owner==='Unknown') ? '' : feature.properties.Owner) + '' +
          '<br/><b>Type of Well:</b> ' + feature.properties['Well Type'] + '' +
          '<br/><b>Well Status:</b> ' + feature.properties.Status + '' +
          '<br/><b>Screening Interval:</b> ' + feature.properties['Depth to Top of Screen (feet.bgs)'] + '-' + feature.properties['Depth to Bottom of Screen\n(feet bgs)'] +
          '<br/><b>Lithology:</b> ' + feature.properties.Lithology + '' +
          '<br/><b>Water-Bearing Zone:</b> ' + feature.properties['Water-Bearing Zone'] + ''
        );
    }
}

export function onEveryFeatureBkgSoils(feature, layer){
    if (feature.properties) {
      layer.bindPopup('<b>ID:</b> ' + feature.properties["Location ID"] + '' +
          '<br/><b>Dataset:</b> ' + feature.properties.Dataset + ''
        );
    }
}

export function onEveryFeatureFirstContact(feature, layer){
  if (feature.properties) {
    layer.bindPopup('<b>ID:</b> ' + feature.properties["well_id"] + '' +
        '<br/><b>Depth to Qal/UMCf Contact:</b> ' + feature.properties.depth_to_contact + ' ft.'
      );
  }
}


export function onEveryFeatureLith(feature, layer){
    if (feature.properties) {
        layer.bindPopup('<b>Description:</b> ' + feature.properties.NVunits_UN + ' (' + feature.properties.UNIT_AGE + ')' +
        '<br /><b>Rock Type:</b> ' + feature.properties.ROCKTYPE1 +
        '<br /><b>Notes:</b> ' + feature.properties.NVunits_ST
      );
    }
}

/*export function onEachFeatureLith(feature, layer) {
    layer.on({
      //mouseover: this.highlightFeature.bind(this),
      //mouseout: this.resetHighlight.bind(this),
      click: function(event) {
        if (feature.properties) {
            layer.bindPopup('<b>Description:</b> ' + feature.properties.NVunits_UN + ' (' + feature.properties.UNIT_AGE + ')' +
                '<br /><b>Rock Type:</b> ' + feature.properties.ROCKTYPE1 +
                '<br /><b>Notes:</b> ' + feature.properties.NVunits_ST
            );
          }
        }
    });
}*/

export function onEveryFeatureSoil(feature, layer){
    if (feature.properties) {
      layer.bindPopup('<b>Association:</b> ' + feature.properties.mapunit_fi
        );
    }
}

export function onEveryFeatureSubarea(feature, layer){
    if (feature.properties) {
      layer.bindPopup('<b>Owned by:</b> ' + feature.properties.PARCEL_OWN);
    }
}

/*export function onEveryFeatureGauge(feature, layer){
    if (feature.properties) {

      layer.bindPopup('<b>Gauge:</b> ' + feature.properties.Gauage_nm + ' (' + feature.properties.ID + ')' +
        '<br />Datum:</b> ' + feature.properties.Gage_dtm_f + ' (ft)'
      );

      layer.on('mouseover', function(e) {
        this.openPopup();
      });

      layer.on({
        click: whenClicked
      });
  }
}*/
