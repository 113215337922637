import { RECEIVE_DATA } from '../actions';

const map_data = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_DATA:
      return action.data.locations;
  default:
    return state;
  }
}

export default map_data;
