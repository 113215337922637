import React, { Component } from 'react';
import Navigation from './Navigation';
import ErrorBoundary from './ErrorBoundary';
import ReactGA from 'react-ga';

if(process.env.REACT_APP_ENV === 'production') {
  if(process.REACT_APP_SITE === 'bmi') {
    ReactGA.initialize('UA-101850507-5');
  } else {
    ReactGA.initialize('UA-101850507-1'); // for NERT GW site
  }
}

class App extends Component {

  render() {
    return (
      <div>
          <ErrorBoundary>
            <Navigation />
          </ErrorBoundary>
      </div>
    );
  }
}

export default App;
