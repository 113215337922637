import React, { Component } from 'react';
import * as d3  from 'd3';
import d3Tip from 'd3-tip';
import styles from '../../css/locPlot.css';

class LocPlot extends Component {

  constructor(props){
    super(props);
    this.svgHeight = this.props.template.height;
    this.svgMargin = 15;
    this.svgWidth = this.props.template.width;
    this.scaleHeight = 30;
    this.yAxisWidth = 50;
    this.plotHeight = this.svgHeight - 2*this.svgMargin - this.scaleHeight;
    this.plotWidth = this.svgWidth - 3*this.svgMargin - this.yAxisWidth;
  }

  componentDidMount() {
    this.renderPlot();
  }

  componentDidUpdate() {
    this.renderPlot();
  }

  renderPlot(){
    var unit,
    unit2 = this.props.unit.get(0);
    if(this.props.type === 2) {
      unit = unit2.set('filter', 'Elevation (' + unit2.get('filter') + ')');
    } else {
      unit = unit2;
    }
    
    d3.selectAll('.locPlotContainer').remove();

    if(!this.props.allResults.size) return;

    const parseDate = d3.timeParse("%Y-%m-%d");

    var dateDomain = [],
        haveDates = true,
        dates = this.props.allResults.groupBy((row) => row.get('date')),
        data = this.props.results.map((row) => { return {'date': new Date(row.get('date')), 'result':row.get('result'), 'detect':row.get('detect')}})
          .sort((a,b) => a.date < b.date ? 1 : -1).toJS(),
        allData = this.props.allResults.map((row) => { return {'date': new Date(row.get('date')), 'result':row.get('result'), 'detect':row.get('detect')}})
          .sort((a,b) => a.date < b.date ? 1 : -1).toJS();

    var svg = d3.select(this.refs.locPlot);
    var plotContainer = svg.append('g')
      .attr('class','locPlotContainer')
      .attr('transform', "translate(0,20)")
      .attr('style',"width:"+this.svgWidth-2*this.svgMargin +"px")

    plotContainer.append("text")
      .style('text-anchor','middle')
      .attr("x", this.svgWidth/2)
      .text("Location: " + this.props.location + " Sample Dates");

    if(dates.size > 1){
      dateDomain = d3.extent(allData, (d) => d.date);
    } else {
      if (this.props.allResults.get(0).get('date') == null){ //NO DATES PROVIDED

        plotContainer.append("text")
          .style('text-anchor','middle')
          .attr("x", this.svgWidth/2)
          .attr("y", this.svgHeight/2 - 100)
          .text("*No reported sample dates.")

        haveDates = false;
        dateDomain = [0, 0];

      } else {
        var year = this.props.allResults.get(0).get('date').split('-')[0]
        var beg = parseDate([year,1,1].join('-'))
        var end = parseDate([year,12,31].join('-'))
        dateDomain = [beg, end];
      }
    }

    var x = d3.scaleTime()
      .domain(dateDomain)
      .range([0, this.plotWidth])
      .nice();

    var xAxisMonth = d3.axisBottom(x)
      .tickFormat(d3.timeFormat('%b'))
      .ticks(5);

    var xAxisYear = d3.axisBottom(x)
      .tickFormat(d3.timeFormat("'%y"))
      .ticks(5);


    var resultMin = 0;
    var yDomain;
    //The lowest result (can be negative!?) or 0
    if(this.props.type === 2) { //wl
      resultMin = this.props.log ? Math.log(1200) : 1200
      yDomain = this.props.log ? [Math.log(2000), resultMin] : [2000, resultMin]
    } else {
      resultMin = this.props.log ? d3.min([0, d3.min(allData, d => Math.log(d.result))]) : d3.min([0, d3.min(allData, d => d.result)])
      yDomain = this.props.log ? [d3.max(allData, (d) => Math.log(d.result)) * 1.1, resultMin] : [d3.max(allData, (d) => d.result) * 1.1, resultMin]
    }

    var y = d3.scaleLinear()
      .domain(yDomain)
      .range([0, this.plotHeight])
      .nice();

    var yAxis = d3.axisLeft(y)
      .tickFormat((d) => this.props.log ? d3.format(".2s")(Math.exp(d)) : (this.props.type === 2 ? d : d3.format(".2s")(d)));

    var valueline = d3.line()
      .x(function(d) { return x(d.date); })
      .y((d) => this.props.log ? y(Math.log(d.result)) : y(d.result));

    var plot = plotContainer.append('g')
      .attr('class','locPlotG')
      .attr('transform', "translate("+this.yAxisWidth+",10)")
      .attr('style', "width:" + this.plotWidth + "px")

    plot.append('g')
      .append('path')
        .attr('class','locPath')
        .attr('d', valueline(allData))

    var tip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html((d) => d.detect + ' ' + d3.format(',')(d.result) + " " + unit.get('filter'))

    svg.call(tip);

    plot.selectAll('.noDot')
      .data(allData)
      .enter().append('circle')
      .attr('class','noDot')
      .attr('r', 2.6)
      .style('fill', '#ccc')
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))

    plot.selectAll('.plotDot')
      .data(data)
      .enter().append('circle')
      .attr('class','plotDot')
      .attr('r', (d) => d.detect === '<' ? 2.6 : 2.6) //TODO Make legend for detect/nondetect
      .style('fill', styles.selectedColor)
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))

    plot.selectAll('.plotDotBuffer')
      .data(data)
      .enter().append('circle')
      .attr('class','plotDotBuffer')
      .attr('r', 8)
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => this.props.log ? y(Math.log(d.result)) : y(d.result))
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide)

    var xAxisCon = plot.append("g")
      .attr('class',"plotXAxis")
      .attr('transform', "translate(0," + this.plotHeight + ")")

    if(haveDates){
     xAxisCon.append("g")
      .call(xAxisMonth)

     xAxisCon.append("g")
      .attr('transform', "translate(0,12)")
      .attr('class','xAxisYear')
      .call(xAxisYear)
    }

    plotContainer.append("g")
      .attr('class',"plotYAxis")
      .attr('transform', "translate("+this.yAxisWidth+",10)")
      .call(yAxis)

    plotContainer.append("text")
      .attr('transform',"translate(10,"+ (this.plotHeight/2 + this.svgMargin) +") rotate(-90)")
      .text(unit.get('filter'));
  }

  render(){
    return (
      <svg
        className="locPlot"
        ref="locPlot"
        style={{width:this.svgWidth, height:this.svgHeight, padding:"0 " + this.svgMargin}}
      >
      </svg>
    )
  }
}

export default LocPlot;
