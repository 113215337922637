import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
//import numeral from 'numeral';
//import * as validate from "validate.js";
import { fetchData, setFetchingText, clearErrorMessage, calculatingData, doneCalculatingData } from '../../actions';
//import ReactLoading from 'react-loading';
import {Table, Column, Cell} from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css'
//import {dateFilterDataTable, locationFilterDataTable, resultFilterDataTable, selectDataTableFilter, setDateFilter} from '../../actions/dataView.js';
import { resizeTableModal, toggleTableModal } from '../../actions/dataView.js';
import Modal from '../extras/Modal';
import SummaryTableView from './SummaryTableView';
import '../../css/dataView.css'
import table_styles from '../../css/dataView.css';
//import Select from '../../react-select-2/src_no_flow';
import fileDownload from 'js-file-download';
import json2csv from 'json2csv';


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
/*validate.extend(validate.validators.datetime, {
  parse: function(value, options) {
    return moment(value, ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM/DD/YY', 'MM-DD-YY', 'YYYY-MM-DD', 'YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'll']);
  },
  format: function(value, options) {
    var format = options.dateOnly ? "ll" : "YYYY-MM-DD hh:mm:ss";
    return moment(value, ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM/DD/YY', 'MM-DD-YY', 'YYYY-MM-DD', 'YYYY', 'MMM DD, YYYY', 'MMMM DD, YYYY', 'll']).format(format);
  }
});

var constraints = {
  lowerdate: {
    datetime: {
      dateOnly: true,
      message: "This must be formatted as a date."
    }
  },
  upperdate: {
    datetime: {
      dateOnly: true,
      message: "This must be formatted as a date."
    }
  }
}*/


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {  //color: '#29abe0', fontWeight: 'bold'
    var {onSortChange, sortDir, children, ...props} = this.props;
    return (
      <Cell {...props}>
        <div style={{cursor: 'pointer', textAlign: 'center'}} onClick={this._onSortChange}>
          {children} {sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}
        </div>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

class DataListWrapper {
  constructor(indexMap, data) {
    this._indexMap = indexMap;
    this._data = data;
  }

  getSize() {
    return this._indexMap.length;
    //return this._indexMap.size;
  }

  getObjectAt(index) {
    //return this._data.getObjectAt(
    //  this._indexMap[index],
    //);
    return this._data.getIn([this._indexMap[index]])
  }
}

const TextCell = ({data, col, rowIndex, ...props}) => (
  <Cell {...props}>
    {data._data.getIn([data._indexMap[rowIndex],col])}
  </Cell>
)

/*const DateCell = ({data, rowIndex, columnKey, ...props}) => (
  <Cell {...props}>
    {data.getObjectAt(rowIndex)[columnKey].toLocaleString()}
  </Cell>
);*/

/*const TooltipCell = ({data, rowIndex, columnKey, ...props}) => (
  const value = data.getObjectAt(rowIndex)[columnKey];
  <Cell
    {...props}
    onMouseEnter={() => { ReactTooltip.show(); }}
    onMouseLeave={() => { ReactTooltip.hide(); }}>
    <div ref='valueDiv' data-tip={value}>
      {value}
    </div>
  </Cell>
);*/

class DataTableViewComp extends Component {

  constructor(props){
    super(props)
    this.onGetDataButtonClick = this.onGetDataButtonClick.bind(this);

    this._data = props.data;

    this._defaultSortIndexes = [];
    var size = this._data.size;
    for (var index = 0; index < size; index++) {
      this._defaultSortIndexes.push(index);
    }

    this.updateTableWidth = this.updateTableWidth.bind(this);

    this._dataList = new DataListWrapper(this._defaultSortIndexes, this._data)

    this.state = {
      analyte: props.analyte,
      filteredData: this._data,
      sortedDataList: this._dataList,
      colSortDirs: {},
      columnWidths: {
        location: 100,
        sample_id: 200,
        result: 100,
        detect: 50,
        unit: 50,
        matrix: 100,
        filtered: 100,
        method: 100,
        sample_date: 100,
        quarter: 50,
        year: 50,
        sample_top_depth: 50,
        sample_bottom_depth: 50,
        sensitivity: 150,
        lat: 70,
        long: 70,
        data_source_type: 200,
        tableWidth: 1000
      },
      modalIsOpen: false,
      modalMessage: ''
    }

    this._onSortChange = this._onSortChange.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

    //this._resetColumns = this._resetColumns.bind(this);

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount(){
    this.setState({"navBarHeight": 62})
    this.setState({"height": window.innerHeight - (62 + 100) })
  }

  componentWillReceiveProps(nextProps) {
    var columnKey = Object.keys(this.state.colSortDirs);

    this._data = nextProps.data;
    //this._data = nextProps.store.get('filteredData');

    this._defaultSortIndexes = [];
    var size = this._data.size;
    for (var index = 0; index < size; index++) {
      this._defaultSortIndexes.push(index);
    }

    if(columnKey.length > 0) {
      var sortIndexes = this._defaultSortIndexes.slice();
      sortIndexes.sort((indexA, indexB) => {
        //var valueA = this._dataList.getObjectAt(indexA)[columnKey];
        //var valueB = this._dataList.getObjectAt(indexB)[columnKey];
        var valueA = this._dataList._data.getIn([indexA, columnKey]);
        var valueB = this._dataList._data.getIn([indexB, columnKey]);
        // numeric sorting
        if(columnKey[0] === 'result' || columnKey[0] === 'sample_bottom_depth') {
          valueA = +valueA;
          valueB = +valueB;
        }
        // date sorting
        if(columnKey[0] === 'sample_date') {
          valueA = moment(valueA, 'll').toDate();
          valueB = moment(valueB, 'll').toDate();
        }
        var sortVal = 0;
        if (valueA > valueB) {
          sortVal = 1;
        }
        if (valueA < valueB) {
          sortVal = -1;
        }
        if (sortVal !== 0 && this.state.colSortDirs[columnKey[0]] === SortTypes.ASC) {
          sortVal = sortVal * -1;
        }
        return sortVal;
      });

      this._dataList = new DataListWrapper(sortIndexes, this._data)
    } else {
      this._dataList = new DataListWrapper(this._defaultSortIndexes, this._data)
    }

    this.setState({
      //analyte: nextProps.analyte,
      filteredData: this._data,
      sortedDataList: this._dataList
    });

    if(nextProps.errorMessage) {
      this.setState({modalMessage: nextProps.errorMessage });
      this.setState({modalIsOpen: true});
    }
  }

  /**
  * Add event listener to resized fixed width table
  */
  componentDidMount() {
    this.updateTableWidth();
    window.addEventListener("resize", this.updateTableWidth);
  }

  /**
  * Remove event listener to resized fixed width table
  */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateTableWidth);
  }

  /*_resetColumns() {
    this.setState({
      columnOrder: Object.keys(columnTitles)
    });
  }*/

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    this.setState(({columnWidths}) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newColumnWidth,
      }
    }));
  }

  _onSortChange(columnKey, sortDir) {
    var sortIndexes = this._defaultSortIndexes.slice();
    sortIndexes.sort((indexA, indexB) => {
      //var valueA = this._dataList.getObjectAt(indexA)[columnKey];
      //var valueB = this._dataList.getObjectAt(indexB)[columnKey];
      var valueA = this._dataList._data.getIn([indexA, columnKey]);
      var valueB = this._dataList._data.getIn([indexB, columnKey]);
      // numeric sorting
      if(columnKey === 'result' || columnKey === 'sample_bottom_depth') {
        valueA = +valueA;
        valueB = +valueB;
      }
      // date sorting
      if(columnKey === 'sample_date') {
        valueA = moment(valueA, 'll').toDate();
        valueB = moment(valueB, 'll').toDate();
      }
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }
      if (sortVal !== 0 && sortDir === SortTypes.ASC) {
        sortVal = sortVal * -1;
      }
      return sortVal;
    });

    this.setState({
      sortedDataList: new DataListWrapper(sortIndexes, this._data),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  updateTableWidth() {
    //if(window.innerWidth < 500) {
    //  this.setState({ tableWidth: 500 });
    //} else {
    this.setState({ tableWidth: document.getElementById("dataTable").clientWidth });
    //}
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  downloadDataView() {
    if(this.props.data.size > 0){
      if(this.props.data.size < 10000){
        //this.props.dispatch(setFetchingText("Downloading CSV..."))
        //this.props.dispatch(showLoading())
        var fields = Object.keys(this.props.data.get(0).toJS());
        var csv_data = json2csv({ data: this.props.data.toJS(), fields: fields});
        var randNum = this.generateRandomNumber(3)
        fileDownload(csv_data, this.props.activeQuery.get('name') + '_' + this.props.activeQuery.get('id') + '_' + this.props.analyte + '_DataTable' + randNum + '.csv')
        //this.props.dispatch(hideLoading())
      } else {

      }
    }
  }

  generateRandomNumber(n) {
    var add = 1, max = 12 - add;
    max        = Math.pow(10, n+add);
    var min    = max/10; // Math.pow(10, n) basically
    var number = Math.floor( Math.random() * (max - min + 1) ) + min;
    return ("" + number).substring(add);
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#222';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.props.dispatch(clearErrorMessage())
  }

  resizeTableModal(table,width,height){
    this.props.dispatch(resizeTableModal(table,width,height))
  }

  toggleTableModal(table){
    if(!this.props.showSummaryTable) {
      //console.log('here');
      this.props.dispatch(setFetchingText("Calculating..."));
      this.props.dispatch(calculatingData());
      this.props.dispatch(toggleTableModal(table));
      this.props.dispatch(doneCalculatingData());
    } else {
      this.props.dispatch(toggleTableModal(table));
    }
  }

  onGetDataButtonClick() {
    this.props.dispatch(fetchData('query'))
  }

  selectAnalyte(e){
    //let value = e.target.value;
    let value = e ? e.value : "";

    if(value !== this.props.analyte && value !== "") {
      this.setState({'analyte': value});
      var analyte_index = 0;
      this.props.analytes.forEach((a, a_index) => {
        if(a.get('value') === e.value) {
          analyte_index = a_index;
          return;
        }
      });
      this.props.dispatch(setFetchingText("Loading Analyte: " + value))
      this.props.dispatch(fetchData(this.props.selectedQuery, analyte_index))
    }
  }

  render() {

    //const isFetching = this.props.isFetching;

    var data = this.state.sortedDataList;

    var colSortDirs = this.state.colSortDirs;

    var columnWidths = this.state.columnWidths;

    //var analyteSelect = [];
    //var analyteOptions = [];
    var groupedOptions = [];

    var analyteGroups = this.props.analytes.groupBy(x => x.get('subtype')).keySeq().toArray();

    var analytes = this.props.analytes;
    analyteGroups.forEach(function(group) {
      groupedOptions.push({ label: group, options: analytes.filter(row => row.get('subtype') === group).toJS() })
      /*analyteOptions = analytes.filter(row => row.get('subtype') === group).map((row) => (
        <option key={row.get('value')} value={row.get('value')}>{row.get('label')}</option>
      ));
      analyteSelect.push(
        <optgroup key={group} label={group}>
          {analyteOptions}
        </optgroup>
      )*/
    })

    //var analytes = this.props.analytes.map((row) => (
    //  <option key={row.get('value')} value={row.get('value')}>{row.get('label')}</option>
    //))
    var analyte_type = null;
    var headerHeight = 37
    if(analytes.size > 0) {
      analyte_type = analytes.filter(row => row.get('value') === this.props.analyte).toJS()[0]['type']
    }
    var includedColumnsInOrder = []
    if(analyte_type === 'Rad') {
      //headerHeight = 55;
      includedColumnsInOrder = ['location','sample_id','sample','result','uncertainty','unit','matrix','sample_date','sample_bottom_depth'];
    } else if(analyte_type === 'Asbestos') {
      //headerHeight = 55;
      includedColumnsInOrder = ['location','sample_id','sample','result','sensitivity','matrix','method','sample_date','sample_bottom_depth'];
    } else {
      includedColumnsInOrder = ['location','sample_id','sample','result','detect','unit','matrix','filtered','method','sample_date','sample_bottom_depth'];
    }

    var allColumnsInfo = {
      location: { title: 'Location', flexGrow: 1, width: 100, fixed: true, align: 'left', isResizable: false },
      sample_id: { title: 'Sample ID', flexGrow: 1, width: columnWidths.sample_id, fixed: false, align: 'center', isResizable: true },
      sample: { title: 'Type', flexGrow: 1, width: 50, fixed: false, align: 'center', isResizable: false },
      result: { title: 'Result', flexGrow: 1, width: 100, fixed: false, align: 'right', isResizable: false },
      detect: { title: 'Detect', flexGrow: 1, width: 50, fixed: false, align: 'center', isResizable: false },
      //detect_ra: { title: 'Detect', flexGrow: 1, width: 50, fixed: false, align: 'center', isResizable: false },
      unit: { title: 'Unit', flexGrow: 1, width: 50, fixed: false, align: 'center', isResizable: false },
      matrix: { title: 'Matrix', flexGrow: 1, width: 100, fixed: false, align: 'center', isResizable: false },
      filtered: { title: 'Total/Dissolved', flexGrow: 1, width: 80, fixed: false, align: 'center', isResizable: false },
      method: { title: 'Method', flexGrow: 1, width: columnWidths.method, fixed: false, align: 'center', isResizable: true },
      sample_date: { title: 'Sample Date', flexGrow: 1, width: 100, fixed: false, align: 'center', isResizable: false },
      quarter: { title: 'Quarter', flexGrow: 1, width: 50, fixed: false, align: 'center', isResizable: false },
      year: { title: 'Year', flexGrow: 1, width: 50, fixed: false, align: 'center', isResizable: false },
      sample_top_depth: { title: 'Top Depth (ft)', flexGrow: 1, width: 70, fixed: false, align: 'right', isResizable: false },
      sample_bottom_depth: { title: 'Depth (ft)', flexGrow: 1, width: 70, fixed: false, align: 'right', isResizable: false },
      sensitivity: { title: 'Analytical Sensitivity', flexGrow: 1, width: columnWidths.sensitivity, fixed: false, align: 'right', isResizable: true },
      uncertainty: { title: 'Uncertainty', flexGrow: 1, width: 100, fixed: false, align: 'right', isResizable: true },
      lat: { title: 'Lat.', flexGrow: 1, width: 70, fixed: false, align: 'right', isResizable: false },
      long: { title: 'Long.', flexGrow: 1, width: 70, fixed: false, align: 'right', isResizable: false },
      data_source_type : { title: 'Data Source', flexGrow: 1, width: columnWidths.data_source_type, fixed: false, align: 'center', isResizable: true }
    }

    var tableWidth = this.state.tableWidth || 1000;

    /* --- SUMMARY STATS  --- **/
    var summaryTable = (<SummaryTableView height={this.props.store.get('summaryTableSize')[1]} width={this.props.store.get('summaryTableSize')[0]} analyte_type={analyte_type} />)

    var summaryTableModal = this.props.showSummaryTable ? (<Modal
      title=''
      body={summaryTable}
      overflow='scroll'
      width={this.props.store.get('summaryTableSize')[0]}
      height={this.props.store.get('summaryTableSize')[1]}
      onClose={this.toggleTableModal.bind(this, 'summary')}
      onResize={this.resizeTableModal.bind(this, 'summary')}/>
    ) : null;
    /* --- END SUMMARY STATS--- **/
//<input  className='form-control' id='dateFilter' placeholder='Date' onChange={this.dateFilter.bind(this)}/>
//
//   basic select works as well: <select className="form-control" id="select" value={this.props.analyte} onChange={this.selectAnalyte.bind(this)}>{analyteSelect}</select>
    var me = this;
    let dataSize = data.getSize()
    return (

      <div className='row' id="dataTable">
        {summaryTableModal}
        {/* cut old filters -- see at bottom */}
        <div className='col-md-12' style={{height: '59px'}}>
          <div id="dataFilterTitle" className='col-sm-3 pull-left'>
            <h3>Data: {dataSize} rows</h3>
          </div>
          <div className='col-sm-6 pull-right' id='summaryButton' style={{paddingTop: '7px'}}>
            <button className='btn btn-info' type="button" onClick={this.downloadDataView.bind(this)}>Download Table</button>
            <button className={this.props.store.get('showSummaryTable') ? "btn btn-info plot-toggle toggled-on" : 'btn btn-info plot-toggle'} type="button" onClick={this.toggleTableModal.bind(this, 'summary')}>Summary Table</button>
          </div>
        </div>
        <Table
          rowHeight={35}
          headerHeight={headerHeight}
          rowsCount={dataSize}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          isColumnResizing={false}
          width={tableWidth}
          maxHeight={this.props.template.compHeight - table_styles.topSpace}
          style={{padding:'0 10px'}}
          {...this.props}
        >
        {includedColumnsInOrder.map(function (columnKey, i) {
          return <Column
            columnKey={columnKey}
            key={i}
            header={<SortHeaderCell
               onSortChange={me._onSortChange}
               sortDir={colSortDirs[columnKey]}>
                  {allColumnsInfo[columnKey].title}
               </SortHeaderCell>}
            cell={<TextCell data={data} col={columnKey}/>}
            align={allColumnsInfo[columnKey].align}
            flexGrow={allColumnsInfo[columnKey].flexGrow}
            width={allColumnsInfo[columnKey].width}
            fixed={allColumnsInfo[columnKey].fixed}
            isResizable={allColumnsInfo[columnKey].isResizable}
          />;
        })}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.DataView,
    isFetching: state.isFetching,
    selectedQuery : state.MapApp.get('selectedQuery'),
    analytes : state.MapApp.get('analytes'),
    analyte : state.MapApp.get('analyte'),
    data: state.MapApp.get('filteredData'),
    showSummaryTable: state.MapApp.get('showSummaryTable'),
    errorMessage: state.MapApp.get('errorMessage'),
    activeQuery: state.QueryApp.get('activeQuery'),
    template:state.Template
  }
}

const DataTableView = connect(
  mapStateToProps
)(DataTableViewComp)

export default DataTableView;
