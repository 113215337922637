import { fromJS, List, Map } from 'immutable';
import { calcStats, getAnalytes, locResults, locStaticResults } from './mapHelper';
//import { createPiperData } from './piperHelper'
import pip from '@turf/boolean-point-in-polygon';
import { multiPolygon as turf_multiPolygon, point as turf_point, featureCollection as turf_featureCollection  } from '@turf/helpers';
import turf_interpolate from '@turf/interpolate';
import shortid from 'shortid';
import numeral from 'numeral';
import moment from 'moment';
import * as d3 from 'd3';
import * as ss from 'simple-statistics';
//import InterpolationWorker from '../components/MapView/interpolation.worker.js';

/*
 *
 * applyFilters
 * Takes a state with state.analyteData and state.activeFilters set and sets
 * state.filteredData
 *
 */
const applyFilters = (state, action) => {
  var filteredData,
      filteredDataTable,
      filteredLocations,
      activeFilters;

  var t1 = performance.now();
  activeFilters = state.get('activeFilters');

  // add leaflet draw polygon filters if applicable
  var polygon = [];
  var polygons = [];
  if(state.get('drawnPolygons').size > 0) {
    activeFilters = activeFilters.set('polygons', Map({type: 'spatial'}));
    state.get('drawnPolygons').forEach(shape => {
      polygon = [];
      shape.get('coords').first().forEach(function(element, index, array) {
        polygon.push([element.lng, element.lat])
      })
      polygons.push([polygon]);
    })
    var multiPoly = turf_multiPolygon(polygons);
  }

  var pt;
  if(activeFilters.size){

    filteredData = state.get('analyteData').filter( (data_point) => {
        var include = true;
        activeFilters.forEach( (activeFilter, field) => {
          switch(activeFilter.get('type')){
              //discrete filters are listed in an array, if our value isn't in the array, don't include it.
              case 'discrete':
                if(activeFilter.get('filters').indexOf(data_point.get(field)) === -1){
                  include = false;
                }
              break;

              //interval filters come with a min and max in an array. If our value is outside that array, don't include it.
              case 'interval':
              if(data_point.get(field) > activeFilter.get('filters').get(0).max() || data_point.get(field) < activeFilter.get('filters').get(0).min()){
                  include = false;
                }
              break;

              case 'spatial':
                pt = turf_point([+data_point.get('long'), +data_point.get('lat')])
                if(!pip(pt, multiPoly)) {
                  include = false;
                }
              break;

              default:
                include = true;
          }
        });
        return include;
    })

  } else { //if no active filters, show all data

    filteredData = state.get('analyteData');

  }

  // Now for the dataView we want to add a field to filtered data:
  var newRow;//, filtered;
  filteredDataTable = filteredData.map(row => {
    //filtered = row.get('filtered') === 'Y' ? 'Dissolved' : (row.get('filtered') === 'N' ? 'Total' : 'Null');
    newRow = row.set('matrix_fraction', (row.get('matrix') === 'Ground Water' || row.get('matrix') === 'Surface Water' || row.get('matrix') === 'Water') ?
      row.get('matrix') + ' (' + row.get('filtered') + ') ' + row.get('unit') :
      row.get('matrix') + ' ' + row.get('unit')
    );//.set('fraction', filtered);
    return newRow;
  })

  //Check if data CAN be logged (i.e. no negatives)
  var canLog = true;
  var negCount = filteredData.filter(row => +row.get('result') < 0).size;
  if(negCount > 0) {
    canLog = false;
  }

  // Now we need to create grouped state items for boxplots
  var boxData = fromJS({'All Data':[], 'filtered':[],'detect':[],'sample':[],'method':[],'year':[], 'hydro': [], 'litho': []});//,'sample_bottom_depth':[]});
  var boxes = ['filtered','detect','sample','method','year','hydro','litho'], tmp, keys, results;

  let allValues = filteredData.map(row => +row.get('result'))
  boxData = boxData.set('All Data',fromJS([['All Data', allValues]]))

  function createKeyData(box) {
    return filteredData.groupBy(m => m.get(boxes[box]));
  }

  function setBoxData(keys) {
    keys.forEach(k => { //
      results = tmp.get(k).map(x => +x.get('result')).toJS();
      boxData = boxData.set(boxes[i], boxData.get(boxes[i]).push(List([k, results])));
    });
  }

  for (var i = 0, len = boxes.length; i < len; i++) {
    tmp = createKeyData(i)
    keys = tmp.keySeq();
    setBoxData(keys);
  }

  var t2 = performance.now();
  console.log("time to filter data: " + (t2 - t1));

  // Calculate the stats.
  var dataStats = calcStats(filteredData);

  // Attach dataStats above to locations, set visible=false if no stats for that location (i.e. no data);
  filteredLocations = state.get('analyteLocations').map( (location) => {

    let locationId = location.get('location');
    let stats = dataStats.get(locationId);
    if(stats) {
      return location.set('visible', true).merge(stats);
    } else {
      return location.set('visible', false);
    }
  })

  var filteredState;
  if(!state.get('showSummaryTable')) {
    filteredState = state.set('filteredData', filteredDataTable).set('filteredLocations', filteredLocations).set('boxData', boxData).set('hasNegatives', !canLog);
  } else {
    var summaryData = makeSummaryTableData(filteredDataTable);
    filteredState = state.set('filteredData', filteredDataTable).set('filteredLocations', filteredLocations).set('boxData', boxData).set('filteredSummaryTableData', summaryData).set('hasNegatives', !canLog);
  }
  /*var orderedDataState;
  if(logData) {
    orderedDataState = filteredState.set('allResults', filteredDataLogged.map( x => +x.get('result')).sort((a,b) => a - b));
  } else {
    orderedDataState = filteredState.set('allResults', filteredData.map( x => +x.get('result')).sort((a,b) => a - b));
  }*/
  var highlightedState = highlightLocations(filteredState, action);
  var withActiveFields = setActiveFields(highlightedState, action);
  var withPlotData = createPlotData(withActiveFields, action);

  var t3 = performance.now();
  console.log("time to calculate stats: " + (t3 - t2));

  if(state.get('selectedLoc') === null) {
    return withPlotData;
  } else {
    return locResults(withPlotData, action);
  }

}

/*
 *
 * create data for sampling frequency plot ... get distinct locations in each quarter
 *
 */
const createPlotData = (state, action) => {
    return state.set('frequencyData', state.get('filteredData').groupBy(x => x.get('quarter')).map(items => items.groupBy(x => x.get('location')).keySeq().toSet()))
}

/*
 *
 * create Summary Table data from 'filteredData' for DataView
 *
 */
const makeSummaryTableData = (data) => {
  var t1 = performance.now();
  var summary, group, item, subset, locations;
  var groupData = [];
  var newData = [];
  var groups = data.groupBy(x => x.get('matrix_fraction')).keySeq();
  var rows = groups.size;
  groups.forEach(x => {
    group = data.filter(c => c.get('matrix_fraction') === x)
    summary = {
      'matrix_fraction': x,
      'samples': group.size,
      'sum': group.map(r => parseInt(r.get('result'),10)).sum,
      'detects': group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).size,
      'detect_freq': group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).size / group.size,
      'min_sample_date': group.map(r => moment(r.get('sample_date'), 'll').toDate()).min(),
      'max_sample_date': group.map(r => moment(r.get('sample_date'), 'll').toDate()).max(),
      'min_detect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).min() : '',
      '25_detect' : (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? ss.quantile( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS(), 0.25 ) : '',
      'median_detect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? d3.median( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
      'mean_detect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? d3.mean( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
      '75_detect' : (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? ss.quantile( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS(), 0.75 ) : '',
      'max_detect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).max() : '',
      'sd_detect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? ss.standardDeviation( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
      'nondetects': group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).size,
      'min_nondetect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).min() : '',
      '25_nondetect' : (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? ss.quantile( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS(), 0.25 ) : '',
      'median_nondetect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? d3.median( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
      'mean_nondetect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? d3.mean( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
      '75_nondetect' : (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? ss.quantile( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS(), 0.75 ) : '',
      'max_nondetect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).max() : '',
      'sd_nondetect': (group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? ss.standardDeviation( group.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS() ) : ''
    }
    groupData.push(summary)

    locations = data.filter(row => row.get('matrix_fraction') === x).groupBy(y => y.get('location')).keySeq();
    locations.forEach(l => {
      subset = data.filter(z => z.get('matrix_fraction') === x && z.get('location') === l)  //TODO include data here??
      item = {
        'matrix_fraction': x,
        'location': l,
        'samples': subset.size,
        'sum': subset.map(r => parseInt(r.get('result'),10)).sum,
        'detects': subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).size,
        'detect_freq': subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).size / subset.size,
        'min_sample_date': subset.map(r => moment(r.get('sample_date'), 'll').toDate()).min(),
        'max_sample_date': subset.map(r => moment(r.get('sample_date'), 'll').toDate()).max(),
        'min_detect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).min() : '',
        '25_detect' : (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? ss.quantile( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS(), 0.25 ) : '',
        'median_detect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? d3.median( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
        'mean_detect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? d3.mean( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
        '75_detect' : (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? ss.quantile( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS(), 0.75 ) : '',
        'max_detect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).max() : '',
        'sd_detect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).size > 0) ? ss.standardDeviation( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) < 0).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
        'nondetects': subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).size,
        'min_nondetect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).min() : '',
        '25_nondetect' : (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? ss.quantile( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS(), 0.25 ) : '',
        'median_nondetect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? d3.median( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
        'mean_nondetect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? d3.mean( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS() ) : '',
        '75_nondetect' : (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? ss.quantile( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS(), 0.75 ) : '',
        'max_nondetect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).max() : '',
        'sd_nondetect': (subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).size > 0) ? ss.standardDeviation( subset.filter(r => ['F','U','N'].indexOf(r.get('detect')) > -1).map(r => parseFloat(r.get('result'),10)).toJS() ) : ''
      }
      newData.push(item)

      return l;
    })

    return x;
  })

  var t2 = performance.now();
  console.log("time to calculate summary table data: " + (t2 - t1));
  //return [newData, rows, groupData];
  return fromJS([newData, rows, groupData]);
}


/*
 *
 * get Depth for the selected matrix
 *
 */
const getDepthExtent = (state, activeMatrix, action) => {
  var allDataForMatrix,
      minDepth,
      maxDepth;

  allDataForMatrix = activeMatrix ? state.get('rawData').filter(x => x.get('matrix') === activeMatrix) : state.get('rawData');

  minDepth = allDataForMatrix.map(x => +x.get('sample_bottom_depth')).min();
  maxDepth = allDataForMatrix.map(x => +x.get('sample_bottom_depth')).max();

  return [minDepth, maxDepth]
}

/*
 *
 * applyAnalyte
 * Takes a state with a state.analyte and state.rawData and applys state.analyteData
 * and assocated stats.
 *
 */
const applyAnalyte = (state, action) => {

  let analyteData = state.get('rawData').filter( (row) => {
    return row.get('analyte') === state.get('analyte');
  });

  var new_data_point;
  let analyteDataFormattedResult = analyteData.map( (data_point) => {
    new_data_point = data_point.set('result', +numeral(+data_point.get('result')).format('0[.]00[00]'))
    return new_data_point;
  });

  let listOfAnalyteLocs = analyteDataFormattedResult.map( (data_point) => {
    return data_point.get('location');
  });

  var analyteLocations = state.get('rawLocations').filter( (location) => {
    return listOfAnalyteLocs.indexOf(location.get('location')) !== -1
  });

  var results = analyteData.map( (row) => row.get('result') )
    .filter((row) => row !== null)
  var max = results.map((row) => parseFloat(row)).max();
  var min = results.map((row) => parseFloat(row)).min();

  var locStats = calcStats(analyteData);
  var maxMean = locStats.map( (row) => row.get('mean'))
    .filter((mean) => mean !== null).max();
  var maxMedian = locStats.map( (row) => row.get('median'))
    .filter((median) => median !== null).max();
  var maxSampleSize = locStats.map( (row) => row.get('sampleSize'))
    .filter((sampleSize) => sampleSize !== null).max();

  return  state.set("analyteData", analyteDataFormattedResult)
     .set("analyteLocations", analyteLocations)
     .set("analyteMax", max)
     .set("analyteMin", min)
     .set("analyteMean", maxMean)
     .set("analyteMedian", maxMedian)
     .set("analyteSampleSize", maxSampleSize);
}

/* * * * * * * * * Discrete Filters Reducer * * * * * * * * */
//TODO? put active filters and intervals into here.
const filters = (state, action) => {
  switch(action.type){

/* * * * * * * * * FILTERS RECEIVE DATA * * * * * * * * */
    case "RECEIVE_DATA":
      /* set our year vars in filter fields */
      let data = state.get('analyteData');
      let defaultUnit = state.get('analyteData').get(0).get('unit');
      let defaultMatrix = state.get('analyteData').get(0).get('matrix');

      let depthExtent = getDepthExtent(state, defaultMatrix, action);

      let filterFields = state.get('filterFields').map((field) =>{
        if(field.get('column') === "year") {
          return field.set('extent',List([state.get('yearMin'), state.get('yearMax')]))
            .set('value',List([state.get('yearMin'), state.get('yearMax')]))
            .set('brush',List())
        } else if(field.get('column') === "sample_bottom_depth") {
          return field.set('extent',List([depthExtent[0], depthExtent[1]]))
            .set('value',List([depthExtent[0], depthExtent[1]]))
            .set('brush',List())
        } else if(field.get('column') === "result") {
          return field.set('extent',List([state.get('resultMin'), state.get('resultMax')]))
              .set('value',List([state.get('resultMin'), state.get('resultMax')]))
              .set('brush',List())
        } else {
          return field
        }
      })

      let filterList = [];
      var i = 0;
      filterFields.forEach( (field) => {
        switch(field.get('type')){
          case "discrete":
          var grouped = data.groupBy( (value) => {
               return value.get(field.get('column'));
            }).keySeq().sort().map( (value) => {
              //if(field.get('column') === 'filtered' && value === 'Y') value = 'Dissolved';
              //if(field.get('column') === 'filtered' && value === 'N') value = 'Total';
              return Map({"filter": value, "field": field.get('column'), "active":false, "id": i, "field_id": field.get('id'),"type":field.get('type'),"single":field.get('single'), "visible": true})
            });//.sortBy(value => value.get('filter'));
            grouped.forEach( (value) => {
              i++
              filterList.push(value);
            })
          break
          case "interval":
            //let extent = field.get('extent').toJS()
            //if(extent[0] !== extent[1]){
              filterList.push(Map({"filter":field.get('value'), "field":field.get('column'), "active":false, "id":i, "field_id": field.get('id'),"type":field.get('type'),"single":field.get('single'),"visible":false}));
              i++;
            //} else {
            //  filterList.push(Map({"filter":field.get('value'), "field":field.get('column'), "active":false, "id":i, "field_id": field.get('id'),"type":field.get('type'),"single":field.get('single'),"visible":false}));
            //  i++;
            //}
          break
          //case 'spatial':
          //break
          default:
        };
      });

      //set all filters with only one option to visible: false
      //AND if they are type 'single' : true set first field's 'active' to true.  --- not programmatical yet....
      var newFilterList = List();
      filterList = List(filterList);
      newFilterList = filterList.map(filter =>
        {
          if(filterList.filter(item => item.get('field') === filter.get('field')).size < 2) {
            return filter.update(
              'visible',
              visible => false
            )
          } else {
            return filter;
          }
        }
      ).update(filterList.findIndex(item => item.get('field') === 'matrix' && item.get('filter') === defaultMatrix), item => item.set('active', true)
      ).update(filterList.findIndex(item => item.get('field') === 'unit' && item.get('filter') === defaultUnit), item => item.set('active', true));

      let fieldsToExpand = newFilterList.filter(row => row.get('active')).map(row => row.get('field'))

      let newFilterFields = filterFields.map(row =>
        row.set('visible', fieldsToExpand.includes(row.get('column')))
          .set('activeFilters', fieldsToExpand.includes(row.get('column')))
        )

      return state.set('filters', newFilterList).set('filterFields', newFilterFields);
    default:
      return state;
  }
}


/* * * * * * * * * Interval Filters Reducer * * * * * * * * */
const interval = (state = List(), action) => {
  switch(action.type){
      case"UPDATE_SLIDER":
        return state.setIn(['filterFields',action.field_id, "value"], List(action.value))
          .setIn(['filterFields',action.field_id,"brush"], List(action.brush))
          .setIn(['filters',action.filter_id,"active"], action.active)
          .setIn(['filters',action.filter_id,"filter"], action.value);
    default:
      return state;
  }
}

/* * * * * * * * * Active Filters Reducer * * * * * * * * */
const setActiveFilters = (state, action) => {
  var  newActiveFilters = state.get('filters').filter((value) => {
        return value.get('active') === true;
      }).groupBy( (value) => {
        return value.get('field');
      }).map( (value) => {
        let type = state.get('filterFields').get(value.get(0).get('field_id')).get('type');
        return Map({'filters' : value.map( (row) => row.get('filter')), 'type' : type});
      });

  var newState = state.set('activeFilters', newActiveFilters);
  return newState;
}

/* * * * * * * * * Set Active Filter Fields * * * * * * * * */
const setActiveFields = (state, action) => {
  let newFilterFields = state.get('filterFields').map(row =>
    row.set('activeFilters', state.get('activeFilters').has(row.get('column')))
  )

  return state.set('filterFields',newFilterFields)
}

/* * * * * * * * * Highlight Locations * * * * * * * * */

const highlightLocations = (state, action) => {
  var t1 = performance.now();
  var coloredLocations,
      histogramSelection = state.get('histogramSelection'),
      locations = state.get('filteredLocations'),
    selectedStat = state.get('radius') === 'location' ? 'max' : state.get('radius'),
    maxVal = locations.map((row) => row.get(selectedStat)).filter((row) => row !== null && row !== undefined).max();

  if(histogramSelection !== null || histogramSelection.length > 0){
      /*
    let locationsInInterval = state.get('filteredData').groupBy( (row) => row.get('location')).filter( (rows) => {
      let results = rows.map((row) => row !== null ? parseFloat(row.get('result')) : null)
      let resultsInInterval = results.filter((row) =>  {
          return (row >= histogramSelection[0] && row < histogramSelection[1] ) || (row === state.get('analyteMax') && row === histogramSelection[1]);
      })
      return resultsInInterval.size;
    })

    coloredLocations = state.get('filteredLocations').map((location) => {
      return locationsInInterval.get(location.get('location')) ? location.set('highlight', true) : location.set('highlight', false);
    });
    */
    coloredLocations = state.get('filteredLocations').map((row) => {
      let val = row.get(selectedStat)
      // let inInterval = (val >= histogramSelection[0] && val < histogramSelection[1]) || (val === histogramSelection[1] && val === maxVal);
      let inInterval = histogramSelection.filter(h => (val >= h[0] && val < h[1]) || (val === h[1] && val === maxVal));
      // return row.set('highlight', inInterval)
      return row.set('highlight', inInterval.length > 0);
    })
  } else {
    coloredLocations = state.get('filteredLocations').map((row) => row.set('highlight', false));
  }

  var t2 = performance.now();
  console.log("time to highlight locations: " + (t2 - t1));
  return state.set('filteredLocations', coloredLocations)
}

/* * * * * * * * * Do Spatial Interpolation * * * * * * * * */

const applyInterpolations = (state, action) => {
  console.log('Updating interpolation grid(s) in map reducer ...')
  // Define Results
  let grid = {};
  let contourGrid = {};
  // Define Input Data
  let mapStat = state.get('radius');
  if(state.get('radius') === 'location') {
    mapStat = 'max';
  }
  let turf_pts = state.get('filteredLocations').filter(row => row.get('visible') && parseFloat(row.get('lat'),10) > 0).toJS().map( (loc) => {
    return turf_point([parseFloat(loc['long'], 10), parseFloat(loc['lat'],10)], {zValue: loc[mapStat], samples: loc['sampleSize']})
  });
  let turf_collection = turf_featureCollection(turf_pts);
  // Do any interpolation
  if(state.get('showGrid')) {
    console.log('updating idw grid...')
    grid = turf_interpolate(turf_collection, state.get('gridCellSize'), { gridType: 'square', property: 'zValue', units: 'kilometers', weight: state.get('distanceWeightingFactor') });
  }
  if(state.get('showContours')) {
    console.log('updating contour grid...')
    contourGrid = turf_interpolate(turf_collection, state.get('gridCellSize'), { gridType: 'points', property: 'zValue', units: 'kilometers', weight: state.get('distanceWeightingFactor') });
  }
  return state.set('interpolationGrid', fromJS(grid)).set('contourInterpolationGrid', fromJS(contourGrid));

  // Define Web Worker(s)
  /*const interpolationWorker = new InterpolationWorker();
  const contourInterpolationWorker = new InterpolationWorker();

  // Do any interpolation
  if(state.get('showGrid') && state.get('showContours')) {
    interpolationWorker.postMessage({ event: 'interpolateSurface', gridType: 'square', cellSize: state.get('gridCellSize'),  weight: state.get('distanceWeightingFactor'), mapStat: state.get('radius'), data: state.get('filteredLocations').toJS() });
    interpolationWorker.addEventListener("message", event => {
      grid = fromJS(event.data);
      contourInterpolationWorker.postMessage({ event: 'interpolateSurface', gridType: 'points', cellSize: state.get('gridCellSize'),  weight: state.get('distanceWeightingFactor'), mapStat: state.get('radius'), data: state.get('filteredLocations').toJS() });
      contourInterpolationWorker.addEventListener("message", event => {
        contourGrid = fromJS(event.data);
        return state.set('interpolationGrid', grid).set('contourInterpolationGrid', contourGrid);
      });
    });
  } else if(state.get('showGrid') && !state.get('showContours')) {
    interpolationWorker.postMessage({ event: 'interpolateSurface', gridType: 'square', cellSize: state.get('gridCellSize'),  weight: state.get('distanceWeightingFactor'), mapStat: state.get('radius'), data: state.get('filteredLocations').toJS() });
    interpolationWorker.addEventListener("message", event => {
      grid = fromJS(event.data);
      console.log(grid)
      return state.set('interpolationGrid', grid);
    });
  } else if(state.get('showContours') && !state.get('showGrid')) {
    contourInterpolationWorker.postMessage({ event: 'interpolateSurface', gridType: 'points', cellSize: state.get('gridCellSize'),  weight: state.get('distanceWeightingFactor'), mapStat: state.get('radius'), data: state.get('filteredLocations').toJS() });
    contourInterpolationWorker.addEventListener("message", event => {
      contourGrid = fromJS(event.data);
      return state.set('contourInterpolationGrid', contourGrid)
    });
  } else {
    return state.set('interpolationGrid', grid).set('contourInterpolationGrid', contourGrid);
  }*/
}

const createInterpolationGrid = (state, action) => {
  var mapStat = state.get('radius');
  if(state.get('radius') === 'location') {
    mapStat = 'max';
  }
  var turf_pts = state.get('filteredLocations').filter(row => row.get('visible') && parseFloat(row.get('lat'),10) > 0).toJS().map( (loc) => {
    return turf_point([parseFloat(loc['long'], 10), parseFloat(loc['lat'],10)], {zValue: loc[mapStat], samples: loc['sampleSize']})
  });
  var turf_collection = turf_featureCollection(turf_pts);
  var grid = turf_interpolate(turf_collection, action.gridCellSize, {gridType: 'square', property: 'zValue', units: 'kilometers', weight: action.distanceWeightingFactor});
  return state.set('distanceWeightingFactor', action.distanceWeightingFactor).set('gridCellSize', action.gridCellSize).set('doGrid', true).set("interpolationGrid", fromJS(grid));
}


/* * * * * * * * * MapApp Reducer * * * * * * * * */
// TODO - generalize!!!
let allFilterFields = fromJS({
  'BMI': [
    {"column": "detect", "title": "Detect", "visible": false, "type": "discrete", "single": false, "id":0, "activeFilters":false},
    {"column": "filtered", "title": "Fraction", "visible": false, "type": "discrete", "single": false, "id":1, "activeFilters":false},
    {"column": "matrix", "title": "Matrix", "visible": false, "type": "discrete", "single": true, "id":2, "activeFilters":false},
    {"column": "hydro", "title": "Hydro", "visible": false, "type": "discrete", "single": false, "id":3, "activeFilters":false},
    {"column": "litho", "title": "Litho", "visible": false, "type": "discrete", "single": false, "id":4, "activeFilters":false},
    {"column": "unit", "title": "Units", "visible": false, "type": "discrete", "single": true, "id":5, "activeFilters":false},
    {"column": "sample", "title": "Sample", "visible": false, "type": "discrete", "single": false, "id":6, "activeFilters":false},
    {"column": "method", "title": "Method", "visible": false, "type": "discrete", "single": false, "id":7, "activeFilters":false},
    {"column": "data_source_type", "title": "Data Source", "visible": false, "type": "discrete", "single": false, "id":8, "activeFilters":false},
    {"column": "dvsr_id", "title": "DVSR ID", "visible": false, "type": "discrete", "single": false, "id":9, "activeFilters":false},
    {"column": "year", "title": "Year", "visible": false, "type": "interval", "single": false, "id":10, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false},
    {"column": "sample_bottom_depth", "title": "Depth (ft)", "visible": false, "type": "interval", "single": false, "id":11, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false},
    {"column": "result", "title": "Result", "visible": false, "type": "interval", "single": false, "id":12, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false}
  ],
  'SNWA': [
    {"column": "detect", "title": "Detect", "visible": false, "type": "discrete", "single": false, "id":0, "activeFilters":false},
    {"column": "filtered", "title": "Fraction", "visible": false, "type": "discrete", "single": false, "id":1, "activeFilters":false},
    {"column": "matrix", "title": "Matrix", "visible": false, "type": "discrete", "single": true, "id":2, "activeFilters":false},
    {"column": "unit", "title": "Units", "visible": false, "type": "discrete", "single": true, "id":3, "activeFilters":false},
    {"column": "sample", "title": "Sample", "visible": false, "type": "discrete", "single": false, "id":4, "activeFilters":false},
    {"column": "method", "title": "Method", "visible": false, "type": "discrete", "single": false, "id":5, "activeFilters":false},
    {"column": "data_source_type", "title": "Data Source", "visible": false, "type": "discrete", "single": false, "id":6, "activeFilters":false},
    {"column": "data_submit_type", "title": "Submitted By", "visible": false, "type": "discrete", "single": false, "id":7, "activeFilters":false},
    {"column": "year", "title": "Year", "visible": false, "type": "interval", "single": false, "id":8, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false},
    {"column": "sample_bottom_depth", "title": "Depth (ft)", "visible": false, "type": "interval", "single": false, "id":9, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false},
    {"column": "result", "title": "Result", "visible": false, "type": "interval", "single": false, "id":10, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false}
  ],
  'WL': [
    {"column": "detect", "title": "Detect", "visible": false, "type": "discrete", "single": false, "id":0, "activeFilters":false},
    {"column": "filtered", "title": "Fraction", "visible": false, "type": "discrete", "single": false, "id":1, "activeFilters":false},
    {"column": "matrix", "title": "Matrix", "visible": false, "type": "discrete", "single": true, "id":2, "activeFilters":false},
    {"column": "hydro", "title": "Hydro", "visible": false, "type": "discrete", "single": false, "id":3, "activeFilters":false},
    {"column": "litho", "title": "Litho", "visible": false, "type": "discrete", "single": false, "id":4, "activeFilters":false},
    {"column": "unit", "title": "Units", "visible": false, "type": "discrete", "single": true, "id":5, "activeFilters":false},
    {"column": "sample", "title": "Sample", "visible": false, "type": "discrete", "single": false, "id":6, "activeFilters":false},
    {"column": "method", "title": "Method", "visible": false, "type": "discrete", "single": false, "id":7, "activeFilters":false},
    {"column": "data_source_type", "title": "Data Source", "visible": false, "type": "discrete", "single": false, "id":8, "activeFilters":false},
    {"column": "year", "title": "Year", "visible": false, "type": "interval", "single": false, "id":9, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false},
    {"column": "result", "title": "Result", "visible": false, "type": "interval", "single": false, "id":10, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false}
    //{"column": "sample_bottom_depth", "title": "Depth (ft)", "visible": false, "type": "interval", "single": false, "id":11, "extent":[0,0], "value":[0,0], "brush":[], "activeFilters":false},
  ]
});

var gauges = [{
    id: '94196784',
    name: 'Vegas Valley Dr.',
    datum: 1690,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419753',
    name: 'Three Kids Wash Blw.',
    datum: 1450,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419756',
    name: 'Lake Las Vegas Inlet',
    datum: 1400,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419700',
    name: 'Pabco Rd.',
    datum: 1540,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419696',
    name: 'Duck Ck.',
    datum: 1605,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419679',
    name: 'Las Vegas Wasteway',
    datum: 1640,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419800',
    name: 'LV Wash Blw. Lake Las Vegas',
    datum: 1280,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '94196783',
    name: 'Flamingo Wash Confl.',
    datum: 1710,
    data: null,
    show: false,
    timestamp: null
  },{
    id: '9419745',
    name: 'C-1 Channel Abv. Mouth',
    datum: 1560,
    data: null,
    show: false,
    timestamp: null
  }]

let initialState = Map({
  "rawData" : List(),
  "possibleLeachate": false,
  "rawLocations" : List(),
  "analyteData" : List(),
  "analyteLocations" : List(),
  "analyteMax": 0,
  "analyteMin": 0,
  "analyteMean": 0,
  "analyteMedian": 0,
  "yearMin": 0,
  "yearMax": 0,
  "depthMin": 0,
  "depthMax": 0,
  "filteredData" : List(),
  "filteredSummaryTableData" : List(),
  "filteredLocations" : List(),
  "activeQueryType": null,
  "allFilterFields": allFilterFields,
  "filterFields" : List(),
  "filters": List(),
  "analytes": List(),
  "analyte": "",
  "boxData": Map(),
  "activeFilters": Map(),
  "locResults":List(),
  "locStaticResults":List(),
  //"allResults": List(),
  "selectedQuery": 0,
  "selectedAnalyte": 0,
  "radius":"max",
  "histogramSelection":[],
  "selectedLocation":null,
  "foundLocation":null,
  "drawnPolygons": List(),
  "overlays": List(),
  "showQuantile":false,
  "quantileSize":[500,400],
  "boxPlotSize":[500,400],
  "uiSize":[500,350],
  "showPiper": false,
  "showModifiedPiper": false,
  "showDurov": false,
  "showQQPlot": false,
  "qqSize":[500,500],
  "showHotSpot": false,
  "hotSpotUnit": 'pixel',
  "hotSpotRadius": 30,
  "showSamplingFreq": false,
  "showClusters": false,
  "showTin": false,
  "showGrid": false,
  "doGrid": false,
  "interpolationGrid": Map(),
  "contourInterpolationGrid": Map(),
  "showBands": false,
  "showContours": false,
  "doContours": false,
  //"doContourGrid": false,
  "showBoxPlot": false,
  "showInfoLegend": true,
  "errorMessage": null,
  "mapCenter": [36.06, -114.985],
  "mapZoom":14,
  "mapBounds": null,
  "draggable":true,
  "showRadius": true,
  "showIntensity": false,
  "hasNegatives": true,
  "gridCellSize": 0.10,  // in Kilometers
  "distanceWeightingFactor": 10,
  "nClusters": 4,
  "showUITools": false,
  "nContours": 11,
  "useSetContourBreaks": false,
  "contourBreaks": [],
  "mapBreaks": 'ckmeans',
  "editKey": shortid.generate(),
  "showSummaryTable": false,
  "frequencyData": List(),
  "frequencySelection": List(),
  "quantileLocation": null,
  "piperLocation": null,
  "gaugeData": fromJS(gauges),
  "gaugeSize":[750,450],
  "showGauges": false
  // previous piper plot reducer stuff:
  //"piperRadius":"location",
  //"scalePoints": true,
  //"piperDomain": [0,0],
  //"piperSize":[750,600],
  //"modPiperSize":[600,600],
  //"durovSize":[600,600],
  //"piperData": List(),
  //"piperLoaded": false
  //"errorMessage": null
});

const MapApp = (state = initialState, action) => {

  var newState = Map();
  var locsState = Map();
  //var newerState = Map();
  var newDrawnPolygons;
  //var activeFilters = Map();
  var id, single, currentVal;

  switch(action.type){

    case "VIEWPOINT_CHANGE":
      return state.set('mapCenter',action.viewpoint.center).set('mapZoom',action.viewpoint.zoom)

/* * * * * * * * * ACTION RECEIVE DATA * * * * * * * * */
    case "API_ERROR":
      return state.set('errorMessage', action.message);

    case "DATA_ERROR":
      return state.set('errorMessage', action.message);

    case "CLEAR_ERROR_MESSAGE":
      return state.set('errorMessage', null);

    /*case "CHANGE_QUERY_TYPE":
    console.log(action.value);
    console.log(state.get('allFilterFields').get(action.value).toJS());
      return state.set('activeQueryType', action.value)
                  .set('filterFields', state.get('allFilterFields').get(action.value));*/

    /*case "RECEIVE_PIPER_DATA":
      return state.set('piperData', fromJS(createPiperData(action.data.data))).set('piperLoaded', true)*/

    case "RECEIVE_DATA":
      console.log("RECEIVING DATA IN reducers/map.js");
      var shifty_matrix_count = 0;
      action.data.data.forEach(function(e, i, a) {
        if(e.matrix === 'Soil' && e.unit === 'ug/L') {
          shifty_matrix_count++;
        }
      })

      let newData = state.set('rawData', fromJS(action.data.data))
        .set('possibleLeachate', shifty_matrix_count > 0)
        .set('rawLocations', fromJS(action.data.locations))
        .set('yearMin', parseInt(action.data.years[0].min_year, 10))
        .set('yearMax', parseInt(action.data.years[0].max_year, 10))
        .set('resultMin', parseInt(action.data.years[0].min_result, 10))
        .set('resultMax', parseInt(action.data.years[0].max_result, 10))
        //.set('depthMin', parseInt(action.data.depths[0].min_depth, 10))
        //.set('depthMax', parseInt(action.data.depths[0].max_depth, 10))
        .set('analytes', fromJS(action.data.analytes))
        .set('analyte', action.data.analytes[action.data.selectedAnalyte].analyte)
        .set('activeFilters', Map())
        .set('selectedQuery', action.data.selectedQuery)
        .set('selectedAnalyte', action.data.selectedAnalyte)
        .set('histogramSelection',[])
        .set('errorMessage', null)
        .set('selectedLocation', null)
        .set('locResults', List())
        .set('useSetContourBreaks', false)
        .set('contourBreaks', [])
        .set('editKey', shortid.generate())  // stop doing this if you don't want to clear out the leaflet draw polygons when analytes or queries change
        .set('drawnPolygons', List()) // stop doing this if you don't want to clear out the leaflet draw polygons when analytes or queries change
        .set('filterFields', state.get('allFilterFields').get(action.data.selectedQueryType))
        .set('activeQueryType', action.data.selectedQueryType)

      //RESET OUR HISTOGRAM AND SELECTION
      let stateWithAnalytes = getAnalytes(newData, action)
      let stateWithAnalyteData = applyAnalyte(stateWithAnalytes, action)
      let stateWithFilters =  filters(stateWithAnalyteData, action)
      let stateWithActiveFilters = setActiveFilters(stateWithFilters, action)
      let stateWithFilteredData = applyFilters(stateWithActiveFilters, action)
      let stateWithoutHighlights = highlightLocations(stateWithFilteredData, action)
      return stateWithoutHighlights;

    case "RECENTER_MAP":
      //console.log('MAP RECENTERING')
      return state.set("mapCenter", [36.06, -114.985]).set("mapZoom", 14)

    case "SET_MAP_BOUNDS":
      //console.log('SETTING MAP BOUNDS')
      return state.set("mapBounds", action.bounds)


/* * * * * * * * * SELECT ANALYTE * * * * * * * * */

    case "SELECT_ANALYTE":
      //console.log(action.value);

      //var stateWithAnalyte = state.set('analyte',action.value)
      //var stateWithAnalyteData = applyAnalyte(state,action)
      //var stateWithFilters = applyFilters(stateWithAnalyteData , action)
      //var stateResetHist = stateWithFilters.set('histogram', null);

      //return stateResetHist;
      return state

/* * * * * * * * * FILTER ACTIONS * * * * * * * * */

    case "TOGGLE_FIELD":
      console.log('TOGGLING FIELD')
      id = parseInt(action.id, 10);
      return state.setIn(['filterFields',id,'visible'], !state.get('filterFields').get(id).get('visible'));


    case "TOGGLE_FILTER":
      console.log('TOGGLING FILTER')
      var t1 = performance.now();
      single = state.get('filters').get(action.id).get('single');
      var field = state.get('filters').get(action.id).get('field');
      if(!single) {
        newState = state.setIn(['filters',action.id,'active'], !state.get('filters').get(action.id).get('active'));
      } else {
        currentVal = state.get('filters').get(action.id).get('active');
        if(!currentVal) {  // if this toggle is turning it 'on', turn all others off and then turn it on
          newState = state.update(  // turn associated filters on, or else turn them off
          'filters',
          filters => filters.map(filter =>
            {
              if(filter.get('id') === action.id) {
                return filter.update(
                  'active',
                  active => true
                )
              } else if (filter.get('id') !== action.id && filter.get('field') === field){
                return filter.update(
                  'active',
                  active => false
                )
              } else {
                return filter;
              }
            }
          ))
        } else {
          newState = state.setIn(['filters',action.id,'active'], !state.get('filters').get(action.id).get('active'));
        }
      }
      var t2 = performance.now();
      console.log('time to toggle filter: ' + (t2-t1));
      return setActiveFilters(newState, action);

    case "UPDATE_SLIDER":
      console.log('UPDATING SLIDER')
      newState =  interval(state , action);
      return setActiveFilters(newState, action);

    case "FIND_LOCATION":
      console.log('FINDING LOCATION')
      return state.set('foundLocation', action.location)

    case "LOSE_LOCATION":
      console.log('LOSING LOCATION')
      return state.set('foundLocation', null)

    case "CHOOSE_LOCATION":
      if(state.get('selectedLocation') === action.location) {
        return state.set('selectedLocation', null).set('locResults', List()).set('locStaticResults', List());
      } else {
        console.log('CHOOSING LOCATION', action.location)
        var stateWithSelectedLoc = state.set('selectedLocation', action.location)
        return locResults(locStaticResults(stateWithSelectedLoc, action), action);
      }

    case "QUANTILE_LOCATION":
      return state.set('quantileLocation', action.location)

    case "PIPER_LOCATION":
      return state.set('piperLocation', action.location)


/* * * * * * * * * FILTER DATA * * * * * * * * */

      /* The Proccess here is to go through each location and filter out data
       * that doesn't match any of the active filters.
       * Then we filter out locations that have no data points.
       */

    case "FILTER_DATA":
      action.location = state.get('selectedLocation');
      if(typeof state.get('filters').get(action.id) === 'undefined') return applyFilters(state,action)

      if( state.get('filters').get(action.id).get('field') === 'matrix') {
        let activeMatrix = state.hasIn(['activeFilters','matrix']) ? state.get('activeFilters').get('matrix').get('filters').get(0) : false;
        let depthExtent = getDepthExtent(state, activeMatrix, action);
        let filterFields = state.get('filterFields').map((field) =>{
          if(field.get('column') === "sample_bottom_depth") {
              return field.set('extent',List([depthExtent[0], depthExtent[1]]))
                .set('value',List([depthExtent[0], depthExtent[1]]))
                .set('brush',List())
          } else {
            return field
          }
        })
        let filters = state.get('filters').map((filter) =>{
          if(filter.get('field') === "sample_bottom_depth") {
              return filter.set('filter',List([depthExtent[0], depthExtent[1]]))
          } else {
            return filter
          }
        })
        newState = applyFilters(state.set('filterFields', filterFields).set('filters', filters), action);
        locsState = locStaticResults(newState, action);
        return applyInterpolations(locsState, action);
      } else if(state.get('filters').get(action.id).get('field') === 'unit'){
        newState = applyFilters(state, action);
        locsState = locStaticResults(newState, action);
        return applyInterpolations(locsState, action);
      } else {
        newState = applyFilters(state, action);
        return applyInterpolations(newState, action);
      }

    case "TOGGLE_LOG_DATA":
      return state.set('logData', !state.get('logData'))

/* * * * * * * * * STAT STUFF * * * * * * * * */

    case "CHOOSE_RADIUS":
      newState = state.set('radius', action.value).set('histogramSelection', []);
      return highlightLocations(newState, action)

    case "CHOOSE_HISTOGRAM":
      // var histogramSelection = action.x0 === state.get('histogramSelection') ? null : [action.x0, action.x1];
      var histogramSelection = state.get('histogramSelection');
      if (action.idx === 'clear') {
        histogramSelection.splice(0, histogramSelection.length)
      } else if (action.idx < 0 || action.idx === null) {
        histogramSelection.push([+action.x0, +action.x1])
      } else {
        histogramSelection.splice(action.idx, 1)
      }
      var stateWithSelection = state.set('histogramSelection', histogramSelection);
      return highlightLocations(stateWithSelection, action);

    case "CHOOSE_FREQUENCY":
      return state.set('frequencySelection', action.locations);

    case "TOGGLE_SHOW_RADIUS":
      return state.set('showRadius', !state.get('showRadius'))

    case "TOGGLE_SHOW_INTENSITY":
      return state.set('showIntensity', !state.get('showIntensity'))

/* * * * * * * * * PLOT MODAL STUFF * * * * * * * * */

    case "TOGGLE_PLOT_MODAL":
      var uiTools = false;
      //var doGrid = false;
      //var doContours = false;
      switch(action.plot) {
        case 'quantile':
          return state.set('showQuantile', !state.get('showQuantile'));
        case 'piper':
          return state.set('showPiper', !state.get('showPiper'));
        case 'modpiper':
          return state.set('showModifiedPiper', !state.get('showModifiedPiper'));
        case 'durov':
          return state.set('showDurov', !state.get('showDurov'));
        case 'hotspot':
          newState = state.set('showHotSpot', !state.get('showHotSpot'));
          uiTools = newState.get('showGrid') || newState.get('showContours') || newState.get('showClusters') || newState.get('showHotSpot')
          return newState.set('showUITools', uiTools);
        case 'sampling':
          return state.set('showSamplingFreq', !state.get('showSamplingFreq'));
        case 'grid':
          newState = state.set('showGrid', !state.get('showGrid'));
          uiTools = newState.get('showGrid') || newState.get('showContours') || newState.get('showClusters') || newState.get('showHotSpot')
          //doGrid = newState.get('showGrid')
          return newState.set('showUITools', uiTools).set('doGrid', false); // set doGrid to false if just turning on this ui or turning it off.
        case 'bands':
          return state.set('showBands', !state.get('showBands'));
        case 'contour':
          newState = state.set('showContours', !state.get('showContours'));
          uiTools = newState.get('showGrid') || newState.get('showContours') || newState.get('showClusters') || newState.get('showHotSpot')
          //doContours = newState.get('doContours')
          return newState.set('showUITools', uiTools).set('doContours', false); // set doContourGrid to false if just turning on this ui or turning it off.
        case 'tin':
          return state.set('showTin', !state.get('showTin'));
        case 'cluster':
          newState = state.set('showClusters', !state.get('showClusters'))
          uiTools = newState.get('showGrid') || newState.get('showContours') || newState.get('showClusters') || newState.get('showHotSpot')
          return newState.set('showUITools', uiTools);
        case 'qqplot':
          return state.set('showQQPlot', !state.get('showQQPlot'));
        case 'boxplot':
          return state.set('showBoxPlot', !state.get('showBoxPlot'));
        case 'gauge':
          return state.set('showGauges', false).set('gaugeData', state.get('gaugeData').map(gauge => gauge.set('show', false)));
        default:
          return state;
      }

    case "RESIZE_PLOT_MODAL":
      switch(action.plot) {
        case 'quantile':
          return state.set('quantileSize', [action.width, action.height]);
        case 'qqplot':
          return state.set('qqSize', [action.width, action.height]);
        case 'boxplot':
          return state.set('boxPlotSize', [action.width, action.height]);
        case 'ui':
          return state.set('uiSize', [action.width, action.height]);
        case 'gauge':
          return state.set('gaugeSize', [action.width, action.height]);
        /*case 'piper':
          return state.set('piperSize',[action.width, action.height]);
        case 'modpiper':
          return state.set('modPiperSize',[action.width, action.height]);
        case 'durov':
          return state.set('durovSize',[action.width, action.height]);*/
        default:
          return state;
      }

/* * * * * * * * * SUMMARY TABLE MODAL STUFF * * * * * * * * */
    case "TOGGLE_TABLE_MODAL":
      switch(action.table) {
        case 'summary':
          if(state.get('showSummaryTable')) {
            return state.set('showSummaryTable', !state.get('showSummaryTable'));
          } else {
            var summaryData = makeSummaryTableData(state.get('filteredData'));
            return state.set('showSummaryTable', !state.get('showSummaryTable')).set('filteredSummaryTableData', summaryData);
          }
        default:
          return state;
      }


/* * * * * * * * * USGS GAUGES STUFF * * * * * * * * */
    case "ADD_GAUGE":
      id = state.get('gaugeData').findIndex(item => item.get('id') === action.id);
      return state.set('showGauges', true).setIn(['gaugeData', id, 'data'], fromJS(action.data))
                  .setIn(['gaugeData', id, 'show'], true)//.setIn(['gaugeData', id, 'timestamp'], new Date());

    case "SHOW_GAUGE":
      id = state.get('gaugeData').findIndex(item => item.get('id') === action.id);
      //console.log(state.get('gaugeData').get(id).get('timestamp'))
      return state.set('showGauges', true).setIn(['gaugeData', id, 'show'], true);

    case "HIDE_GAUGE":
      id = state.get('gaugeData').findIndex(item => item.get('id') === action.id);
      if(state.get('gaugeData').filter(gauge => gauge.get('show')).size < 2) {
        return state.set('showGauges', false).setIn(['gaugeData', id, 'show'], false);
      } else {
        return state.setIn(['gaugeData', id, 'show'], false);
      }


/* * * * * * * * * LEAFLET DRAW POLYGON STUFF * * * * * * * * */
    case "POLYGON_CREATED":
      newDrawnPolygons = state.get('drawnPolygons').set(state.get('drawnPolygons').size, Map({id: action.id, coords: List(action.polygon), layer: Map(action.layer)}));
      return state.set('drawnPolygons', newDrawnPolygons);

    case "POLYGON_DELETED":
      newDrawnPolygons = state.get('drawnPolygons').filter(x => action.ids.indexOf(x.get('id').toString()) < 0);
      return state.set('drawnPolygons', newDrawnPolygons);

    case "POLYGON_RESET":
      return state.set('drawnPolygons', fromJS(action.data))


/* * * * * * * * * LEAFLET OVERLAYS STUFF * * * * * * * * */
    case "RECEIVE_OVERLAYS":
      return state.set('overlays', List(action.data));


/* * * * * * * * * MAP UI TOOLS MODAL STUFF * * * * * * * * */
    case "CHANGE_INTERPOLATION_GRID":
      //newState = state.set('distanceWeightingFactor', action.distanceWeightingFactor).set('gridCellSize', action.gridCellSize).set('doGrid', true);
      return createInterpolationGrid(state, action);

    case "CHANGE_INTERPOLATION_GRID_FROM_WORKER":
      return state.set('distanceWeightingFactor', action.distanceWeightingFactor).set('gridCellSize', action.gridCellSize).set('doGrid', true).set("interpolationGrid", fromJS(action.interpolationGrid));

    case "CHANGE_NUMBER_OF_CLUSTERS":
      return state.set('nClusters', action.value);

    case "CHANGE_NUMBER_OF_CONTOURS":
      return state.set('nContours', action.value);

    case "CHANGE_CONTOURS":
      return state.set('contourBreaks', action.contourBreaks).set('nContours', action.nContours).set('doContours', true);

    case "CHANGE_CONTOURS_GRID":
      return state.set('contourBreaks', action.contourBreaks).set('nContours', action.nContours).set('doContours', true).set("contourInterpolationGrid", fromJS(action.contourInterpolationGrid));

    case "CHANGE_CONTOUR_CHOICE":
      if(action.choice === 'breaks') {
        return state.set('useSetContourBreaks', true)//.set('doContourGrid', true);
      } else {
        return state.set('useSetContourBreaks', false)//.set('doContourGrid', true);
      }

    case "CHANGE_HOT_SPOT_UNIT":
      return state.set('hotSpotUnit', action.unit);

    case "UPDATE_HOT_SPOT_RADIUS":
      return state.set('hotSpotRadius', +action.radius);

    default:
      return state;
  }
}

export default MapApp;
