import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './css/bootswatch.css';
import './css/index.css';
import { screenResize } from './actions';

//const middleware = [ thunk ]
/*if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}*/

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

window.addEventListener('resize', () => {
    store.dispatch(screenResize(window.innerWidth));
});

console.log("INITIALIZING ENV: " + process.env.REACT_APP_SITE)
if(process.env.REACT_APP_SITE === 'bmi') {
  document.title = 'BMI Database';
} else {
  document.title = 'NERT GW Database';
}

//let site_class = "App " + process.env.REACT_APP_SITE + "-App";

ReactDOM.render(
  <div>
  <Provider store={store}>
      <App />
    </Provider>
  </div>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
