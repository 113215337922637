import React from 'react';

const OverviewMain = (props) => {
  if(process.env.REACT_APP_SITE === 'bmi') {
    return (
      <div id='overview'>
        <h1> BMI Complex, Common Areas, and Vicinity Database (BMIdbase) version 3 BETA </h1>
        <h3>Overview</h3>
          <p>
            Thank you for visiting the BMI Database website.
            Some data is still being loaded into the site database, and some data issues are still being worked out
            with the companies. Resolutions to these issues will be posted in the “What’s New” section of the site home page.
          </p>
          <p>
            The purpose of this site is provide access to data from a variety of parties located within and near the
            BMI Complex and Common Areas in Henderson, Nevada. In addition to access to data, this site provides access
            to certain tools which can be used to manipulate and depict the data. Parties that have provided data are as follows:
          </p>
          <ul>
            <li>American Pacific Corporation (AMPAC)</li>
            <li>Basic Management Incorporated (BMI)</li>
            <li>City of Henderson</li>
            <li>Clean Water Coalition (CWC)</li>
            <li>Montrose Chemical Company of California (Montrose)</li>
            <li>Olin Chlor Alkali (Olin)</li>
            <li>Stauffer Management Company (Stauffer)</li>
            <li>Syngenta Crop Protection (Sygenta)</li>
            <li>Titanium Metals Corporation (TIMET)</li>
            <li>Tronox Incorporated (Tronox)</li>
          </ul>
          <p style={{textAlign:'center',marginTop:20}}>
          This aerial photo shows BMI Complex and Common Areas.
          <img src={require('./ANNUAL STAKEHOLDER 31_3.jpg')} alt="BMI Complex"/>
          </p>

      </div>
    )
  } else {
    return (
      <div id='overview'>
        <h1> Welcome to the NERT Groundwater Database </h1>
        <h3>Overview</h3>
          <p style={{textIndent: '25px'}}>
            The purpose of this site is provide access to groundwater data from a variety of parties located within and near the
            Black Mountain Industrial (BMI) Complex and Common Areas in Henderson, Nevada. Funding for this site is provided by the 
            Nevada Environmental Response Trust (NERT). The NERT site is a 346-acre part of the larger BMI Complex undergoing remediation 
            and was formerly owned by Tronox LLC (previously Kerr-McGee Chemical Corporation). 
          </p>
          <p style={{textIndent: '25px'}}>In addition to access to data, this site provides access
            to tools that can be used to explore, download, manipulate and visualize user-built database queries. Parties that have provided 
            groundwater data are as follows:
          </p>
          <ul>
            <li>American Pacific Corporation (AMPAC)</li>
            <li>Basic Management Incorporated (BMI)</li>
            <li>City of Henderson</li>
            <li>Clean Water Coalition (CWC)</li>
            <li>Montrose Chemical Company of California (Montrose)</li>
            <li>Olin Chlor Alkali (Olin)</li>
            <li>Stauffer Management Company (Stauffer)</li>
            <li>Syngenta Crop Protection (Sygenta)</li>
            <li>Titanium Metals Corporation (TIMET)</li>
            <li>Tronox Incorporated (Tronox)</li>
            <li>Southern Nevada Watershed Authority (SNWA)</li>
          </ul>
          <p style={{textAlign:'center',marginTop:20}}>
          This aerial photo shows BMI Complex and Common Areas with the NERT site labeled in the foreground.
          <img src={require('./ANNUAL STAKEHOLDER 31_3.jpg')} alt="BMI Complex"/>
          </p>

      </div>
    )
  }
}

export default OverviewMain;
