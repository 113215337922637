import React from 'react';

const Howto = (props) => {
  return (
    <div id='howto'>
      <h3>How To Use This Site</h3>
      <div className='howto_section'>
        This website allows you to query, download, and explore data from the BMI Complex, Common Areas, and Vicinity Database (BMIdbase). There are four main sections available in the top menuof the website:
        <ul>
          <li><b>Home: </b>Contains general information about the BMIdbase.</li>
          <li><b>Standardized Locations: </b>Searchable list of standardized and alternate groundwater well IDs.</li>
          <li><b>Parameter Table: </b>Searchable list of standardized and alternate parameter CAS IDs.</li>
          <li><b>Query Data: </b>Contains an interface for querying the BMIdbase, executing saved queries,  and downloading executed queries.</li>
          <li><b>View Data: </b>Contains an interface for filtering and viewing data for an executed query.</li>
          <li><b>Map: </b>Contains an interactive map, plots, and statistics for investigating an executed query.</li>
        </ul>
      </div>

      <div className='howto_section'>
        <h3>Home</h3>

        <b>To Read About this Web Site and Database Project:</b>
        <ol>
          <li>Click on the "OVERVIEW" menu item in the menu on the left side of the page.</li>
        </ol>

        <b>To Read More About the BMI Site and History:</b>
        <ol>
          <li>Click on the "HISTORY" menu item in the menu on the left side of the page.</li>
        </ol>

        <b>To Download Common Datasets:</b>
        <ol>
          <li>Click on the "DOWNLOADABLE DATASETS" menu item in the menu on the left side of the page.</li>
          <li>This will take you to a page showing available datasets downloadable as Excel Workbooks (xlsx file types).</li>
        </ol>

        <b>To See Recent Data Uploads:</b>
        <ol>
          <li>Click on the "WHAT'S NEW" menu item in the menu on the left side of the page.</li>
          <li>This will take you to a page showing a list of approved DVSRs that have been uploaded to the database.</li>
        </ol>

        <b>To Report a Bug:</b>
        <ol>
          <li>Click on the "REPORT A BUG" menu item in the menu on the left side of the page.</li>
          <li>This will take you to a page where you can type a description of the bug(s) you have noticed on the site.</li>
          <li>Below the text box is a 'Submit' button. Click this when you are ready to send off the bug(s) you are reporting. This will send the text to the site administrators via email.</li>
          <li>After submitting the bug description(s), you will have the option to submit up to 4 more bug descriptions per session.</li>
        </ol>
      </div>

      <div className='howto_section'>
        <h3>Standardized GW Locations</h3>

        <b>To Search for a Standardized Groundwater Well ID:</b>
        <ol>
          <li>Click on the "STANDARDIZED GW LOCATIONS" menu item in the top menu.</li>
          <li>Type the well id you'd like to find in the search bar.</li>
          <li>As you type, only matching options will be shown below instead of the full list.</li>
          <li>To search another well id or alternate well id, simply delete what you have typed in the search bar and begin typing in your new search.</li>
        </ol>
      </div>

      <div className='howto_section'>
        <h3>Parameter Table</h3>

        <b>To Search for a Standardized CAS ID:</b>
        <ol>
          <li>Click on the "PARAMETER TABLE" menu item in the top menu.</li>
          <li>Type the parameter name or CAS id you'd like to find in the search bar.</li>
          <li>As you type, only matching options will be shown below instead of the full list.</li>
          <li>To search another parameter name or CAS id, simply delete what you have typed in the search bar and begin typing in your new search.</li>
        </ol>
      </div>

      <div className='howto_section'>
        <h3>Query Data</h3>

        <b>To Build a New Query:</b>
        <ol>
          <li>Click on the "Query Data" menu item in the top menu.</li>
          <li>Select the “Build a New Query” menu item.</li>
          <li>You are given a choice of filter fields in the first column. To query on these fields, drag them over to the Selected Fields column. The order of the fields determines which filters are available in each consecutive field.</li>
          <li>Click on each field in the Selected Fields column to display the available filters in the Filters column.</li>
          <li>Click on filters to include them in the query. Filters that are greyed out in the Selected Filters column match no rows in the database given the selected filters above.</li>
        </ol>

        <b>To Execute an Existing Query:</b>
        <ol>
          <li>To build a new Query follow these steps:</li>
          <li>Click on the “Query Data” menu item in the top menu. </li>
          <li>Select the “Select an Existing Query” menu item. </li>
          <li>Click on a query in the Queries column.</li>
          <li>Click the Execute Query button at the bottom of the page.</li>
        </ol>
        <b>Download CSV</b>
        <br />
        <p>
          When a query has been executed, click Download CSV to download the data as a CSV.
        </p>
    </div>

    <div className='howto_section'>
      <h3>View Data</h3>
      <p>
        When a query has been executed, click on "View Data" in the top menu to view a table of the data.
        Use the dropdown and filter input and to filter the data.
      </p>
    </div>

    <div className='howto_section'>
      <h3>Map</h3>
      When a query has been executed, click on "Map" in the top menu.
      <ul>
        <li>Only one parameter can be viewed at a time. To select an parameter, choose from the drop down list on the top left.</li>
        <li>Use the filters in the left sidebar to filter the data.</li>
        <li>The radius of the markers can be set using the marker style options.</li>
        <li>Clicking on the bars of the histogram highlights the corresponding markers on the map. </li>
        <li>Clicking on a map marker will show the location name and plot the results on the right.</li>
      </ul>
    </div>
  </div>
      )
}

export default Howto;
