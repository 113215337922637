import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { setPiperLocation } from '../../actions/map';
import '../../css/piperPlot.css';

class PiperPlotComp extends Component {

  constructor(props){
    super(props)
    this.state = {
      filteredData : this.props.data.filter(item => item.get('visible') && item.get('ionCheck')).toJS()
    }
  }
  componentDidMount(){
    //if(this.props.data.size > 0) {
      this.renderPlot();
    //}
  }

  componentDidUpdate(){
    this.renderPlot();
  }
  
  componentWillReceiveProps(nextProps){
    this.setState({
      filteredData : nextProps.data.filter(item => item.get('visible') && item.get('ionCheck')).toJS()
    });
  }
  
  renderPlot(){

    var scale = this.props.scale;
    var radiusStat = this.props.radius;
    var getRadius = d3.scaleLinear().domain(this.props.domain).range([2,9]).clamp(true); 

    d3.selectAll('.piperPlotContainer').remove();

    var dim = Math.min(this.props.size[0], this.props.size[1]);

    this.svgMargin = 20;
    this.plotHeight = dim -  2*this.svgMargin;
    this.plotWidth = dim  - 2*this.svgMargin;

    var self = this;

    var cation_axes_labels = ['Magnesium (Mg)', 'Calcium (Ca)', 'Sodium (Na) + Potassium (K)'],
        anion_axes_labels = ['Carbonate (CO\u2083) + Bicarbonate (HCO\u2083)', 'Chloride (Cl)', 'Sulfate (SO\u2084)'],
        diamond_axes_labels = ['Sulfate (SO\u2084) + Chloride (Cl)', 'Calcium (Ca) + Magnesium (Mg)'],
        axis_ticks = [0,20,40,60,80,100],
        tickLabelMargin = 5,
        axisLabelMargin = 10,
        minor_axis_ticks = d3.range(0, 101, 5),
        diamond_minor_axis_ticks = d3.range(0, 101, 5),
        middle_space = 80,
        w_excess = this.props.size[0] - dim - (middle_space) - (2*this.svgMargin),
        h_excess = this.props.size[1] - dim - (2*this.svgMargin),
        font_size;

    if(dim >= 500) {
      font_size = "1rem"
    } else if(dim < 500 && dim >= 30){
      font_size = "0.8rem"
    } else {
      font_size = "0.5rem"
    }

    if (w_excess < 0) {
      w_excess = 0;
    }
    if (h_excess < 0) {
      h_excess = 0;
    }

    var svg = d3.select(this.refs.piperPlot);
    var plotContainer = svg.append('g')
      .attr('class', 'piperPlotContainer')
      .attr('width', dim)
      .attr('height', dim)

    var ternaryCation = plotContainer.append('g')
      .attr('class', 'ternary cation')
      .attr('width', ((this.plotWidth/2) - (middle_space/2)))
      .attr('height', (this.plotHeight/2))
      .attr("transform", "translate(" + (this.svgMargin + (w_excess/2)) + "," + ((this.plotHeight/2)+this.svgMargin) + ")"); 

    var ternaryAnion = plotContainer.append('g')
      .attr('class', 'ternary anion')
      .attr('width', ((this.plotWidth/2) - (middle_space/2)))
      .attr('height', (this.plotHeight/2))
      .attr("transform", "translate(" + ((this.svgMargin + (w_excess/2) + (this.plotWidth/2) + middle_space)) + "," + ((this.plotHeight/2)+this.svgMargin) + ")");

    var diamond = plotContainer.append('g')
      .attr('class', 'diamond')
      .attr('width', ((this.plotWidth/2) - middle_space))
      .attr('height', ((this.plotHeight)))
      .attr("transform", "translate(" + ((this.svgMargin + (w_excess/2) + (this.plotWidth/4) + (middle_space/2))) + "," + this.svgMargin + ")");

    var axesCation = ternaryCation.append('g').attr('class','axes');

    var axesAnion = ternaryAnion.append('g').attr('class','axes');

    var axesDiamond = diamond.append('g').attr('class','axes');

    var w = (this.plotHeight/2) ; // length of one side of the triangle
    var h = Math.sqrt( w * w - (w/2)*(w/2));

    var corners = [
      [this.svgMargin, h + this.svgMargin], // ll
      [ w + this.svgMargin, h + this.svgMargin], // lr
      [(w/2) + this.svgMargin, this.svgMargin],  // top
      [(w/2) + this.svgMargin, (2*h) + this.svgMargin] ] // diamond bottom

    //cation axis names
    axesCation.selectAll('.axis-title')
      .data(cation_axes_labels)
      .enter()
        .append('g')
          .attr('class', 'axis-title')
          .attr('transform',function(d,i){
            var coord_label,
                v = 50;
            if(i===0) coord_label = coord( [v, 0, 100-v] );
            if(i===1) coord_label = coord( [v, 100-v, 0] );
            if(i===2) coord_label = coord( [0, 100-v, v] );
            //return 'translate('+corners[i][0]+','+corners[i][1]+')';
            return 'translate(' + coord_label[0] + ',' + coord_label[1] + ')'
          })
        .append('text')
        .style('font-size', font_size)
        .text(function(d){ return d; })
        .attr('text-anchor', function(d,i){
          /*if(i===0) return 'end';
          if(i===2) return 'middle';
          return 'start';  */
          if(i===0) return 'middle';//'end';
          if(i===1) return 'middle';
          return 'middle';//'start';  
        })
        .attr('x', function(d,i) {
          var x;
          if(i===0) x = -axisLabelMargin * 0;
          if(i===1) x = 0;
          if(i===2) x = axisLabelMargin * 0;
          return x;
        })
        .attr('y', function(d,i) {
          var y;
          if(i===0) y = -axisLabelMargin * 3; //0;
          if(i===1) y = axisLabelMargin * 3;
          if(i===2) y = -axisLabelMargin * 3; //0;
          return y;
        })
        .attr('transform', function(d,i) {
          var r;
          if(i===0) r = 'rotate(-60)';
          if(i===1) r = 'rotate(0)';
          if(i===2) r = 'rotate(60)';
          return r;
        });
        /*.attr('transform', function(d,i){
          var theta = 0;
          if(i===0) theta = 120;
          if(i===1) theta = 60;
          if(i===2) theta = -90;
          var x = axisLabelMargin * Math.cos(theta * 0.0174532925),
              y = axisLabelMargin * Math.sin(theta * 0.0174532925);
          return 'translate('+x+','+y+')'
        });*/

    //anion axis names
    axesAnion.selectAll('.axis-title')
      .data(anion_axes_labels)
      .enter()
        .append('g')
          .attr('class', 'axis-title')
          .attr('transform',function(d,i){
            var coord_label,
                v = 50;
            if(i===0) coord_label = coord( [v, 0, 100-v] );
            if(i===1) coord_label = coord( [v, 100-v, 0] );
            if(i===2) coord_label = coord( [0, 100-v, v] );
            //return 'translate('+corners[i][0]+','+corners[i][1]+')';
            return 'translate(' + coord_label[0] + ',' + coord_label[1] + ')'
          })
        .append('text')
        .style('font-size', font_size)
          .text(function(d){ return d; })
          .attr('text-anchor', function(d,i){
            /*if(i===0) return 'end';
            if(i===2) return 'middle';
            return 'start';  */
            if(i===0) return 'middle';//'end';
            if(i===1) return 'middle';
            return 'middle';//'start';  
          })
          .attr('x', function(d,i) {
            var x;
            if(i===0) x = -axisLabelMargin * 0;
            if(i===1) x = 0;
            if(i===2) x = axisLabelMargin * 0;
            return x;
          })
          .attr('y', function(d,i) {
            var y;
            if(i===0) y = -axisLabelMargin * 3; //0;
            if(i===1) y = axisLabelMargin * 3;
            if(i===2) y = -axisLabelMargin * 3; //0;
            return y;
          })
          .attr('transform', function(d,i) {
            var r;
            if(i===0) r = 'rotate(-60)';
            if(i===1) r = 'rotate(0)';
            if(i===2) r = 'rotate(60)';
            return r;
          });

    //Diamond axis names
    axesDiamond.selectAll('.axis-title')
      .data(diamond_axes_labels)
      .enter()
        .append('g')
          .attr('class', 'axis-title')
          .attr('transform',function(d,i){
            var coord_label,
                v = 50;
            if(i===0) coord_label = coord( [v, 0, 100-v] );
            //if(i===1) coord_label = coord( [v, 100-v, 0] );
            if(i===1) coord_label = coord( [0, 100-v, v] );
            //return 'translate('+corners[i][0]+','+corners[i][1]+')';
            return 'translate(' + coord_label[0] + ',' + coord_label[1] + ')'
          })
        .append('text')
        .style('font-size', font_size)
          .text(function(d){ return d; })
          .attr('text-anchor', function(d,i){
            /*if(i===0) return 'end';
            if(i===2) return 'middle';
            return 'start';  */
            if(i===0) return 'middle';//'end';
            if(i===1) return 'middle';
            return 'middle';//'start';  
          })
          .attr('x', function(d,i) {
            var x;
            if(i===0) x = -axisLabelMargin * 0;
            //if(i===1) x = 0;
            if(i===1) x = axisLabelMargin * 0;
            return x;
          })
          .attr('y', function(d,i) {
            var y;
            if(i===0) y = -axisLabelMargin * 3; //0;
            //if(i===1) y = tickLabelMargin * 3;
            if(i===1) y = -axisLabelMargin * 3; //0;
            return y;
          })
          .attr('transform', function(d,i) {
            var r;
            if(i===0) r = 'rotate(-60)';
            //if(i===1) r = 'rotate(0)';
            if(i===1) r = 'rotate(60)';
            return r;
          });

    //ticks
    //var n = axis_ticks.length;
    if(diamond_minor_axis_ticks){
      diamond_minor_axis_ticks.forEach(function(v) {  
        var coord1 = coord( [v, 0, 100-v] );
        var coord2 = coord( [v, 100-v, 0] );
        var coord3 = coord( [0, 100-v, v] );
        //var coord4 = coord( [100-v, 0, v] );

        var coord5 = rev_coord( [v, 0, 100-v] );
        var coord6 = rev_coord( [v, 100-v, 0] );
        var coord7 = rev_coord( [0, 100-v, v] );
        //var coord8 = rev_coord( [100-v, 0, v] );

        axesDiamond.append("line")
          //.attrs( lineAttributes(coord1, coord2) )
          .attr( "x1", lineAttributes(coord1, coord2).x1 )
          .attr( "x2", lineAttributes(coord1, coord2).x2 )
          .attr( "y1", lineAttributes(coord1, coord2).y1 )
          .attr( "y2", lineAttributes(coord1, coord2).y2 )
          .classed('a-axis minor-tick', true);  

        axesDiamond.append("line")
          //.attrs( lineAttributes(coord2, coord3) )
          .attr( "x1", lineAttributes(coord2, coord3).x1 )
          .attr( "x2", lineAttributes(coord2, coord3).x2 )
          .attr( "y1", lineAttributes(coord2, coord3).y1 )
          .attr( "y2", lineAttributes(coord2, coord3).y2 )
          .classed('b-axis minor-tick', true);  

        /*axesDiamond.append("line")
          //.attrs( lineAttributes(coord3, coord4) )
          .attr( "x1", lineAttributes(coord3, coord4).x1 )
          .attr( "x2", lineAttributes(coord3, coord4).x2 )
          .attr( "y1", lineAttributes(coord3, coord4).y1 )
          .attr( "y2", lineAttributes(coord3, coord4).y2 )
          .classed('c-axis minor-tick', true);  */

        axesDiamond.append("line")
          //.attrs( lineAttributes(coord1, coord2) )
          .attr( "x1", lineAttributes(coord5, coord6).x1 )
          .attr( "x2", lineAttributes(coord5, coord6).x2 )
          .attr( "y1", lineAttributes(coord5, coord6).y1 )
          .attr( "y2", lineAttributes(coord5, coord6).y2 )
          .classed('a-axis minor-tick', true);  

        axesDiamond.append("line")
          //.attrs( lineAttributes(coord2, coord3) )
          .attr( "x1", lineAttributes(coord6, coord7).x1 )
          .attr( "x2", lineAttributes(coord6, coord7).x2 )
          .attr( "y1", lineAttributes(coord6, coord7).y1 )
          .attr( "y2", lineAttributes(coord6, coord7).y2 )
          .classed('b-axis minor-tick', true);  

        /*axesDiamond.append("line")
          //.attrs( lineAttributes(coord3, coord4) )
          .attr( "x1", lineAttributes(coord7, coord8).x1 )
          .attr( "x2", lineAttributes(coord7, coord8).x2 )
          .attr( "y1", lineAttributes(coord7, coord8).y1 )
          .attr( "y2", lineAttributes(coord7, coord8).y2 )
          .classed('c-axis minor-tick', true);  */
      });
    }

    if(minor_axis_ticks){
      minor_axis_ticks.forEach(function(v) {  
        var coord1 = coord( [v, 0, 100-v] );
        var coord2 = coord( [v, 100-v, 0] );
        var coord3 = coord( [0, 100-v, v] );
        var coord4 = coord( [100-v, 0, v] );

      axesCation.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis minor-tick', true);  

      axesCation.append("line")
        //.attrs( lineAttributes(coord2, coord3) )
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis minor-tick', true);  

      axesCation.append("line")
        //.attrs( lineAttributes(coord3, coord4) )
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis minor-tick', true);  

      axesAnion.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis minor-tick', true);  

      axesAnion.append("line")
        //.attrs( lineAttributes(coord2, coord3) )
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis minor-tick', true);  

      axesAnion.append("line")
        //.attrs( lineAttributes(coord3, coord4) )
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis minor-tick', true);      
      });
    }

    axis_ticks.forEach(function(v) {  
      var coord1 = coord( [v, 0, 100-v] );
      var coord2 = coord( [v, 100-v, 0] );
      var coord3 = coord( [0, 100-v, v] );
      var coord4 = coord( [100-v, 0, v] );

      var coord5 = rev_coord( [v, 0, 100-v] );
      var coord6 = rev_coord( [v, 100-v, 0] );
      var coord7 = rev_coord( [0, 100-v, v] );
      //var coord8 = rev_coord( [100-v, 0, v] );

      axesDiamond.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis tick', true); 

      axesDiamond.append("line")
        //.attrs( lineAttributes(coord2, coord3) )
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis tick', true); 

      axesDiamond.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord5, coord6).x1 )
        .attr( "x2", lineAttributes(coord5, coord6).x2 )
        .attr( "y1", lineAttributes(coord5, coord6).y1 )
        .attr( "y2", lineAttributes(coord5, coord6).y2 )
        .classed('a-axis tick', true); 

      axesDiamond.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord6, coord7).x1 )
        .attr( "x2", lineAttributes(coord6, coord7).x2 )
        .attr( "y1", lineAttributes(coord6, coord7).y1 )
        .attr( "y2", lineAttributes(coord6, coord7).y2 )
        .classed('b-axis tick', true); 

      axesCation.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis tick', true);  

      axesCation.append("line")
        //.attrs( lineAttributes(coord2, coord3) )
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis tick', true);  

      axesCation.append("line")
        //.attrs( lineAttributes(coord3, coord4) )
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis tick', true); 

      axesAnion.append("line")
        //.attrs( lineAttributes(coord1, coord2) )
        .attr( "x1", lineAttributes(coord1, coord2).x1 )
        .attr( "x2", lineAttributes(coord1, coord2).x2 )
        .attr( "y1", lineAttributes(coord1, coord2).y1 )
        .attr( "y2", lineAttributes(coord1, coord2).y2 )
        .classed('a-axis tick', true);  

      axesAnion.append("line")
        //.attrs( lineAttributes(coord2, coord3) )
        .attr( "x1", lineAttributes(coord2, coord3).x1 )
        .attr( "x2", lineAttributes(coord2, coord3).x2 )
        .attr( "y1", lineAttributes(coord2, coord3).y1 )
        .attr( "y2", lineAttributes(coord2, coord3).y2 )
        .classed('b-axis tick', true);  

      axesAnion.append("line")
        //.attrs( lineAttributes(coord3, coord4) )
        .attr( "x1", lineAttributes(coord3, coord4).x1 )
        .attr( "x2", lineAttributes(coord3, coord4).x2 )
        .attr( "y1", lineAttributes(coord3, coord4).y1 )
        .attr( "y2", lineAttributes(coord3, coord4).y2 )
        .classed('c-axis tick', true); 

      //tick labels
      axesDiamond.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord1[0] + ',' + coord1[1] + ')'
        })
        .append("text")
          .attr('text-anchor','end')
          .attr('x', -tickLabelMargin)
          .text( function (d) { if(v === 0 || v === 100) { return ''; } else { return (100 - v); } } )
          .classed('a-axis tick-text', true );
      
      axesDiamond.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord3[0] + ',' + coord3[1] + ')'
        })
        .append("text")
          .text( function (d) { if(v === 0 || v === 100) { return ''; } else { return v; } } )
          .attr('x',tickLabelMargin)
          .classed('c-axis tick-text', true);

      axesCation.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord1[0] + ',' + coord1[1] + ')'
        })
        .append("text")
          //.attr('transform','rotate(60)')
          .attr('text-anchor','end')
          .attr('x',-tickLabelMargin)
          .text( function (d) { return (100 - v); } )
          .classed('a-axis tick-text', true );
      
      axesCation.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord2[0] + ',' + coord2[1] + ')'
        })
        .append("text")
          //.attr('transform','rotate(-60)')
          .attr('text-anchor','middle')
          .attr('y',tickLabelMargin * 3)
          .text( function (d) { return v; } )
          .classed('b-axis tick-text', true); 
      
      axesCation.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord3[0] + ',' + coord3[1] + ')'
        })
        .append("text")
          .text( function (d) { return (100 - v); } )
          .attr('x',tickLabelMargin)
          .classed('c-axis tick-text', true);

      axesAnion.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord1[0] + ',' + coord1[1] + ')'
        })
        .append("text")
          //.attr('transform','rotate(60)')
          .attr('text-anchor','end')
          .attr('x',-tickLabelMargin)
          .text( function (d) { return v; } )
          .classed('a-axis tick-text', true );

      axesAnion.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord2[0] + ',' + coord2[1] + ')'
        })
        .append("text")
          //.attr('transform','rotate(-60)')
          .attr('text-anchor','middle')
          //.attr('x',-tickLabelMargin)
          .attr('y',tickLabelMargin * 3)
          .text( function (d) { return (100- v); } )
          .classed('b-axis tick-text', true);

      axesAnion.append('g')
        .attr('transform',function(d){
          return 'translate(' + coord3[0] + ',' + coord3[1] + ')'
        })
        .append("text")
          .text( function (d) { return v; } )
          .attr('x',tickLabelMargin)
          .classed('c-axis tick-text', true);
    }); 

    function lineAttributes(p1, p2){
      return { x1:p1[0], y1:p1[1],
           x2:p2[0], y2:p2[1] };
    }

    function coord(arr){
      var a = arr[0], b=arr[1], c=arr[2]; 
      var sum, pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
          a /= sum;
          b /= sum;
          c /= sum;
        pos[0] =  corners[0][0]  * a + corners[1][0]  * b + corners[2][0]  * c;
        pos[1] =  corners[0][1]  * a + corners[1][1]  * b + corners[2][1]  * c;
      }
        return pos;
    }

    function rev_coord(arr){
      var a = arr[0], b=arr[1], c=arr[2]; 
      var sum, pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
          a /= sum;
          b /= sum;
          c /= sum;
        pos[0] =  corners[0][0]  * a + corners[1][0]  * b + corners[3][0]  * c;
        pos[1] =  corners[0][1]  * a + corners[1][1]  * b + corners[3][1]  * c;
      }
        return pos;
    }

    function dia_coord(arr){
      var a = arr[0], b = arr[1], c = arr[2], d = arr[3]; 
      //var sum; 
      var pos = [0,0];
        //sum = a + b;
        //if(sum !== 0) {
        //  a /= sum;
        //  b /= sum;
        pos[0] = corners[0][0] + w * a + w * b;
        pos[1] = corners[2][1] + h * c + h * d;
      //}
      return pos;
    }

    function rev_dia_coord(arr){
      var a = arr[0], b=arr[1], c = arr[2], d = arr[3]; 
      //var sum; 
      var pos = [0,0];
        //sum = a + b;
        //if(sum !== 0) {
        //  a /= sum;
        //  b /= sum;
        pos[0] = corners[0][0] + w * a + w * b;
        pos[1] = corners[2][1] + h * c + h * d;
      //}
      return pos;
    }

    /*function scale(p, factor) {
        return [p[0] * factor, p[1] * factor];
    }*/

    /*var anionTip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html(function(d) {
        return "Chloride: " + d.cl_text + "<br/>" + "Sulfate: " + d.so4_text;
      })

     var cationTip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html(function(d) {
        return "Calcium: " + d.ca_text + "<br/>" + "Magnesium: " + d.mg_text;
      })*/

    /*var locTip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html(function(d) {
        return d.location;
      });*/

    var coords;
    //var me = this;
    var anionCircles = ternaryAnion.append("g").selectAll("circle")
      .data( this.state.filteredData.map( function(d) { 
          coords = coord([d.carbs, d.cl, d.so4])
          d.x = coords[0]
          d.y = coords[1]
          return d; 
      }))

    anionCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'anion piper ' + d.location.replace(/[\./ ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) {
          self.props.dispatch(setPiperLocation(d.location));
          // eslint-disable-next-line
          /*plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .attr('transform', 'translate(' + ((me.plotWidth/2) + middle_space + (w_excess/2) + (me.plotWidth/4)) + ',' + (me.plotHeight/4 + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location )
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr("dy", '1.1em')
                .text("Cl: " + (d.cl ) + '%')
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', 1.1 * 2 + 'em')
                .text('CO\u2083,HCO\u2083: ' + (d.carbs ) + '%')
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', ((1.1 * 3) + 0.1) + 'em')
                .text('SO\u2084: ' + (d.so4 ) + '%')  */
          // eslint-disable-next-line 
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
          
        })
        .on('mouseout', function(d) { 
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\./ ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', anionTip.show)
        //.on('mouseout', anionTip.hide);

    /*anionCircles.transition()
      .attr("cx", function (d) { return d.x; })
      .attr("cy", function (d) { return d.y; })
      .attr("r", 4);*/

    var cationCircles = ternaryCation.append("g").selectAll("circle")
      .data( this.state.filteredData.map( function(d) { 
          coords = coord([d.ca, d.nak, d.mg])
          d.x = coords[0]
          d.y = coords[1]
          return d; 
      }))

    cationCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'cation piper ' + d.location.replace(/[\./ ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) { 
          self.props.dispatch(setPiperLocation(d.location));
          //locTip.show(d);
          // eslint-disable-next-line
          /*plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .attr('transform', 'translate(' + ((me.plotWidth/2) + middle_space + (w_excess/2) + (me.plotWidth/4)) + ',' + (me.plotHeight/4 + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location )
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr("dy", '1.1em')
                .text("Ca: " + (d.ca ) + "%")
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', 1.1 * 2 + 'em')
                .text('Mg: ' + (d.mg ) + '%')
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', ((1.1 * 3) + 0.1) + 'em')
                .text('NaK: ' + (d.nak ) + '%') */ 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
          
        })
        .on('mouseout', function(d) { 
          //locTip.hide(d);
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\./ ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
        //.on('mouseover', cationTip.show)
        //.on('mouseout', cationTip.hide);       

    /*cationCircles.transition()
      .attr("cx", function (d) { return d.x; })
      .attr("cy", function (d) { return d.y; })
      .attr("r", 4);*/

    var diamondCircles = diamond.append("g").selectAll("circle")
      .data( this.state.filteredData.map( function(d) { 
          if(d.carbs < 50 || d.nak < 50) {
            coords = rev_dia_coord([(d.nak/2)/100, ((100-d.carbs)/2)/100, d.nak/100, d.carbs/100])
          } else {
            coords = dia_coord([(d.nak/2)/100, ((100-d.carbs)/2)/100, d.nak/100, d.carbs/100])
          }
          d.x = coords[0]
          d.y = coords[1]
          return d; 
      }))

    diamondCircles.enter()
      .append("circle")
      // eslint-disable-next-line
        .attr('class', function(d) { return 'diamond piper ' + d.location.replace(/[\./ ,:-]+/g, "-"); })
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; })
        .attr("r", function(d) {
          if(scale && radiusStat !== 'location') {
            return getRadius(d.stat);
          } else {
            return 2.5;
          }
        })
        .on('mouseover', function(d) { 
          self.props.dispatch(setPiperLocation(d.location));
          //console.log([d.location,d.location.replace(/[\. ,:-]+/g, "-")])
          // eslint-disable-next-line
          /*plotContainer.append("g").attr("class", "location-" + d.location.replace(/[\. ,:-]+/g, "-"))
            .attr('transform', 'translate(' + ((me.plotWidth/2) + middle_space + (w_excess/2) + (me.plotWidth/4)) + ',' + (me.plotHeight/4 + (h_excess/2)) + ')')
            .append("text")
              .attr('text-anchor','start')
              .text( "Location: " + d.location)
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr("dy", '1.1em')
                .text("Ca: " + (d.ca ) + "%.......Cl: " + (d.cl ) + '%')
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', 1.1 * 2 + 'em')
                .text('Mg: ' + (d.mg ) + '%.......CO\u2083,HCO\u2083: ' + (d.carbs ) + '%')
              .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', ((1.1 * 3) + 0.1) + 'em')
                .text('NaK: ' + (d.nak ) + '%.......SO\u2084: ' + (d.so4 ) + '%')  */

          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".diamond").classed('diamond', false).classed('piper-highlighted-diamond', true).raise();
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".cation").classed('cation', false).classed('piper-highlighted-cation', true).raise(); 
          // eslint-disable-next-line
          d3.selectAll('.' + d.location.replace(/[\./ ,:-]+/g, "-")).filter(".anion").classed('anion', false).classed('piper-highlighted-anion', true).raise(); 
          
        })
        .on('mouseout', function(d) { 
          // eslint-disable-next-line
          d3.selectAll(".location-" + d.location.replace(/[\./ ,:-]+/g, "-")).remove();
          d3.selectAll('.piper-highlighted-diamond').classed('piper-highlighted-diamond', false).classed('diamond', true);
          d3.selectAll('.piper-highlighted-cation').classed('piper-highlighted-cation', false).classed('cation', true);
          d3.selectAll('.piper-highlighted-anion').classed('piper-highlighted-anion', false).classed('anion', true);
          self.props.dispatch(setPiperLocation(null));
        });
  }
  
  render(){
    //const {filteredData} = this.state;
    return (
      <svg ref='piperPlot' width={this.props.size[0]} height={this.props.size[1]} ></svg>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.PiperApp.get('data'),
    size: state.PiperApp.get('piperSize'),
    scale: state.PiperApp.get('scalePoints'),
    domain: state.PiperApp.get('domain'),
    radius: state.PiperApp.get('radius')
  }
}

const PiperPlot = connect(mapStateToProps)(PiperPlotComp)

export default PiperPlot;