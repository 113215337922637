import React, { Component } from 'react';
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { hideGauge } from '../../actions/map';
import 'font-awesome/css/font-awesome.css';
import '../../css/gaugePlot.css';

//class GaugeToolComp extends Component {
class GaugeTool extends Component {

  componentDidMount() {
    this.renderPlot();
  }

  componentDidUpdate(){
    this.renderPlot();
  }

  renderPlot() {

    d3.selectAll('.gaugePlotContainer').remove();

    var plotData = this.props.data.filter(datum => datum.get('show'))

    var loopSize = plotData.size;

    var svg = d3.select(this.refs.gaugePlot);

    var minHeight = 250;
    var maxHeight = 350;
    var plotHeight = (this.props.height < minHeight ? minHeight : this.props.height);
    plotHeight = (this.props.height > maxHeight ? maxHeight : this.props.height);

    var minWidth = 350;
    var plotWidth = (this.props.width < minWidth ? minWidth : this.props.width)

    var yAxisWidth = 45;
    var xWidth = plotWidth - (yAxisWidth + 15);
    var titleHeight = 20;
    var xAxisHeight = 25;
    var yHeight = plotHeight - (titleHeight + xAxisHeight);

    var formatTime = d3.timeFormat("%m/%d/%Y");

    var plot, json, data, id, name, datum, tip, flowTitle, flowGraph, flowYAxis, xscale, yscale, flowLine, X, Y, closeButton;
    var self;

    var xFlowLineFx = function(d) {
      return xscale(d[0]);
    }

    var yFlowLineFx = function(d) {
      return yscale(d[1]);
    }

    var closeClickFx = function() {
      self.props.dispatch(hideGauge(id.toString()));
    }

    var cxFx = function(d) {
      return xscale(d[0]);
    }

    var cyFx = function(d) {
      return yscale(d[1]);
    }

    var mouseOverFx = function(d) {
      return tip.show(d, this);
    }

    var mouseOutFx = function(d) {
      return tip.hide(d);
    }

    for (var i = 0; i < loopSize; i++) {

      self = this;

      data = plotData.get(i);
      id = data.get('id');
      name = data.get('name');
      datum = data.get('datum');

      //var preRef = 'http://ida.water.usgs.gov/ida/available_records.cfm?sn=0' + id;
      //var postRef = 'http://waterservices.usgs.gov/nwis/iv/?format=rdb,1.0&sites=0' + id + '&parameterCd=00060,00065';
      plot = svg.append('g').attr('class','gaugePlotContainer')
                .style('height', plotHeight).style('width', plotWidth)
                .attr('transform', 'translate(0,' + (i * plotHeight) + ')')
          //.html('<span class="closeHTML" onclick="this.parentNode.parentNode.removeChild(this.parentNode); reducePanelHeight(); return false;">x</span><b>Gauge:</b> ' + 'tmp' + ' (0' + id + ')' +
          //'<br />Datum:</b> ' + feature_properties_Gage_dtm_f + ' (ft)  <br /><div style="width:100%;height:250px;position:relative;display:inline-block;"><div class="' + axisRef + '" style="width:45px;height:250px;background-color:white;float:left;"><svg style="position:relative;width:100%;height:100%;"></svg></div><div class="gaugeGraph" id="' + graphRef + '"><svg style="position:absolute;width:100%;height:100%;"></svg></div></div>' +
          //'<br/><span style:"float:left;" >Download 15-minute data: <a href="' + preRef + '" target="_blank">Pre-2007</a>  --  <a href="' + postRef + '" target="_blank">Post-2007</a></span>');

      json = data.get('data').get(0).toJS();
      tip = d3Tip()
         .attr('class', 'd3-tip')
         .offset([-10, 0])
         .html((d) => "<span style='color:grey'>" + d[1] + " (cfs)</br>" + formatTime(d[0]) + "</span>" )
      flowTitle = plot.append('g').style('width', xWidth).style('height', titleHeight).attr('transform', "translate(" + (yAxisWidth + 5) + "," + (titleHeight + 5) + ")");
      flowTitle.append("text").attr('dy', '0em').style("text-anchor", "start").text(name);
      flowTitle.append("text").attr('dy', '1em').style('text-anchor', 'start').text('(0' + id + ')');
      flowTitle.append("text").attr('dy', '2em').style('text-anchor', 'start').text('Datum: ' + datum + ' ft.');
      flowGraph = plot.append('g').style('width', xWidth).style('height', yHeight);
      flowYAxis = plot.append('g').style('width', yAxisWidth).style('height', yHeight);
      xscale = d3.scaleTime().domain([json.date[0], json.date[(json.date.length - 1)]]).rangeRound([0, xWidth]);
      yscale = d3.scaleLinear().domain(d3.extent(json.flow)).range([yHeight, 0]);
      flowLine = d3.line().x(xFlowLineFx).y(yFlowLineFx);
      X = d3.axisBottom(xscale).tickFormat(d3.timeFormat("%Y")).ticks(8);
      Y = d3.axisLeft(yscale).tickFormat(d3.format(",.2s"));
      flowGraph.append("path").attr('transform', "translate(" + yAxisWidth + "," + titleHeight + ")").attr('class', 'line').attr("d", flowLine(d3.zip(json.date, json.flow))).attr("class", 'gaugeLine').attr("stroke-width", 1);
      flowGraph.selectAll("circle").data(d3.zip(json.date, json.flow)).enter().append("circle").attr('transform', "translate(" + yAxisWidth + "," + titleHeight + ")").style('pointer-events','all').attr('class', 'gaugeCircle').call(tip).attr('fill','none').attr('r',2).attr("stroke", 'none').attr('cy', cyFx).attr('cx', cxFx).on("mouseover", mouseOverFx).on("mouseout", mouseOutFx);
      plot.append('g').style('width', xWidth).style('height', xAxisHeight).attr("class", "x axis").attr("transform", "translate(" + yAxisWidth + "," + (yHeight + titleHeight) + ")").call(X);
      flowYAxis.append('g').attr('class', 'timey axis').attr('transform', "translate(" + yAxisWidth + "," + titleHeight + ")").call(Y);
      flowYAxis.append("text").attr("class", 'hoverboxh1').attr('transform', "translate(10," + (titleHeight + (yHeight / 2)) + ") rotate(-90)").style("text-anchor","middle").text("Mean Monthly Discharge (cfs)");
      closeButton = plot.append('g').attr('transform', 'translate(' + ((xWidth + yAxisWidth) - 5) + ',' + (titleHeight + 5) + ')')
      closeButton.append('text').attr('text-size', '0.5em').attr('text-anchor', 'end').attr('text-decoration', 'underline').attr('class', 'close-text').text('close').on('click', closeClickFx)
    }
  }

  render() {
    var minWidth = 350;
    var plotWidth = (this.props.width < minWidth ? minWidth : this.props.width)

    var minHeight = 250;
    var maxHeight = 350;
    var plotHeight = (this.props.height < minHeight ? minHeight : this.props.height)
    plotHeight = (this.props.height > maxHeight ? maxHeight : this.props.height)
    var totalHeight = (this.props.data.filter(datum => datum.get('show')).size) * plotHeight;

    return (
      <svg ref='gaugePlot' width={plotWidth} height={totalHeight}></svg>
    )
  }
}

export default GaugeTool;
