import { Map } from 'immutable';
import * as d3 from 'd3';

/* * * * * * * * * Stats Calculator * * * * * * * * */

export const calcStats = (data) => {
    console.log('calculationg calcStats in mapHelper.js reducer')
    var locations = data.groupBy( (row) => row.get('location')).map((row, index) => {

      let results = row.map((row) => row.get('result') ? parseFloat(row.get('result')): null);
      let resultsNoNulls = results.filter((num) => num !== null);

      let resultsJS = results.toJS();

      let mean = d3.mean(resultsJS);
      let median = d3.median(resultsJS);
      let max = d3.max(resultsJS);
      let min = d3.min(resultsJS);

      return Map({min: min, max: max, mean: mean, median: median, sampleSize: resultsNoNulls.size});
    }).filter( (row) => row.get('max'));

  return locations;

}

export const getAnalytes = (state, action) => {
  console.log('running getAnalytes in mapHelper.js reducer')
  let analytes = state.get('analytes').map((row) => {
    return Map({'label': row.get('analyte'),'value': row.get('analyte'), 'type': row.get('type'), 'subtype': row.get('subtype') });
  });//.toSet();//BOOM! toSet() gets all distinct values. HOLLA!

  return state.set('analytes',analytes);

}

export const parseDate = d3.timeParse("%Y-%m-%d");
/*
 * NOTE: Returns the results sorted by date for ease of plotting.
 */
export const locResults = (state, action) => {
  console.log('calculating locResults in mapHelper.js reducer')
  let results = state.get('filteredData').filter((row) => row.get('location') === action.location)
    .map((row) => Map({'result':parseFloat(row.get('result')), 'date':row.get('sample_date'), 'sample_bottom_depth':parseFloat(row.get('sample_bottom_depth')), 'sample_top_depth':parseFloat(row.get('sample_top_depth')),'detect': (['F','U','N'].indexOf(row.get('detect')) < 0 ? '' : '<')}))
    .sort((a,b) => {
      let aDate = parseDate(a.get('date'));
      let bDate = parseDate(b.get('date'));
      return aDate > bDate ? -1 : 1
  })
  return state.set('locResults', results).set('showInfoLegend', results.size > 0);
}

export const locStaticResults = (state, action) => {
  let results = state.get('analyteData').filter((row) =>
    state.get('activeFilters').get('matrix') &&
    state.get('activeFilters').get('unit') &&
    row.get('location') === action.location &&
    row.get('matrix') === state.get('activeFilters').get('matrix').get('filters').get(0) &&
    row.get('unit') === state.get('activeFilters').get('unit').get('filters').get(0)
  ).map((row) => Map({'result':parseFloat(row.get('result')), 'date':row.get('sample_date'), 'sample_bottom_depth':parseFloat(row.get('sample_bottom_depth')), 'sample_top_depth':parseFloat(row.get('sample_top_depth')), 'detect': (['F','U','N'].indexOf(row.get('detect')) < 0 ? '' : '<')}))
      .sort((a,b) => {
        let aDate = parseDate(a.get('date'));
        let bDate = parseDate(b.get('date'));
        return aDate > bDate ? -1 : 1
      })
  return state.set('locStaticResults', results);
}

export const format = (number) => {
  var form = "";
  number = parseFloat(number)

  switch (true){
    case (number >= 10000 || number%1 === 0):
      form = ".2s";
    break
    case (number < 10000 && number >= 100):
      form = ".5r";
    break
    case (number < 100 && number >= .001):
      form = ".3r";
    break
    case (number < .001):
      form = ".0e"
    break
    default:

  }
  return d3.format(form)(number);
}
